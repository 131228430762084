import React, {useEffect} from 'react';
import { gql, useQuery } from '@apollo/client';
//material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
//components
import NotesAndImages from './NotesAndImages';
import NewDoorHardware from './NewDoorHardware';
import NewDoorTrim from './NewDoorTrim';
import SpecsType from '../Specs/SpecsType';
import AttributeSelect from '../AttributeSelect';
import * as Lib from '../lib/lib';
import CustomToggleGroup from '../../common/CustomToggleGroup'
import AdditionalItem from './AdditionalItem';
import MeasureUnits from './MeasureUnits'
import InlineEdit from '../../common/InlineEdit'

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper:{
        padding:'16px',
    },
    group:{
        flexDirection: 'row',
    },
    chip: {
        margin: theme.spacing(1),
    },
    button: {
        // margin: theme.spacing(1),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
}));


const BIFOLD_QUERY = gql`
    query productGroups($field: String!, $condition: Condition) {
        productGroups(field: $field,condition: $condition) {
            _id
        }
    }
`;

const NewDoors = (props) => {
    const { context } = props;

    useEffect(() => {
        if (
            context.state.editDoorIndex === -1 ||
            !('doorFunction' in context.state.measures.door) ||
            context.state.measures.door.doorFunction === ''
        ) {
            context.copySpecs(
                'specs',
                'specialSpecs',
                'door',
                null,
                context.state.editDoorIndex === -1 ? null : context.state.doors[context.state.editDoorIndex].attributes
            );
        } else {
            context.copySpecs(
                'specs',
                'specialSpecs',
                'door',
                null,
                context.state.doors[context.state.editDoorIndex].attributes
            );
        }
    }, []);

    useEffect(() => {
        //TODO: fix
        if (
            context.state.addDoorDialog &&
            context.state.editDoorIndex === -1 &&
            (
                !('itemIndex' in context.state.measures.door) ||
                context.state.measures.door.itemIndex === 0
            )
        ) {
            const maxIndex = Math.max(
                    ...context.state.doors
                        .filter(o => o)
                        .map(o => 'itemIndex' in o ? o.itemIndex : 0)
                , 0);
            // console.log(context.state.doors.length, maxIndex);
            context.setMeasureDoorAttr('itemIndex', maxIndex + 1)
            context.setMeasureDoorAttr('doorFunction', '')
        }
    }, [
        context.state.addDoorDialog, 
        context.state.editDoorIndex,
        context.state.measures.door,

    ]);

    const handleSpecChange =  ({specs, copy, propagate}) => {
        context.saveSpecs({
            scope: 'specialSpecs',
            category: 'door',
            specs,
            copy,
            propagate
        });
    };

    const handleChange = (field, value) => {
        context.setMeasureDoorAttr(field, value)
    };

    const handleDeleteImage = (index) => {
        context.deleteDoorImage(index)
    };

    const classes = useStyles();
    const door = context.state.measures.door;
    const disabled = context.isDoneTakeoff();
    const additionalItem = door.additionalItem
    const additionalItemObj = Object.keys(additionalItem).length === 0 && additionalItem.constructor === Object ? 
                                context.state.additionalOrder.additionalItem : additionalItem
    const {data} = useQuery(BIFOLD_QUERY, {
        variables: {
            field:          "bifold",
            condition: {
                category:   "Door",
                type:       context.state.specialSpecs.door.type      === '' ? context.state.specs.door.type      : context.state.specialSpecs.door.type,
                style:      context.state.specialSpecs.door.type      === '' ? context.state.specs.door.style     : context.state.specialSpecs.door.style,
                frame:      context.state.specialSpecs.door.type      === '' ? context.state.specs.door.frame     : context.state.specialSpecs.door.frame || '',
                core:       context.state.specialSpecs.door.type      === '' ? context.state.specs.door.core      : context.state.specialSpecs.door.core,
                thickness:  context.state.specialSpecs.door.type      === '' ? context.state.specs.door.thickness : context.state.specialSpecs.door.thickness,
                finish:  context.state.specialSpecs.door.type      === '' ? context.state.specs.door.finish : context.state.specialSpecs.door.finish,
                material:  context.state.specialSpecs.door.type      === '' ? context.state.specs.door.material : context.state.specialSpecs.door.material,
                sticking:  context.state.specialSpecs.door.type      === '' ? context.state.specs.door.sticking : context.state.specialSpecs.door.sticking,
                panelProfile:  context.state.specialSpecs.door.type      === '' ? context.state.specs.door.panelProfile : context.state.specialSpecs.door.panelProfile,
                width:      door.width
            }
        }
    })
    const enabled = data?.productGroups && data.productGroups.some((e)=> e && e._id === 'true')
    if (enabled === false && door.isBifold) 
        context.setMeasureDoorAttr('isBifold', false)
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>
                        Door{ " " }
                        <InlineEdit
                            text={ door.title || door.itemIndex ||  "" }
                            onSetText={ value => handleChange('title', value)}
                        />
                    </Typography>
                </Grid>
                {
                    context.state.isAdditionalOrder &&
                    <AdditionalItem 
                        additionalItem={additionalItemObj}
                        disabled={disabled}
                        onItemChange={(value)=> {
                            handleChange('additionalItem', value)
                        }}
                    />
                }
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AttributeSelect
                                    disabled={disabled}
                                    field={"width"}
                                    category={"door"}
                                    title={"Width"}
                                    hideDelete
                                    value={door.width}
                                    handleChange={(event, value) => context.setMeasureDoorAttr('width', event ? event.target.value : value ? value : '')}
                                    refSpec={context.state.specialSpecs.door}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SpecsType
                                    disabled={disabled}
                                    category={'door'}
                                    attributes={['supplier', 'type', 'height', 'style', 'thickness', 'core', 'finish', 'material', 'panelProfile', 'sticking']}
                                    handleChange={handleSpecChange}
                                    specs={context.state.specialSpecs.door}
                                    globalSpecs={context.state.specs.door}
                                />
                            </Grid>
                        </Grid>
                        {
                            Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs) !== 'Utility' &&
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    {
                                        data &&
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={door.isBifold}
                                                    disabled={disabled || !enabled}
                                                    onChange={(event) => context.setState({
                                                        measures: {
                                                            ...context.state.measures,
                                                            door: {
                                                                ...door,
                                                                isBifold: event.target.checked,
                                                                doorFunction: event.target.checked ? 'bifold' : '',
                                                                handleDirection: '',
                                                                handleType: '',
                                                                handleMode: '',
                                                                hasHardware: false,
                                                                latching: '',
                                                                leftHandleMode: '',
                                                                rightHandleMode: '',
                                                                operating: '',
                                                            }
                                                        }
                                                    })}
                                                />
                                            }
                                            label="Bifold door"
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={disabled}
                                                checked={door.isDouble}
                                                onChange={(event) => {
                                                    return context.setState({
                                                        measures: {
                                                            ...context.state.measures,
                                                            door: {
                                                                ...door,
                                                                isDouble: event.target.checked,
                                                                doorFunction: '',
                                                                handleDirection: '',
                                                                handleType: '',
                                                                handleMode: '',
                                                                hasHardware: false,
                                                                latching: '',
                                                                leftHandleMode: '',
                                                                rightHandleMode: '',
                                                                operating: '',
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                        }
                                        label="Double door"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={disabled}
                                                checked={door.hasCutdown}
                                                onChange={(event) => {
                                                    return context.setState({
                                                        measures: {
                                                            ...context.state.measures,
                                                            door: {
                                                                ...door,
                                                                hasCutdown: event.target.checked,
                                                                cutdownHeight: event.target.checked ? door.cutdownHeight : 0,
                                                                ...(
                                                                    !door.cutdownLocation &&
                                                                    {
                                                                    cutdownLocation: Lib.deepAttributeExists(['cutdown'], context.state.specs.door_treatment) ?
                                                                        context.state.specs.door_treatment.cutdown : '',
                                                                    }
                                                                ),
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                        }
                                        label="Cutdown Height"

                                    />
                                </Grid>
                                {
                                    door.hasCutdown &&
                                    <>
                                        {
                                            // (
                                            //     context.state.specs.door_treatment.prep === 'machined' ||
                                            //     context.state.installPricingMode === 'contract_pricing'
                                            // ) &&
                                            <Grid item xs={12}>
                                                <CustomToggleGroup
                                                    disabled={disabled}
                                                    items={{
                                                        'onsite': 'On Site',
                                                        'inshop': 'In Shop'
                                                    }}
                                                    value={
                                                        door.cutdownLocation || ''
                                                    }
                                                    handleChange={ (event, value) => {
                                                        context.setMeasureDoorAttr('cutdownLocation', value || '');
                                                    }}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                disabled={disabled}
                                                id="new-trim-length"
                                                label={`New Height`}
                                                className={classes.textField}
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                                type={"number"}
                                                value={door.cutdownHeight || ""}
                                                onChange={ (event) => context.setMeasureDoorAttr('cutdownHeight', event.target.value)}
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={disabled}
                                                checked={door.hasExtraService}
                                                onChange={(event) => {
                                                    return context.setState({
                                                        measures: {
                                                            ...context.state.measures,
                                                            door: {
                                                                ...door,
                                                                hasExtraService: event.target.checked,
                                                                extraService: event.target.checked ? door.extraService : 0
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                        }
                                        label="Add Extra Service"

                                    />
                                </Grid>
                                {
                                    door.hasExtraService &&
                                    <Grid item xs={12}>
                                        <CustomToggleGroup
                                            disabled={disabled}
                                            items={{
                                                '1': 'One',
                                                '2': 'Two',
                                                '3': 'Three',
                                                '4': 'Four',
                                            }}
                                            value={`${door.extraService}`}
                                            handleChange={(event, value) => context.setMeasureDoorAttr('extraService', value || 1)}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <MeasureUnits
                                disabled={disabled}
                                value={door.unitNumber || ""}
                                handleChange={event => context.setMeasureDoorAttr('unitNumber', event.target.value)}
                            />
                        </Grid>

                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <NewDoorHardware context={context} />
                </Grid>
                {
                    Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs) !== 'Utility' &&
                    <Grid item xs={12}>
                        <NewDoorTrim context={context} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <NotesAndImages
                        disabled={disabled}
                        category={"doors"}
                        notes={door.notes}
                        images={ door.images }
                        handleChange={handleChange}
                        handleDeleteImage={handleDeleteImage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}



export default NewDoors