import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ApolloConsumer } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
//components
import TopBarUserBack from './common/TopBarUserBack';
import UserForm from './NewUser/UserForm';
import UserFormProvider from './UserFormProvider';
import NotAuthorized from './NotAuthorized';

const useStyles = makeStyles(() => ({
    root:{

    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

const NewUser = () => {
    const classes = useStyles();
    return (
        <ApolloConsumer>
            {
                apolloClient => {
                    return (
                        <UserFormProvider apolloClient={apolloClient}>
                            <Helmet>
                                <title>Riverside Takeoffs :: New User</title>
                            </Helmet>
                            <div className={classes.root}>
                                <TopBarUserBack title="New User"/>
                                {
                                    localStorage.getItem('role') === 'admin' ?
                                        <UserForm /> :
                                        <NotAuthorized />
                                }
                            </div>
                        </UserFormProvider>
                    )
                }
            }
        </ApolloConsumer>
    )
}

export default NewUser