import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Snackbar from '@material-ui/core/Snackbar';
import classNames from 'classnames';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//components
import {TakeoffFormContext} from '../TakeoffFormContext'
import CustomerInfo from './CustomerInfo';
import SpecsTabs from './SpecsTabs';
import Measure from './Measure';
import Summary from './Summary';
import TopBarBack from '../common/TopBarBack';
import LoadingModal from '../common/LoadingModal';
import WarningIcon from '@material-ui/icons/Warning';
import { deepAttributeExists } from './lib/lib';
// import * as Lib from "./lib/lib";

// import OfflineDetector from "../OfflineDetector"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop:'104px',
        width: '100%',
    },
    takeoffTabs:{
        position:'fixed',
        top:'56px',
        backgroundColor: theme.palette.primary.dark,
    },
    flex: {
        flex: 1,
    },

    "@media (min-width: 0px) and (orientation: landscape)":{
        root:{
            marginTop: '96px',
        },
        takeoffTabs:{
            top:'48px',
        },
    },
    "@media (min-width: 600px)":{
        root:{
            marginTop: '112px',
        },
        takeoffTabs:{
            top:'64px',
        },
    },
    "@media print": {
        root: {
            marginTop: 0
        }
    },
    hideOnPrint:{
        '@media print' : {
            display: 'none'
        }
    },
    progress: {
        margin: theme.spacing(2),
    },
}));

const TakeoffTabs = () => {
    const classes = useStyles();
    const context = useContext(TakeoffFormContext);
    const pricingTier = context.state.customerType ? deepAttributeExists(['pricingTier'], context.state[context.state.customerType]) : 'gb1';
    const {cpu, status}   = context.state;
    const base = context.state.takeoffEditId ? `/updateTakeoff/${context.state.takeoffEditId}` : '/newTakeoff';
    const handleChangeTab = (tab) => {
        context.setPrimaryTab(tab);
    }
    // console.log(cpu)
    return (
        <div className={classes.root}>
            {/* <OfflineDetector /> */}
            <TopBarBack
                disabled={context.isDoneTakeoff()}
                status={status}
                title={`${context.state.takeoffEditId ? "Update" : "New"} Takeoff`}
                takeoffEditId={context.state.takeoffEditId || ''}
                username={context.state.creator ? context.state.creator : ''}
                code={context.state.code || ''}
                pricingTier={pricingTier}
            />
            <AppBar className={classNames(classes.takeoffTabs, classes.hideOnPrint)} color='primary' elevation={0}>
                <Router basename={base} >
                    <Tabs
                        value={context.state.primaryTab}
                        onChange={(event, value) => {
                            handleChangeTab(value)
                            // context.setState({primaryTab: value})
                        }}
                        centered
                        variant="fullWidth"
                    >
                        <Tab label="Customer" value='customer'  />
                        <Tab label="Specs" value='specs'  />
                        <Tab 
                            label={<div>{cpu?.discontinued?.length > 0 && <WarningIcon style = { {verticalAlign : 'middle'} } /> }Measure</div> } 
                            value='measure' 
                        />
                        <Tab label="Summary" value='summary'  />
                    </Tabs>
                </Router>
            </AppBar>
            <Switch>
                <Route path={`${base}/customer`} render={() => <CustomerInfo context={context} />} />
                <Route path={`${base}/specs`} render={() => <SpecsTabs context={context} />} />
                <Route path={`${base}/measure`} render={() => <Measure context={context} />} />
                <Route path={`${base}/summary`} render={() => <Summary
                    doors={context.state.doors}
                    windows={context.state.windows}
                    trim={context.state.trim}
                    hardware={context.state.hardware}
                    shelving={context.state.shelving}
                    context={context}
                />} />
            </Switch>
            <LoadingModal
                open={context.state.loadingModal}
                error={context.state.error}
                handleClose={()=>context.setState({loadingModal: false})}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={4000}
                open={context.state.takeoffSaved}
                onClose={ () => context.setState({takeoffSaved : false})}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Takeoff saved!</span>}
            />

        </div>
    )
}

export default TakeoffTabs
