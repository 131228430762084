import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Slide from '@material-ui/core/Slide';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

import {Translator} from '../lib/Translator';
import MeasureTabs from './MeasureTabs';
import * as Lib from "../lib/lib";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    flex: {
        flex: 1,
    },
    margin: {
        marginRight: '8px',
        color: theme.palette.primary.dark
    }
}));

const ValidationDialog = ({validation = {}, show, handleClose}) => {
    const {issues = [], warning = []} = validation || {};
    const list = (
        <div style={{width: 'auto', maxHeight: '75vh'}}>
            {
                issues.length > 0 &&
                <List subheader={<ListSubheader component="div">Required Items</ListSubheader>} >
                    {
                        issues.map((issue, i) => (
                            <ListItem button key={i}>
                                <ListItemText primary={Translator(issue)} />
                            </ListItem>
                        ))
                    }
                </List>
            }
            {
                warning.length > 0 &&
                <>
                    <Divider />
                    <List subheader={<ListSubheader component="div">Optional Items</ListSubheader>}>
                        {
                            warning.map((warn, i) => (
                                <ListItem button key={i}>
                                    <ListItemText primary={Translator(warn)} />
                                </ListItem>
                            ))
                        }
                    </List>
                </>
            }
        </div>
    )
    return (
        <Drawer anchor="bottom" open={show} onClose={handleClose}>
            <div
            tabIndex={0}
            role="button"
            onClick={handleClose}
            onKeyDown={handleClose}
            >
                {list}
            </div>
        </Drawer>
    )
}

const AddProductDialog  = ({ context }) => {
    const classes = useStyles();
    const isOpen = context.state.addDoorDialog;
    const [buttonClicked, setButonClicked] = useState('');

    const [vOpen, setVOpen] = useState(false);
    const handleClose = () => {
        setVOpen(false);
        setButonClicked('');
    }

    useEffect(() => {
        if (!isOpen)
            setButonClicked('');
    },[isOpen]);

    const state = context.state;

    const validation = validateForm(state);
    useEffect(() => {
        if (buttonClicked === 'warning' &&
            validation &&
            (validation.warning.length > 0 || validation.issues.length > 0)
        ) {
            setVOpen(true);
        } else if (buttonClicked === 'submit' && validation && validation.issues.length > 0) {
            setVOpen(true);
        } else if (buttonClicked === 'submit') {
            if (state.measureTab === 'door') {
                let attributes = {
                    category:    "Door",
                    type:       state.specialSpecs.door.type   === '' ? state.specs.door.type  : state.specialSpecs.door.type,
                    style:      state.specialSpecs.door.type   === '' ? state.specs.door.style     : state.specialSpecs.door.style,
                    supplier:   state.specialSpecs.door.type   === '' ? state.specs.door.supplier  : state.specialSpecs.door.supplier,
                    core:       state.specialSpecs.door.type   === '' ? state.specs.door.core      : state.specialSpecs.door.core,
                    thickness:  state.specialSpecs.door.type   === '' ? state.specs.door.thickness : state.specialSpecs.door.thickness,
                    height:     state.specialSpecs.door.type   === '' ? state.specs.door.height    : state.specialSpecs.door.height,
                    frame:      state.specialSpecs.door.type   === '' ? state.specs.door.frame     : state.specialSpecs.door.frame,
                    finish:     state.specialSpecs.door.type   === '' ? state.specs.door.finish    : state.specialSpecs.door.finish,
                    material:   state.specialSpecs.door.type   === '' ? state.specs.door.material  : state.specialSpecs.door.material,
                    sticking:   state.specialSpecs.door.type   === '' ? state.specs.door.sticking  : state.specialSpecs.door.sticking,
                    panelProfile: state.specialSpecs.door.type === '' ? state.specs.door.panelProfile : state.specialSpecs.door.panelProfile,
                    width:      state.measures.door.width,
                    bifold:     state.specialSpecs.door.type   !== 'Utility' && state.measures.door.isBifold
                };
                let doorPromise = context.getAnyProduct( attributes );
                doorPromise.then( door => {
                    if (door) {
                        context.addMeasureDoor(door);
                    }
                })
            }
            else if (state.measureTab === 'trim') {
                context.addMeasureTrim(context.state.measures.custom.trim.type);
            }
            else if (state.measureTab === 'hardware') {
                context.addMeasureHardware(context.state.measures.custom.hardware.type);
            }
            else if (state.measureTab === 'shelving') {
                //console.log('Add Shelving...');
                context.addShelving();
            }
            else if (state.measureTab === 'window') {
                context.addOpening(context.state.measures.custom.window.type);
            }
            else if (state.measureTab === 'custom_product') {
                context.addCustomProduct();
            }
            else if (state.measureTab === 'custom_install_service') {
                context.addCustomInstall();
            }
        }
    },[buttonClicked]);

    const handleClick = (type) => {
        setButonClicked(type);
        // setValidation(validateForm(state));
    }
    return (
        <div>
            <Dialog
                fullScreen
                open={state.addDoorDialog}
                onClose={() => context.closeDoorDialog()}
                TransitionComponent={Transition}
            >
                <AppBar elevation={0} color="secondary" position="sticky">
                    <Toolbar>
                        <IconButton
                            className={classes.closeButton}
                            color="inherit"
                            onClick={() => context.closeDoorDialog()}
                            aria-label="Close"
                        >
                            <Icon>close</Icon>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            {
                                (
                                    state.editDoorIndex     !== -1 ||
                                    state.editTrimIndex     !== -1 ||
                                    state.editHardwareIndex !== -1 ||
                                    state.editShelvingIndex !== -1 ||
                                    state.editWindowIndex   !== -1
                                )
                                ?
                                'Update ' : 'Add '}
                            {context.humanize(context.state.measureTab === 'window' ? 'Openings' : context.state.measureTab)}
                        </Typography>
                        <ValidationDialog
                            validation={validation}
                            show={vOpen}
                            handleClose={handleClose}
                        />
                        <div>
                            {
                                (!validation || validation.warning.length > 0 || validation.issues.length > 0 ) &&
                                <IconButton
                                    disableRipple= {true}
                                    aria-label="Warning"
                                    className={classes.margin}
                                    onClick={() => handleClick('warning')}
                                >
                                    {
                                        !validation ||
                                        validation.issues.length > 0 ?
                                            <ErrorIcon /> : <WarningIcon />
                                    }
                                </IconButton>
                            }
                            <Button
                                disabled={
                                    context.isDoneTakeoff() ||
                                    (buttonClicked !== '' && Lib.deepAttributeExists(['issues', 0], validation) != null)
                                }
                                onClick={() => handleClick('submit')}
                                variant="contained"
                                color="default"
                            >
                                {
                                    state.editDoorIndex     !== -1 ||
                                    state.editTrimIndex     !== -1 ||
                                    state.editHardwareIndex !== -1 ||
                                    state.editShelvingIndex !== -1 ||
                                    state.editHardwareIndex !== -1 ||
                                    state.editWindowIndex   !== -1
                                        ?
                                        "UPDATE" : "ADD"}
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
                <MeasureTabs context={context} />
            </Dialog>

        </div>

    )
}

const doorCutdownDiff = (door, specialSpecs) => {
    const height = Lib.deepAttributeExists(['door', 'height'], specialSpecs) || 0;
    const newHeight = door.cutdownHeight || 0;
    return parseInt(height) - parseInt(newHeight)
}

const validateForm = (state) => {
    const {measureTab, specialSpecs, isAdditionalOrder} = state;

    const object    = state.measureTab === 'door' ? state.measures.door : state.measures.custom[state.measureTab];
    const warning   = [];
    const issues    = [];

    if(isAdditionalOrder) {
        if (
            !Lib.deepAttributeExists(['additionalItem'], object) ||
            !Lib.deepAttributeExists(['additionalItem', 'bill'], object) ||
            !Lib.deepAttributeExists(['additionalItem', 'po_required'], object) 
        ) 
            issues.push('MISSING_ADDITIONAL_ITEM_INFO');
        if (
            !Lib.deepAttributeExists(['additionalItem', 'labour'], object)
        ) warning.push('MISSING_ADDITIONAL_ITEM_LABOUR_BY');
    }
    if (measureTab === 'door') {
        if (Lib.isEmptyOrNullObject(specialSpecs.door)) issues.push('MISSING_DOOR_SPECS');
        else if (!Lib.deepAttributeExists(['width'], object)) issues.push('MISSING_DOOR_WIDTH');
        // if (object.hasCutdown && !object.cutdownLocation) issues.push('MISSING_CUTDOWN_DOOR_Location');
        if (object.hasCutdown && !object.cutdownHeight) issues.push('MISSING_CUTDOWN_DOOR_HEIGHT');
        if (object.hasExtraService && !object.extraService) issues.push('MISSING_EXTRA_SERVICE_AMOUNT');
        else if (object.hasExtraService && !object.notes) issues.push('ADD_A_NOTE_TO_EXPLAIN_THE_REASON_FOR_EXTRA_INSTALL_SERVICE');
        else if (object.hasExtraService && object.notes.length < 10) issues.push('NOTE_IS_TOO_SHORT');

        // No 8' casing for 96" door
        if (
            Lib.deepAttributeExists(['casing', 'length'], specialSpecs.trim) === '8' &&
            specialSpecs.door.height === '96'
        ) issues.push('CASING_FOR_8FT_DOORS_MUST_BE_LONGER_THAN_8FT');

        if (!object.doorFunction) warning.push('MISSING_HARDWARE');
        else if (['swing', 'bifold'].includes(object.doorFunction)) {
            if (!object.isDouble && !object.handleDirection) issues.push('MISSING_SWING_DIRECTION');
            else if (!object.handleType) warning.push('MISSING_HANDLE');
            else if (object.handleType && Lib.isEmptyOrNullObject(specialSpecs.hardware[object.handleType])) issues.push('MISSING_'+object.handleType.toUpperCase()+'_SPECS');
            else if (object.handleType !== 'bifold_knob') {
                if (!object.isDouble && !object.handleMode) issues.push('MISSING_HANDLE_MODE');
                else if (object.isDouble && (!object.leftHandleMode || !object.rightHandleMode)) issues.push('MISSING_DOUBLE_HANDLE_MODE');
            }

            if (object.doorFunction === 'swing') {
                if (object.hasCutdown && doorCutdownDiff(object, specialSpecs) > 3) {
                    if (!object.hasCustomHinge) issues.push('MISSING_CUSTOM_HINGES_LOCATIONS');
                    if (!object.hasCustomLockset) issues.push('MISSING_CUSTOM_LOCKSET_LOCATION');
                }
                if (object.isDouble && !object.latching) issues.push('MISSING_LATCHING');
                else if (object.isDouble) {
                    if (object.latching === 'flushbolt' && !object.operating) issues.push('MISSING_OPERATING_DOOR');
                    if (object.latching && !Lib.deepAttributeExists([object.latching, 'code'], specialSpecs.products)) issues.push('MISSING_'+object.latching.toUpperCase()+'_SPECS');
                }

                if (Lib.isEmptyOrNullObject(specialSpecs.hardware.doorstopper)) warning.push('MISSING_DOORSTOPPER_SPECS');
                else if (!object.isDouble && !object.doorstopper_mode) issues.push('MISSING_DOORSTOPPER_MODE');
                else if (object.isDouble){
                    if (!object.doorstopper_mode_left ) issues.push('MISSING_LEFT_DOORSTOPPER_MODE');
                    if (!object.doorstopper_mode_right) issues.push('MISSING_RIGHT_DOORSTOPPER_MODE');
                }

                if (!Lib.deepAttributeExists(['hinge', 'code'], specialSpecs.products)) warning.push('MISSING_HINGE_SPECS');
                else if (!object.hingesPerDoor) issues.push('MISSING_HINGE_PER_DOOR');
                if (
                    object.hasCustomHinge &&
                    (!object.customHinges || object.customHinges.filter(a=>a).length < object.hingesPerDoor)
                ) issues.push('MISSING_HINGE_LOCATIONS')
                if (
                    object.hasCustomLockset &&
                    !object.customLockset 
                ) issues.push('MISSING_LOCKSET_LOCATION')
            }
        } else if (object.doorFunction === 'pocket') {
            if (Lib.isEmptyOrNullObject(specialSpecs.hardware.pocket)) issues.push('MISSING_POCKET_SPECS');
            else if (!object.pocket_mode) issues.push('MISSING_POCKET_HANDLE_MODE');
        } else if (object.doorFunction === 'barn') {
            if (!Lib.deepAttributeExists(['barn_door_track', 'code'], specialSpecs.products)) issues.push('MISSING_BARN_TRACK_SPECS');
        } else if (object.doorFunction === 'bypass') {
            if (
                Lib.isEmptyOrNullObject(specialSpecs.hardware.bipass_door_track) ||
                !object.bipassTrackLength 
            ) issues.push('MISSING_BIPASS_TRACK_SPECS');
            if (!Lib.deepAttributeExists(['finger_pull', 'code'], specialSpecs.products)) issues.push('MISSING_FINGER_PULL_SPECS');
        }
        if (Lib.deepAttributeExists(['door', 'type'], specialSpecs) !== 'Utility') {
            if (!object.materialType) warning.push('MISSING_JAMB');
            if (!Lib.deepAttributeExists(['casing', 'code'], specialSpecs.products)) warning.push('MISSING_CASING');
            if (!object.isBifold && object.doorFunction !== 'bypass' && !Lib.deepAttributeExists(['doorstop', 'code'], specialSpecs.products)) warning.push('MISSING_DOORSTOP');
            if (object.latching === 'flushbolt' && !Lib.deepAttributeExists(['burlap', 'code'], specialSpecs.products)) warning.push('MISSING_BURLAP');
            if (object.addBackband && !object.backbandSides) issues.push('MISSING_BACKBAND_SIDES');
            else if (object.addBackband && !Lib.deepAttributeExists(['backband', 'code'], specialSpecs.products))  issues.push('MISSING_BACKBAND_SPECS');
            if (object.addArchitrave && !object.architraveSides) issues.push('MISSING_ARCHITRAVE_SIDES');
            else if (object.addArchitrave && !Lib.deepAttributeExists(['architrave', 'code'], specialSpecs.products))  issues.push('MISSING_ARCHITRAVE_SPECS');
        }
    }
    else if (['trim', 'hardware'].includes(measureTab)) {
        if (object.type === '') issues.push('MISSING_TYPE');
        else if (Lib.isEmptyOrNullObject(specialSpecs[measureTab][object.type])) issues.push('MISSING_'+object.type.toUpperCase()+'_SPECS');
        else if (!Lib.deepAttributeExists(['products', object.type, 'code'], specialSpecs)) issues.push('INCOMPLETE_SPECS');
        else if (!Lib.deepAttributeExists(['count'], object)) issues.push('MISSING_QUANTITY');
    }
    else if (measureTab === 'shelving') {
        if (object.type === '') issues.push('MISSING_SHELF_TYPE');
        else if (['organizer', 'accessories'].includes(object.type)) { 
            if (object.type === 'organizer' && Lib.isEmptyOrNullObject(specialSpecs.shelving.organizer)) issues.push('MISSING_ORGANIZER_SPECS');
            if (object.type === 'accessories' && object.accessories.length === 0) issues.push('MISSING_ACCESSORIES');
            object.accessories.forEach((accessory, i) => {
                if (Lib.isEmptyOrNullObject(accessory.specs)) issues.push(`MISSING_ACCESSORY_${i+1}_SPECS`);
            })
            if(object.type === 'organizer') {
                // check for empty wall designs
                const dimensions = Object.keys(object.dimensions || {}).filter(d => !['', '0'].includes(object.dimensions[d]));
                if (!object.dimensions || dimensions.length < 3) issues.push('MISSING_DIMENSIONS');
                else {
                    Object.keys(object.dimensions).forEach((dimension, i) => {
                        if (object.dimensions[dimension] === '') issues.push(`MISSING_ORGANIZER_${dimension}`)
                    })
                }
                let emptyDesigns = true;
                let emptyWidths = true;
                let totalWidth = Object.keys(object.dimensions || {})
                                    .filter(a => a.includes('width') && a !== 'width_6')
                                    .reduce((acc, wall) => acc + Number(object.dimensions[wall]), 0);
                let allDesignWidths = 0;
                Object.keys(object.wallDesigns || {}).forEach((wall, i) => {
                    let wallWidths = 0
                    object.wallDesigns[wall]?.designs?.filter(a => a).map((wallDesign, j) => {
                        if (wallDesign.design !== '') emptyDesigns = false;
                        else issues.push(`MISSING_WALL_${i+1}_DESIGN_${j+1}`);

                        if (wallDesign.width !== '' || wallDesign.width !== 0) emptyWidths = false;
                        else issues.push(`MISSING_WALL_${i+1}_WIDTH_${j+1}`);

                        wallWidths += Number(wallDesign.width);
                        allDesignWidths += Number(wallDesign.width);
                    });
                    // console.log(`wall_width_${i+1}`, wallWidths, object.dimensions);
                    if (object.layout !== 'reach_in' && object.dimensions?.[`width_${i+1}`] < (wallWidths + 0.5 + (object.wallDesigns[wall].hasJoiner ? 24 : 0) )) 
                        issues.push(`WALL_${i+1}_Organizers_WIDTH_EXCEEDS_WALL_WIDTH`);
                });
                if ( object.layout !== 'reach_in' && totalWidth < allDesignWidths + 0.5 ) issues.push('WALL_DESIGNS_EXCEEDS_TOTAL_WIDTH');
                if ( emptyDesigns ) issues.push('MISSING_WALL_DESIGNS');
                if ( emptyWidths ) issues.push('MISSING_WALL_WIDTHS');
            }
        } else {
            if (object.type === 'wire' && Lib.isEmptyOrNullObject(specialSpecs.shelving.wire)) issues.push('MISSING_WIRE_SPECS');
            else if (!Lib.deepAttributeExists(['depth'], object)) issues.push('MISSING_DEPTH');
            else {
                if (!Lib.deepAttributeExists(['width'], object) || object.width <= 0) issues.push('MISSING_WIDTH');
                else if (object.type === 'wood' && object.width > 300)  issues.push('MAX_WIDTH_IS_120');
                if (!Lib.deepAttributeExists(['quantity'], object)) issues.push('MISSING_QUANTITY');
                if (object.type === 'wood' && !Lib.deepAttributeExists(['products', 'cleat', 'code'], specialSpecs)) issues.push('MISSING_CLEAT_SPECS');
                if (object.type === 'wood' && !object.rod_finish) warning.push('MISSING_SHELF_ROD')
            }
        }
    }
    else if (measureTab === 'window') {
        if (object.type === '') issues.push('MISSING_TYPE');
        else {
            switch (object.type) {
                case 'window':
                    if (!object.size) issues.push('MISSING_SIZE');
                    else if (
                        object.size === 'custom' &&
                        (
                            !Lib.deepAttributeExists(['width'], object ) ||
                            !Lib.deepAttributeExists(['height'], object )
                        )
                    ) issues.push('MISSING_DIMENSIONS');

                    if (object.hasBurlap) {
                        if (!Lib.deepAttributeExists(['burlap', 'code'], specialSpecs.products)) issues.push('MISSING_BURLAP_SPECS');
                    }
                    if (object.addSill) {
                        if (!Lib.deepAttributeExists(['sill', 'code'], specialSpecs.products)) issues.push('MISSING_SILL_SPECS');
                        else {
                            if (!object.sillDepth) issues.push('MISSING_SILL_DEPTH');
                            if (!object.sillLength) issues.push('MISSING_SILL_LENGTH');
                        }
                    }
                    break;
                case 'exterior_door':
                    if (!object.size) issues.push('MISSING_SIZE');
                    else {
                        if (!object.operating) warning.push('MISSING_SWING_DIRECTION');
                        else if (!object.hardwareOptions) issues.push('MISSING_DOOR_HANDLE');
                        else if (object.hardwareOptions) {
                            const hardwareTypes = object.hardwareOptions.split("_");
                            hardwareTypes.forEach(type => {
                                switch (type) {
                                    case 'gripset':
                                            if (Lib.isEmptyOrNullObject(specialSpecs.hardware.gripset)) warning.push('MISSING_GRIPSET_SPECS');
                                            else if (object.size !== 'double' && !object.extHardwareMode) warning.push('MISSING_GRIPSET_MODE');
                                            else if (object.size === 'double') {
                                                if (!object.extHardwareMode) warning.push('MISSING_OPERATING_GRIPSET_MODE');
                                                if (!object.extHardwareMode2) warning.push('MISSING_FIXED_GRIPSET_MODE');
                                            }
                                        break;
                                    case 'deadbolt':
                                            if (!Lib.deepAttributeExists(['deadbolt', 'code'], specialSpecs.products)) issues.push('MISSING_DEADBOLT_SPECS');
                                        break;
                                    case 'entry':
                                    case 'passage':
                                    case 'interior':
                                        if (!object.handleType && type === 'interior') warning.push('MISSING_HANDLE_TYPE');
                                        else if (!object.handleType) issues.push('MISSING_HANDLE_TYPE');
                                        if (object.handleType) {
                                            if (Lib.isEmptyOrNullObject(specialSpecs.hardware[object.handleType])) issues.push('MISSING_'+object.handleType.toUpperCase()+'_SPECS');
                                            else if (object.size !== 'double' && !object.extHandleMode) issues.push('MISSING_HANDLE_MODE');
                                            else if (object.size === 'double') {
                                                if (!object.extHandleMode) issues.push('MISSING_OPERATING_HANDLE_MODE');
                                                if (!object.extHandleMode2) warning.push('MISSING_FIXED_HANDLE_MODE');
                                            }
                                        }
                                        break;
                                    default:
                                }
                            })
                        }
                        if (Lib.isEmptyOrNullObject(specialSpecs.hardware.doorstopper)) warning.push('MISSING_DOORSTOPPER_SPECS');
                        else if (!(["", "double"].includes(object.size)) && !object.doorstopper_mode) issues.push('MISSING_DOORSTOPPER_MODE');
                        else if (object.size === 'double'){
                            if (!object.doorstopper_mode_left ) issues.push('MISSING_LEFT_DOORSTOPPER_MODE');
                            if (!object.doorstopper_mode_right) issues.push('MISSING_RIGHT_DOORSTOPPER_MODE');
                        }
                    }
                    if (object.hasCloser && !Lib.deepAttributeExists(['closer', 'code'], specialSpecs.products)) issues.push('MISSING_CLOSER_SPECS');
                    break;
                case 'arch_window':
                    if (
                        !Lib.deepAttributeExists(['width'], object ) ||
                        !Lib.deepAttributeExists(['height'], object )
                    ) issues.push('MISSING_DIMENSIONS');
                    if (Lib.isEmptyOrNullObject(specialSpecs.trim.curve)) issues.push('MISSING_CURVE_SPECS');
                    break;
                case 'caps':
                    if (!object.layout) issues.push('MISSING_LAYOUT');
                    else if (
                        !Lib.deepAttributeExists(['width'], object ) ||
                        !Lib.deepAttributeExists(['height'], object )
                    ) issues.push('MISSING_DIMENSIONS');
                    break;
                case 'shower_stall':
                    if (!object.materialType) issues.push('MISSING_SHOWER_STALL_MATERIAL')
                    // else if (!Lib.deepAttributeExists([object.materialType, 'code'], specialSpecs.products))
                    //     issues.push('MISSING_'+object.materialType.toUpperCase()+'_SPECS');
                    break;
                case 'stairs':
                        if (
                            !Lib.deepAttributeExists(['cove', 'code'], specialSpecs.products) &&
                            !object.materialType
                        ) {
                            warning.push('MISSING_STAIR_MATERIAL')
                        } else {
                            if(Lib.deepAttributeExists(['cove', 'code'], specialSpecs.products) && !object.coveLength) issues.push('MISSING_COVE_LENGTH');
                            else if (object.materialType) {
                                if (!Lib.deepAttributeExists([object.materialType, 'code'], specialSpecs.products)) issues.push('MISSING_'+object.materialType.toUpperCase()+'_SPECS');
                                else if (!object.materialLength) issues.push('MISSING_'+object.materialType.toUpperCase()+'_LENGTH')
                            }
                        }
                        if (object.addD4s) {
                            if (!Lib.deepAttributeExists(['flat_stock', 'code'], specialSpecs.products)) issues.push('MISSING_FLAT_STOCK_SPECS');
                            else if (!object.d4sLength) issues.push('MISSING_FLAT_STOCK_LENGTH')
                        }
                        if (object.addCasing) {
                            if (!Lib.deepAttributeExists(['casing', 'code'], specialSpecs.products)) issues.push('MISSING_CASING_SPECS');
                            else if (!object.casingLength) issues.push('MISSING_CASING_LENGTH')
                        }
                        if (object.addHandrail) {
                            if (!Lib.deepAttributeExists(['handrail', 'code'], specialSpecs.products)) issues.push('MISSING_HANDRAIL_SPECS');
                            else if (!object.handrailLength) issues.push('MISSING_HANDRAIL_LENGTH')
                            if (!Lib.deepAttributeExists(['handrail_bracket', 'code'], specialSpecs.products)) warning.push('MISSING_HANDRAIL_BRACKET_SPECS');
                        }
                    break;
                case 'archway':
                    if (
                        !Lib.deepAttributeExists(['width'], object ) ||
                        !Lib.deepAttributeExists(['height'], object )
                    ) issues.push('MISSING_DIMENSIONS');
                    if (object.addBackband && !object.backbandSides) issues.push('MISSING_BACKBAND_SIDES');
                    else if (object.addBackband && !Lib.deepAttributeExists(['backband', 'code'], specialSpecs.products))  issues.push('MISSING_BACKBAND_SPECS');
                    if (object.addArchitrave && !object.architraveSides) issues.push('MISSING_ARCHITRAVE_SIDES');
                    else if (object.addArchitrave && !Lib.deepAttributeExists(['architrave', 'code'], specialSpecs.products))  issues.push('MISSING_ARCHITRAVE_SPECS');
                    break;
                case 'attic':
                    if (!Lib.deepAttributeExists(['flat_stock', 'code'], specialSpecs.products)) warning.push('MISSING_FLAT_STOCK_SPECS');
                    if (!Lib.deepAttributeExists(['attic_lid', 'code'], specialSpecs.products)) warning.push('MISSING_ATTIC_LID_SPECS');
                    if (!Lib.deepAttributeExists(['attic_hatch', 'code'], specialSpecs.products)) warning.push('MISSING_ATTIC_HATCH_SPECS');
                    if (!Lib.deepAttributeExists(['attic_foam_tape', 'code'], specialSpecs.products)) warning.push('MISSING_ATTIC_FOAM_TAPE_SPECS');
                    break;
                case 'column':
                    if (!Lib.deepAttributeExists(['column', 'code'], specialSpecs.products)) issues.push('MISSING_COLUMN_SPECS');
                    break;
                default:
            }
            if (["archway", "window", 'caps'].includes(object.type)) {
                if (object.hasExtraService && !object.extraService) issues.push('MISSING_EXTRA_SERVICE_AMOUNT');
                else if (object.hasExtraService && !object.notes) issues.push('ADD_A_NOTE_TO_EXPLAIN_THE_REASON_FOR_EXTRA_INSTALL_SERVICE');
                else if (object.hasExtraService && object.notes.length < 10) issues.push('NOTE_IS_TOO_SHORT');
            }
            if (!(['exterior_door', 'stairs', "arch_window", 'column', "window", 'shower_stall'].includes(object.type))) {
                if (!Lib.deepAttributeExists(['casing', 'code'], specialSpecs.products)) issues.push('MISSING_'+object.type.toUpperCase()+'_CASING');
            }
            if (object.type === 'window') {
                if (
                    !Lib.deepAttributeExists(['casing', 'code'], specialSpecs.products) &&
                    !object.addBuildout && !object.hasBurlap && !object.addSill && !object.addArchitrave && !object.addBackband
                ) issues.push('MISSING_'+object.type.toUpperCase()+'_TRIM');
            }
            if (['caps', 'archway'].includes(object.type)) {
                if (!object.materialType) issues.push('MISSING_'+object.type.toUpperCase()+'_MATERIAL');
                // else if (!Lib.deepAttributeExists([object.materialType, 'code'], specialSpecs.products)) issues.push('MISSING_'+object.materialType.toUpperCase()+'_SPECS');
                // if (['flat_stock', 'sheet'].includes(object.materialType) && !object.quantity) issues.push('MISSING_'+object.type.toUpperCase()+'_MATERIAL_QUANTITY');
                // TODO: Fix custom header
                if (object.customHeader) {
                    if (!object.headerMaterial) issues.push('MISSING_CUSTOM_HEADER_MATERIAL');
                    else if (!Lib.deepAttributeExists(['archway_header', 'code'], specialSpecs.products)) issues.push('MISSING_CUSTOM_HEADER_SPECS');
                    else if (['flat_stock', 'sheet'].includes(object.headerMaterial) && !object.headerQuantity) issues.push('MISSING_CUSTOM_HEADER_QUANTITY');
                }
            }
            if (['window', 'exterior_door'].includes(object.type)) {
                if (object.addBuildout && !object.materialType) issues.push('MISSING_BUILDOUT_MATERIAL');
                if (object.addArchitrave) {
                    if (!Lib.deepAttributeExists(['architrave', 'code'], specialSpecs.products)) issues.push('MISSING_ARCHITRAVE_SPECS');
                    else if (!object.architraveWidth) issues.push('MISSING_ARCHITRAVE_WIDTH');
                }
                if (object.addBackband) {
                    if (!Lib.deepAttributeExists(['backband', 'code'], specialSpecs.products)) issues.push('MISSING_BACKBAND_SPECS');
                }
            }
        }
    } else if (measureTab === 'custom_product') {
        if (object?.type === '') issues.push('MISSING_TYPE');
        if (object?.code === '') issues.push('MISSING_PRODUCT');
        if (!object?.count || object.count  < 1) issues.push('MISSING_QUANTITY');
    } else if (measureTab === 'custom_install_service') {
        if (object?.type === '') issues.push('MISSING_TYPE');
        if (object?.code === '') issues.push('MISSING_INSTALL_ITEM');
        if (!object?.count || object.count  < 1) issues.push('MISSING_QUANTITY');
    }
    // Material Type
    if (
        Lib.deepAttributeExists(['materialType'], object) &&
        (measureTab !== 'door' || Lib.deepAttributeExists(['door', 'type'], specialSpecs) !== 'Utility')
    ) {
        if (Lib.isEmptyOrNullObject(specialSpecs.trim[object.materialType])) issues.push('MISSING_'+ object.materialType.toUpperCase()+'_SPECS');
        else {
            if ( ['flat_stock', 'sheet'].includes(object.materialType) && !object.quantity)
                issues.push('MISSING_'+object.materialType.toUpperCase()+'_QUANTITY');
            if (['flat_stock', 'sheet'].includes(object.materialType)) {
                if ( measureTab === 'door' && !object.openingDepth ) issues.push('MISSING_'+object.materialType.toUpperCase()+'_DEPTH');
                if ( measureTab === 'window' && ['window', 'exterior_door'].includes(object.type) && !object.openingDepth) issues.push('MISSING_'+object.materialType.toUpperCase()+'_DEPTH');
            }
        }
    }
    return {issues, warning};
};

export default AddProductDialog