import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import ImportFileModal from './ImportFileModal'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import moment from 'moment'
import ReactS3Uploader from 'react-s3-uploader';
import Fab from '@material-ui/core/Fab';
import { NODE_ENV, REACT_APP_API_HOST, REACT_APP_S3_LINK } from '../envs';

const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '80px',
        marginTop: '16px',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 100,
    },
    input: {
        display: 'none',
    },
    container:{
        padding: '8px 16px 8px 16px'
    },
    innerContainer:{
        padding: '16px'
    },
    textRight:{
        textAlign: 'right'
    },
    list: {
        padding: '8px 16px 8px 8px',
    },
    progress: {
        margin: theme.spacing(2),
    },
    link: {
        color: 'black',
    }
}));

const ImportList = ({ context }) => {
    const classes = useStyles();
    const [uploader, setUploader] = useState(null)
    const apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
    const bucket =  window.location.hostname === 'localhost' || window.location.hostname.includes("dev") ? 'rmg-dev-uploads' : window.location.hostname.includes("staging") ? 'rmg-test-uploads' : 'rmg-prod-uploads';

    const onUploadFinish = (file) => {
        uploader.clear();
        context.setState({filename: file.filename});
        let config = {headers: {'Authorization': "bearer " + localStorage.getItem('token')}};
        let data = {
            filename: file.filename,
            type: context.state.tab,
        };
        axios.post(apiBaseUrl+'file-uploaded', data, config)
        .then(function (res) {
            context.pollServer();
            if ('data' in res) {
                document.getElementById("csv-upload-form").reset();
                context.fetchDataImports(true)
            }
        })
        .catch(() => {
            context.setState({
                dialog: false
            });
        });
    };

    const onUploadError = (error) => {
        console.log(error)
    };

    const onUploadProgress = (percent) => {
        context.setState({progress : percent})
    }

    const onUploadStart = (file, next) => {
        context.setState({dialog: true})
        next(file)
    }

    return (
        <div className={classes.root}>
            <ImportFileModal context={context} />
            <Grid container spacing={0} className={classes.container}>
                {
                    context.state.loading ?
                        <CircularProgress size={50} className={classes.progress} /> :
                    context.state.list.map( (one, i) => (
                        <Grid key={i} item xs={12}>
                            <Paper style={{height: '100%'}}>
                                <Grid container spacing={1} className={classes.innerContainer} alignItems={'baseline'} >
                                    <Grid item xs={6}>
                                        <Typography variant={"body1"}>
                                            [{context.humanize(one.status === "ready" ? 'Imported' : one.status)}]
                                            <a target="_blank" rel="noopener noreferrer" className={classes.link} href={`${REACT_APP_S3_LINK}/${bucket}/${one.filename}`}> {context.getFilename(one.filename)}</a>
                                            {
                                                one.noOfLines && ` (${one.noOfLines} lines)`
                                            }
                                        </Typography>
                                        <Typography variant={'caption'}>
                                            {
                                                one.status === 'ready' && `${one.total} updates`
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant={'caption'} className={classes.textRight}>
                                            {moment(one.createdAt).fromNow()}
                                            { one.createdBy && 'username' in one.createdBy ? ` by ${one.createdBy.username}` : ''}
                                        </Typography>
                                    </Grid>
                                    {
                                        ['changed', 'added', 'removed']
                                            .filter( key => {
                                                let typeTotal = Object.keys(one[key])
                                                    .filter(ikey => one[key][ikey])
                                                    .reduce((total, current) => total + one[key][current], 0)
                                                return Object.keys(one[key]).length > 0 && typeTotal && typeTotal > 0
                                            })
                                            .map( (type, j ) => (
                                            <Grid key={j} item xs={12} sm={4} style={{height: '100%'}}>
                                                <Typography variant={"body2"}>{context.humanize(type)}</Typography>
                                                <div className={classes.list}>
                                                    {
                                                        Object.keys(one[type])
                                                            .filter(key => one[type][key])
                                                            .map((change, k) => (
                                                            <Typography key={k}>{one[type][change]} {context.humanize(change)}</Typography>
                                                        ))
                                                    }
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                    {
                                        one.notes &&
                                        Object.keys(one.notes).length > 0 &&
                                        <Grid item xs={12}>
                                            {
                                                'header' in one.notes &&
                                                !one.notes.header &&
                                                <Typography variant={'caption'}>{`CSV file header is incorrect`}</Typography>
                                            }
                                            {
                                                'codes' in one.notes &&
                                                one.notes.codes.length > 0 &&
                                                <Typography variant={'caption'}>{`Duplicate codes: ${one.notes.codes.join(', ')}`}</Typography>
                                            }
                                            {
                                                'attributes' in one.notes &&
                                                one.notes.attributes.length > 0 &&
                                                <Typography variant={'caption'}>{`Duplicate attributes: ${one.notes.attributes.join(', ')}`}</Typography>
                                            }
                                            {
                                                'error' in one.notes &&
                                                <Typography variant={'caption'}>{`Unknown error!`}</Typography>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                    ))
                }
            </Grid>
            <form id={'csv-upload-form'} method={'post'} encType='multipart/form-data'>
                <ReactS3Uploader
                    id="import-file"
                    signingUrl="s3/sign"
                    signingUrlMethod="GET"
                    accept=".csv"
                    s3path="data-imports/"
                    preprocess={onUploadStart}
                    onProgress={onUploadProgress}
                    onError={onUploadError}
                    onFinish={onUploadFinish}
                    uploadRequestHeaders={{'x-amz-acl': 'private'}}
                    signingUrlHeaders={{ Authorization: `bearer ${localStorage.getItem('token')}` }}
                    // signingUrlWithCredentials={ true }
                    contentDisposition="auto"
                    scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
                    server={apiBaseUrl}
                    // inputRef={cmp => this.importFile = cmp}
                    autoUpload={true}
                    multiple={false}
                    style={{display: "none"}}
                    ref={ refUploader => { setUploader(refUploader) }}
                />
                <label htmlFor="import-file">
                    <Fab component="span" className={classes.fab} color="secondary" aria-label="add">
                        <UploadIcon />
                    </Fab>
                </label>
            </form>
        </div>
    );
}

export default ImportList