import React, {useState, useEffect, useRef} from 'react';
import * as Lib from './lib'
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useScrollToTop = (initialScrollState = false) => {
    const [scrollToTop, setScrollToTop] = useState(initialScrollState);
  
    useEffect(() => {
      if (scrollToTop) {
        setScrollToTop(false);
        try {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } catch (error) {
          window.scrollTo(0, 0);
        }
      }
    }, [scrollToTop, setScrollToTop]);
  
    return setScrollToTop;
  };
  
export const SummaryLabel = ({type, code}) => (
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {
            type && <Typography>{Lib.humanize(type === 'machine' ? 'Pre-Hang' : type)} |&nbsp;</Typography>
        }
        <CopyToClipboard text={code} />
    </div>
)

export const CopyToClipboard = ({text}) => {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setCopied(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, [copied]);

    const handleClick = () => {
        Lib.copyToClipboard(text)
        setCopied(true);
    }
    return (
        <Tooltip title={copied ? 'Copied' : 'Click to copy'} placement="right">
            <span style={{cursor: 'pointer'}} onClick={handleClick}>
                <Typography>{text}</Typography>
            </span>
        </Tooltip>
    )
}

export const ConfirmModal = (props) => {
    const {
        open, 
        title, 
        message, 
        textOk = 'Ok', 
        handleOk, 
        textCancel = 'Cancel', 
        handleCancel
    } = props;
    return (
        <Dialog
            open={open}
            disableBackdropClick={false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                title &&
                <DialogTitle id="confirmation-dialog-title1">{title}</DialogTitle>
            }
            <DialogContent>
                <Typography variant="subtitle1">
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="default">
                    {textCancel}
                </Button>
                <Button onClick={handleOk} color="primary">
                    {textOk}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
}

export function useKeypress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []); 
    return keyPressed;
}
