import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
// import UserListItemActions from './UserListItemActions'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'

const useStyles = makeStyles(() => ({
    listItemUsers: {
        justifyContent: 'space-between',
      },
}));

const UserListItem = ({ user }) => {
    const classes = useStyles();
    let createdAt = new Date(user.createdAt)
    const role = user.role === 'foreman' ? 'Outside Sales' :
                    user.role === 'staff' ? 'Inside Sales' : user.role
    return (
        <>
            <ListItem
                button
                className={classes.listItemUsers}
                component={(props) => <Link to={{ pathname: `/updateUser/${user.id}`, userid: `${user.id}` }} {...props} />}
            >
                <Grid container className={classes.root} spacing={16}>
                    <Grid item xs={12}>
                        <Grid container spacing={16}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>{user.name} - {user.username}</Typography>
                                <Typography variant="caption" gutterBottom>{role ? role.charAt(0).toUpperCase() + role.slice(1) : ''}</Typography>
                                <Typography variant="body2" gutterBottom>{user.email}</Typography>
                            </Grid>
                            <Grid item xs={4}>

                                <Typography variant="caption" align="right">
                                    { user.createdAt ? moment(createdAt).fromNow() : '' }
                                </Typography>
                                <Typography variant="caption" align="right">
                                    by {user.createdBy.username}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
        </>
    );
}

export default UserListItem