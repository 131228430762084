import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import TotalPrice from '../NewTakeoff/Summary/TotalPrice';
// import CPU from '../NewTakeoff/Cpu';
import {humanize} from '../common/lib';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '@media print' : {
            display: 'none'
        }
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 12,
    },
    buttonProgress: {
        color: theme.palette.secondary.light,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    tile: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    h6: {
        marginBottom: '0px',
    },
    caption: {
        marginTop: '-5px'
    },
    disabled: {
        color: 'white',
        backgroundColor: theme.palette.primary[900]
    }
}));

const TopBarBack = React.memo(({takeoffEditId, code, username, status, disabled, pricingTier}) => {
    const classes = useStyles();
    let history = useHistory();

    useEffect(() => {
      console.log(pricingTier)
    }, [pricingTier])
    
    return (
        <div className={classes.root}>
            <AppBar className={disabled ? classes.disabled : undefined} position="fixed" color={"primary"} elevation={0}>
                <Toolbar>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Back"
                        onClick={() => {
                            history.push('/')
                        }}
                    >
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <div className={classes.tile}>
                        <Typography variant="h6" color="inherit" gutterBottom={false} className={classes.h6}>
                            {takeoffEditId === '' ? 'New Takeoff': `${code}`}
                        </Typography>
                        {
                            username &&
                            <Typography variant="caption" color="inherit" className={classes.caption}>
                                By {username} ({humanize(status)})
                            </Typography>
                        }
                    </div>
                    {/* <CPU interactive={false} /> */}
                    <TotalPrice />
                </Toolbar>
            </AppBar>
        </div>
    )
})

export default TopBarBack