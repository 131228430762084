import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router';
import { useParams, Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { Helmet } from 'react-helmet-async'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TopBar from './common/TopBar';
import axios from "axios/index";
import CircularProgress from '@material-ui/core/CircularProgress';
import { NODE_ENV, REACT_APP_API_HOST } from './envs';

const useStyles = makeStyles(() => ({
    root:{
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    margin: {
        margin: 4
    },
    paper:{
        marginTop:'24px',
        paddingTop:'24px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '24px',
        maxWidth: '400px',
    },
}));

const ConfirmUser = () => {
    let { token } = useParams();
    const [state, setState] = useState({
        password:'',
        password2:'',
        msg: '',
        password_error: '',
        password2_error: '',
        showPassword: false,
        showPassword2: false,
        redirect: false,
        validToken: false,
        loading: true,
        error: false
    });

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const handleChange = (field, value) => {
        setState({
            ...state,
            [field]: value 
        })
    }

    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenedAt');
        localStorage.removeItem('username');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('role');
        localStorage.removeItem('name');
        localStorage.removeItem('filter');

        var apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
        var payload= {
            token
        }

        let options = {
            method: 'post',
            url: apiBaseUrl + 'checkToken',
            data: { payload },
            headers: {
                'Content-Type': 'application/json'
            }
        }

        axios(options)
        .then(function (response) {
            if (response.status === 200) {
                if (response.data.result){
                    setState({
                        ...state,
                        validToken : true,
                        loading: false
                    })
                } else {
                    setState({
                        ...state,
                        error: true, 
                        loading: false
                    })
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }, []);

    const handleConfirm = () => {
        const { password, password2} = state
        if (password && password2 && password === password2) {
            var apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
            var payload= {
                password: password,
                token
            }
            let options = {
                method: 'post',
                url: apiBaseUrl + 'confirmUser',
                data: { payload },
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            axios(options)
                .then(function (response) {
                    if (response.status === 200) {
                        if (response.data.result) {
                            localStorage.setItem('username', response.data.username)
                            localStorage.setItem('token', response.data.token)
                            const now = new Date()
                            localStorage.setItem('tokenedAt', now)
                            setState({
                                ...state,
                                redirect : <Redirect to='/' />
                            })
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                });
        }
    }

    const { redirect } = state;
    const classes = useStyles();

    if (redirect) {
        return <Redirect to='/'/>;
    }
    // console.log(state)
    return (
        <div className={classes.root}>
            <Helmet>
                <title>Riverside Takeoffs :: Confirm User</title>
            </Helmet>
            <TopBar isLoggedIn={false} title="Create a password"/>
            {
                state.loading ?
                    <CircularProgress size={50} className={classes.progress} /> :
                    <Paper className={classes.paper} elevation={24}>
                        {
                            state.error ?
                                <>
                                    <Typography variant="h6">
                                        Confirmation link not valid.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        You may have created a password for this account.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <Link component={RouterLink} to="/login">
                                            Login
                                        </Link>
                                        {" "}with your username and password or,
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <Link component={RouterLink} to="/forgot-password">
                                            Reset
                                        </Link>
                                        {" "}your password here.
                                    </Typography>
                                </>
                            :
                            state.validToken ?
                                <FormControl fullWidth className={classes.margin}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6'>
                                                Create a password
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="outlined-adornment-password"
                                                variant="outlined"
                                                label="Password"
                                                type={state.showPassword ? 'text' : 'password'}
                                                value={state.password}
                                                onChange={(event) => handleChange('password', event.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Toggle password visibility"
                                                                onClick={() => setState(state => ({ ...state, showPassword: !state.showPassword }))}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="outlined-adornment-password2"
                                                variant="outlined"
                                                label="Re-Enter Password"
                                                type={state.showPassword ? 'text' : 'password'}
                                                value={state.password2}
                                                onChange={(event) => handleChange('password2', event.target.value)}
                                                error={!(
                                                    (
                                                        state.password === ''  &&
                                                        state.password2 === ''
                                                    )
                                                    ||
                                                    (
                                                        state.password  &&
                                                        state.password2  &&
                                                        state.password === state.password2
                                                    )
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => handleConfirm()}
                                                fullWidth>
                                                Confirm
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                :
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>
                                            Houston, we have a problem!
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            This page is invalid. Try clicking on the link in your email again.
                                        </Typography>
                                    </Grid>
                                </Grid>

                        }
                        {state.redirect}
                    </Paper>
            }
        </div>
    )
}

export default ConfirmUser