import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";

export const ConfirmationDialog = ({ open, title, variant, description, onSubmit, onClose}) => (
    <Dialog open={open}>
        <DialogTitle id="alert-dialog-title2">{title || ""}</DialogTitle>
        <DialogContent>
        <DialogContentText>{description || ""}</DialogContentText>
        </DialogContent>
        <DialogActions>
        {
            variant === "danger" && (
            <>
                <Button color="primary" onClick={onClose} autoFocus>
                    CANCEL
                </Button>
                <Button color="primary" onClick={onSubmit}>
                    OK
                </Button>
            </>
        )}
        {
            variant === "info" && (
            <Button color="primary" onClick={onSubmit}>
                OK
            </Button>
        )}
      </DialogActions>
    </Dialog>
);
