import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { createHttpLink } from '@apollo/client/link/http'
import { setContext } from '@apollo/client/link/context'
import { REACT_APP_API_HOST, IS_DEV_ENV } from '../components/envs';
import brown from '@material-ui/core/colors/brown';
import orange from '@material-ui/core/colors/orange';
import indigo from '@material-ui/core/colors/blueGrey';
import blue from '@material-ui/core/colors/lightBlue';
import axios from 'axios';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

const apiBaseUrl = process.env.NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
// const timestamp = parseInt(Date.now() / 1000);

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export const refreshToken = () => {
  const payload= {
      token: localStorage.getItem('token')
  }
  const options = {
      method: 'post',
      url: apiBaseUrl + 'refresh',
      data: { payload },
      headers: {
          'Content-Type': 'application/json'
      }
  }
  axios(options)
  .then(function (response) {
      if (response.status === 200) {
          localStorage.setItem('token', response.data.token)
          const parsedToken = parseJwt(response.data.token)
          localStorage.setItem('role', parsedToken.role)
          localStorage.setItem('name', parsedToken.name)
          localStorage.setItem('tokenedAt', new Date())
          localStorage.setItem('expiresAt', parsedToken.exp)
          // TODO: Maybe save them in a global context state object
          // console.log("refresh successful", parsedToken);
      }
      else {
          removeUserInfo(true);
      }
  })
  .catch(function (e) {
      if (process.env.NODE_ENV !== 'development') {
          Sentry.captureException(new Error(`[GraphQL error]: Message: ${e}`))
      }
      // console.log("Error: "+ e);
      removeUserInfo(true);
  });
}

export const removeUserInfo = (reload = false, saveCurrent = false) => {
  if (saveCurrent)
      localStorage.setItem('lastHref', window.location.pathname)
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('tokenedAt');
  localStorage.removeItem('username');
  localStorage.removeItem('expiresAt');
  localStorage.removeItem('name');
  localStorage.removeItem('filter');
  if (reload) window.location.reload();
}

const customFetch = (uri, options) => {
  const timestamp = parseInt(Date.now() / 1000);
  const initialRequest = fetch(uri, options);

  if (timestamp > localStorage.getItem('expiresAt')) {
      removeUserInfo(true, true);
  }
  else{
      return initialRequest.then((response) => {
          if (
              localStorage.getItem('token') &&
              localStorage.getItem('expiresAt') &&
              timestamp > (localStorage.getItem('expiresAt') - 86400) &&
              timestamp < localStorage.getItem('expiresAt')
          ) {
              refreshToken();
          } else if ((response.status === 401)) {
              removeUserInfo(true, true);
          } else {
              return(response);
          }
      })
  }
}

const httpLink = createHttpLink({
  uri: apiBaseUrl+'graphql',
  fetch: customFetch
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
      headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
      }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>{
      if (process.env.NODE_ENV !== 'development') Sentry.captureException(new Error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
    });
  }
  if (networkError) {
      if (process.env.NODE_ENV !== 'development') {
          // Sentry.captureException(new Error(`[Network error]: Message: ${networkError}`))
      } else {
          console.log(`[Network error]:`, networkError)
      }
      // console.log(`[Network error]: ${JSON.stringify(networkError, null, 4)}`);
      if (networkError.status === 401) {
          removeUserInfo(true, true);
      }
  }
});

const link = ApolloLink.from([
  authLink,
  errorLink,
  httpLink,
]);

export const apolloClient = new ApolloClient({
  link,
  onError: (e) => {
      if (process.env.NODE_ENV !== 'development')
        Sentry.captureException(new Error(`[GraphQL error]: ${e}`))
  },
  cache: new InMemoryCache({
    dataIdFromObject: obj => obj.id || null,
    addTypename: false,
    fragmentMatcher: {
        match: ({ id }, typeCond, context) => !!context.store.get(id)
    }
  })
  // headers: {
  //   authorization: token ? `Bearer ${token}` : "",
  // },
  
});

export const theme = createMuiTheme({
  palette: {
      primary: IS_DEV_ENV ? indigo :  brown,
      secondary : IS_DEV_ENV ? blue : orange,
  },
  typography: {
      useNextVariants: true,
  },
});
