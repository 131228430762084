import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import TakeoffInfoSummary from './TakeoffInfoSummary';
import * as Lib from '../lib/lib';

const DoorPrepMill = ({ mill_prep, notes, descriptions, classes }) => {

  return (
    (
        Object.keys(mill_prep.curves).length > 0 ||
        Object.keys(mill_prep.sills).length > 0
    ) ?
    <Grid spacing={2} container className="break-print">
        <Grid item xs={12}>
            <TakeoffInfoSummary
                isPrint
                label="Mill Prep"
                notes={notes}
            />
        </Grid>
        <Grid item xs={12} style={{
            '@media print' : {
                clear: 'both',
                breakBefore: 'all !important',
            }
        }}>
            <List className={classes.list+ " groupesdList"} subheader={<li />}>
            {
                    Object.keys(mill_prep)
                    .filter(type => Object.keys(mill_prep[type]).length > 0)
                    .map( (type, i) => (
                        <React.Fragment key={i}>
                            <ListSubheader className={classes.listSubheader}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}><Typography variant="body1">{Lib.humanize(type)}</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">{type ? 'Depth' : 'Width' }</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">{type === 'sills' ? 'Length' :'Height'}</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                </Grid>
                            </ListSubheader>
                            {
                                Object.keys(mill_prep[type]).map((code, m) => (
                                    Object.keys(mill_prep[type][code]).map((width, n) => (
                                        Object.keys(mill_prep[type][code][width]).map((height, l) => (
                                            <ListItem key={m+n+l} className={classes.summaryDoorListItem}>
                                                <Grid  container spacing={1}>
                                                    <Grid item xs={6}><Typography>{code}</Typography></Grid>
                                                    <Grid item xs={2}><Typography>{width} IN</Typography></Grid>
                                                    <Grid item xs={2}><Typography>{height} IN</Typography></Grid>
                                                    <Grid item xs={2}><Typography>{mill_prep[type][code][width][height]}</Typography></Grid>
                                                    <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                </Grid>
                                            </ListItem>
                                        ))
                                    ))
                                ))
                            }
                        </React.Fragment>
                    ))
                }
            </List>
        </Grid>
    </Grid> : ""
  );
};

export default DoorPrepMill;