import React, {useState} from 'react';
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Divider, Grid, List, Typography, CircularProgress, ListItem } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import CpuReport from './CpuReport'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth : 1080,
        alignItems:'center',
        margin: 'auto',
        marginBottom: '24px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    container: {
        marginTop: '8px'
    },
    item: {
        marginTop: '16px'
    },
    list: {
        position: 'relative',
        overflow: 'auto',
        width: '100%',
        paddingBottom:'0px',
        boxShadow: theme.shadows[5] + '!important',
    },
    title: {
        marginLeft: '8px'
    },
    listItem: {
        justifyContent: 'space-between',
    },
    listSubheader:{
        backgroundColor: theme.palette.grey[300],
        color: "black",
        paddingTop: "8px",
        paddingBottom: "8px",
        pageBreakInside: 'avoid',
        breakInside: 'avoid-page'
    },
    chip: {
        minWidth: '100px',
        marginRight: theme.spacing(1),
    },
    flexDesc: {
        display: 'flex',
        alignItems: 'center'
    },
    description: {
        // alignSelf: 'center'
    },
    fColumn: {
        // flexDirection: 'column',
        justifyContent: 'flex-end'
    }
}));

const LOG_QUERY = gql`
    query getTakeoffActivityLog($id: ID!, $action: String, $status: String) {
        getTakeoffActivityLog (id: $id, action: $action, status: $status) {
            id description action user {username} createdAt cpuObject {id}
        }
    }
`;

const ActivityLog = ({id, title, status, showLoading}) => {
    const classes = useStyles();
    const [cpuReport, setCpuReport] = useState(null);
    const { loading, error, data } = useQuery(LOG_QUERY, {
        variables: {
            id,
            status,
        },
        fetchPolicy: 'network-only'
    });
    if (!id) return <div></div>
    if (loading && showLoading) return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <CircularProgress className={classes.progress} />
            </Grid>
        </Grid>
    );
    if (error) return `Error! ${error.message}`;
    // console.log(action)
    return (
        data?.getTakeoffActivityLog &&
        data.getTakeoffActivityLog.length > 0 ?
        <Grid container className={classes.root}>
            {
                cpuReport &&
                <CpuReport 
                    report={cpuReport} 
                    handleClose={() => setCpuReport(null)} 
                />
            }
            <Grid item xs={12}>
                {
                    title &&
                    <Typography variant="h6">{title}</Typography>
                }
                <List className={classes.list+ " groupesdList"} subheader={<li />}>
                    {
                        data.getTakeoffActivityLog &&
                        data.getTakeoffActivityLog.map((log,i) => (
                            <React.Fragment key={i}>
                                <ListItem
                                    button={log.cpuObject?.id}
                                    className={classes.summaryDoorListItem}
                                    onClick={() => setCpuReport(log.cpuObject?.id)}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={9} className={classes.flexDesc}>
                                            <Chip label={log.action} className={classes.chip} />
                                            <Typography variant="body2" className={classes.description}>{log.description}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3} className={classnames(classes.flexDesc, classes.fColumn)}>
                                            <Typography variant="caption">by {log.user?.username}&nbsp;at&nbsp;{moment(log.createdAt).format('HH:mm:ss, MMM DD')}</Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))
                    }
                    {
                        [ 'new', 'New'].includes(status) &&
                        data.getTakeoffActivityLog &&
                        data.getTakeoffActivityLog.length < 1 &&
                        <ListItem className={classes.summaryDoorListItem}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">New</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        No Activities yet!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    }
                </List>
            </Grid>
        </Grid>
        : null
    )
}

export default ActivityLog