import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ApolloConsumer } from '@apollo/client';
import { Helmet } from 'react-helmet-async'
//components
import TopBarUserBack from './common/TopBarUserBack';
import UserForm from './NewUser/UserForm';
import UserFormProvider from './UserFormProvider'

const useStyles = makeStyles(() => ({
    root:{

    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

const ProfileForm = (props) => {
    const classes = useStyles();
    let userId = ''
    if (typeof props.match.params.id !== 'undefined') {
        userId = props.match.params.id
    }

    return (
        <ApolloConsumer>
            {
                apolloClient => {
                    return (
                        <UserFormProvider apolloClient={apolloClient} userid={userId} isProfile={true}>
                            <div className={classes.root}>
                                <Helmet>
                                    <title>Riverside Takeoffs :: Profile</title>
                                </Helmet>
                                <TopBarUserBack title="Profile"/>
                                <UserForm />
                            </div>
                        </UserFormProvider>
                    )
                }
            }
        </ApolloConsumer>
    )
}

export default ProfileForm