import React from "react";
import Fab from '@material-ui/core/Fab';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import UserList from './Users/UserList';
import UserListProvider from './UserListProvider';
import NotAuthorized from './NotAuthorized';
import TopBar from './common/TopBar';

const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '80px'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 100,
    },
}));

const Users = () => {
    const classes = useStyles();
    return (
        <UserListProvider>
            <div className={classes.root}>
                <Helmet>
                    <title>Riverside Takeoffs :: Users List</title>
                </Helmet>
                <TopBar isLoggedIn={true} title="Users"/>
                {
                    localStorage.getItem('role') === 'admin' ?
                        <UserList/> :
                        <NotAuthorized />
                }
                <Fab component={Link} to="/newUser" color="secondary" aria-label="add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </div>
        </UserListProvider>
    );
}

export default Users
