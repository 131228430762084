import React from 'react';
import Main from "./components/Main";
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client';
import {ThemeProvider } from '@material-ui/core/styles';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/browser';

import {apolloClient, theme, removeUserInfo, refreshToken} from './lib/apolloClient'

const App = () => {
  const timestamp = parseInt(Date.now() / 1000);

  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({ dsn: 'https://b8d0029f97cb45ca8db049863c166e88:a5e81b85076841fa9a12513836c5425e@sentry.io/1460374' });
  }
  
  
  if (!localStorage.getItem('expiresAt')) {
    removeUserInfo(false, true);
  }
  else if (
    localStorage.getItem('token') &&
    localStorage.getItem('expiresAt') &&
    timestamp > (localStorage.getItem('expiresAt') - 86400) &&
    timestamp < localStorage.getItem('expiresAt')
  ) {
    refreshToken();
  }
  else {
    // console.log(parseJwt(localStorage.getItem('token')))
  }
    
  if ("%NODE_ENV%" != 'development' && localStorage.getItem('username')) {
    Sentry.configureScope((scope) => {
      scope.setUser({"username": localStorage.getItem('username') || 'Unknown'});
    });
  }
  return (
    <HelmetProvider>
    <ApolloProvider client={apolloClient}>
      <Router>
        <ThemeProvider theme={theme}>
          <Main/>
        </ThemeProvider>
      </Router>
    </ApolloProvider>
    </HelmetProvider>
  );
}

export default App;