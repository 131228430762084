import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ApolloConsumer } from '@apollo/client';
import { Helmet } from 'react-helmet-async'
import ImportProvider from './ImportProvider'
import ImportTabs from './Import/ImportTabs';
import TopBar from './common/TopBar'

const useStyles = makeStyles(() => ({
    root:{
        maxWidth : '100%',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '80px'
    },
}));

const Import = () => {
    const classes = useStyles();
    return (
        <ApolloConsumer>
            {
                apolloClient => (
                    <ImportProvider apolloClient={apolloClient}>
                        <div className={classes.root}>
                            <Helmet>
                                <title>Riverside Takeoffs :: Import data</title>
                            </Helmet>
                            <TopBar isLoggedIn={true} title="Import Data"/>
                            <ImportTabs/>
                        </div>
                    </ImportProvider>
                )
            }
        </ApolloConsumer>
    );
}

export default Import;