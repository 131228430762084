import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {installItems} from '../../common/constants'

import DoorPrepDoors from './DoorPrepDoors';
import DoorPrepShelves from './DoorPrepShelves';
import DoorPrepMill from './DoorPrepMill';
import DoorPrepOnsite from './DoorPrepOnsite';
import DoorPrepOpeningInstall from './DoorPrepOpeningInstall';
import useDoorPrep from '../lib/useDoorPrep';
import PrepOrganizers from './PrepOrganizers';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth : 1080,
        alignItems:'center',
        margin: 'auto',
        marginBottom: '100px',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    container: {
        marginTop: '8px'
    },
    item: {
        marginTop: '16px'
    },
    list: {
        position: 'relative',
        overflow: 'auto',
        width: '100%',
        paddingBottom:'0px',
        boxShadow: theme.shadows[5] + '!important',
    },
    title: {
        marginLeft: '8px'
    },
    listItem: {
        justifyContent: 'space-between',
    },
    listSubheader:{
        backgroundColor: theme.palette.grey[300],
        color: "black",
        paddingTop: "8px",
        paddingBottom: "8px",
        pageBreakInside: 'avoid',
        breakInside: 'avoid-page'
    },
    summaryDoorListItem: {
        borderBottom: "2px solid " + theme.palette.grey[300] + '!important',
        backgroundColor: 'white',
        pageBreakInside: 'avoid',
        breakInside: 'avoid-page'
    },
    info: {
        width: '100%'
    },
    summary: {
        '@media print' : {
            clear: 'both',
            breakAfter: 'all !important',
        }
    }
}));

const DoorPrep = ({ context }) => {
    const classes = useStyles();
    const {
        door_prep, 
        backset, 
        jamb_prep, 
        jambTypes, 
        descriptions, 
        loose, 
        ndLoose, 
        uoms, 
        hingeDesc, 
        doorNotes,
        shelves,
        organizers,
        mill_prep,
        opening_count,
        has,
        extra,
        notes,
        onsite,
        wire_install,
        opening
    } = useDoorPrep();

    return (
        <div className={classes.root}>
            <DoorPrepDoors 
                classes={classes} 
                door_prep={door_prep} 
                backset={backset} 
                notes={notes} 
                jamb_prep={jamb_prep} 
                jambTypes={jambTypes} 
                descriptions={descriptions} 
                loose={loose} 
                ndLoose={ndLoose} 
                doorNotes={doorNotes} 
                uoms={uoms}
                hingeDesc={hingeDesc} 
            />
            <DoorPrepShelves
                classes={classes} 
                shelves={shelves}
                descriptions={descriptions} 
                notes={notes} 
            />
            <PrepOrganizers 
                classes={classes} 
                organizers={organizers} // organizers
                descriptions={descriptions} 
                notes={notes} 
            />
            <DoorPrepMill
                classes={classes} 
                mill_prep={mill_prep}
                descriptions={descriptions} 
                notes={notes} 
            />
            <DoorPrepOnsite
                classes={classes} 
                onsite={onsite}
                descriptions={descriptions} 
                notes={notes} 
            />
            <DoorPrepOpeningInstall
                classes={classes} 
                context={context}
                opening_count={opening_count}
                extra={extra}
                has={has}
                installItems={installItems}
                wire_install={wire_install}
                opening={opening}
                descriptions={descriptions} 
                notes={notes} 
            />
        </div>
    )
};

export default DoorPrep