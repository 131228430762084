import React from "react";
import {OutlinedInput, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

const MeasureUnits = ({disabled, value, handleChange}) => (
  <FormControl variant="outlined" style={{minWidth: 125, maxWidth: 125, marginTop: 8}} disabled={disabled}>
    <InputLabel id="demo-simple-select-outlined-label">Unit Number</InputLabel>
    <Select
      value={value}
      onChange={handleChange}
      input={
        <OutlinedInput
          labelWidth={80}
          name="unitNumber"
          id="outlined-age-simple"
        />
      }
    >
      <MenuItem value={""}>{""}</MenuItem>
      {
        Array(40).fill().map( (num, i) => (
          <MenuItem key={i+1} value={i+1}>{i+1}</MenuItem>
        ))
      }
    </Select>
  </FormControl>
)

export default MeasureUnits;