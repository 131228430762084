import React from "react";
import TakeoffListContext from './TakeoffListContext'
import { ApolloConsumer, gql } from '@apollo/client';
import update from 'immutability-helper';
import { TakeoffList_Fragment } from './common/fragments.graphql';
import { REACT_APP_MAXPERPAGE} from './envs';

const TAKEOFFS_QUERY = gql`
    query AllTakeoffs($page: Int, $keyword: String, $filter: Filter) {
        allTakeoffs (page: $page, keyword: $keyword, filter: $filter) { 
            id,
            copiedFromId copiedFromCode copyType isAdditionalOrder
            code,
            customerType,
            customer {
                name
            },
            prospect {
                firstname, lastname
            },
            createdBy{username, name},
            updatedBy{username},
            createdAt,
            updatedAt,
            doorsNo trimNo hardwareNo openingNo shelvingNo indvItemNo indvInstallNo
            site{
                lot, civic, city, modelType, modelElevation, modelOption, siteMeasure, contractPricing, installationRequired
            },
            status
            isLocked lockedAt
            mine
        }
    }
    
`;

export default class TakeoffListProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            loading: true,
            takeoffIds: [],
            confirmDialogOpen: false,
            takeoffAction: {},
            authors: [],
            takeoffs: [],
            listPage: 0,
            loadMoreLabel: 'Load More',
            showFilters: false,
            canPaginate: true,
            filter: {},
            team:[],
            anchorEls: {
                '5b201f256a88158e9ed65ac8': null
            },
        }
    }

    componentDidMount() {
        const { apolloClient } = this.props;

        if (['staff'].includes(localStorage.getItem('role'))) {
            const teamPromise = this.getTeam(apolloClient);
            teamPromise.then( team => {
                if (team) {
                    this.setState({
                        team: [
                            ...team.map(user => user.id),

                        ]
                    })
                }
            });
        }

        const authorsPromise = this.getAuthors(apolloClient)
        authorsPromise.then( authors => {
            if (authors) {
                this.setState({authors: authors})
            }
        });

        const takeoffsPromise = this.getTakeoffs(apolloClient)
        takeoffsPromise.then( takeoffs => {
            if (takeoffs) {
                let canPaginate = takeoffs.length > 0 && takeoffs.length >= REACT_APP_MAXPERPAGE ? true : false
                this.setState({
                    loading: false,
                    canPaginate: canPaginate,
                    loadMoreLabel: canPaginate ? 'Load More' : 'No More Takeoffs',
                    takeoffs: takeoffs
                })
            }
        });
    }

    refetchQueries = (queries) => {
        return Promise.all(queries.map(q => this.props.apolloClient.query({
            ...q,
            fetchPolicy: 'network-only'
        })))
    };

    clearFilters = () => {
        this.setState({
            filter: {
                from: null,
                to: null,
            }
        })
    }

    loadTakeoffList = (apolloClient) => {
        this.setState({
            loading: true,
            canPaginate: true,
            listPage: 0
        }, () => {
            const takeoffsPromise = this.getTakeoffs(apolloClient)
            takeoffsPromise.then( takeoffs => {
                if (takeoffs) {
                    let canPaginate = takeoffs.length > 0 && takeoffs.length >= REACT_APP_MAXPERPAGE ? true : false
                    this.setState({
                        loading: false,
                        canPaginate: canPaginate,
                        loadMoreLabel: canPaginate ? 'Load More' : 'No More Takeoffs',
                        takeoffs: takeoffs
                    })
                }
            })
        })
    };

    cloneTakeoff = async (id, apolloClient) => {
        const filter = JSON.parse(localStorage.getItem('filter') || '{}')
        const variables = {
            page: this.state.listPage,
            filter,
        }

        const CLONE_TAKEOFF = gql`
            mutation cloneTakeoff{
                cloneTakeoff(id: "${id}") { ...TakeoffListParts }
            }
            ${TakeoffList_Fragment}
        `;

        const {data} = await apolloClient.mutate({
            mutation: CLONE_TAKEOFF,
            // refetchQueries: [{ query: GET_TAKEOFFS}],
            update: (store, { data: { cloneTakeoff } }) => {
                const data = store.readQuery({ query: TAKEOFFS_QUERY, variables: variables });
                if (cloneTakeoff && cloneTakeoff.length > 0) {
                    const newState = update(this.state, {
                        takeoffs: {$unshift: [cloneTakeoff[0]]}
                    })
                    this.setState(newState)
                    store.writeQuery({ query: TAKEOFFS_QUERY, data });
                }
            },
        });
        if (data.cloneTakeoff !== null) {
            return data.cloneTakeoff
        }
        return false
    }

    cloneTakeoffSpecs = async (id, apolloClient) => {
        const filter = JSON.parse(localStorage.getItem('filter') || '{}');
        const variables = {
            page: this.state.listPage,
            filter,
        }

        const CLONE_TAKEOFF = gql`
            mutation cloneTakeoffSpecs{
                cloneTakeoffSpecs(id: "${id}") { ...TakeoffListParts }
            }
            ${TakeoffList_Fragment}
        `;

        const {data} = await apolloClient.mutate({
            mutation: CLONE_TAKEOFF,
            // refetchQueries: [{ query: GET_TAKEOFFS}],
            update: (store, { data: { cloneTakeoffSpecs } }) => {
                const data = store.readQuery({ query: TAKEOFFS_QUERY, variables: variables });
                if (cloneTakeoffSpecs && cloneTakeoffSpecs.length > 0) {
                    const newState = update(this.state, {
                        takeoffs: {$unshift: [cloneTakeoffSpecs[0]]}
                    })
                    this.setState(newState)
                    store.writeQuery({ query: TAKEOFFS_QUERY, data });
                }
            },
        });
        if (data.cloneTakeoffSpecs !== null) {
            return data.cloneTakeoffSpecs
        }
        return false
    }

    addAdditionalOrder = async (id, apolloClient) => {
        const filter = JSON.parse(localStorage.getItem('filter') || '{}');
        const variables = {
            page: this.state.listPage,
            filter,
        }

        const CLONE_TAKEOFF = gql`
            mutation addAdditionalOrder{
                addAdditionalOrder(id: "${id}") { ...TakeoffListParts }
            }
            ${TakeoffList_Fragment}
        `;

        const {data} = await apolloClient.mutate({
            mutation: CLONE_TAKEOFF,
            // refetchQueries: [{ query: GET_TAKEOFFS}],
            update: (store, { data: { addAdditionalOrder } }) => {
                const data = store.readQuery({ query: TAKEOFFS_QUERY, variables: variables });
                if (addAdditionalOrder && addAdditionalOrder.length > 0) {
                    const newState = update(this.state, {
                        takeoffs: {$unshift: [addAdditionalOrder[0]]}
                    })
                    this.setState(newState)
                    store.writeQuery({ query: TAKEOFFS_QUERY, data });
                }
            },
        });
        if (data.addAdditionalOrder !== null) {
            return data.addAdditionalOrder
        }
        return false
    }

    getAuthors = async (apolloClient) => {
        const AUTHORS_QUERY = gql`
            query GetAuthors{
                getAuthors {
                    id, name, username
                }
            }
        `;

        const {data} = await apolloClient.query({
            query: AUTHORS_QUERY,
        });
        if (data.getAuthors !== null) {
            return data.getAuthors
        }
        return false
    }

    getTeam = async (apolloClient) => {
        const TEAM_QUERY = gql`
            query GetTeam{
                getMyTeam {
                    id
                }
            }
        `;

        const {data} = await apolloClient.query({
            query: TEAM_QUERY,
        });
        if (data.getMyTeam !== null) {
            return data.getMyTeam
        }
        return false
    }

    getTakeoffs = async (apolloClient) => {
        const filter = JSON.parse(localStorage.getItem('filter') || '{}')
        const variables = {
            page: this.state.listPage,
            filter,
        }

        const {data} = await apolloClient.query({
            query: TAKEOFFS_QUERY,
            fetchPolicy: 'network-only',
            variables: variables
        });
        if (data.allTakeoffs !== null) {
            return data.allTakeoffs
        }
        return false
    }

    deleteTakeoff = async (id, action = 'delete', apolloClient) => {
        const filter = JSON.parse(localStorage.getItem('filter') || '{}');
        const variables = {
            page: this.state.listPage,
            filter,
        };
        const DELETE_TAKEOFF = gql`
            mutation deleteTakeoff($id:ID! ,$action: String){
                deleteTakeoff(id: $id, action: $action)
            }
        `;

        const {data} = await apolloClient.mutate({
            mutation: DELETE_TAKEOFF,
            variables: { id, action } ,
            refetchQueries: [{ query: TAKEOFFS_QUERY, variables: variables}],
            update: () => {
                const newTakeoffs = this.state.takeoffs.filter(takeoff => {
                    return takeoff.id !== id
                });
                this.setState({takeoffs: newTakeoffs})
            }
        });
        if (data.deleteTakeoff !== null) {
            return data.deleteTakeoff
        }
        return false
    };

    handleCpu = async (id, apolloClient) => {
        const REQUEST_CPU = gql`
            mutation RequestCpu ($id: ID!) {
                requestCpu (id: $id)
            }
        `;

        const {data} = await apolloClient.mutate({
            mutation: REQUEST_CPU,
            variables: { id } ,
            // refetchQueries: [{ query: TAKEOFFS_QUERY, variables: variables}],
            // update: () => {
            //     const newTakeoffs = this.state.takeoffs.filter(takeoff => {
            //         return takeoff.id !== id
            //     });
            //     this.setState({takeoffs: newTakeoffs})
            // }
        });
        if (data.requestCpu !== null) {
            return data.requestCpu
        }
        return false
    }

    render () {
        return (
            <ApolloConsumer>
                { apolloClient => {
                    return (
                        <TakeoffListContext.Provider value={{
                            state: this.state,
                            setState: (obj) => {
                                this.setState(obj);
                            },
                            humanize: (str) => {
                                if (str === null || str === 'null' || str === undefined) return '';
                                var frags = str.split('_');
                                for (let i=0; i<frags.length; i++) {
                                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                                }
                                return frags.join(' ');
                            },
                            doAction: async () => {
                                const {id, action } = this.state.takeoffAction;
                                switch (action) {
                                    case "restore":
                                    case "archive":
                                    case "delete":
                                        await this.deleteTakeoff(id, action, apolloClient);
                                        break;
                                    case "clone":
                                        await this.cloneTakeoff(id, apolloClient);
                                        break;
                                    case "clone-specs":
                                        await this.cloneTakeoffSpecs(id, apolloClient);
                                        break;
                                    case "add-additional":
                                        await this.addAdditionalOrder(id, apolloClient);
                                        break;
                                    case "cpu":
                                        await this.handleCpu(id, apolloClient);
                                        break;
                                    default:
                                        break;
                                }
                                this.setState({ takeoffAction: {}, confirmDialogOpen: false })
                            },
                            getTakeoffs: () => {
                                this.loadTakeoffList(apolloClient)
                            },
                            paginate: () => {
                                if (this.state.canPaginate) {
                                    this.setState( prevState => ({
                                        listPage: prevState.listPage + 1
                                    }), () => {
                                        const takeoffsPromise = this.getTakeoffs(apolloClient)
                                        takeoffsPromise.then( takeoffs => {
                                            if (takeoffs) {
                                                let canPaginate = takeoffs.length > 0 && takeoffs.length >= REACT_APP_MAXPERPAGE ? true : false
                                                this.setState( prevState => ({
                                                    canPaginate: canPaginate,
                                                    loadMoreLabel: canPaginate ? 'Load More' : 'No More Takeoffs',
                                                    takeoffs: [...prevState.takeoffs, ...takeoffs]
                                                }))
                                            }
                                        })
                                    })
                                }
                            },
                            filterTakeoffs: () => {
                                localStorage.setItem('filter', JSON.stringify(this.state.filter))
                                this.setState({
                                    showFilters: false,
                                }, () => {
                                    this.loadTakeoffList(apolloClient)
                                })
                            },
                            deleteFilter: (filter) => {
                                let newFilter = update(this.state.filter, {
                                    $unset: [filter]
                                });
                                localStorage.setItem('filter', JSON.stringify(newFilter));
                                this.setState({
                                    filter: newFilter
                                }, () => {
                                    this.loadTakeoffList(apolloClient)
                                })
                            },
                            clearFilters: () => {
                                this.clearFilters()
                            },
                            closeFilters: () => {
                                this.setState({
                                    showFilters: false,
                                    filter: {},
                                })
                            },
                            showFilter: () => {
                                this.setState({
                                    showFilters: true
                                })
                            },
                            getAuthorsNames: (ids = []) => {
                                const authors = ids.map( id => this.state.authors.find( author => author.id === id))
                                let names = authors.filter(user => user).map( author => author.username);
                                return authors.length === this.state.authors.length ? "Everyone" : names.join(', ')
                            }
                        }}>
                            {this.props.children}
                        </TakeoffListContext.Provider>
                    )
                }}
            </ApolloConsumer>
        )
    }
}