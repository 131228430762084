import React from "react";
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import TakeoffList from './Takeoffs/TakeoffList'

import TakeoffListProvider from './TakeoffListProvider'
import { ApolloConsumer } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
    root:{
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '80px'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 100,
    },
}));

const Takeoffs = () => {
    const classes = useStyles();
    return (
        <ApolloConsumer>
            {
                apolloClient => (
                    <TakeoffListProvider apolloClient={apolloClient}>
                        <div className={classes.root}>
                            <Helmet>
                                <title>Riverside Takeoffs :: Takeoffs List</title>
                            </Helmet>

                            <TakeoffList />
                            <Fab component={Link} to="/newTakeoff" color="secondary" aria-label="add" className={classes.fab}>
                                <AddIcon />
                            </Fab>
                        </div>
                    </TakeoffListProvider>
                )
            }
        </ApolloConsumer>
    );
}

export default Takeoffs
