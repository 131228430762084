import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ActivityLog from './ActivityLog';
import {Typography, Grid, Paper, Button, FormControl, RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
import { NODE_ENV, REACT_APP_API_HOST } from '../../envs';
import { gql, useQuery, useMutation  } from '@apollo/client';
import { useConfirmation } from "../../ConfirmationService";
import CustomToggleGroup from '../../common/CustomToggleGroup'
import CPU from '../Cpu';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    container: {
        marginTop: '8px'
    },
    item: {
        marginTop: '16px'
    },
    list: {
        position: 'relative',
        overflow: 'auto',
        width: '100%',
        paddingBottom:'0px',
    },
    title: {
        marginLeft: '8px'
    },
    listSubheader1: {
        backgroundColor: theme.palette.grey[300],
        color: "black",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderBottom: "1px solid " + theme.palette.grey[200]
    },
    "@media (min-width: 1080px)":{
        list:{
            boxShadow: theme.shadows[5],
        },
    },
    listItem: {
        justifyContent: 'space-between',
    },
    gridListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.main,
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
    },
    buttonBase: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    drawerIcon: {
        width: '40px'
    },
    paper: {
        padding: "16px"
    }
}));


const WAREHOUSES_QUERY = gql`
    query allWarehouses{
        allWarehouses {
            title value emails
        }
    }
`;

const UNLOCK_MUTATION = gql`
    mutation unlockTakeoff($id: ID!){
        unlockTakeoff (id: $id) {
            id code isLocked 
        }
    }
`;

const ShareTakeoff = ({ context }) => {
    const classes = useStyles();
    const {state, isDoneTakeoff, } = context;
    const {takeoffEditId, isLocked, lockedAt} = state;
    
    const {data} = useQuery(WAREHOUSES_QUERY);
    const [unlockMutation] = useMutation(UNLOCK_MUTATION);
    const disabled = isDoneTakeoff();
    const [reviewType, setReviewType] = React.useState('');
    const [processingOffice, setProcessingOffice] = React.useState(context.state.location || '');

    const confirm = useConfirmation();
    const [isSend, setIsSend] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [counter, setCounter] = React.useState(0);

    let warehouses = [];
    if (data && data?.allWarehouses) {
        warehouses = data.allWarehouses
    }

    const handleDownload = async () => {
        const downloadPromise = downloadXML(context.state.takeoffEditId, context.state.code);
        downloadPromise.then(value => value && context.setState({status: 'estimate'}))
    }

    const handleUnlock = () => {
        confirm({
            variant: "danger",
            catchOnCancel: true,
            title: "Unlock",
            description: `The takeoff has been locked for than 10 minutes, Are you sure you want to unlock this takeoff?`
        })
        .then(() => {
            unlockMutation({variables: {id: takeoffEditId}})
        })
        .catch(() => console.log('error'));
      }

    const ShareTO = (warehouse) => {
        setIsSend(true);
        confirm({
                variant: warehouse ? "danger" : 'info',
                catchOnCancel: true,
                title: "Submit for review",
                description: warehouse ? `Are you sure you want to submit this Takeoff for review?` : 'Please select an office to process this Takeoff!'
            })
            .then(() => {
                if (warehouse) {
                    const send = context.notifyOffice(warehouse, reviewType);
                    send.then(res => {
                        if (res) {
                            const date = new Date();
                            setIsSend(false);
                            setCounter(counter+1);
                            setMessage(`Sent on ${date.toLocaleString('en-US')}`)
                        }
                    })
                } else {
                    setIsSend(false);
                }
            })
            .catch(() => setIsSend(false));
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {
                    ['admin', 'staff'].includes(localStorage.getItem('role')) &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}><Typography variant="h6">Download to Seradex</Typography></Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        className={classes.margin}
                                        disabled={isSend}
                                        onClick={handleDownload}
                                    >
                                        Download
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant="h6">Check Product Updates (CPU)</Typography></Grid>
                            <Grid item xs={12}>
                                <CPU />
                            </Grid>
                            {
                                isLocked && 
                                (!lockedAt || (new Date() - new Date(lockedAt) ) / (1000 * 60) > 5 ) &&
                                <Button
                                    onClick={handleUnlock}
                                    color={'default'}
                                    variant="contained"
                                >
                                    Cancel CPU
                                </Button>
                            }
                        </Grid>
                    </Paper>
                </Grid>
                {
                    !disabled &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}><Typography variant="h6">Submit for review</Typography></Grid>
                                <Grid>
                                    <CustomToggleGroup
                                        disabled={disabled}
                                        items={{
                                            'quote': 'To Quote',
                                            'process': 'To Process',
                                        }}
                                        value={reviewType}
                                        handleChange={(event, value) => setReviewType(value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        <FormControl component="fieldset" fullWidth>
                                            <RadioGroup
                                                aria-label={`Processing Office`}
                                                name={`office` }
                                                // style={{flexDirection: 'row'}}
                                                value={processingOffice}
                                                onChange={(event) => setProcessingOffice(event.target.value)}
                                                disabled={isSend}
                                            >
                                                {
                                                    warehouses.map( (warehouse, i) => (<FormControlLabel key={i} value={warehouse.value} control={<Radio />} label={warehouse.title} />))
                                                }
                                                {
                                                    (!warehouses || warehouses.length === 0) &&
                                                    <FormControlLabel control={<Radio />} label={'No available offices'} />
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        disabled={!reviewType ||!processingOffice || isSend}
                                        className={classes.margin}
                                        onClick={()=> ShareTO(processingOffice)}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                {
                                    message &&
                                    <Grid item xs={12}>
                                        <Typography>{message}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                }
                <ActivityLog key={counter} title="History" action="NOTIFY" id={context.state.takeoffEditId} />
            </Grid>
        </div>
    )
};

const downloadXML = (takeoffId, code) => {
    const apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
    let config = {
        headers: {'Authorization': "bearer " + localStorage.getItem('token')},
        timeout: 4000
    };
    let data = {takeoffId};
    return axios.post(apiBaseUrl+'export-xml', data, config)
        .then((res) => {
            if (res) {
                triggerDownload(res.data, code)
                return true
            }
        })
        .catch(function (err) {
            if (err.code !== 'ECONNABORTED'){
                // console.log('Error',err.code)
            }
            return false
        });
}

const triggerDownload = (data, code) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const blob = new Blob([data], {type: "text/xml"});
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = (code ? code : 'takeoff') +'-'+ Math.floor(Date.now() / 1000) ;
    a.click();
    window.URL.revokeObjectURL(url);
}



export default ShareTakeoff