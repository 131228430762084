import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import { ApolloConsumer } from "@apollo/client";
//components
import TakeoffTabs from './NewTakeoff/TakeoffTabs';
import {TakeoffFormProvider} from './TakeoffFormContext'
import { ConfirmationServiceProvider } from "./ConfirmationService";

const useStyles = makeStyles(() => ({
    root:{

    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

const UpdateTakeoff = ({ match }) => {
    const classes = useStyles();
    const takeoffId = match.params.id !== 'undefined' ? match.params.id : '';
    return (
        <ApolloConsumer>
        {
            apolloClient => (
                <TakeoffFormProvider apolloClient={apolloClient} takeoffid={takeoffId} >
                    <ConfirmationServiceProvider>
                        <Helmet>
                            <title>Riverside Takeoffs :: Update Takeoff</title>
                        </Helmet>
                        <div className={classes.root}>
                            <TakeoffTabs/>
                        </div>
                    </ConfirmationServiceProvider>
                </TakeoffFormProvider>
            )
        }
        </ApolloConsumer>
    );
}

export default UpdateTakeoff