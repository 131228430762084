import React, {useEffect, useReducer} from "react";
//material UI
import { makeStyles } from "@material-ui/core/styles";
import {
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Slide, 
    FormHelperText, 
    FormControl, 
    Button, 
    Typography, 
    Paper,
    TextField,
    Grid
} from "@material-ui/core";
//components
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomerDialog from "./CustomerList";
import ProspectDialog from "./Prospect";
import * as Lib from './lib/lib'
import CustomToggleGroup from '../common/CustomToggleGroup';
import TakeoffNotes from './TakeoffNotes'
import CustomerDetails from './CustomerDetails'
import AdditionalOrder from './AdditionalOrder'
import useDeepCompareEffect from 'use-deep-compare-effect'
import SVU from '../../images/svu.mp3';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    button: {
        marginRight: '16px'
    },
    appBar: {
        position: "relative"
    },
    flex: {
        flex: 1
    },
    paper: {
        padding: "16px",
        height: '100%'
    },
    input: {
        color: 'white',
        '::before':{
            backgroundColor: 'white',
            '::hover':{
                backgroundColor:'white',
            },
        },

    },
    'input::before':{
        backgroundColor: 'white',
    },
    closeButton: {
        marginRight: -12
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const WAIT_INTERVAL = 1000;

function reducer(state, action) {
    if (action.type === 'edit') {
      return {
        ...state,
        ...action.payload
      };
    }
    throw Error('Unknown action.');
  }

const CustomerInfo = (props) => {
    const {context, fullScreen } = props;
    const [state, dispatch] = useReducer(reducer, {
        siteDialog: false,
        customerListDialog: false,
        prospectDialog: false,
        customerType: context.state.customerType,
        customerInfo: context.state.customerType && context.state[context.state.customerType] || {},
        site: {},
        prospect: {},
        validate: false,
        changeCounter: 0
    })
    const {customerType, customerInfo, site, validate, customerListDialog, prospectDialog, siteDialog} = state;
    let timer;

    const handleChange = (value, context) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            context.setState({customerKeyword: value})
        }, WAIT_INTERVAL);
    }

    useEffect(() => {
        timer = null;
    }, []);

    useEffect(() => {
        if (customerType) {
            context.updateCustomer(customerInfo, customerType);
        }
    }, [customerType]);

    useDeepCompareEffect(() => {
        if (
            !prospectDialog && !customerListDialog && !siteDialog &&
            customerInfo.pricingTier
        ) {
            context.updateCustomer(customerInfo, customerType)
        }
    }, [customerInfo]);

    const saveSiteDetails = ( clear = false) => {
        const site = clear ? {} : state.site;
        // console.log(clear, site, site);
        // const validation = Lib.deepAttributeExists();
        if (
            !clear &&
            (!site.name || !site.civic || !site.city )
        ) {
            dispatch({
                type: 'edit',
                payload: { validated: true }
            });
        } else {
            dispatch({
                type: 'edit',
                payload: {
                    siteDialog: false,
                    site: {}
                }
            });
            context.saveSiteDetails(site)
            
        }
    }

    const openSiteDialog = (dialog, type) => {
        dispatch({
            type: 'edit',
            payload: {
                [dialog]: true,
                [type]: context.state[type] || {}
            }
        });
    };

    const closeDialogs = () => {
        dispatch({
            type: 'edit',
            payload: {
                siteDialog: false,
                customerListDialog: false,
                prospectDialog: false,
            }
        });
    };

    const handleProspect = field => (event, value) => {
        dispatch({
            type: 'edit',
            payload: {
                customerInfo: {
                    ...state.customerInfo,
                    [field]: field === 'pricingTier' ? value : event.target.value
                }
            }
        });
    };

    const updateCustomer = (customer) => {
        dispatch({
            type: 'edit',
            payload: {
                customerInfo: customer
            }
        });
    }

    const updateSiteDetails = (field, value) => {
        dispatch({ 
            type: 'edit',
            payload: { 
                site: { 
                    ...site, 
                    [field]: value
                } 
            } 
        })
    }

    const handleInstallPricing = (event, value) => {
        if (value === 'union') {
            const audio = new Audio(SVU);
            audio.volume = 0.1;
            audio.play();
        }
        context.saveInstallPricing('installPricingMode', value || '');
    }

    const classes = useStyles();
    
    const disabled = context.isDoneTakeoff();
    return (
        <div className={classes.root}>
            <CustomerDialog
                context={context}
                rootClasses={classes}
                open={customerListDialog}
                closeDialog={closeDialogs}
                handleChange={handleChange}
                updateCustomer={updateCustomer}
            />

            <ProspectDialog
                prospect={customerInfo}
                context={context}
                rootClasses={classes}
                open={prospectDialog}
                closeDialog={closeDialogs}
                handleProspect={handleProspect}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom={false}>
                                    Customer
                                </Typography>
                                <CustomToggleGroup
                                    disabled={disabled}
                                    items={{
                                        customer: 'Customer',
                                        prospect: 'Prospect',
                                    }}
                                    value={customerType}
                                    handleChange={ (event, value) => dispatch({
                                        type: 'edit',
                                        payload: {
                                            customerType: value
                                        }
                                    }) }
                                />
                            </Grid>
                            <CustomerDetails
                                showPricing
                                customerType={customerType}
                                customer={customerInfo}
                                updateCustomer={updateCustomer}
                            />
                            {
                                customerType &&
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() => openSiteDialog(customerType === 'customer' ? 'customerListDialog' : 'prospectDialog', customerType)}
                                        color={Lib.isEmptyOrNullObject(context.state[customerType]) ? 'secondary' : 'default'}
                                        className={classes.button}
                                        variant="contained"
                                        disabled={disabled}
                                    >
                                        {Lib.isEmptyOrNullObject(context.state[customerType]) ? 'Add' : 'Edit'}
                                    </Button>
                                    {
                                        (
                                            customerType  ||
                                            !Lib.isEmptyOrNullObject(context.state[customerType])
                                        )
                                        &&
                                        <Button
                                            disabled={disabled}
                                            onClick={() => context.removeCustomer()}
                                            color={'default'}
                                            className={classes.button}
                                            variant="contained"
                                        >
                                            Clear
                                        </Button>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Paper>
                </Grid>
                {
                    <Dialog
                        fullScreen={fullScreen}
                        open={siteDialog}
                        onClose={() => dispatch({ type: 'edit', payload: { siteDialog: false } })}
                        TransitionComponent={Transition}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth={'md'}
                    >
                        <DialogTitle id="responsive-dialog-title">
                            Edit Site Details
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        required
                                        fullWidth
                                        error={validate && !site.name}
                                        label="Site Name"
                                        placeholder="Enter the Site Name"
                                        margin="dense"
                                        variant="outlined"
                                        // type={'number'}
                                        value={site.name || ''}
                                        onChange={ (event) => updateSiteDetails('name', event.target.value)}
                                    />
                                    {
                                        validate && !site.name &&
                                        <FormHelperText error>Site Name is required</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="lot"
                                        fullWidth
                                        label="Lot"
                                        placeholder="Enter the Lot Number"
                                        margin="dense"
                                        variant="outlined"
                                        // type={'number'}
                                        value={site.lot || ''}
                                        onChange={ (event) => updateSiteDetails('lot', event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="address"
                                        error={validate && !site.civic}
                                        fullWidth
                                        required
                                        label="Civic"
                                        placeholder="Enter the Site Address"
                                        margin="dense"
                                        variant="outlined"
                                        value={site.civic || ''}
                                        onChange={ (event) => updateSiteDetails('civic', event.target.value)}
                                    />
                                    {
                                        validate && !site.civic &&
                                        <FormHelperText error>Civic is required</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="intersection"
                                        fullWidth
                                        label="Intersection"
                                        placeholder="Enter the Nearest Intersection"
                                        margin="dense"
                                        variant="outlined"
                                        value={site.intersection || ''}
                                        onChange={ (event) => updateSiteDetails('intersection', event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="city"
                                        error={validate && !site.city}
                                        fullWidth
                                        required
                                        label="City"
                                        placeholder="Enter the City"
                                        margin="dense"
                                        variant="outlined"
                                        value={site.city || ''}
                                        onChange={ (event) => updateSiteDetails('city', event.target.value)}
                                    />
                                    {
                                        validate && !site.city &&
                                        <FormHelperText error>City is required</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="modelName"
                                        fullWidth
                                        label="Model Name"
                                        placeholder="Enter the Model Name"
                                        margin="dense"
                                        variant="outlined"
                                        value={site.modelType || ''}
                                        onChange={ (event) => updateSiteDetails('modelType', event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="elevation"
                                        fullWidth
                                        label="Elevation"
                                        placeholder="Enter the Elevation"
                                        margin="dense"
                                        variant="outlined"
                                        value={site.modelElevation || ''}
                                        onChange={ (event) => updateSiteDetails('modelElevation', event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="option"
                                        fullWidth
                                        label="Option"
                                        placeholder="Enter the Option"
                                        margin="dense"
                                        variant="outlined"
                                        value={site.modelOption || ''}
                                        onChange={ (event) => updateSiteDetails('modelOption', event.target.value) }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => dispatch({
                                    type: 'edit',
                                    payload: {
                                        site: {},
                                        siteDialog: false
                                    }
                                })}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => saveSiteDetails()}
                                variant={'contained'}
                                color="secondary"
                                disabled={Lib.isEmptyOrNullObject(site) || disabled}
                            >
                                Apply
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
                <Grid item xs={12} md={4}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom={false}>
                                    Site Details
                                </Typography>
                            </Grid>
                            {
                                context.state.site &&
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom={false}>
                                        {context.state.site.name || ''}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom={false}>
                                        {context.state.site.lot || ''}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom={false}>
                                        {context.state.site.civic || ''}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom={false}>
                                        {context.state.site.intersection ? `Near ${context.state.site.intersection}` : ''}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom={false}>
                                        {context.state.site.city || ''}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom={false}>
                                        {context.state.site.modelType ? `${context.state.site.modelType}, ` : ''} {context.state.site.modelElevation ? `${context.state.site.modelElevation}, ` : ''} {context.state.site.modelOption || ''}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => openSiteDialog('siteDialog', 'site')}
                                    color={Lib.isEmptyOrNullObject(context.state.site)? 'secondary':'default'}
                                    className={classes.button}
                                    variant="contained"
                                    disabled={disabled}
                                >
                                    {Lib.isEmptyOrNullObject(context.state.site)? 'Add':'Edit'}
                                </Button>
                                {
                                    !Lib.isEmptyOrNullObject(context.state.site) &&
                                    <Button
                                        disabled={disabled}
                                        onClick={() => saveSiteDetails(true)}
                                        color={'default'}
                                        className={classes.button}
                                        variant="contained"
                                    >
                                        Clear
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom={false}>
                                    Installation
                                </Typography>
                                <CustomToggleGroup
                                    disabled={disabled || (!context.isEmptyTakeoff() && context.state.installPricingMode === 'union')}
                                    disableItem={!context.isEmptyTakeoff() ? ['union'] : []}
                                    items={{
                                        contract_pricing: 'Per Opening',
                                        union: 'SVU',
                                        none: 'No Install'
                                    }}
                                    value={context.state.installPricingMode}
                                    handleChange={ handleInstallPricing}
                                />
                            </Grid>
                            {
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth className={classes.formControl}>
                                            <DatePicker
                                                autoOk
                                                disabled={disabled}
                                                label="Due Date"
                                                variant="dialog"
                                                inputVariant="outlined"
                                                clearable
                                                value={context.state.installAt}
                                                onChange={(date) => context.saveInstallPricing('installAt', date || null)}
                                                animateYearScrolling={false}
                                                format={'dd/MM/yyyy'}
                                                // mask={value => value ? "__/__/____" : ""}
                                            />
                                        </FormControl>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            }
                        </Grid>
                    </Paper>
                </Grid>
                {
                    context.state.isAdditionalOrder &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <AdditionalOrder 
                                additionalOrder={context.state.additionalOrder}
                                disabled={context.isDoneTakeoff()} 
                                saveAdditionalOrderInfo={context.saveAdditionalOrderInfo}
                            />
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">General Notes</Typography>
                            </Grid>
                            <TakeoffNotes
                                showNotes
                                disabled={disabled}
                                value={context.state.notes}
                                onChange={(value)=> context.saveInstallPricing('notes', value || '')}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default CustomerInfo
