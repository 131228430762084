import React from "react";
//material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpecStatusIcon from './SpecStatusIcon';
import {trimAttributes} from '../../common/constants'
import SpecsType from './SpecsType'
import * as Lib from "../lib/lib";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper: {
        padding: "16px"
    },
    expansionPanelSummary: {
        paddingRight: "16px",
        paddingLeft: "16px"
    },
    flex: {
        flexGrow: 1
    },

    closeButton: {
        marginLeft: -12
    },
    icon:{
        marginRight: '8px'
    },
    list: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
        margin: '8px'
    }
}));

const Trim = (props) => {
    const { context } = props;
    const classes = useStyles();

    const handleSpecChange = (category, type) => ({specs, propagate}) => {
        if (
            !Lib.isEmptyOrNullObject(specs) &&
            type !== 'curve'
        ) {
            const typeAttributes = {
                category: Lib.humanize(category),
                type: type === 'sill' ? 'Window Stool' : Lib.humanize(/jamb/.test(type) ? 'jamb' : type),
                ...specs,
            };
            // console.log(typeAttributes)
            const typePromise = context.getAnyProduct(typeAttributes);
            typePromise.then(product => {
                if (product) {
                    const typeProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        attributes:     typeAttributes
                    };
                    // console.log(typeProduct);
                    context.saveSpecs({
                        product: typeProduct,
                        category,
                        type,
                        specs,
                        propagate
                    });

                    if (
                        ['casing', 'baseboard'].includes(type)
                    ) {
                        const {baseboard, casing} = context.state.specs.trim;
                        if (
                            (
                                (type === 'casing' && Lib.deepAttributeExists(['width'], specs)) ||
                                Lib.deepAttributeExists(['width'], casing)
                            )
                            &&
                            (
                                (type === 'baseboard' && Lib.deepAttributeExists(['width'], specs)) ||
                                Lib.deepAttributeExists(['width'], baseboard)
                            )
                        ) {
                            const attributes = {
                                supplier: specs.supplier,
                                casing: Lib.parseStringNumber(type === 'casing' ? specs.width : casing.width),
                                baseboard: Lib.parseStringNumber(type === 'baseboard' ? specs.width : baseboard.width)
                            }
                            const openeningServicePromise = context.getLabourCost({
                                category: 'Install',
                                type: 'Opening',
                                attributes
                            });
                            openeningServicePromise.then(opening => {
                                if(opening) {
                                    const {pricingTiers, ...service} = opening;
                                    context.saveSpecs({
                                        product: {
                                            ...service,
                                            price: pricingTiers
                                        },
                                        category: 'products',
                                        type: 'opening_service',
                                        attributes
                                    });
                                }
                            })
                        }
                    }
                }
            })
        } else {
            context.saveSpecs({
                product: {},
                category,
                type,
                specs,
                propagate
            });
        }
    };


    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                {
                    [
                        'baseboard',
                        'casing',
                        'doorstop',
                        'jamb',
                        'jamb_84',
                        'jamb_96',
                        'crown_moulding',
                        'shoe_moulding',
                        'flat_stock',
                        'architrave',
                        'backband',
                        'burlap',
                        'sill',
                        'cove',
                        'quarter_round',
                        'handrail',
                        'attic_lid',
                        'attic_hatch',
                        'attic_foam_tape',
                        'shim',
                        'curve',
                        'panel_mould',
                        'shiplap',
                        'column',
                        'sheet'
                    ].sort().map((type, index) => {
                        return (
                            <Accordion key={"trim-"+index} elevation={4}>
                                <AccordionSummary
                                    className={classes.expansionPanelSummary}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <SpecStatusIcon
                                        specs={context.state.specs.trim[type]}
                                        product={context.state.specs.products[type] || {}}
                                        attributes={trimAttributes(type, true)}
                                    />
                                    <Typography variant="h6">{context.humanize(type)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            context.isSpecProductSet(type) &&
                                            <Grid item xs={12} className={classes.list}>
                                                <Typography variant="body2">{context.state.specs.products[type].description}</Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <SpecsType
                                                hideCopy
                                                disabled={context.isDoneTakeoff()}
                                                category={'trim'}
                                                type={type === 'sill' ? "window_stool" : /jamb/.test(type) ? 'jamb' : type}
                                                attributes={trimAttributes(type)}
                                                handleChange={handleSpecChange('trim', type)}
                                                specs={
                                                    context.state.specs.trim[type]
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </Grid>
        </Grid>
    );
}


export default Trim
