import React from "react";
import { AdditionalOrderConstants } from "../common/constants";
import { Grid, Typography, FormControlLabel, Radio, Switch } from "@material-ui/core";
import AdditionalItem from "./Measure/AdditionalItem";


const AdditionalOrder = ({additionalOrder, disabled, saveAdditionalOrderInfo}) => {
    
    const onChange = (group) => (field, value = '') => {
        if (group === 'additionalItem') {
            saveAdditionalOrderInfo({
                ...additionalOrder,
                [group]: field,
            })
        } else {
            saveAdditionalOrderInfo({
                ...additionalOrder,
                [field]: value,
            })
        }
    };
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Additional Order</Typography>
                <Typography variant="caption">Reasons for additional order (Select One)</Typography>
            </Grid>
            
            {/*   date_required */}
            <Grid item xs={12} md={6} lg={4}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            checked={additionalOrder.rush_request}
                            onChange={(event) => onChange(null)('rush_request', event.target.checked) }
                        />
                    }
                    label="Immediate/Rush Request?"
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            checked={additionalOrder.home_occupied}
                            onChange={(event) => onChange(null)('home_occupied', event.target.checked)}
                        />
                    }
                    label="Home Occupied?"
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Reason for Additional Order</Typography>
                    </Grid>
                    {
                        Object.keys(AdditionalOrderConstants.riversideError).map( (item) => (
                            <Grid key={item} item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            disabled={disabled}
                                            checked={additionalOrder.riversideError === item}
                                            value={item}
                                            onChange={(event) => onChange(null)('riversideError', event.target.value)}
                                        />
                                    }
                                    label={AdditionalOrderConstants.riversideError[item]}
                                />

                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AdditionalItem
                    title={"For Each Additional Items"}
                    additionalItem={additionalOrder.additionalItem || {}}
                    disabled={disabled}
                    onItemChange={onChange('additionalItem')}
                />
            </Grid>
        </Grid>
    )
}

export default AdditionalOrder