import React from "react";
import { Helmet } from 'react-helmet-async';
import { ApolloConsumer } from "@apollo/client";
//components
import TakeoffTabs from './NewTakeoff/TakeoffTabs';
import {TakeoffFormProvider} from './TakeoffFormContext';
import { ConfirmationServiceProvider } from "./ConfirmationService";



const NewTakeoff = ({ location}) => {
    const { takeoffid } = location;
    return (
        <ApolloConsumer>
        {
            apolloClient => (
                <TakeoffFormProvider apolloClient={apolloClient} takeoffid={takeoffid}>
                    <ConfirmationServiceProvider>
                        <Helmet>
                            <title>Riverside Takeoffs :: New Takeoff</title>
                        </Helmet>
                        <div>
                            <TakeoffTabs />
                        </div>
                    </ConfirmationServiceProvider>
                </TakeoffFormProvider>
            )
        }
        </ApolloConsumer>
    )
}

export default NewTakeoff