import React, {useState, useEffect, useContext} from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import Button from "@material-ui/core/Button";
import { useConfirmation } from "../../components/ConfirmationService";
import {TakeoffFormContext} from '../TakeoffFormContext';
import CircularProgress from '@material-ui/core/CircularProgress'
import {usePrevious} from '../common/hooks'

const REQUEST_CPU = gql`
  mutation RequestCpu ($id: ID!) {
    requestCpu (id: $id)
  }
`;

const GET_TAKEOFF = gql`
  query checkLocked ($id: ID!) {
    checkLocked (id: $id) {
      isLocked checkedAt lockedAt
    }
  }
`;

const CPU = ({interactive = true}) => {
  const [ping, setPing] = useState(false)
  const prevPing = usePrevious(ping);

  const context = useContext(TakeoffFormContext);
  const {state, refreshTakeoffData, isDoneTakeoff} = context;
  const {takeoffEditId, isLocked} = state;
  
  const disabled = isDoneTakeoff() || isLocked;
  const [requestCpu] = useMutation(REQUEST_CPU);
  const confirm = useConfirmation();

  const {  data, startPolling, stopPolling } = useQuery(GET_TAKEOFF, {
    variables: {id: takeoffEditId},
    skip: !takeoffEditId || !ping || !isLocked
  })
  
  const handleClick = () => {
    confirm({
        variant: "danger",
        catchOnCancel: true,
        title: "Request CPU",
        description: `Are you sure you want to run CPU for this takeoff?`
    })
    .then(() => {
        requestCpu({variables: {id: takeoffEditId}})
        context.setState({isLocked: true, lockedAt: new Date()})
        setTimeout(setPing(true), 3000);
    })
    .catch(() => console.log('error'));
  }

  useEffect(() => {
    if (isLocked && !ping) {
      // console.log(`TO is locked at opening`)
      setPing(true)
    }
  }, []);

  useEffect(() => {
    if (!prevPing && ping) {
      // console.log(`Start polling`)
      startPolling(5000)
    } else {
      // console.log(`Stop polling`)
      stopPolling()
    }
  }, [ping, startPolling, stopPolling]);

  useEffect(() => {
    // console.log(prevPing, ping, data?.checkLocked?.isLocked === false)
    if (prevPing && ping && data?.checkLocked?.isLocked === false) {
      setPing(false);
      refreshTakeoffData()
      // setTimeout(refreshTakeoffData(), 3000);
    }
  }, [data?.checkLocked?.isLocked, data?.checkLocked?.checkedAt, prevPing, setPing, refreshTakeoffData]);

  // console.log(prevPing)
  return (
      takeoffEditId ?
      interactive ?
        <Button
          onClick={handleClick}
          color={'default'}
          variant="contained"
          disabled={disabled}
        >
          { ping ? <CircularProgress /> : "Request" }
        </Button> : 
      isLocked ? <CircularProgress /> : ""
      : "" 
  )
}

export default CPU;