import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles/index";
import { useOnClickOutside, useKeypress } from "./hooks";

const useStyles = makeStyles(() => ({
  // inlineTextCopy,
  inlineTextInput: { // --active
    background: 'none',
    border: 'none',
    borderBottom: '1px dashed #999999',
    outline: 'none',
  },
  inlineTextCopy: { //--active
    cursor: 'pointer'
  },
  // inlineTextCopy,
  // inlineTextInput: { // --hidden
  //   display: 'none'
  // },
  // inlineTextInput: { //--active
  //   borderBottom: '1px solid #666666',
  //   textAlign: 'left'
  // }
}));

function InlineEdit(props) {
  const classes = useStyles();
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.text);

  const wrapperRef = useRef(null);
  const textRef = useRef(null);
  const inputRef = useRef(null);

  const enter = useKeypress("Enter");
  const esc = useKeypress("Escape");

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      props.onSetText(inputValue);
      setIsInputActive(false);
    }
  });

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive) {
      inputRef.current.focus();
    }
  }, [isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      // if Enter is pressed, save the text and case the editor
      if (enter) {
        props.onSetText(inputValue);
        setIsInputActive(false);
      }
      // if Escape is pressed, revert the text and close the editor
      if (esc) {
        setInputValue(props.text);
        setIsInputActive(false);
      }
    }
  }, [enter, esc]); // watch the Enter and Escape key presses

  // console.log(isInputActive)
  return (
    <span className={classes.inlineText} ref={wrapperRef}>
      {
        isInputActive ? 
        <TextField
          variant="outlined"
          size="small"
          ref={inputRef}
          // style={{ width: Math.ceil(inputValue.length * 1.2) + "ex" }}
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value);
          }}
        /> :
        <span
          ref={textRef}
          onClick={() => setIsInputActive(true)}
        >
          {props.text}
        </span>
      }
      
    </span>
  );
}

export default InlineEdit;