import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import TakeoffInfoSummary from './TakeoffInfoSummary';
import * as Lib from '../lib/lib';
import {SummaryLabel} from '../../common/hooks';

const DoorPrepOpeningInstall = ({ installItems, wire_install, opening, opening_count, extra, has, notes, classes, context }) => {

  return (
    (
        (
            opening_count > 0 &&
            Lib.deepAttributeExists(['opening_service', 'code'], context.state.specs.products)
        ) ||
        (extra && Object.keys(extra).length > 0 ) ||
        has.wire_install
    ) ?
    <>
        <Grid spacing={2} container className="break-print">
            <Grid item xs={12}>
                <TakeoffInfoSummary
                    isPrint
                    label="Onsite Prep"
                    notes={notes}
                />
            </Grid>
            <Grid item xs={12}>
                <List className={classes.list+ " groupesdList"} subheader={<li />}>
                    <ListSubheader className={classes.listSubheader}>
                        <Grid container spacing={1}>
                            <Grid item xs={10}><Typography variant="body1">Install</Typography></Grid>
                            <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                        </Grid>
                    </ListSubheader>
                    {
                        opening_count > 0 &&
                        Lib.deepAttributeExists(['opening_service', 'code'], context.state.specs.products) &&
                        <ListItem className={classes.summaryDoorListItem}>
                            <Grid  container spacing={1}>
                                <Grid item xs={10}><SummaryLabel code={context.state.specs.products.opening_service.code} /></Grid>
                                <Grid item xs={2}><Typography>{opening_count} {Lib.uoms(context.state.specs.products.opening_service.uom)}</Typography></Grid>
                                <Grid item xs={12}><Typography variant="caption">{context.state.specs.products.opening_service.description}</Typography></Grid>
                            </Grid>
                        </ListItem>
                    }
                    {
                        opening_count > 0 &&
                        Lib.deepAttributeExists(['opening_service', 'code'], context.state.specs.products) &&
                        Object.keys(opening).map( (code, i) => {
                            return (
                                <ListItem key={i} className={classes.summaryDoorListItem}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={10}><SummaryLabel code={Lib.humanize(installItems[code], true)} /></Grid>
                                        <Grid item xs={2}><Typography>{opening[code]['count']} EA</Typography></Grid>
                                        <Grid item xs={12}><Typography variant="caption">****{Lib.humanize(code, true)}****</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })
                    }
                    {
                        extra &&
                        Object.keys(extra).map( (code, i) => {
                            return (
                                <ListItem key={i} className={classes.summaryDoorListItem}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={10}><SummaryLabel code={code} /></Grid>
                                        <Grid item xs={2}><Typography>{extra[code].count}  {Lib.uoms(extra[code].uom) }</Typography></Grid>
                                        <Grid item xs={12}><Typography variant="caption">{extra[code].description}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })
                    }
                    {
                        has.wire_install &&
                        Object.keys(wire_install).map( (code, i) => {
                            return (
                                <ListItem  key={i} className={classes.summaryDoorListItem}>
                                    <Grid  container spacing={1}>
                                        <Grid item xs={10}><SummaryLabel code={code} /></Grid>
                                        <Grid item xs={2}><Typography>{Math.ceil(wire_install[code].count)}  {Lib.uoms(wire_install[code].uom) }</Typography></Grid>
                                        <Grid item xs={12}><Typography variant="caption">{wire_install[code].description}</Typography></Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Grid>
        </Grid>
    </> : ""
  );
};

export default DoorPrepOpeningInstall;