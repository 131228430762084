import React from "react";
import UserListContext from './UserListContext'
import { ApolloConsumer, gql } from '@apollo/client';

export default class UserProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            userIds: [],
            confirmDialogOpen: false,
            search: '',
            deleteUserId: '',
            cloneUserId: '',
            showFilters: false,
            filter: {
                code:       '',
                customer:   '',
                fromDate:   null,
                toDate:     null,
                createdBy:  '',
            },
            anchorEls: {
                '5b201f256a88158e9ed65ac8': null
            },
        }
    }

    cloneUser = async (apolloClient) => {
        const CLONE_TAKEOFF = gql`
            mutation cloneUser{
                cloneUser(id: "${this.state.cloneUserId}") {
                    id, username, email, phone, name, role, createdBy{username}, createdAt
                }
            },
        `;

        const GET_TAKEOFFS = gql`
            query AllUsers{
                allUsers {
                    id, username, email, phone, name, role, createdBy{username}, createdAt
                }
            }
        `;

        const {data} = await apolloClient.mutate({
            mutation: CLONE_TAKEOFF,
            // refetchQueries: [{ query: GET_TAKEOFFS}],
            update: (store, { data: { cloneUser } }) => {
                // console.log('mutation:',cloneUser)
                const data = store.readQuery({ query: GET_TAKEOFFS });
                data.allUsers.unshift(cloneUser);
                store.writeQuery({ query: GET_TAKEOFFS, data });
            },
        });
        // console.log('data',data)
        if (data.cloneUser !== null) {
            // console.log('mutation response:',data.cloneUser)
            return data.cloneUser
        }
        return false
    }

    deleteUser = async (apolloClient) => {
        const DELETE_USER = gql`
            mutation deleteUser{
                deleteUser(id: "${this.state.deleteUserId}")
            }
        `;

        const GET_USERS = gql`
            query AllUsers{
                allUsers {
                    id, username, email, phone, name, role, createdBy{username}, createdAt
                }
            }
        `;

        const {data} = await apolloClient.mutate({
            mutation: DELETE_USER,
            refetchQueries: [{ query: GET_USERS}]
        });
        if (data.deleteUser !== null) {
            return data.deleteUser
        }
        return false
    }

    render () {
        return (
            <ApolloConsumer>
                { apolloClient => {
                    return (
                        <UserListContext.Provider value={{
                            state: this.state,
                            setState: (obj) => {
                                this.setState(obj)
                                //console.log(obj)
                            },
                            cloneUser: () => {
                                var clonePromise = this.cloneUser(apolloClient);
                                clonePromise.then( (user) => {
                                    if (user) {
                                        // console.log(`user cloned is ${this.state.cloneUserId} and new user is ${user.id}`)
                                    }
                                });
                                this.setState({ confirmDialogOpen: false, cloneUserId: ''})
                            },
                            removeUser: () => {
                                var deletePromise = this.deleteUser(apolloClient);
                                deletePromise.then( (user) => {
                                    if (user) {
                                        // console.log(`user deleted is ${this.state.deleteUserId}`)
                                    }
                                });
                                this.setState({ confirmDialogOpen: false, deleteUserId: ''})
                            },
                            searchUser: () => {

                            },
                            filterUsers: () => {
                                this.setState({
                                    showFilters: false,
                                }, () => {
                                    // this.loadTakeoffList(apolloClient)
                                })
                            },
                            clearFilters: () => {
                                this.clearFilters()
                            },
                            closeFilters: () => {
                                this.setState({
                                    showFilters: false,
                                    filter: {
                                        code:       '',
                                        customer:   '',
                                        fromDate:   null,
                                        toDate:     null,
                                        createdBy:  '',
                                    },
                                })
                            },
                            showFilter: () => {
                                this.setState({
                                    showFilters: true
                                })
                            }
                        }}>
                            {this.props.children}
                        </UserListContext.Provider>
                    )
                }}
            </ApolloConsumer>
        )
    }
}