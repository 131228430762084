import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles/index";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import NotesAndImages from "./NotesAndImages"
import NewExteriorDoor from "./NewExteriorDoor"
import * as Lib from '../lib/lib';
import SpecsType from '../Specs/SpecsType';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CustomToggleGroup from '../../common/CustomToggleGroup'
import WWLLIcon from '../../../images/caps/2W+2L.png'
import WWLIcon from '../../../images/caps/2W+L.png'
import LIcon from '../../../images/caps/L.png'
import LLIcon from '../../../images/caps/L+L.png'
import WLLIcon from '../../../images/caps/W+2L.png'
import WLIcon from '../../../images/caps/W+L.png'
import JambMaterial from './JambMaterial'
import {trimAttributes} from '../../common/constants'
import AdditionalItem from './AdditionalItem';
import MeasureUnits from './MeasureUnits'
import InlineEdit from '../../common/InlineEdit'

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop:"16px",
        maxWidth : 1080,
        display: "flex",
        alignItems:"center",
        justifyContent: "center",
        margin: "auto",
    },
    paper:{
        padding:"16px",
    },
    group:{
        flexDirection: "row",
    },
    chip: {
        margin: theme.spacing(1),
    },
    button: {
        // margin: theme.spacing(1),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    list: {
        border: "1px solid "+theme.palette.grey[300],
        borderRadius: "4px",
        backgroundColor: theme.palette.grey[100],
        marginBottom: "16px"
    }
}));

const NewWindow = (props) => {
    const { context } = props;
    const classes = useStyles();
    const inputRef = useRef(null);
    const opening = context.state.measures.custom.window;
    const disabled = context.isDoneTakeoff();
    const additionalItem = opening.additionalItem
    const additionalItemObj = Object.keys(additionalItem).length === 0 && additionalItem.constructor === Object ? 
                                context.state.additionalOrder.additionalItem : additionalItem
    const [state, setState] = useState({
        cap_material: '',
        labelWidth: 0,
    })

    useEffect(() => {
        if (context.state.editWindowIndex === -1 ) {
            setState({
                ...state, 
                labelWidth: inputRef.current.offsetWidth || 110,
            });
        }
    }, []);

    useEffect(() => {
        if (context.state.editWindowIndex !== -1) {
            const window = context.state.windows[context.state.editWindowIndex];
            if (window) {
                ['trim', 'hardware']
                .filter(category => window[category])
                .forEach(category => {
                    Object.keys(window[category])
                        .filter(type => {
                            return  (window[category][type] &&
                                'code' in window[category][type] &&
                                window[category][type].code
                            ) || type === 'double'
                        })
                        .forEach( type => {
                            if (type === 'double' && window.hardware.double) {
                                Object.keys(window.hardware.double).forEach(side => {
                                    Object.keys(window.hardware.double[side])
                                    .filter(type => window.hardware.double[side][type])
                                    .forEach(type => {
                                        const item = window.hardware.double[side][type];
                                        const attributes = Array.isArray(item) && item.length > 0 ? item[0].attributes : typeof item === 'object' ? item.attributes : null;
                                        if (attributes ) {
                                            context.copySpecs('specs', 'specialSpecs', 'hardware', type, attributes);
                                        }
                                    });
                                });
                            }
                            if (!['curve'].includes(type)) {
                                context.copySpecs('specs', 'specialSpecs', 'products', (type === 'jamb' ? window.materialType : type), window[category][type]);
                            }
                            context.copySpecs('specs', 'specialSpecs', category, (type === 'jamb' ? window.materialType : type), window[category][type].attributes || {});
                        })
                });
                if (Lib.deepAttributeExists(['sheet', 'code'], window)) {
                    context.copySpecs('specs', 'specialSpecs', 'sheet', "", window.sheet.attributes);
                    context.copySpecs('specs', 'specialSpecs', 'products', 'sheet', window.sheet);
                }
            }
        } else {
            context.copySpecs('specs', 'specialSpecs', 'trim');
            context.copySpecs('specs', 'specialSpecs', 'hardware');
            context.copySpecs('specs', 'specialSpecs', 'products');
        }
    }, []);

    const handleSpecChange = (category, type = null, mode = null) =>  ({specs, copy, propagate}) => {
        const finalType = category === 'sheet' ?
                            null : ['flat_stock', 'd4s'].includes(type) ?
                                'Flat Stock' : mode && specs.type ?
                                    specs.type : type === 'buildout' ?
                                        'Flat Stock' : type === 'sill' ?
                                            'Window Stool' : type;
        // console.log(category, type, mode, specs, finalType);
        if (
            (
                /jamb/.test(type) ||
                category === 'sheet' ||
                ['deadbolt', 'd4s', 'column', 'architrave', 'jamb', 'casing', 'closer', 'buildout', 'sill', 'backband', 'flat_stock', 'burlap', 'attic_lid', 'attic_hatch', 'attic_foam_tape', 'handrail', 'handrail_bracket', 'doorstop', 'quarter_round', 'cove', 'sheet', 'archway_header'].includes(type)
            ) &&
            !Lib.isEmptyOrNullObject(specs)
        ) {
            const productAttribute = {
                category: context.humanize(category),
                ...(type ? {type: /jamb/.test(type) ? 'jamb' : type === 'archway_header' ? mode : finalType} : {}),
                ...specs,
            };
            // console.log(productAttribute)
            const productPromise = context.getAnyProduct(productAttribute);
            productPromise.then(product => {
                if (product && !Lib.isEmptyOrNullObject(specs)) {
                    const newProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        // count:          specs.window.quantity,
                        attributes:     productAttribute
                    };
                    context.saveSpecs({
                        scope: 'specialSpecs',
                        product: newProduct,
                        category,
                        type,
                        specs,
                        copy,
                        propagate
                    });
                } else {
                    context.saveSpecs({
                        scope: 'specialSpecs',
                        category,
                        type,
                        specs,
                        copy,
                        propagate
                    });
                }
            })
        } else {
            context.saveSpecs({
                scope: 'specialSpecs',
                category,
                type,
                specs,
                copy
            });
        }
    };

    const handleChange = (field, value) => {
        // console.log(field, value)
        let maxIndex = 0;
        if (field === 'type') {
             maxIndex = Math.max(
                ...context.state.windows
                .filter(item => item && item.type === value)
                .map(o => 'itemIndex' in o ? o.itemIndex : 0)
                , 0);
        }
        context.setState({
            measures: {
                ...context.state.measures,
                custom: {
                    ...context.state.measures.custom,
                    window: {
                        ...context.state.measures.custom.window,
                        [field]: value,
                        ...(field === 'type' ? {itemIndex: maxIndex+1} : {})
                    }
                }
            }
        })
    };

    const handleDeleteImage = (index) => {
        // Add new function
        context.deleteTypeImage(index, "window")
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>
                        {
                            opening.type &&
                            `${Lib.humanize(opening.type)} `
                        }
                        <InlineEdit
                            text={ opening.title || opening.itemIndex ||  "" }
                            onSetText={ value => handleChange('title', value)}
                        />
                    </Typography>
                </Grid>
                {
                    context.state.isAdditionalOrder &&
                    <AdditionalItem 
                        additionalItem={additionalItemObj}
                        disabled={disabled}
                        onItemChange={(value)=> {
                            handleChange('additionalItem', value)
                        }}
                    />
                }
                {
                    (
                        context.state.editWindowIndex === -1 ||
                        !(['stairs', 'shower_stall', 'attic', 'column'].includes(opening.type))
                    ) &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                {
                                    context.state.editWindowIndex === -1 &&
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel
                                                htmlFor="window-type"
                                                ref={inputRef}
                                                required
                                            >
                                                Type
                                            </InputLabel>
                                            <Select
                                                disabled={disabled}
                                                value={opening.type}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={50}
                                                        name="type"
                                                        id="window-type"
                                                    />
                                                }
                                                inputProps={{
                                                    name: "type",
                                                    id: "window-type",
                                                }}
                                                onChange={ event => handleChange('type', event.target.value)}
                                            >
                                                <MenuItem value={""}></MenuItem>
                                                <MenuItem value={"window"}>Window</MenuItem>
                                                <MenuItem value={"exterior_door"}>Exterior Door</MenuItem>
                                                <MenuItem value={"caps"}>Caps</MenuItem>
                                                <MenuItem value={"archway"}>Trimmed Archway</MenuItem>
                                                <MenuItem value={"arch_window"}>Arch Window</MenuItem>
                                                <MenuItem value={"stairs"}>Stairs</MenuItem>
                                                <MenuItem value={"attic"}>Attic</MenuItem>
                                                <MenuItem value={"shower_stall"}>Shower Stall</MenuItem>
                                                <MenuItem value={"column"}>Column</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {
                                    opening.type === "window" &&
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel
                                                htmlFor="window-size"
                                                ref={inputRef}
                                                required
                                            >
                                                Size
                                            </InputLabel>
                                            <Select
                                                disabled={disabled}
                                                value={opening.size}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={50}
                                                        name="size"
                                                        id="window-size"
                                                    />
                                                }
                                                inputProps={{
                                                    name: "size",
                                                    id: "window-size",
                                                }}
                                                onChange={ event => handleChange('size', event.target.value)}
                                            >
                                                <MenuItem value={""}></MenuItem>
                                                <MenuItem value={"small"}>Small (3' x 4' or less)</MenuItem>
                                                <MenuItem value={"medium"}>Medium (between 3' x 4' and 4' x 6')</MenuItem>
                                                <MenuItem value={"large"}>Large (between 4' x 6' and 6' x 8')</MenuItem>
                                                <MenuItem value={"custom"}>Custom (greater than  6' x 8')</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {
                                    opening.type === "caps" &&
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" gutterBottom={false}>
                                            Layout
                                        </Typography>
                                        <CustomToggleGroup
                                            disabled={disabled}
                                            items={{
                                                'w+2*l': <img alt='W + 2L' src={WLLIcon}/>,
                                                'w+l': <img alt='W + L' src={WLIcon}/>,
                                                'l+l': <img alt='L + L' src={LLIcon}/>,
                                                '2*w+2*l': <img alt='2W + 2L' src={WWLLIcon}/>,
                                                'l': <img alt='L' src={LIcon}/>,
                                                '2*w+l': <img alt='2W + L' src={WWLIcon}/>
                                            }}
                                            value={opening.layout}
                                            handleChange={(event, value) => handleChange('layout', value || '')}
                                        />
                                    </Grid>
                                }
                                {
                                    opening.type === "exterior_door" &&
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel
                                                htmlFor="ext-size"
                                                ref={inputRef}
                                                required
                                            >
                                                Size
                                            </InputLabel>
                                            <Select
                                                disabled={disabled}
                                                input={
                                                    <OutlinedInput
                                                        labelWidth={50}
                                                        name="size"
                                                        id="ext-size"
                                                    />
                                                }
                                                value={opening.size}
                                                onChange={ event => handleChange('size', event.target.value)}
                                            >
                                                <MenuItem value={''}></MenuItem>
                                                <MenuItem value={'single'}>Single</MenuItem>
                                                <MenuItem value={'single_large'}>Single Large</MenuItem>
                                                <MenuItem value={'double'}>Double</MenuItem>
                                                <MenuItem value={'1_side'}>1 Side Lite</MenuItem>
                                                <MenuItem value={'2_side'}>2 Side Lite</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {
                                    (
                                        (
                                            opening.type === "window" &&
                                            opening.size === "custom"
                                        ) ||
                                        (
                                            opening.type === "caps" &&
                                            opening.layout
                                        ) ||
                                        (
                                            opening.type === "arch_window" &&
                                            !Lib.isEmptyOrNullObject(context.state.specialSpecs.trim.curve)
                                        ) ||
                                        opening.type === "archway"
                                    )
                                    &&
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} xs={12}>
                                                <TextField
                                                    required
                                                    disabled={disabled}
                                                    id="custom-window-width"
                                                    label={`Width (in Inches)`}
                                                    className={classes.textField}
                                                    margin="dense"
                                                    variant="outlined"
                                                    fullWidth
                                                    type={"number"}
                                                    helperText={opening.type==='arch_window' ? 'Max width for arch windows is 120"': ''}
                                                    value={opening.width || ""}
                                                    onChange={ (event) => handleChange('width', event.target.value)}
                                                />
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <TextField
                                                    required
                                                    disabled={disabled}
                                                    id="custom-window-height"
                                                    label={`${opening.type === "caps" ? "Length" : "Height"} (in Inches)`}
                                                    className={classes.textField}
                                                    margin="dense"
                                                    variant="outlined"
                                                    fullWidth
                                                    type={"number"}
                                                    value={opening.height || ''}
                                                    onChange={ (event) => handleChange('height', event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    opening.type === "arch_window" &&
                                    <Grid item xs={12}>
                                        <SpecsType
                                            disabled={disabled}
                                            category={'trim'}
                                            type={'curve'}
                                            attributes={trimAttributes('curve')}
                                            handleChange={handleSpecChange('trim', 'curve')}
                                            specs={context.state.specialSpecs.trim.curve}
                                            globalSpecs={context.state.specs.trim.curve}
                                        />
                                    </Grid>
                                }
                                {
                                    ["window", "arch_window"].includes(opening.type) &&
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    disabled={disabled}
                                                    checked={opening.isHigh}
                                                    onChange={(event) => handleChange('isHigh', event.target.checked)}
                                                />
                                            }
                                            label="High Window"
                                        />
                                    </Grid>
                                }
                                {
                                    opening.isHigh &&
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            disabled={disabled}
                                            label="High Window Location"
                                            className={classes.textField}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            // type={"number"}
                                            value={opening.highOpeningLocation}
                                            onChange={ (event) => handleChange('highOpeningLocation', event.target.value)}
                                        />
                                    </Grid>
                                }
                                {
                                    ["window", "archway", "caps"].includes(opening.type) &&
                                    <>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        checked={opening.hasExtraService}
                                                        onChange={(event) => context.setMeasureOpenAttr('hasExtraService', event.target.checked, {'extraService': event.target.checked ? opening.extraService : 0})}
                                                    />
                                                }
                                                label="Add Extra Service"

                                            />
                                        </Grid>
                                        {
                                            opening.hasExtraService &&
                                            <Grid item xs={12}>
                                                <CustomToggleGroup
                                                    disabled={disabled}
                                                    items={{
                                                        '1': 'One',
                                                        '2': 'Two',
                                                        '3': 'Three',
                                                        '4': 'Four',
                                                    }}
                                                    value={`${opening.extraService}`}
                                                    handleChange={(event, value) => context.setMeasureOpenAttr('extraService', value || 1)}
                                                />
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <MeasureUnits
                                    disabled={disabled}
                                    value={opening.unitNumber || ""}
                                    handleChange={event => context.setMeasureOpenAttr('unitNumber', event.target.value) }
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {
                    opening.type === "exterior_door" &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <NewExteriorDoor disabled={disabled} context={context} handleSpecChange={handleSpecChange} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {
                    ['caps', 'archway'].includes(opening.type) &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <JambMaterial
                                        disabled={disabled}
                                        type={opening.type}
                                        value={opening.materialType}
                                        quantity={opening.quantity}
                                        showDepth={opening.type !== 'caps'}
                                        openingDepth={opening.openingDepth}
                                        items={{
                                            jamb: '80',
                                            jamb_84: '84',
                                            jamb_96: '96',
                                            sheet: 'sheet',
                                            flat_stock: 'Flat',
                                        }}
                                        handleSpecChange={handleSpecChange}
                                        handleChange={handleChange}
                                        specs={context.state.specs}
                                        specialSpecs={context.state.specialSpecs}
                                    />
                                </Grid>
                                {
                                    opening.type === 'archway' &&
                                    <>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        disabled={disabled}
                                                        checked={opening.customHeader}
                                                        onChange={(event) => context.setMeasureOpenAttr('customHeader', event.target.checked)}
                                                    />
                                                }
                                                label="Custom Archway Header"
                                            />
                                        </Grid>
                                        {
                                            opening.customHeader &&
                                            <Grid item xs={12}>
                                                <JambMaterial
                                                    disabled={disabled}
                                                    customType="headerMaterial"
                                                    customQuantity="headerQuantity"
                                                    customSpecType="archway_header"
                                                    type={opening.type}
                                                    value={opening.headerMaterial}
                                                    quantity={opening.headerQuantity}
                                                    items={{
                                                        jamb: '80',
                                                        jamb_84: '84',
                                                        jamb_96: '96',
                                                        sheet: 'sheet',
                                                        flat_stock: 'Flat',
                                                    }}
                                                    handleSpecChange={handleSpecChange}
                                                    handleChange={handleChange}
                                                    specs={context.state.specs}
                                                    specialSpecs={context.state.specialSpecs}
                                                />
                                            </Grid>
                                        }
                                    </>
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {
                    opening.type &&
                    opening.type !== "arch_window" &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom={false}>Trim</Typography>
                                    </Grid>
                                    {
                                        context.state.editWindowIndex !== -1 &&
                                        (['stairs', 'shower_stall', 'attic', 'column'].includes(opening.type)) &&
                                        <Grid item xs={12}>
                                            <MeasureUnits
                                                disabled={disabled}
                                                value={opening.unitNumber || ""}
                                                handleChange={event => context.setMeasureOpenAttr('unitNumber', event.target.value) }
                                            />
                                        </Grid>
                                    }
                                    {
                                        opening.type === 'shower_stall' &&
                                        <>
                                            <Grid item xs={12}>
                                                <CustomToggleGroup
                                                    disabled={disabled}
                                                    items={{
                                                        casing: 'Casing',
                                                        doorstop: 'Doorstop',
                                                        cove: 'Cove',
                                                    }}
                                                    value={opening.materialType}
                                                    handleChange={(event, value) => context.setMeasureOpenAttr('materialType', value || '')}
                                                />
                                            </Grid>
                                            {
                                                opening.materialType &&
                                                <Grid item xs={12}>
                                                        <SpecsType
                                                            disabled={disabled}
                                                            category={'trim'}
                                                            type={opening.materialType}
                                                            attributes={trimAttributes(opening.materialType)}
                                                            handleChange={handleSpecChange('trim' ,opening.materialType)}
                                                            specs={context.state.specialSpecs.trim[opening.materialType]}
                                                            globalSpecs={context.state.specs.trim[opening.materialType]}
                                                        />
                                                    </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        opening.type === 'stairs' &&
                                        <>
                                            {
                                                opening.type === "stairs" &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={4}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            disabled={disabled}
                                                                            checked={opening.attributes?.addStraight}
                                                                            onChange={(event) => context.setMeasureOpenAttr('attributes', {
                                                                                ...opening.attributes,
                                                                                addStraight: event.target.checked
                                                                            })}
                                                                        />
                                                                    }
                                                                    label="Straight"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            disabled={disabled}
                                                                            checked={opening.attributes?.addHalfFlight}
                                                                            onChange={(event) => context.setMeasureOpenAttr('attributes', {
                                                                                ...opening.attributes,
                                                                                addHalfFlight: event.target.checked
                                                                            })}
                                                                        />
                                                                    }
                                                                    label="Half-Flight"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={4}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            disabled={disabled}
                                                                            checked={opening.attributes?.addWinders}
                                                                            onChange={(event) => context.setMeasureOpenAttr('attributes', {
                                                                                ...opening.attributes,
                                                                                addWinders: event.target.checked
                                                                            })}
                                                                        />
                                                                    }
                                                                    label="Winders"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sm={6} xs={12}>
                                                        <TextField
                                                            required
                                                            disabled={disabled}
                                                            id="quantity"
                                                            label={`Quantity`}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            variant="outlined"
                                                            fullWidth
                                                            type={"number"}
                                                            value={opening.quantity || 1}
                                                            onChange={ (event) => handleChange('quantity', event.target.value)}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom={false}>Cove</Typography>
                                            </Grid>
                                            {
                                                Lib.deepAttributeExists(['cove', 'code'], context.state.specialSpecs.products) &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={disabled}
                                                        id="new-cove-length"
                                                        label={`Cove Length (FT)`}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                        type={"number"}
                                                        value={opening.coveLength || ""}
                                                        onChange={ (event) => context.setMeasureOpenAttr('coveLength', event.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <SpecsType
                                                    disabled={disabled}
                                                    category={'trim'}
                                                    type={'cove'}
                                                    attributes={trimAttributes('cove')}
                                                    handleChange={handleSpecChange('trim' , 'cove')}
                                                    specs={context.state.specialSpecs.trim.cove}
                                                    globalSpecs={context.state.specs.trim.cove}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomToggleGroup
                                                    disabled={disabled}
                                                    items={{
                                                        doorstop: 'Doorstop',
                                                        quarter_round: 'Qtr Rnd',
                                                    }}
                                                    value={opening.materialType}
                                                    handleChange={(event, value) => context.setMeasureOpenAttr('materialType', value || '')}
                                                />
                                            </Grid>
                                            {
                                                opening.materialType &&
                                                <>
                                                    {
                                                        Lib.deepAttributeExists([opening.materialType, 'code'], context.state.specialSpecs.products) &&
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                disabled={disabled}
                                                                id="new-material-length"
                                                                label={`Length (FT)`}
                                                                className={classes.textField}
                                                                margin="dense"
                                                                variant="outlined"
                                                                fullWidth
                                                                type={"number"}
                                                                value={opening.materialLength || ""}
                                                                onChange={ (event) => context.setMeasureOpenAttr('materialLength', event.target.value)}
                                                            />
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12}>
                                                        <SpecsType
                                                            disabled={disabled}
                                                            category={'trim'}
                                                            type={opening.materialType}
                                                            attributes={trimAttributes(opening.materialType)}
                                                            handleChange={handleSpecChange('trim' ,opening.materialType)}
                                                            specs={context.state.specialSpecs.trim[opening.materialType]}
                                                            globalSpecs={context.state.specs.trim[opening.materialType]}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                    }
                                    {
                                        opening.type === 'stairs' &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.addD4s}
                                                            onChange={(event) => context.setMeasureOpenAttr('addD4s', event.target.checked)}
                                                        />
                                                    }
                                                    label="Add Flat Stock"
                                                />
                                            </Grid>
                                            {
                                                opening.addD4s &&
                                                Lib.deepAttributeExists(['flat_stock', 'code'], context.state.specialSpecs.products) &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        disabled={disabled}
                                                        id="new-d4s-length"
                                                        label={`Length (FT)`}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                        type={"number"}
                                                        value={opening.d4sLength || ""}
                                                        onChange={ (event) => context.setMeasureOpenAttr('d4sLength', event.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                opening.addD4s &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        label={'Flat Stock'}
                                                        category={'trim'}
                                                        type={'flat_stock'}
                                                        attributes={trimAttributes('flat_stock')}
                                                        handleChange={handleSpecChange('trim' ,'flat_stock')}
                                                        specs={context.state.specialSpecs.trim.flat_stock}
                                                        globalSpecs={context.state.specs.trim.flat_stock}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        opening.type === 'stairs' &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.addCasing}
                                                            onChange={(event) => context.setMeasureOpenAttr('addCasing', event.target.checked)}
                                                        />
                                                    }
                                                    label="Add Casing"
                                                />
                                            </Grid>
                                            {
                                                opening.addCasing &&
                                                Lib.deepAttributeExists(['casing', 'code'], context.state.specialSpecs.products) &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        disabled={disabled}
                                                        id="new-casing-length"
                                                        label={`Casing Length (FT)`}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                        type={"number"}
                                                        value={opening.casingLength || ""}
                                                        onChange={ (event) => context.setMeasureOpenAttr('casingLength', event.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                opening.addCasing &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        category={'trim'}
                                                        type={'casing'}
                                                        attributes={trimAttributes('casing')}
                                                        handleChange={handleSpecChange('trim', 'casing')}
                                                        specs={context.state.specialSpecs.trim.casing}
                                                        globalSpecs={context.state.specs.trim.casing}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.addHandrail}
                                                            onChange={(event) => context.setMeasureOpenAttr('addHandrail', event.target.checked)}
                                                        />
                                                    }
                                                    label="Add Handrail"
                                                />
                                            </Grid>
                                            {
                                                opening.addHandrail &&
                                                Lib.deepAttributeExists(['handrail', 'code'], context.state.specialSpecs.products) &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        disabled={disabled}
                                                        id="new-trim-length"
                                                        label={`Length (FT)`}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                        type={"number"}
                                                        value={opening.handrailLength || ""}
                                                        onChange={ (event) => context.setMeasureOpenAttr('handrailLength', event.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                opening.addHandrail &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        category={'trim'}
                                                        type={'handrail'}
                                                        attributes={trimAttributes('handrail')}
                                                        handleChange={handleSpecChange('trim', 'handrail')}
                                                        specs={context.state.specialSpecs.trim.handrail}
                                                        globalSpecs={context.state.specs.trim.handrail}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                opening.addHandrail &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        category={'hardware'}
                                                        type={'handrail_bracket'}
                                                        attributes={['style', 'width', 'finish']}
                                                        handleChange={handleSpecChange('hardware', 'handrail_bracket')}
                                                        specs={context.state.specialSpecs.hardware.handrail_bracket}
                                                        globalSpecs={context.state.specs.hardware.handrail_bracket}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                opening.addHandrail &&
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disabled={disabled}
                                                                checked={opening.continuousHandrail}
                                                                onChange={(event) => context.setMeasureOpenAttr('continuousHandrail', event.target.checked)}
                                                            />
                                                        }
                                                        label="Continuous Handrail"
                                                    />

                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        opening.type === 'attic' &&
                                        <Grid item xs={12}>
                                            <SpecsType
                                                disabled={disabled}
                                                category={'trim'}
                                                type={'flat_stock'}
                                                attributes={trimAttributes('flat_stock')}
                                                handleChange={handleSpecChange('trim' ,'flat_stock')}
                                                specs={context.state.specialSpecs.trim.flat_stock}
                                                globalSpecs={context.state.specs.trim.flat_stock}
                                            />
                                        </Grid>
                                    }
                                    {
                                        ["window", "exterior_door"].includes(opening.type) &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.addBuildout}
                                                            onChange={(event) => context.setState({
                                                                measures: {
                                                                    ...context.state.measures,
                                                                    custom: {
                                                                        ...context.state.measures.custom,
                                                                        window: {
                                                                            ...opening,
                                                                            addBuildout: event.target.checked,
                                                                            ...(
                                                                                !event.target.checked && {
                                                                                    materialType: '',
                                                                                    quantity: '',
                                                                                    openingDepth: ''
                                                                                }
                                                                            )
                                                                        }
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                    }
                                                    label="Add Buildout"
                                                />
                                            </Grid>
                                            {
                                                opening.addBuildout &&
                                                <Grid item xs={12}>
                                                    <JambMaterial
                                                        disabled={disabled}
                                                        type={'Buildout'}
                                                        value={opening.materialType}
                                                        quantity={opening.quantity}
                                                        showDepth={true}
                                                        openingDepth={opening.openingDepth}
                                                        items={{
                                                            sheet: 'sheet',
                                                            flat_stock: 'Flat Stock',
                                                        }}
                                                        handleSpecChange={handleSpecChange}
                                                        handleChange={handleChange}
                                                        specs={context.state.specs}
                                                        specialSpecs={context.state.specialSpecs}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        ["exterior_door", "window"].includes(opening.type) &&
                                        (
                                            opening.type !== 'exterior_door' ||
                                            (opening.size && opening.size.indexOf("side") > -1)
                                        ) &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.hasBurlap}
                                                            onChange={(event) => context.setMeasureOpenAttr('hasBurlap', event.target.checked)}
                                                        />
                                                    }
                                                    label={`Add Burlap ${opening.type === 'window' ? ' for Windows' : ''}`}
                                                />
                                            </Grid>
                                            {
                                                opening.hasBurlap &&
                                                (
                                                    opening.type !== 'exterior_door' ||
                                                    (opening.size && opening.size.indexOf("side") > -1)
                                                ) &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        label={'Burlap'}
                                                        category={'trim'}
                                                        type={'burlap'}
                                                        attributes={trimAttributes('burlap')}
                                                        handleChange={handleSpecChange('trim' ,'burlap')}
                                                        specs={context.state.specialSpecs.trim.burlap}
                                                        globalSpecs={context.state.specs.trim.burlap}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                opening.hasBurlap &&
                                                context.state.specialSpecs.trim?.burlap?.style &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled={disabled}
                                                        label={`Burlap Quantity`}
                                                        className={classes.textField}
                                                        variant="outlined"
                                                        fullWidth
                                                        type={"number"}
                                                        value={opening.burlapCount || ""}
                                                        onChange={ (event) => context.setMeasureOpenAttr('burlapCount', event.target.value)}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        opening.type === "window" &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.addSill}
                                                            onChange={(event) => context.setMeasureOpenAttr('addSill', event.target.checked)}
                                                        />
                                                    }
                                                    label="Add Sill"
                                                />
                                            </Grid>
                                            {
                                                opening.addSill &&
                                                Lib.deepAttributeExists(['sill', 'code'], context.state.specialSpecs.products) &&
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                required
                                                                disabled={disabled}
                                                                id="window-sill"
                                                                label="Sill depth in Inches, includes overhang"
                                                                className={classes.textField}
                                                                margin="dense"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={opening.sillDepth}
                                                                onChange={ (event) => context.setMeasureOpenAttr('sillDepth', event.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                required
                                                                disabled={disabled}
                                                                id="window-sill"
                                                                label="Sill length in Inches"
                                                                className={classes.textField}
                                                                margin="dense"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={opening.sillLength}
                                                                onChange={ (event) => context.setMeasureOpenAttr('sillLength', event.target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {
                                                opening.addSill &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        label={'Sill'}
                                                        category={'trim'}
                                                        type={'window_stool'}
                                                        attributes={trimAttributes('sill')}
                                                        handleChange={handleSpecChange('trim' ,'sill')}
                                                        specs={context.state.specialSpecs.trim.sill}
                                                        globalSpecs={context.state.specs.trim.sill}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        ['window', 'exterior_door', 'archway'].includes(opening.type) &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.addArchitrave}
                                                            onChange={(event) => context.setMeasureOpenAttr('addArchitrave', event.target.checked)}
                                                        />
                                                    }
                                                    label="Add Architrave"
                                                />
                                            </Grid>
                                            {
                                                opening.type === 'archway' &&
                                                opening.addArchitrave &&
                                                <Grid item xs={12}>
                                                    <CustomToggleGroup
                                                        disabled={disabled}
                                                        items={{
                                                            one_side: 'One Side',
                                                            two_sides: 'Two Sides',
                                                        }}
                                                        value={opening.architraveSides}
                                                        handleChange={ (event, value) => context.setMeasureOpenAttr('architraveSides', value)}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                opening.addArchitrave &&
                                                ['window', 'exterior_door'].includes(opening.type) &&
                                                Lib.deepAttributeExists(['architrave', 'code'], context.state.specialSpecs.products) &&
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        disabled={disabled}
                                                        id="window-architrave-width"
                                                        label="Architrave width in Inches"
                                                        className={classes.textField}
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={opening.architraveWidth || ''}
                                                        type={"number"}
                                                        onChange={ (event) => context.setMeasureOpenAttr('architraveWidth', event.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                (
                                                    (
                                                        opening.type === 'archway' &&
                                                        opening.architraveSides
                                                    ) || (
                                                        opening.type !== 'archway' &&
                                                        opening.addArchitrave
                                                    )
                                                ) &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        category={'trim'}
                                                        type={'architrave'}
                                                        attributes={trimAttributes('architrave')}
                                                        handleChange={handleSpecChange('trim' ,'architrave')}
                                                        specs={context.state.specialSpecs.trim.architrave}
                                                        globalSpecs={context.state.specs.trim.architrave}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        ["window", "exterior_door", "archway"].includes(opening.type) &&
                                        <>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={disabled}
                                                            checked={opening.addBackband}
                                                            onChange={(event) => context.setMeasureOpenAttr('addBackband', event.target.checked)}
                                                        />
                                                    }
                                                    label="Add Backband"
                                                />
                                            </Grid>
                                            {
                                                opening.addBackband &&
                                                opening.type === 'archway' &&
                                                <Grid item xs={12}>
                                                    <CustomToggleGroup
                                                        disabled={disabled}
                                                        items={{
                                                            one_side: 'One Side',
                                                            two_sides: 'Two Sides',
                                                        }}
                                                        value={opening.backbandSides}
                                                        handleChange={ (event, value) => context.setMeasureOpenAttr('backbandSides', value)}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                (
                                                    (
                                                        opening.type === 'archway' &&
                                                        opening.backbandSides
                                                    ) || (
                                                        opening.type !== 'archway' &&
                                                        opening.addBackband
                                                    )
                                                ) &&
                                                <Grid item xs={12}>
                                                    <SpecsType
                                                        disabled={disabled}
                                                        category={'trim'}
                                                        type={'backband'}
                                                        attributes={trimAttributes('backband')}
                                                        handleChange={handleSpecChange('trim' ,'backband')}
                                                        specs={context.state.specialSpecs.trim.backband}
                                                        globalSpecs={context.state.specs.trim.backband}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        !(['stairs', "arch_window", 'column', 'shower_stall'].includes(opening.type)) &&
                                        <Grid item xs={12}>
                                            <SpecsType
                                                disabled={disabled}
                                                category={'trim'}
                                                type={'casing'}
                                                attributes={trimAttributes('casing')}
                                                handleChange={handleSpecChange('trim', 'casing')}
                                                specs={context.state.specialSpecs.trim.casing}
                                                globalSpecs={context.state.specs.trim.casing}
                                            />
                                        </Grid>
                                    }
                                    {
                                        opening.type === 'column' &&
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom={false}>Column</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <SpecsType
                                                    disabled={disabled}
                                                    category={'trim'}
                                                    type={'column'}
                                                    attributes={trimAttributes('column')}
                                                    handleChange={handleSpecChange('trim', 'column')}
                                                    specs={context.state.specialSpecs.trim.column}
                                                    globalSpecs={context.state.specs.trim.column}
                                                />
                                            </Grid>
                                            {
                                                1 === 2 &&
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                disabled={disabled}
                                                                checked={opening.addSplit}
                                                                onChange={(event) => context.setMeasureOpenAttr('addSplit', event.target.checked)}
                                                            />
                                                        }
                                                        label="Add Splitting Charge"
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                {
                    opening.type === 'attic' &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SpecsType
                                        disabled={disabled}
                                        category={'trim'}
                                        type={'attic_lid'}
                                        attributes={trimAttributes('attic_lid')}
                                        handleChange={handleSpecChange('trim' ,'attic_lid')}
                                        specs={context.state.specialSpecs.trim.attic_lid}
                                        globalSpecs={context.state.specs.trim.attic_lid}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SpecsType
                                        disabled={disabled}
                                        category={'trim'}
                                        type={'attic_hatch'}
                                        attributes={trimAttributes('attic_hatch')}
                                        handleChange={handleSpecChange('trim' ,'attic_hatch')}
                                        specs={context.state.specialSpecs.trim.attic_hatch}
                                        globalSpecs={context.state.specs.trim.attic_hatch}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SpecsType
                                        disabled={disabled}
                                        category={'trim'}
                                        type={'attic_foam_tape'}
                                        attributes={trimAttributes('attic_foam_tape')}
                                        handleChange={handleSpecChange('trim' ,'attic_foam_tape')}
                                        specs={context.state.specialSpecs.trim.attic_foam_tape}
                                        globalSpecs={context.state.specs.trim.attic_foam_tape}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <NotesAndImages
                        disabled={disabled}
                        category={"window"}
                        notes={opening.notes}
                        images={ opening.images }
                        handleChange={handleChange}
                        handleDeleteImage={handleDeleteImage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}


export default NewWindow