import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import MeasureListItemActions from "./MeasureListItemActions";
import ProductState from './ProductState';
import * as Lib from '../lib/lib';

const useStyles = makeStyles(() => ({
    avoidBreak: {
        '@media print': {
            display: 'block',
            breakInside: 'avoid',
        }
    },
    measureTrimListItem: {
        justifyContent: 'space-between',
        '@media print': {
            breakInside: 'avoid',
        }
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const MeasureShelvingListItem = ({ shelf, dIndex, context, disabled, cpu }) => {
    const classes = useStyles();
    if (!shelf || !shelf.product) return null
    return (
        <div>
            <ListItem
                button
                className={classes.measureTrimListItem}
                onClick={() => {
                    context.editShelving(dIndex)
                }}
            >
                <Grid alignItems='center' container className={classes.avoidBreak} spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant={ "body2" } gutterBottom>
                            <ProductState cpu={cpu} code={shelf?.product?.code || ''} />
                            {shelf.product?.isSpecial && "**"}
                            Shelf {shelf.title || shelf.itemIndex}: 
                            {['wood', 'wire'].includes(shelf.type) ?`${shelf.width}" x ${shelf.quantity} :`: ''} {shelf.product.code}
                            {
                                context.state.isAdditionalOrder && 
                                ` (${
                                    [
                                        shelf.additionalItem.labour,
                                        shelf.additionalItem.bill,
                                        shelf.additionalItem.po_required
                                    ]
                                    .filter (x => x)
                                    .map(x => x && Lib.humanize(x)).join(', ')
                                })`
                            }
                            {shelf.unitNumber ? ` (Unit ${shelf.unitNumber})` : ""}
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            {shelf.product.description}
                        </Typography>
                        {
                            'trim' in shelf &&
                            shelf.trim &&
                            Object.keys(shelf.trim).length > 0 &&
                            Object.keys(shelf.trim)
                                .map((type, i) => (
                                    shelf.trim[type] &&
                                    'code' in shelf.trim[type] &&
                                    shelf.trim[type].code &&
                                    <Typography key={i} variant="body2" gutterBottom>
                                        {Lib.humanize(type)} {`${Math.ceil(shelf.trim[type].count)} ${Lib.uoms(shelf.trim[type].uom)} x ${shelf.trim[type].code}`}
                                    </Typography>
                                ))
                        }
                        {
                            'hardware' in shelf &&
                            shelf.hardware &&
                            Object.keys(shelf.hardware).length > 0 &&
                            Object.keys(shelf.hardware)
                            .filter(type => shelf.hardware[type])
                            .map((type, i) => (
                                Array.isArray(shelf.hardware[type]) ?
                                shelf.hardware[type].map((hardware, j) => (
                                    hardware?.code &&
                                    <Typography key={j} variant="body2" gutterBottom>
                                        {context.humanize(type)} {`${hardware.count} ${Lib.uoms(hardware.uom)} x ${hardware.code}`}
                                    </Typography>
                                )) :
                                'code' in shelf.hardware[type] &&
                                shelf.hardware[type].code  &&
                                <Typography key={i} variant="body2" gutterBottom>
                                    {context.humanize(type)} {`${shelf.hardware[type].count} ${Lib.uoms(shelf.hardware[type].uom)} x ${shelf.hardware[type].code}`}
                                </Typography>
                            ))
                        }
                        {
                            Object.keys(shelf.wallDesigns || {})
                            .map((wall) => (shelf.wallDesigns?.[wall]?.designs || []).filter(a => a).map((design, i) => (
                                <Typography key={i} variant="body2" gutterBottom>
                                    {design.product?.count > 1 ? `${design.product?.count} x ` : ``}{context.humanize(design.product?.code)} {design.product?.description}
                                </Typography>
                            )))
                        }
                        {
                            shelf.accessories &&
                            shelf.accessories
                            .filter( i => i && i.code)
                            .map((accessory, i) => (
                                <Typography key={i} variant="body2" gutterBottom>
                                    {accessory.count > 1 ? `${accessory.count} x ` : ``}{context.humanize(accessory.code)} {accessory.description}
                                </Typography>
                            ))
                        }
                        {
                            shelf.addedInstall ||
                            context.state.installPricingMode !== 'none' ?
                            shelf.install_service?.filter(service => service && 'code' in service && service.code)
                            .map((service, i) => (
                                <Typography key={i} variant="body2" gutterBottom>
                                    {service.count > 1 ? `${service.count} x ` : ``}{context.humanize(service.code)} {service.description}
                                </Typography>
                            )) : 
                            ['organizer', 'accessories'].includes(shelf.type) &&
                            shelf.install_service?.filter(service => service && 'code' in service && service.code)
                            .map((service, i) => (
                                <Typography key={i} variant="body2" gutterBottom>
                                    {service.count > 1 ? `${service.count} x ` : ``}{context.humanize(service.code)} {service.description}
                                </Typography>
                            ))
                        }
                        {
                            shelf.images.length > 0 &&
                            <Typography variant={'caption'}>{shelf.images.length} IMAGE(S) ATTACHED</Typography>
                        }
                        {
                            shelf.notes &&
                            <Typography className={classes.ellipsis} variant="caption" gutterBottom>
                                NOTES | {shelf.notes}
                            </Typography>
                        }
                    </Grid>
                    {
                        !disabled &&
                        <Grid item xs={2}>
                            <MeasureListItemActions context={context} category={'shelving'} index={dIndex} />
                        </Grid>
                    }
                </Grid>
            </ListItem>
            <Divider />
        </div>
    );
}

export default MeasureShelvingListItem