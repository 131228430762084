import React from "react";
//material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpecStatusIcon from './SpecStatusIcon';
import Typography from "@material-ui/core/Typography";

import SpecsType from './SpecsType'
import AvailableDimensions from './AvailableDimensions'
import * as Lib from "../lib/lib";
import { shelvingAttributes } from "../../common/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "16px",
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper: {
        padding: "16px"
    },
    group: {
        flexDirection: "row"
    },
    list: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
        margin: '8px'
    }
}));

const fullSpecs = ['wood', 'wire', 'cleat', 'shelf_rod'];

const Shelving = (props) => {
    const { context } = props;
    const classes = useStyles();
    const handleSpecChange = (category, type) => ({specs, copy, propagate}) => {
        // console.log(copy)
        if (
            fullSpecs.includes(type) &&
            !Lib.isEmptyOrNullObject(specs)
        ) {
            const typeAttributes = {
                category: type=== 'cleat' ? "Trim" : Lib.humanize(category),
                type: type=== 'cleat' ? "Flat Stock" :Lib.humanize(type),
                ...specs,
            };
            const typePromise = context.getAnyProduct(typeAttributes);
            typePromise.then(product => {
                if (product) {
                    const typeProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        attributes:     typeAttributes
                    };
                    context.saveSpecs({
                        product: typeProduct,
                        category,
                        type,
                        specs,
                        propagate
                    });
                }
            })
        } else {
            context.saveSpecs({
                category,
                type,
                specs,
                propagate,
                ...(fullSpecs.includes(type) ? {product: {}} : {})
            });
        }

    };

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                {
                    ['wire', 'cleat', 'organizer'].map( (type, index) => {
                        return (
                            <Accordion elevation={4} key={index}>
                                <AccordionSummary
                                    className={classes.expansionPanelSummary}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <SpecStatusIcon 
                                        specs={context.state.specs.shelving[type]} 
                                        attributes={shelvingAttributes(type, true)} 
                                    />
                                    <Typography variant="h6">{context.humanize(type === 'wood' ? 'Melamine': type)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            context.state.specs.shelving[type] &&
                                            Lib.checkSpecs(context.state.specs.shelving[type], shelvingAttributes(type)) &&
                                            <AvailableDimensions context={context} category={'shelving'} type={type} specs={context.state.specs.shelving[type]} />
                                        }
                                        {
                                            context.isSpecProductSet(type) &&
                                            <Grid item xs={12} className={classes.list}>
                                                <Typography variant="body2">{context.state.specs.products[type].description}</Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <SpecsType
                                                hideCopy
                                                disabled={context.isDoneTakeoff()}
                                                category={type === 'cleat' ? 'trim' : 'shelving'}
                                                label={type === 'wood' ? 'Melamine': type}
                                                type={type === 'cleat' ? 'flat_stock' : type}
                                                attributes={shelvingAttributes(type)}
                                                handleChange={handleSpecChange('shelving', type)}
                                                specs={context.state.specs.shelving[type] || {}}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    )
                }
            </Grid>
        </Grid>
    );
}

export default Shelving
