import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {humanize} from '../common/lib'

const useStyles = makeStyles((theme) => ({
    root: {

    },
    paper: {
        padding: "16px",
        marginTop: '70px'
    },
    flex: {
        flex: 1
    },
    button: {
        margin: theme.spacing(1),
    },
    container: {
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing(1),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};

const FilterTakeoffs = ({ context }) => {
    const classes = useStyles();
    const { filter, authors, team } = context.state;
    const {status: statuses = []} = filter;

    const [toggleSelect, setToggleSelect] = useState(false);
    const [selectedAuthors, setSelectedAuthors] = useState([]);
    // const [selectedStatuses, setSelectedStatuses] = useState([]);

    const codeInput = useRef(null)

    useEffect(() => {
        codeInput.current.focus();
        if (
            'authors' in filter &&
            authors.length === filter.authors.length
        ){
            setToggleSelect(true);
        }
        setSelectedAuthors('authors' in filter && filter.authors.length > 0 ? filter.authors : ['admin', 'staff'].includes(localStorage.getItem('role')) ? team : [])
    }, []);
    
    const selectAuthor = (event) => {
        let selected;
        if (event.target.value.includes('toggle')) {
            selected = !toggleSelect ? authors.map(author => author.id) : [];
            setToggleSelect(!toggleSelect);
        } else {
            selected = event.target.value.filter(value => value !== 'toggle');
            if (
                'authors' in filter &&
                authors.length === selected.length
            ){
                setToggleSelect(true);
            } else {setToggleSelect(false);}
        }
        setSelectedAuthors(selected);
        context.setState({
            filter: {
                ...filter,
                authors: selected
            }
        })
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" color="primary" elevation={0}>
                <Toolbar>
                    <IconButton
                        // to="/"
                        // component={Link}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Back"
                        onClick={() => context.closeFilters()}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.flex}>
                        Filter
                    </Typography>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            setToggleSelect(false);
                            setSelectedAuthors([]);
                            context.clearFilters()
                        }}
                    >
                        Clear
                    </Button>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={() => {context.filterTakeoffs()}}
                    >
                        Apply
                    </Button>
                </Toolbar>
            </AppBar>
            <Paper className={classes.paper} elevation={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="code-simple"
                                inputRef={codeInput}
                                fullWidth
                                label="Code"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">TO-</InputAdornment>,
                                }}
                                type={'number'}
                                // margin="dense"
                                value={filter.code || ""}
                                onChange={(event) => {
                                    context.setState({
                                        filter: {
                                            ...filter,
                                            code: event.target.value
                                        }
                                    })
                                }}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                      ev.preventDefault();
                                      context.filterTakeoffs()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.show_additional}
                                        onChange={(event) => {
                                            context.setState({
                                                filter: {
                                                    ...filter,
                                                    show_additional: event.target.checked
                                                }
                                            })
                                        }}
                                    />
                                }
                                label="Show Additional TOs"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.show_copied}
                                        onChange={(event) => {
                                            context.setState({
                                                filter: {
                                                    ...filter,
                                                    show_copied: event.target.checked
                                                }
                                            })
                                        }}
                                    />
                                }
                                label="Show Copied TOs"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="select-multiple-checkbox">Status</InputLabel>
                                <Select
                                    multiple
                                    value={statuses}
                                    renderValue={ selected => selected.map(status => humanize(status)).join(', ')}
                                    onChange={(event) => {
                                        context.setState({
                                            filter: {
                                                ...filter,
                                                status: event.target.value
                                            }
                                        })
                                    }}
                                    input={
                                        <OutlinedInput
                                            labelWidth={90}
                                            name="type"
                                            id="takeoff-status-filter"
                                        />
                                    }
                                >
                                    <MenuItem value={"new"}>
                                        <Checkbox checked={statuses.indexOf('new') > -1} />
                                        <ListItemText primary={`New`} />
                                    </MenuItem>
                                    <MenuItem value={"review"}>
                                        <Checkbox checked={statuses.indexOf('review') > -1} />
                                        <ListItemText primary={`Review`} />
                                    </MenuItem>
                                    <MenuItem value={"estimate"}>
                                        <Checkbox checked={statuses.indexOf('estimate') > -1} />
                                        <ListItemText primary={`Estimate`} />
                                    </MenuItem>
                                    <MenuItem value={"sold"}>
                                        <Checkbox checked={statuses.indexOf('sold') > -1} />
                                        <ListItemText primary={`Sold`} />
                                    </MenuItem>
                                    <MenuItem value={"archived"}>
                                        <Checkbox checked={statuses.indexOf('archived') > -1} />
                                        <ListItemText primary={`Archived`} />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="site-simple"
                                fullWidth
                                label="Site Name"
                                variant="outlined"
                                value={filter.site_name || ""}
                                onChange={(event) => {
                                    context.setState({
                                        filter: {
                                            ...filter,
                                            site_name: event.target.value
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="lot-simple"
                                fullWidth
                                label="Lot"
                                variant="outlined"
                                value={filter.lot || ""}
                                type={'number'}
                                onChange={(event) => {
                                    context.setState({
                                        filter: {
                                            ...filter,
                                            lot: event.target.value
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="civic-simple"
                                fullWidth
                                label="Civic"
                                variant="outlined"
                                value={filter.civic || ""}
                                onChange={(event) => {
                                    context.setState({
                                        filter: {
                                            ...filter,
                                            civic: event.target.value
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="customer-simple"
                                fullWidth
                                label="Customer"
                                variant="outlined"
                                // margin="dense"
                                value={filter.customer || ""}
                                onChange={(event) => {
                                    context.setState({
                                        filter: {
                                            ...filter,
                                            customer: event.target.value
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth className={classes.formControl}>
                                <DatePicker
                                    autoOk
                                    label="From date"
                                    variant="dialog"
                                    inputVariant="outlined"
                                    clearable
                                    disableFuture
                                    format="dd/MM/yyyy"
                                    // mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
                                    maxDate={filter.to ? filter.to : new Date()}
                                    maxDateMessage="Date must be less than today"
                                    value={filter.from}
                                    onChange={(date) => {
                                        context.setState({
                                            filter: {
                                                ...filter,
                                                from: date
                                            }
                                        })
                                    }}
                                    animateYearScrolling={false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth className={classes.formControl}>
                                <DatePicker
                                    autoOk
                                    label="To date"
                                    variant="dialog"
                                    inputVariant="outlined"
                                    clearable
                                    disableFuture
                                    maxDateMessage="Date must be less than today"
                                    value={filter.to}
                                    onChange={(date) => {
                                        context.setState({
                                            filter: {
                                                ...filter,
                                                to: date
                                            }
                                        })
                                    }}
                                    animateYearScrolling={false}
                                    format={'dd/MM/yyyy'}
                                    // mask={value => value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []}
                                    minDate={filter.from ? filter.from : '2000-01-01'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="select-multiple-checkbox">Created By</InputLabel>
                                <Select
                                    multiple
                                    value={selectedAuthors}
                                    onChange={selectAuthor}
                                    input={
                                        <OutlinedInput
                                            labelWidth={90}
                                            name="type"
                                            id="takeoff-author-filter"
                                        />
                                    }
                                    renderValue={selected => authors
                                                            .filter(author => selected.includes(author.id))
                                                            .map(author => author.name)
                                                            .join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"toggle"}>
                                        <Checkbox checked={toggleSelect} />
                                        <ListItemText primary={"Everyone"} />
                                    </MenuItem>
                                {authors.map((author, i) => (
                                    <MenuItem key={i} value={author.id}>
                                        <Checkbox checked={selectedAuthors.indexOf(author.id) > -1} />
                                        <ListItemText primary={`${author.name}`} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Paper>
        </div>
    )
}

export default FilterTakeoffs