import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from '@material-ui/core/Tab';
import SpecsDoors from "./Specs/Doors";
import SpecsHardware from "./Specs/Hardware";
import SpecsTrim from "./Specs/Trim";
import SpecsShelving from "./Specs/Shelving";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    tabsIndicator: {
        backgroundColor: theme.palette.grey[50],
    },
}));

const SpecsTabs = ({ context }) => {
    const classes = useStyles();
    const [tab, setTab] = useState('doors');

    // useEffect(() => {
    //     console.log(window.location.hash.replace("#", ""));
    //     if (window.location.hash)
    //         setTab(window.location.hash.replace("#", ""));
    // }, []);

    return (
        <div className={classes.root}>
            <AppBar color="secondary" position="static" elevation={0}>
                <Tabs
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                    centered
                    variant="fullWidth"
                    classes={{indicator: classes.tabsIndicator }}
                >
                    <Tab value='doors' label="doors" />
                    <Tab value='hardware' label="hardware" />
                    <Tab value='trim' label="trim" />
                    <Tab value='shelving' label="shelving" />
                </Tabs>
            </AppBar>
            {tab === 'doors' && <SpecsDoors context={context} />}
            {tab === 'hardware' && <SpecsHardware context={context} />}
            {tab === 'trim' && <SpecsTrim context={context} />}
            {tab === 'shelving' && <SpecsShelving context={context} />}
        </div>
    );
}

export default SpecsTabs