import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ReactS3Uploader from 'react-s3-uploader';
import PreviewGallery from './PreviewGallery'
import { REACT_APP_S3_LINK } from '../../envs';

const useStyles = makeStyles((theme) => ({
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    progress: {
        marginLeft: 16,
    },
}));

const pdfPlaceholder = `https://via.placeholder.com/200x100/cccccc/808080?text=pdf`

const ProductImages = (props) => {
    const {category, disabled, images, handleChange, handleDeleteImage} = props
    const imagesUploader = useRef(null);
    const uploaderRef = useRef(null);
    const classes = useStyles();
    const [state, setState] = useState({
        images : [],
        images_preview : [],
        count: 0,
        uploader: null,
    })

    useEffect(() => {
        let images_preview = []
        images.forEach( (image, i) => {
            const isPdf = image.toLowerCase().indexOf('.pdf') > 0;
            const previewImage = isPdf ? pdfPlaceholder : getS3ImagePath(image)
            images_preview[i] = previewImage
        })
        setState({
            ...state,
            images,
            images_preview
        });
    }, []);

    const getS3ImagePath = (image) => {
        // let bucket = window.location.hostname === 'localhost' ? 'riverside-local' : window.location.hostname.includes("dev") ? 'riverside-dev' : window.location.hostname.includes("staging") ? 'riverside-staging' : 'riverside-prod'
        let bucket =  window.location.hostname === 'localhost' || window.location.hostname.includes("dev") ? 'rmg-dev-uploads' 
                    : window.location.hostname.includes("staging") ? 'rmg-test-uploads' : 'rmg-prod-uploads';
        return `${REACT_APP_S3_LINK}${bucket}/${image}`
    }

    const onUploadFinish = (file) => {
        const images = [
            ...state.images,
            file.filename
        ]
        const isPdf = file.filename.toLowerCase().indexOf('.pdf') > 0;
        const previewImage = isPdf ? pdfPlaceholder : getS3ImagePath(file.filename)
        setState( prevState => {
            return {
                ...prevState,
                count : prevState.count -1,
                images,
                images_preview: [
                    ...state.images_preview,
                    previewImage
                ]
            }
        })
        handleChange(images)
    }

    const onUploadStart = (file, next) => {
        setState( prevState => {
            return {...prevState, count : prevState.count +1}
        })
        if (file.type === "application/pdf") next(file)
        else compressFile(file, next, { format: 'image/png' });
    }

    const dataURItoBlob = (dataURI, format) => {
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: format });
    }

    const compressFile = (file, next, options) => {
        const maxWidth = 1200
        const maxHeight = 1200
        const { format } = options;
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {
            const { width, height } = img;
            if (width > maxWidth) {
                let ratio = width / maxWidth
                canvas.width = maxWidth;
                canvas.height = height / ratio;
                ctx.drawImage(img, 0, 0, canvas.width , canvas.height);
                next(new File([dataURItoBlob(canvas.toDataURL(format, 0.5))], file.name, { type: format }));
            }
            else if (height > maxHeight) {
                let ratio = height / maxHeight
                canvas.height = maxHeight;
                canvas.width = width / ratio;
                ctx.drawImage(img, 0, 0, canvas.width , canvas.height);
                next(new File([dataURItoBlob(canvas.toDataURL(format, 0.5))], file.name, { type: format }));
            }
            else {
                next(file)
            }
        };
        img.src = URL.createObjectURL(file);
    }

    const deleteImage = (index) => {
        let images_preview = state.images_preview.slice()
        images_preview.splice(index, 1)
        setState({ ...state, images_preview })
        handleDeleteImage(index)
    }

    const onUploadError = (error) => {
        console.log(error)
    }

    const onUploadProgress = (percent, message) => {
        console.log(percent, message)
    }

    const handleSelectClick = () => {
        imagesUploader.current.click();
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ReactS3Uploader
                    id="file"
                    signingUrl="/s3/sign"
                    signingUrlMethod="GET"
                    accept="image/*,application/pdf"
                    s3path={`m_${category}/`}
                    preprocess={onUploadStart}
                    onProgress={onUploadProgress}
                    onError={onUploadError}
                    onFinish={onUploadFinish}
                    // signingUrlWithCredentials={ true }      // in case when need to pass authentication credentials via CORS
                    uploadRequestHeaders={{'x-amz-acl': 'private'}}
                    signingUrlHeaders={{ Authorization: `bearer ${localStorage.getItem('token')}` }}
                    contentDisposition="auto"
                    scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
                    server={window.location.hostname === 'localhost' ? `http://${window.location.hostname}:8001` : `https://${window.location.hostname}`}
                    inputRef={imagesUploader}
                    autoUpload={true}
                    multiple={true}
                    style={{display: "none"}}
                    ref={ uploaderRef}
                />
                <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={handleSelectClick}
                    disabled={disabled || state.count > 0 }
                >
                    {state.count > 0 ? 'Uploading' : 'Select Images'}
                    {
                        state.count > 0 ?
                            <CircularProgress size={20} className={classes.progress} /> : ''
                    }

                </Button>
            </Grid>
            <Grid item xs={12}>
                <PreviewGallery
                    deleteImage={deleteImage}
                    images_preview={state.images_preview}
                    images={state.images}
                    getFilePath={getS3ImagePath}
                />
            </Grid>
        </Grid>
    )
}

export default ProductImages