import React from 'react';
//components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as Lib from './lib/lib'
import withMobileDialog from "@material-ui/core/withMobileDialog/index";
import MaskedInput from 'react-text-mask';

import CustomToggleGroup from '../common/CustomToggleGroup'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// const context = useContext(TakeoffFormContext);
function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }


const ProspectDialog = ({context,prospect, open, closeDialog, handleProspect, fullScreen}) => {
    // const [firstname, setFirstname]     = useState(prospect.firstname || '');
    // const [lastname, setLastname]       = useState(prospect.lastname || '');
    // const [email, setEmail]             = useState(prospect.email || '');
    // const [phone, setPhone]             = useState(prospect.phone || '');
    // const [location, setLocation]       = useState(prospect.location || '');
    // const [gps, setGps]                 = useState(prospect.gps || '');
    // const [address, setAddress]         = useState(prospect.address || '');
    // const [address2, setAddress2]       = useState(prospect.address2 || '');
    // const [city, setCity]               = useState(prospect.city || '');
    // const [province, setProvince]       = useState(prospect.province || '');
    // const [postalCode, setPostalCode]   = useState(prospect.postalCode || '');
    // const [country, setCountry]         = useState(prospect.country || '');
    // const [pricingTier, setPricingTier] = useState(prospect.pricingTier || '');
    const disabled = context.isDoneTakeoff();
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={closeDialog}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                Prospect
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div>
                            <Typography variant="subtitle1" gutterBottom={false}>
                                Pricing Tier
                            </Typography>
                        </div>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                lp00: 'LP',
                                lp05: '-5%',
                                lp10: '-10%',
                                lp15: '-15%',
                            }}
                            value={prospect.pricingTier || ''}
                            handleChange={ handleProspect('pricingTier')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel
                                htmlFor="lead-source"
                                required
                            >
                                Lead Source
                            </InputLabel>
                            <Select
                                disabled={disabled}
                                input={
                                    <OutlinedInput
                                        labelWidth={120}
                                        name="Lead Source"
                                        id="lead-source"
                                    />
                                }
                                value={prospect.leadSource}
                                onChange={ handleProspect('leadSource')}
                            >
                                <MenuItem value={null}>{""}</MenuItem>
                                <MenuItem value={'email_marketing'}>{"E-Mail Marketing"}</MenuItem>
                                <MenuItem value={'referral'}>{"Referral"}</MenuItem>
                                <MenuItem value={'sales_rep'}>{"Sales Rep"}</MenuItem>
                                <MenuItem value={'social_media'}>{"Social Media"}</MenuItem>
                                <MenuItem value={'phone'}>{"Phone"}</MenuItem>
                                <MenuItem value={'walk_in'}>{"Walk-In"}</MenuItem>
                                <MenuItem value={'website'}>{"Website"}</MenuItem>
                                <MenuItem value={'facebook'}>{"Facebook add campaign"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            id="firstname"
                            fullWidth
                            disabled={disabled}
                            label="First Name"
                            placeholder="First Name"
                            margin="dense"
                            variant="outlined"
                            value={prospect.firstname || ''}
                            onChange={ handleProspect('firstname')}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            id="lastname"
                            fullWidth
                            disabled={disabled}
                            label="Last Name"
                            placeholder="Last Name"
                            margin="dense"
                            variant="outlined"
                            value={prospect.lastname || ''}
                            onChange={ handleProspect('lastname')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            fullWidth
                            disabled={disabled}
                            label="Email"
                            placeholder="Email"
                            margin="dense"
                            variant="outlined"
                            value={prospect.email || ''}
                            onChange={ handleProspect('email')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled={disabled}
                            label="Company"
                            placeholder="Company"
                            margin="dense"
                            variant="outlined"
                            value={prospect.company || ''}
                            onChange={handleProspect('company')}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled={disabled}
                            label="Phone"
                            placeholder="Phone"
                            margin="dense"
                            variant="outlined"
                            value={prospect.phone || ''}
                            onChange={handleProspect('phone')}
                            id="formatted-numberformat-input"
                            InputProps={{
                                inputComponent: TextMaskCustom,
                            }}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="address"
                            fullWidth
                            disabled={disabled}
                            label="Address"
                            placeholder="Address"
                            margin="dense"
                            variant="outlined"
                            value={prospect.address || ''}
                            onChange={ handleProspect('address')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="address2"
                            fullWidth
                            disabled={disabled}
                            label="Address Line 2"
                            placeholder="Address Line 2"
                            margin="dense"
                            variant="outlined"
                            value={prospect.address2 || ''}
                            onChange={ handleProspect('address2')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="city"
                            fullWidth
                            disabled={disabled}
                            label="City"
                            placeholder="City"
                            margin="dense"
                            variant="outlined"
                            value={prospect.city || ''}
                            onChange={ handleProspect('city')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="postal"
                            fullWidth
                            disabled={disabled}
                            label="Postal Code"
                            placeholder="Postal Code"
                            margin="dense"
                            variant="outlined"
                            value={prospect.postalCode || ''}
                            onChange={ handleProspect('postalCode')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                >
                    Cancel
                </Button>
                <Button
                    disabled={
                        disabled || 
                        !('pricingTier' in prospect) || 
                        prospect.pricingTier === null || 
                        prospect.pricingTier === '' || 
                        !prospect.leadSource
                    }
                    onClick={() => {
                        closeDialog();
                        context.updateCustomer(prospect, 'prospect');
                    }}
                    variant={'contained'}
                    color="secondary">
                    {Lib.isEmptyOrNullObject(context.state.prospect) ? 'Add' : 'Edit'}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withMobileDialog()(ProspectDialog);