import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import TakeoffInfoSummary from './TakeoffInfoSummary';
import { humanize } from '../lib/lib';

const PrepOrganizers = ({ organizers, notes, descriptions, classes }) => {
  const dims = (dimensions = {}, showDim = true) => Object.keys(dimensions || {}).map(k => `${showDim ? `${k}: ` : ''}${dimensions[k]}`).join(' x ');
  return (
    organizers.length > 0 ?
        <Grid spacing={2} container className="break-print">
            <Grid item xs={12}>
                <TakeoffInfoSummary
                    isPrint
                    label="Organizers Prep"
                    notes={notes}
                />
            </Grid>
            <Grid item xs={12} style={{
                '@media print' : {
                    clear: 'both',
                    breakBefore: 'all !important',
                }
            }}>
                <List className={classes.list+ " groupesdList"} subheader={<li />}>
                    <ListSubheader className={classes.listSubheader}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}><Typography variant="body1">Closets</Typography></Grid>
                            <Grid item xs={6}><Typography variant="body1">Organizers</Typography></Grid>
                        </Grid>
                    </ListSubheader>
                {
                    organizers.map((organizer, i) => (
                        <ListItem key={i} className={classes.summaryDoorListItem}>
                            <Grid  container spacing={1}>
                                <Grid item xs={6} style={{display: 'flex', flexDirection: 'column'}}>
                                    <Typography>Closet {i+1}</Typography>
                                    <Typography variant='caption'>Layout: {humanize(organizer.layout)}</Typography>
                                    <Typography variant='caption'>Dims: {dims(organizer.dimensions, false)} IN</Typography>
                                    {
                                        organizer.notes && (
                                            <Typography variant="caption">
                                                NOTE: {organizer.notes}
                                            </Typography>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={6} style={{display: 'flex', flexDirection: 'column'}}>
                                    {
                                        Object
                                        .keys(organizer.wallDesigns)
                                        .filter(wall => organizer.wallDesigns[wall]?.designs?.length > 0)
                                        .map((wall, j) => (
                                            <Fragment key={j}>
                                                <Typography variant='button'>{wall} {organizer.wallDesigns[wall].hasJoiner && '+joiner'}: </Typography>
                                                {
                                                    organizer.wallDesigns[wall].designs.map((d, l) => (
                                                        <Typography key={j+l} variant='caption'>{d.design}: {d.width}"</Typography>
                                                    ))
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))
                }
                </List>
            </Grid>
        </Grid> : ""
  );
};

export default PrepOrganizers;