import React from 'react';
// import { withStyles } from '@material-ui/core/styles';
import ActivityLog from './ActivityLog';

const Activity = ({id}) => {
    if (!id) return <div>{""}</div>;
    return (
        <>
            <ActivityLog title="Sold" status="sold" id={id} />
            <ActivityLog title="Estimate" status="estimate" id={id} />
            <ActivityLog title="Review" status="review" id={id} />
            <ActivityLog showLoading title="New" status="new" id={id} />
            <ActivityLog title="Archived" status="archived" id={id} />
        </>
    )
}

export default Activity;