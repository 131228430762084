import React from "react";
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '8px 8px 0px 8px',
    },
    chip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    category: {
        color: 'black',
        backgroundColor: theme.palette.grey[400]
    }
}));

const SpecsChips = (props) => {
    const classes = useStyles();
    const { context, specLabel, refSpecs= null, category, allowedAttributes= [], type, label, disabled } = props;
    const specs = refSpecs ? refSpecs : type ? context.state[specLabel][category][type] : context.state[specLabel][category];
    // console.log(emptySpecs, allowedAttributes, category, type, refSpecs, specs)
    const emptySpecs = allowedAttributes
                        .filter(spec => specs && specs[spec]).length < 1;
    return (
        <div className={classes.root}>
            {
                emptySpecs &&
                <Typography variant={'subtitle1'} style={{paddingBottom: '8px'}}>
                    Add {context.humanize(label ? label : type || category)} specs
                </Typography>
            }
            {
                !emptySpecs &&
                (type || ['door', 'sheet', 'shelving'].includes(category)) &&
                <Chip
                    label={context.humanize(label ? label : type || category)}
                    className={disabled ? classnames(classes.chip, classes.category) : classes.chip}
                    color={"primary"}
                />
            }
            {
                specs &&
                (
                    ['door', 'sheet', 'shelving'].includes(category) ||
                    ( category === 'trim' && type)  ||
                    ( category === 'hardware' && type)
                ) &&
                Object.keys(specs)
                .filter(a => specs[a] !== 'n/a')
                .map( (attribute, i) => {
                    if (!['trimlength', 'category'].includes(attribute) && specs[attribute] ) {
                        if (allowedAttributes && allowedAttributes.length > 0 && allowedAttributes.includes(attribute)) {
                            return (<Chip
                                key={'chip'+i}
                                // avatar={<Avatar>{this.abbr[attribute]}</Avatar>}
                                label={specs[attribute] === true ? attribute : specs[attribute]}
                                className={classes.chip}
                                variant="outlined"
                            />)
                        }
                        else if (Object.keys(allowedAttributes).length === 0) {
                            return (<Chip
                                key={'chip'+i}
                                // avatar={<Avatar>{this.abbr[attribute]}</Avatar>}
                                label={specs[attribute]}
                                className={classes.chip}
                                variant="outlined"
                            />)
                        }
                    }
                    return null;
                })
            }
        </div>
    )
}

export default SpecsChips