import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UncompletedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CompletedIcon from "@material-ui/icons/CheckBox";
import * as Lib from '../lib/lib'

const useStyles = makeStyles((theme) => ({
    icon:{
        marginRight: '8px',
        color: theme.palette.secondary[400]
    },
}));

const SpecStatusIcon = ({specs, attributes, product = {}}) => {
    const classes = useStyles();
    const status = Lib.checkSpecs(specs, attributes);
    const p_status = Lib.deepAttributeExists(['code'], product);
    return p_status || status ?
            <CompletedIcon className={classes.icon}/> :
            <UncompletedIcon className={classes.icon}/>
}

export default SpecStatusIcon