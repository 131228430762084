import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    dialogImageContainer: {
        paddingTop: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        maxWidth : '1080px',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    image: {
        cursor: 'pointer',
        width: '100%',
        height: '100px',
        objectFit: 'cover'
    },
    delete: {
        position: 'absolute',
        bottom: '16px',
        right: '16px'

    },
    imageRoot:{
        position: 'relative'
    },
    dialogImage: {
        width: '100%',
        height: '100%'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const pdfPlaceholder = `https://via.placeholder.com/500x400/cccccc/808080?text=pdf`

const PreviewGallery = (props) => {
    const { images_preview, images, getFilePath, deleteImage } = props;
    const classes = useStyles();
    const [state, setState] = useState({
        open: false,
        dialogImage: '',
        fullscreen: false,
        type: '',
        index: '',
        isPdf: false
    })

    const handleClickOpen = (preview, image, index) => {
        const isPdf = image.toLowerCase().indexOf('.pdf') > 0;
        setState({ ...state, fullscreen: true, type: 'show', open: true, dialogImage: image, index: index, isPdf });
    };

    const handleDeleteConfirm = async () => {
        await deleteImage(state.index)
        setState({...state, open: false, index: ''});
    }

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleDelete = () => {
        setState({ ...state, fullscreen: false, type: 'delete', open: true});
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {
                    images_preview.map( (image, i) => (
                        <Grid item key={i} xs={4} className={classes.imageRoot} onClick={() => handleClickOpen(image, images[i], i)}>
                            <img className={classes.image} src={image} alt={'gallery'} />
                        </Grid>
                        )
                    )
                }
            </Grid>
            <Dialog
                fullScreen={state.fullscreen}
                open={state.open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                {
                    state.type === 'show' &&
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant={'h6'} color={'inherit'} className={classes.flex}>
                                Image {state.index+1}
                            </Typography>
                            <Button variant="contained" color="secondary" onClick={handleDelete}
                            >
                                Delete
                            </Button>
                        </Toolbar>
                    </AppBar>
                }
                <div className={classes.dialogImageContainer}>
                    {
                        state.type === 'delete' ?
                            <div>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Are you sure you want to delete this image?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDeleteConfirm} color="primary">
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </div>
                            :
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {
                                        state.isPdf ?
                                        <a target="_blank" href={getFilePath(state.dialogImage)}>
                                            <img src={pdfPlaceholder} alt={'gallery-preview'} className={classes.dialogImage} />    
                                        </a>
                                        :
                                        <img src={getFilePath(state.dialogImage)} alt={'gallery-preview'} className={classes.dialogImage} />
                                    }
                                </Grid>
                            </Grid>
                    }
                </div>
            </Dialog>
        </div>
    )
}

export default PreviewGallery