import React, { useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import {Grid, Paper, FormControl, TextField, Typography} from '@material-ui/core';
import { Select, MenuItem, InputLabel, OutlinedInput} from '@material-ui/core';
import { FormControlLabel, Switch } from '@material-ui/core';
import AttributeSelect from '../AttributeSelect';
import NotesAndImages from './NotesAndImages';
import SpecsType from '../Specs/SpecsType';
import * as Lib from "../lib/lib";
import CustomToggleGroup from '../../common/CustomToggleGroup'
import AdditionalItem from './AdditionalItem';
import MeasureUnits from './MeasureUnits'
import InlineEdit from '../../common/InlineEdit'
import ShelfOrganizer from './ShelfOrganizer';

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper:{
        padding:'16px',
    },
    group:{
        flexDirection: 'row',
    },
    chip: {
        margin: theme.spacing(1),
    },
    product: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
        marginBottom: '16px'
    },
}));

const NewShelving = (props) => {
    const { context } = props;
    const classes = useStyles();
    const disabled = context.isDoneTakeoff();
    const installPricingMode = context.state.installPricingMode;

    const shelving = context.state.measures.custom.shelving;
    const additionalItem = shelving.additionalItem
    const additionalItemObj = Object.keys(additionalItem).length === 0 && additionalItem.constructor === Object ? 
                                context.state.additionalOrder.additionalItem : additionalItem

    useEffect(() => {
        if (context.state.editShelvingIndex === -1 ) {
            const maxIndex = Math.max(
                ...context.state.shelving
                .filter(o => o)
                .map(o => 'itemIndex' in o ? o.itemIndex : 0)
            , 0);
            context.setState({
                measures: {
                    ...context.state.measures,
                    custom:{
                        ...context.state.measures.custom,
                        shelving: {
                            ...shelving,
                            itemIndex: maxIndex + 1
                        }
                    }
                }
            });
            context.copySpecs('specs', 'specialSpecs', 'shelving');
            context.copySpecs('specs', 'specialSpecs', 'hardware');
            context.copySpecs('specs', 'specialSpecs', 'trim');
            context.copySpecs('specs', 'specialSpecs', 'products', 'cleat');
            context.copySpecs('specs', 'specialSpecs', 'shelving', 'organizer', {});
        } else {
            const shelving = context.state.shelving[context.state.editShelvingIndex];
            context.copySpecs('specs', 'specialSpecs', 'shelving', shelving.type, shelving.product.attributes);
            ['trim', 'hardware'].forEach(category => {
                Object.keys(shelving[category])
                .filter(type => {
                    return  (shelving[category][type] &&
                        'code' in shelving[category][type] &&
                        shelving[category][type].code
                    )
                })
                .forEach( type => {
                    // console.log(category, type, shelving[category][type])
                    if (['cleat'].includes(type)) {
                        context.copySpecs('specs', 'specialSpecs', 'products', 'cleat', shelving[category][type]);
                    }
                    if (!['bracket'].includes(type)) {
                        context.copySpecs('specs', 'specialSpecs', ['cleat'].includes(type) ? 'shelving' : category, ['cleat'].includes(type) ? 'cleat' : type, shelving[category][type].attributes || {});
                    }
                })
            })
            // context.copySpecs('specs', 'specialSpecs', 'hardware');
            // context.copySpecs('specs', 'specialSpecs', 'products', 'd4s');
        }
    }, []);

    const handleChange = (field, value, extraChange = {}) => {
        context.setState({
            measures: {
                ...context.state.measures,
                custom: {
                    ...context.state.measures.custom,
                    shelving: {
                        ...shelving,
                        ...extraChange,
                        [field]: value ? value : '',
                    }
                }
            }
        });
        if (field === 'type') {
            context.copySpecs('specs', 'specialSpecs', 'shelving', value);
            context.copySpecs('specs', 'specialSpecs', 'products', value);
        }
    };

    const handleSpecChange = (category, type) => ({specs, copy, propagate}) => {
        if (
            category === 'shelving' &&
            type     === 'organizer' &&
            Lib.isEmptyOrNullObject(specs)
        ) {
            context.setState({
                measures: {
                    ...context.state.measures,
                    custom: {
                        ...context.state.measures.custom,
                        shelving: {
                            ...shelving,
                            depth: ''
                        }
                    }
                }
            })
        }
        if (
            ['wood', 'wire', 'cleat'].includes(type) &&
            !Lib.isEmptyOrNullObject(specs)
        ) {
            const typeAttributes = {
                category: type === 'cleat' ? "Trim" : Lib.humanize(category),
                type: type === 'cleat' ? "Flat Stock" : Lib.humanize(type),
                ...specs,
            };
            const typePromise = context.getAnyProduct(typeAttributes);
            typePromise.then(product => {
                if (product) {
                    const typeProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        attributes:     typeAttributes
                    };
                    // console.log(typeProduct);
                    context.saveSpecs({
                        scope: 'specialSpecs',
                        product: typeProduct,
                        category,
                        type,
                        specs,
                        copy,
                        propagate
                    });
                }
            })
        } else {
            context.saveSpecs({
                scope: 'specialSpecs',
                category,
                type,
                specs,
                copy,
                propagate
            });
        }

    };

    const handleDeleteImage = (index) => {
        context.deleteTypeImage(index, 'shelving')
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Shelf{" "}
                        <InlineEdit
                            text={ shelving.title || shelving.itemIndex || "" }
                            onSetText={(value) => handleChange('title', value)}
                        />
                    </Typography>
                </Grid>
                {
                    context.state.isAdditionalOrder &&
                    <AdditionalItem 
                        additionalItem={additionalItemObj}
                        disabled={disabled}
                        onItemChange={( value)=> {
                            context.setState({
                                measures: {
                                    ...context.state.measures,
                                    custom: {
                                        ...context.state.measures.custom,
                                        shelving: {
                                            ...shelving,
                                            additionalItem: value
                                        }
                                    }
                                }
                            })
                        }}
                    />
                }
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel
                                        htmlFor="product-type"
                                        required
                                    >
                                        Type
                                    </InputLabel>
                                    <Select
                                        disabled={disabled}
                                        value={shelving.type}
                                        onChange={ (event) => handleChange('type', event.target.value || '')}
                                        input={
                                            <OutlinedInput
                                                labelWidth={100}
                                                name="type"
                                                id="product-type"
                                            />
                                        }
                                    >
                                        <MenuItem value={''}>{" "}</MenuItem>
                                        <MenuItem value={'wire'}>Wire</MenuItem>
                                        <MenuItem value={'wood'}>Melamine</MenuItem>
                                        <MenuItem value={'organizer'}>Organizer</MenuItem>
                                        <MenuItem value={'accessories'}>Accessories</MenuItem>
                                    </Select>
                                </FormControl>
                                {/* <CustomToggleGroup
                                    disabled={disabled}
                                    items={{
                                        wire: 'Wire',
                                        wood: 'Melamine',
                                    }}
                                    value={shelving.type}
                                    handleChange={ (event, value) => handleChange('type', value || '')}
                                /> */}
                            </Grid>
                            {
                                ['organizer', 'accessories'].includes(shelving.type) &&
                                <ShelfOrganizer 
                                    disabled={disabled}
                                    shelving={shelving}
                                    showOrganizer={shelving.type === 'organizer'}
                                    handleSpecChange={handleSpecChange}
                                    handleChange={handleChange}
                                    organizerSpecs={context.state.specialSpecs.shelving.organizer || {}}
                                />
                            }
                            {
                                shelving.type === 'wire' &&
                                <Grid item xs={12}>
                                    <SpecsType
                                        disabled={disabled}
                                        label={shelving.type === 'wood' ? 'Melamine' : shelving.type}
                                        category={'shelving'}
                                        type={shelving.type}
                                        attributes={['mode']}
                                        handleChange={handleSpecChange('shelving', shelving.type)}
                                        specs={context.state.specialSpecs.shelving[shelving.type.toLowerCase()]}
                                        globalSpecs={context.state.specs.shelving[shelving.type.toLowerCase()]}
                                    />
                                </Grid>
                            }
                            {
                                (
                                    shelving.type === 'wood' ||
                                    (
                                        shelving.type === 'wire' &&
                                        Lib.deepAttributeExists(['wire', 'mode'], context.state.specialSpecs.shelving)
                                    )
                                ) &&
                                <Grid item xs={12}>
                                    <AttributeSelect
                                        hideDelete
                                        disabled={disabled}
                                        selectionControl={"radio"}
                                        field={"depth"}
                                        category={"Shelving"}
                                        type={shelving.type}
                                        title={"Depth"}
                                        value={shelving.depth}
                                        refSpec={{
                                            category: 'Shelving',
                                            type: shelving.type,
                                            ...(
                                                shelving.type === 'wire' &&
                                                {
                                                    mode: Lib.deepAttributeExists(['wire', 'mode'], context.state.specialSpecs.shelving)
                                                }
                                            )
                                            // mode: shelving.rod_finish,
                                        }}
                                        handleChange={(event, value) => {
                                            context.setState({
                                                measures: {
                                                    ...context.state.measures,
                                                    custom: {
                                                        ...context.state.measures.custom,
                                                        shelving: {
                                                            ...shelving,
                                                            depth: value || ''
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                            {
                                shelving.depth &&
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" required className={classes.formControl} fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            required
                                            id="width"
                                            type={"number"}
                                            label="Shelf Width (IN)"
                                            placeholder="Enter the shelf width"
                                            margin="dense"
                                            variant="outlined"
                                            value={shelving.width}
                                            onChange={ (event) => {
                                                context.setState({
                                                    measures: {
                                                        ...context.state.measures,
                                                        custom:{
                                                            ...context.state.measures.custom,
                                                            shelving: {
                                                                ...shelving,
                                                                width: event.target.value
                                                            }
                                                        }
                                                    },
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            }
                            {
                                shelving.depth &&
                                <Grid item xs={12}>
                                    <Typography variant={'body1'}>
                                        Quantity
                                    </Typography>
                                    <CustomToggleGroup
                                        disabled={disabled}
                                        items={{
                                            '1': '1',
                                            '2': '2',
                                            '3': '3',
                                            '4': '4',
                                        }}
                                        value={shelving.quantity}
                                        handleChange={(_event, value) => handleChange('quantity', value || '') }
                                    />
                                </Grid>
                            }
                            {
                                shelving.type === 'wire' &&
                                shelving.quantity &&
                                installPricingMode !== 'contract_pricing' &&
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={disabled}
                                                checked={shelving.addedInstall || false}
                                                onChange={(event) => handleChange('addedInstall', event.target.checked)}
                                            />
                                        }
                                        label={'Add install'}
                                    />
                                </Grid>
                            }
                            {
                                shelving.type === 'wood' &&
                                <Grid item xs={12}>
                                    <AttributeSelect
                                        hideDelete
                                        disabled={disabled}
                                        selectionControl={"radio"}
                                        field={"finish"}
                                        category={"Hardware"}
                                        type={"Rod"}
                                        title={"Shelf Rod"}
                                        value={shelving.rod_finish}
                                        refSpec={{
                                            category: 'Hardware',
                                            type: 'Rod',
                                            rod_finish: shelving.rod_finish,
                                        }}
                                        handleChange={(event, value) => {
                                            context.setState({
                                                measures: {
                                                    ...context.state.measures,
                                                    custom: {
                                                        ...context.state.measures.custom,
                                                        shelving: {
                                                            ...shelving,
                                                            rod_finish: value || ''
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                            {
                                shelving.type === 'wood' &&
                                shelving.depth &&
                                <Grid item xs={12}>
                                    <Typography variant={'body1'}>
                                        Additional Rods
                                    </Typography>
                                    <CustomToggleGroup
                                        disabled={disabled}
                                        items={{
                                            '1': '1',
                                            '2': '2',
                                            '3': '3',
                                            '4': '4',
                                        }}
                                        value={shelving.additional_rods}
                                        handleChange={(event, value) => handleChange('additional_rods', value || '') }
                                    />
                                </Grid>
                            }
                            {
                                shelving.type === 'wood' &&
                                shelving.depth &&
                                <Grid item xs={12}>
                                    <Typography variant={'body1'}>
                                        Additional Brackets
                                    </Typography>
                                    <CustomToggleGroup
                                        disabled={disabled}
                                        items={{
                                            '1': '1',
                                            '2': '2',
                                            '3': '3',
                                            '4': '4',
                                        }}
                                        value={shelving.additional_brackets}
                                        handleChange={(event, value) => handleChange('additional_brackets', value || '') }
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <MeasureUnits
                                    disabled={disabled}
                                    value={shelving.unitNumber || ""}
                                    handleChange={event => handleChange('unitNumber', event.target.value ) }
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {
                    shelving.type === 'wood' &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={'subtitle1'}>
                                        Cleat
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SpecsType
                                        disabled={disabled}
                                        label={'Cleat'}
                                        category={'trim'}
                                        type={'flat_stock'}
                                        attributes={['supplier' ,'style', 'finish', 'species', 'width', 'thickness', 'length']}
                                        handleChange={handleSpecChange('shelving' ,'cleat')}
                                        specs={context.state.specialSpecs.shelving.cleat}
                                        globalSpecs={context.state.specs.shelving.cleat}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <NotesAndImages
                        disabled={disabled}
                        category={"shelving"}
                        notes={shelving.notes}
                        images={ shelving.images }
                        handleChange={handleChange}
                        handleDeleteImage={handleDeleteImage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default NewShelving