import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import useTakeoffParser from '../lib/useTakeoffParser';
import NumberEasing from 'che-react-number-easing';

const useStyles = makeStyles(() => ({
    root: {
        marginLeft: '16px'
    },
    price: {
        color: 'white'
    }
}));

const TotalPrice = () => {
    const classes = useStyles();
    const [{prices}, loading] = useTakeoffParser();
    const [price, setPrice] = useState(0);

    const total = !loading && Object.keys(prices).reduce((sum,key) => sum + parseFloat(prices[key]||0),0);
    useEffect( () => {
        setPrice(Math.ceil(total))
    }, [total])
    if (loading) return (<Typography variant="h4" className={classes.price}>$...</Typography>)
    return (
        <Typography className={classes.root} variant="h5" color="inherit">
            $
            <NumberEasing
                value={price}
                speed={300}
                ease='quintInOut'
                // trail={true}
                precision={0}
                useLocaleString={true}
            />
        </Typography>
    )
}

export default TotalPrice