export const humanize = (str, allCaps = false) => {
    if (str === 'n/a') return 'N/A'
    if (str === null || str === 'null' || str === undefined) return '';
    let frags = str.split('_');
    for (let i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return allCaps ? frags.join(' ').toUpperCase() : frags.join(' ');
};

export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};