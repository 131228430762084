import { gql } from '@apollo/client';
import * as Lib from "./lib";

const checkAttribute = (field, attribute, spec ={}) => {
    return  field !== attribute &&
        Object.keys(spec).length !== 0 &&
        attribute in spec &&
        spec[attribute] !== null
}

export const attributeValuesQueryOptions = ({category, type, field: attribute, refSpec: specs, extraAttributes = {}}) => {
    // console.log(category, type, attribute, specs)
    // console.log(extraAttributes)
    // if (!specs) return {};
    const realType = specs?.type ? Lib.humanize(specs.type) : ['shoe_moulding', 'Shoe Moulding'].includes(type) ? 'Doorstop' : Lib.humanize(type)
    return {
        category:   Lib.humanize(category),
        type:       realType,
        core:       checkAttribute(attribute, 'core', specs)         ? specs.core         : '' ,
        species:    checkAttribute(attribute, 'species', specs)      ? specs.species      : '' ,
        style:      checkAttribute(attribute, 'style', specs)        ? specs.style        : '' ,
        width:      checkAttribute(attribute, 'width', specs)        ? specs.width        : '' ,
        thickness:  checkAttribute(attribute, 'thickness', specs)    ? specs.thickness    : '' ,
        supplier:   checkAttribute(attribute, 'supplier', specs)     ? specs.supplier     : '' ,
        brand:      checkAttribute(attribute, 'brand', specs)        ? specs.brand        : '' ,
        finish:     checkAttribute(attribute, 'finish', specs)       ? specs.finish       : '' ,
        frame:      checkAttribute(attribute, 'frame', specs)        ? specs.frame        : '' ,
        firerated:  checkAttribute(attribute, 'firerated', specs)    ? specs.firerated    : '' ,
        rosette:    checkAttribute(attribute, 'rosette', specs)      ? specs.rosette      : '' ,
        length:     checkAttribute(attribute, 'length', specs)       ? specs['length']    : '' ,
        height:     checkAttribute(attribute, 'height', specs)       ? specs.height       : '' ,
        radius:     checkAttribute(attribute, 'radius', specs)       ? specs.radius       : '' ,
        mode:       checkAttribute(attribute, 'mode', specs)         ? specs.mode         : '' ,
        tracklength:checkAttribute(attribute, 'tracklength', specs)  ? specs.tracklength  : '' ,
        size:       checkAttribute(attribute, 'size', specs)         ? specs.size         : '' ,
        material:   checkAttribute(attribute, 'material', specs)     ? specs.material     : '' ,
        depth:      checkAttribute(attribute, 'depth', specs)        ? specs.depth        : '' ,
        direction:  checkAttribute(attribute, 'direction', specs)    ? specs.direction    : '' ,
        grade:      checkAttribute(attribute, 'grade', specs)        ? specs.grade        : '' ,
        color:      checkAttribute(attribute, 'color', specs)        ? specs.color        : '' ,
        design:     checkAttribute(attribute, 'design', specs)       ? specs.design       : '' ,
        hardware:   checkAttribute(attribute, 'hardware', specs)     ? specs.hardware     : '' ,
        drawer:     checkAttribute(attribute, 'drawer', specs)       ? specs.drawer       : '' ,
        accessory:  checkAttribute(attribute, 'accessory', specs)    ? specs.accessory    : '' ,
        sticking:   checkAttribute(attribute, 'sticking', specs)    ? specs.sticking    : '' ,
        panelProfile: checkAttribute(attribute, 'panelProfile', specs)    ? specs.panelProfile    : '' ,
        backband:   checkAttribute(attribute, 'backband', specs)     ? specs.backband     : false ,
        ...extraAttributes
    }
}

export const attributeValuesQuery = (extraFields = []) => {
    return gql`
        query GetAttributeValues($field: String!, $condition: Condition, $allowedValues: [String], $extraFields: [String]  ) {
            productGroups(field: $field, condition: $condition, allowedValues:  $allowedValues, extraFields: $extraFields) {
                _id ${extraFields.join(' ')}
            }
        }
    `;
}
