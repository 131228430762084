import React from 'react';
//material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SpecStatusIcon from './SpecStatusIcon';
import AvailableDimensions from './AvailableDimensions';
//components
import SpecsType from './SpecsType'
import * as Lib from "../lib/lib";

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper:{
        padding:'16px',
    },
    icon:{
        marginRight: '8px',
        // color: theme.palette.grey[400]
        color: theme.palette.secondary[400]
    },
    expansionPanelSummary: {
        paddingRight: "16px",
        paddingLeft: "16px"
    },
    verticalBorder: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    list: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
        margin: '8px'
    }
}));

const fullSpecs = ['hinge', 'bifold_knob', 'flushbolt', 'closer', 'catches', 'deadbolt', 'barn_door_track', 'finger_pull', 'handrail_bracket'];

const Hardware = ({ context }) => {
    const classes = useStyles();

    const handleSpecChange = (category, type) => ({specs, propagate}) => {
        if (
            fullSpecs.includes(type) &&
            !Lib.isEmptyOrNullObject(specs)
        ) {
            const {...productSpecs} = specs;
            // const {extra_specs, type: notType, ...productSpecs} = specs;
            const typeAttributes = {
                category: context.humanize(category),
                type: type,
                ...productSpecs,
            };
            const typePromise = context.getAnyProduct(typeAttributes);
            typePromise.then(product => {
                if (product) {
                    const typeProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        attributes:     typeAttributes
                    };
                    // console.log(typeProduct);
                    context.saveSpecs({
                        product: typeProduct,
                        category,
                        type,
                        specs,
                        propagate
                    });
                }
            })
        } else {
            context.saveSpecs({
                category,
                type,
                specs,
                propagate,
                ...(fullSpecs.includes(type) ? {product: {}} : {})
            });
        }

    };

    const attributes = (type, validation = false) => {
        let attributes = [];
        switch (type) {
            case "hinge":
                attributes = ['width', 'height', 'radius', 'finish', 'style', 'brand'];
                break;
            case "lever":
            case "knob":
                attributes = validation ? ['brand', 'finish', 'style'] : ['brand', 'finish', 'style', 'rosette', 'firerated'];
                break;
            case "bifold_knob":
                attributes =  ['brand', 'finish'];
                break;
            case "deadbolt":
                attributes = ['brand', 'mode', 'finish', 'style', 'rosette', 'firerated'];
                break;
            case "gripset":
            case "closer":
                attributes = ['brand', 'finish', 'style'];
                break;
            case "flushbolt":
                attributes =['length', 'finish', 'style', 'width'];
                break;
            case "catches":
                attributes = ['style', 'finish'];
                break;
            case "doorstopper":
                attributes = ['finish'];
                break;
            case "pocket":
                attributes = ['rosette', 'finish', 'brand' ];
                break;
            case "barn_door_track":
                attributes = ['brand', 'style', 'finish', 'tracklength'];
                break;
            case "bipass_door_track":
                attributes = ['brand', 'style'];
                break;
            case "finger_pull":
                attributes = ['finish', 'size'];
                break;
            case "handrail_bracket":
                attributes = ['finish', 'style', 'width'];
                break;
            default:
                attributes = [];
                break
        }
        return attributes;
    };
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                {
                    ['hinge', 'lever', 'knob', 'bifold_knob', 'gripset', 'deadbolt', 'closer', 'doorstopper', 'flushbolt', 'catches', 'pocket', 'barn_door_track', 'bipass_door_track', 'finger_pull', 'handrail_bracket'].map( (type, index) => {
                        const specs = context.state.specs.hardware[type];
                        // console.log(type, specs)
                        return (
                            <Accordion key={"hardware-"+index} elevation={4}>
                                <AccordionSummary
                                    className={classes.expansionPanelSummary}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <SpecStatusIcon
                                        specs={context.state.specs.hardware[type]}
                                        product={context.state.specs.products[type] || {}}
                                        attributes={attributes(type, true)}
                                    />
                                    <Typography variant="h6">{context.humanize(type)}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            !['hinge', 'bifold_knob', 'flushbolt', 'closer', 'catches', 'deadbolt', 'barn_door_track', 'finger_pull', 'handrail_bracket'].includes(type) &&
                                            context.state.specs.hardware[type] &&
                                            Lib.checkSpecs(context.state.specs.hardware[type], attributes(type, true)) &&
                                            <AvailableDimensions context={context} category={'hardware'} type={type} specs={context.state.specs.hardware[type]} />
                                        }
                                        {
                                            context.isSpecProductSet(type) &&
                                            <Grid item xs={12} className={classes.list}>
                                                <Typography variant="body2">{context.state.specs.products[type].description}</Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <SpecsType
                                                hideCopy
                                                disabled={context.isDoneTakeoff()}
                                                category={'hardware'}
                                                type={Lib.humanize(type)}
                                                attributes={attributes(type)}
                                                handleChange={handleSpecChange('hardware', type)}
                                                specs={specs}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }

            </Grid>
        </Grid>
    )
}

export default Hardware