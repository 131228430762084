import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmptyMeasureImage from '../../../images/empty_measure.png';
//components
import MeasureDoorListItem from './MeasureDoorListItem';
import MeasureTrimListItem from './MeasureTrimListItem';
import MeasureListItem from './MeasureListItem';
import MeasureCustomItem from './MeasureCustomItem';
import MeasureShelvingListItem from './MeasureShelvingListItem';
import MeasureHardwareListItem from './MeasureHardwareListItem';
import TakeoffInfoSummary from '../Summary/TakeoffInfoSummary';

const useStyles = makeStyles((theme) => ({
    list: {
        position: 'relative',
        overflow: 'auto',
        width: '100%',
        paddingBottom:'0px',
    },
    container: {
        marginTop: '8px',
        // add empty space at the bottom of the page
        paddingBottom: '50px',
    },
    item: {
        marginTop: '16px'
    },
    title: {
        marginLeft: '8px'
    },
    "@media (min-width: 1080px)":{
        list:{
            boxShadow: theme.shadows[5],
        },
    },
    flex: {
        display: 'flex',
        height: '80vh',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    flexItem: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const MeasureList = ({ context }) => {
    const classes = useStyles();
    const {cpu}   = context.state;
    const [duplicate, setDuplicate] = useState(0)
    const disabled = context.isDoneTakeoff();
    const handleClose = () => {
        context.setState({
            duplicateMeasureItem: {},
            deleteMeasureItem: {},
        })
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} >
                <TakeoffInfoSummary
                    isPrint
                    showImages
                    label="Order Summary"
                    showPrices={false}
                />
            </Grid>
            {
                context.state.trim
                .filter(trim => trim && 'product' in trim && trim.product && 'code' in trim.product && trim.product.code)
                    .length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <Typography variant="h6" className={classes.title}>Trim</Typography>
                </Grid>
            }
            {
                context.state.trim
                .filter(trim => trim && 'product' in trim && trim.product && 'code' in trim.product && trim.product.code).length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <List className={classes.list}>
                        {
                            context.state.trim
                            .filter(trim => trim && 'product' in trim && trim.product && 'code' in trim.product && trim.product.code)
                            .map((trim, i) => {
                                return (
                                    <MeasureTrimListItem key={i} cpu={cpu} disabled={disabled} trim={trim} dIndex={i} context={context} />
                                )
                            })
                        }
                    </List>
                </Grid>
            }
            {
                context.state.doors.length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <Typography variant="h6" className={classes.title}>Doors</Typography>
                </Grid>
            }
            {
                context.state.doors.length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <List className={classes.list}>
                        {
                            context.state.doors.map((door, i) =>
                                <MeasureDoorListItem key={'measure-list-tem-'+i} cpu={cpu} disabled={disabled} door={door} dIndex={i} context={context} />
                            )
                        }
                    </List>
                </Grid>
            }
            {
                context.state.hardware
                .filter(hardware => hardware && 'product' in hardware && hardware.product && 'code' in hardware.product && hardware.product.code)
                    .length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <Typography variant="h6" className={classes.title}>Hardware</Typography>
                </Grid>
            }
            {
                context.state.hardware
                .filter(hardware => hardware && 'product' in hardware && hardware.product && 'code' in hardware.product && hardware.product.code)
                    .length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <List className={classes.list}>
                        {
                            context.state.hardware
                            .filter(hardware => hardware && 'product' in hardware && hardware.product && 'code' in hardware.product && hardware.product.code)
                            .map((hardware, i) => {
                                return (
                                    <MeasureHardwareListItem key={i} cpu={cpu} disabled={disabled} hardware={hardware} dIndex={i} context={context} />
                                )
                            })
                        }
                    </List>
                </Grid>
            }
            {
                'windows' in context.state &&
                context.state.windows &&
                context.state.windows.length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <Typography variant="h6" className={classes.title}>Openings</Typography>
                </Grid>
            }
            {
                'windows' in context.state &&
                context.state.windows &&
                context.state.windows.length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <List className={classes.list}>
                        {
                            'windows' in context.state &&
                            context.state.windows &&
                            context.state.windows.map((window, i) =>
                                <MeasureListItem key={'measure-list-window-'+i} cpu={cpu} disabled={disabled} object={window} category={window.type} dIndex={i} context={context} />
                            )
                        }
                    </List>
                </Grid>
            }
            {
                'shelving' in context.state && context.state.shelving.length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <Typography variant="h6" className={classes.title}>Shelving</Typography>
                </Grid>
            }
            {
                'shelving' in context.state && context.state.shelving.length > 0 &&
                <Grid item xs={12} className={classes.item}>
                    <List className={classes.list}>
                        {
                            context.state.shelving.map((shelf, i) =>
                                <MeasureShelvingListItem key={'measure-list-shelf-'+i} cpu={cpu} disabled={disabled} shelf={shelf} dIndex={i} context={context} />
                            )
                        }
                    </List>
                </Grid>
            }
            {
                context.state.custom_products?.length > 0 &&
                <>
                    <Grid item xs={12} className={classes.item}>
                        <Typography variant="h6" className={classes.title}>Individual Items</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.item}>
                        <List className={classes.list}>
                            {
                                context.state.custom_products.map((custom_product, i) =>
                                    <MeasureCustomItem type="custom_product" key={'measure-list-custom_products-'+i} cpu={cpu} disabled={disabled} product={custom_product} dIndex={i} context={context} />
                                )
                            }
                        </List>
                    </Grid>
                </>
            }
            {
                context.state.custom_install_services?.length > 0 &&
                <>
                    <Grid item xs={12} className={classes.item}>
                        <Typography variant="h6" className={classes.title}>Individual Installs</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.item}>
                        <List className={classes.list}>
                            {
                                context.state.custom_install_services.map((custom_install_service, i) =>
                                    <MeasureCustomItem type="custom_install_service" key={'measure-list-custom_install_services-'+i} cpu={cpu} disabled={disabled} product={custom_install_service} dIndex={i} context={context} />
                                )
                            }
                        </List>
                    </Grid>
                </>
            }
            {
                context.state.doors.length === 0 &&
                context.state.shelving.length === 0 &&
                context.state.windows.length === 0 &&
                context.state.hardware.length === 0 &&
                Object.values(context.state.trim).every(x => (x === null || x.length === 0)) &&
                <Grid item xs={12}>
                    <div className={classes.flex}>
                        <div className={classes.flexItem}>
                            <img src={EmptyMeasureImage} alt={'No measured items yet!'} style={{width: '100%', height: '100%'}} />
                        </div>
                    </div>
                </Grid>
            }
            <Dialog
                open={Object.keys(context.state.deleteMeasureItem).length > 0}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                {
                    !disabled &&
                    <DialogActions>
                        <Button onClick={() => context.setState({deleteMeasureItem: {}})} color="default">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                if ('category' in context.state.deleteMeasureItem) {
                                    context.removeMeasureItem(
                                        context.state.deleteMeasureItem.category,
                                        context.state.deleteMeasureItem.index
                                    );
                                }
                            }}
                            color="primary" autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                }
            </Dialog>
            <Dialog
                open={Object.keys(context.state.duplicateMeasureItem).length > 0}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Add Copies"}</DialogTitle>
                {
                    'category' in context.state.duplicateMeasureItem &&
                    <DialogContent>
                        <TextField
                            required
                            disabled={disabled}
                            id="standard-number"
                            label="No Of Copies"
                            placeholder="Enter no. of copies"
                            value={duplicate || ''}
                            onChange={(e) => setDuplicate(e.target.value)}
                            type="number"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="dense"
                            variant="outlined"
                            inputProps={{ pattern: "[0-9]*" }}
                            autoFocus
                        />
                    </DialogContent>
                }
                {
                    !disabled &&
                    <DialogActions>
                        <Button onClick={() => context.setState({duplicateMeasureItem: {}})} color="default">
                            Cancel
                        </Button>
                        <Button
                            disabled={isNaN(duplicate) || duplicate < 1}
                            onClick={() => {
                                const count = duplicate;
                                setDuplicate(0)
                                if ('category' in context.state.duplicateMeasureItem) {
                                    context.duplicateMeasureItem(count);
                                }
                            }}
                            color="primary" autoFocus
                        >
                            Copy
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </Grid>
    );
}


export default MeasureList