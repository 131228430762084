import React from 'react';
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    // console.log(props, localStorage.getItem('token'))
    return (
      localStorage.getItem('token') ? 
      <Component {...props} /> : 
      <Redirect to={{
        pathname: '/login',
        state: { 
          referrer: props.location.pathname 
        }
      }} />
    )
  }} />
)

export default PrivateRoute;