import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import NotesAndImages from './NotesAndImages'
import SpecsType from '../Specs/SpecsType';
import * as Lib from '../lib/lib';
import AdditionalItem from './AdditionalItem';
import MeasureUnits from './MeasureUnits'
import InlineEdit from '../../common/InlineEdit'

const attributesFor = (type) => {
    let attributes = [];
    switch (type) {
        case "hinge":
            attributes = ['width', 'height', 'radius', 'finish', 'style', 'brand'];
            break;
        case "lever":
            attributes = ['brand', 'mode', 'style', 'finish', 'rosette', 'direction'];
            break;
        case "bifold_knob":
            attributes = ['brand', 'finish'];
            break;
        case "knob":
            attributes = ['brand', 'mode', 'style', 'finish', 'rosette'];
            break;
        case "deadbolt":
            attributes = ['brand', 'mode', 'finish', 'style', 'rosette', 'firerated'];
            break;
        case "gripset":
        case "closer":
            attributes = ['brand', 'style', 'finish'];
            break;
        case "flushbolt":
            attributes =['length', 'finish', 'style', 'width'];
            break;
        case "catches":
            attributes = ['style', 'finish'];
            break;
        case "doorstopper":
            attributes = ['mode', 'finish'];
            break;
        case "pocket":
            attributes = [ 'rosette', 'finish', 'brand', 'mode' ];
            break;
        case "barn_door_track":
            attributes = ['brand', 'style', 'finish', 'tracklength'];
            break;
        case "bipass_door_track":
            attributes = ['brand', 'style', 'tracklength'];
            break;
        case "finger_pull":
            attributes = ['finish', 'size'];
            break;
        case "handrail_bracket":
            attributes = ['style', 'width', 'finish'];
            break;
        default:
            attributes = [];
            break;
    }
    return attributes;
};

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper:{
        padding:'16px',
    },
    group:{
        flexDirection: 'row',
    },
    chip: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    box: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
    }
}));

const NewHardware = (props) => {
    const { context } = props;
    const inputLabel = useRef(null);
    const [state, setState] = useState({
        type: '',
        hardware: {},
        specialSpecs: {},
        labelWidth: 0,
    })
    const classes = useStyles();
    const types = ['hinge', 'knob', 'bifold_knob', 'lever', 'deadbolt', 'gripset', 'closer', 'doorstopper', 'flushbolt', 'catches', 'pocket', 'barn_door_track', 'bipass_door_track', 'finger_pull', 'handrail_bracket']
    const disabled = context.isDoneTakeoff();
    const hardware = context.state.measures.custom.hardware;
    const additionalItem = hardware.additionalItem
    const additionalItemObj = Object.keys(additionalItem).length === 0 && additionalItem.constructor === Object ? 
                                context.state.additionalOrder.additionalItem : additionalItem

    useEffect(() => {
        if (context.state.editHardwareIndex === -1 ) {
            setState({
                ...state, 
                labelWidth: inputLabel.current.offsetWidth,
            });
        }
    }, []);

    useEffect(() => {
        if (context.state.editHardwareIndex === -1 ) {
            context.copySpecs('specs', 'specialSpecs', 'hardware');
            context.copySpecs('specs', 'specialSpecs', 'products');
        } else {
            setState({
                ...state, 
                type: hardware.type
            })
        }
    }, []);

    const handleChange = (field, value) => {
        let maxIndex = 0;
        if (field === 'type') {
            maxIndex = Math.max(
                ...context.state.hardware
                .filter(item => item && item.type === value)
                .map(o => 'itemIndex' in o ? o.itemIndex : 0)
                , 0);
        }
        context.setState({
            measures: {
                ...context.state.measures,
                custom: {
                    ...context.state.measures.custom,
                    hardware: {
                        ...hardware,
                        [field]: value,
                        ...(field === 'type' ? {itemIndex: maxIndex+1} : {})
                    }
                }
            }
        }, () => {
            if (value) {
                context.copySpecs('specs', 'specialSpecs', 'hardware', value);
            }
            if (Lib.deepAttributeExists(['products', value, 'code'], context.state.specialSpecs)) {
                context.copySpecs('specs', 'specialSpecs', 'products', value);
            }
        })
    }

    const handleDeleteImage = (index) => {
        // Add new function
        context.deleteTypeImage(index, 'hardware')
    }

    const handleSpecChange = type => async ({specs, propagate}) => {
        if (!Lib.isEmptyOrNullObject(specs)) {
            const typeAttributes = {
                category: "Hardware",
                type: type,
                ...specs,
            };
            const typePromise = context.getAnyProduct(typeAttributes);
            typePromise.then(product => {
                if (product) {
                    const typeProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        attributes:     typeAttributes
                    };
                    // console.log(typeProduct);
                    context.saveSpecs({
                        scope: 'specialSpecs',
                        product: typeProduct,
                        category: 'hardware',
                        type,
                        specs,
                        propagate
                    });
                }
            })
        }
    }

    
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>
                        {
                            hardware.type &&
                            `
                                ${Lib.humanize(hardware.type)} 
                            `
                        }
                        <InlineEdit
                            text={ hardware.title || hardware.itemIndex ||  "" }
                            onSetText={ value => handleChange('title', value)}
                        />
                    </Typography>
                </Grid>
                {
                    context.state.isAdditionalOrder &&
                    <AdditionalItem 
                        additionalItem={additionalItemObj}
                        disabled={disabled}
                        onItemChange={(value)=> {
                            handleChange('additionalItem', value)
                        }}
                    />
                }
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            {
                                context.state.editHardwareIndex === -1  &&
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel
                                            htmlFor="trim-type"
                                            required
                                            ref={inputLabel}
                                        >
                                            Type
                                        </InputLabel>
                                        <Select
                                            disabled={disabled}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={state.labelWidth}
                                                    name="type"
                                                    id="trim-type"
                                                />
                                            }
                                            value={hardware.type}
                                            onChange={ event => {
                                                setState({...state, type: event.target.value});
                                                handleChange('type', event.target.value);
                                            }}
                                        >
                                            <MenuItem value={''}></MenuItem>
                                            {
                                                types.map((type, i) => (
                                                    <MenuItem key={i} value={type}>{Lib.humanize(type)}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            {
                                state.type &&
                                <Grid item xs={12}>
                                    <SpecsType
                                        hideCopy
                                        disabled={disabled}
                                        category={'hardware'}
                                        type={state.type}
                                        attributes={attributesFor(state.type)}
                                        handleChange={handleSpecChange(state.type)}
                                        specs={context.state.specialSpecs.hardware[state.type]}
                                        globalSpecs={context.state.specs.hardware[state.type]}
                                    />
                                </Grid>
                            }
                            {
                                state.type &&
                                Lib.deepAttributeExists(['products', state.type, 'code'], context.state.specialSpecs) !== null &&
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled={disabled}
                                        id="new-hardware-length"
                                        label={`Quantity`}
                                        className={classes.textField}
                                        fullWidth
                                        margin={'dense'}
                                        variant={'outlined'}
                                        value={hardware.count || ''}
                                        type={"number"}
                                        onChange={ (event) => {
                                            context.setState({
                                                measures: {
                                                    ...context.state.measures,
                                                    custom: {
                                                        ...context.state.measures.custom,
                                                        hardware:{
                                                            ...hardware,
                                                            count: event.target.value
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <MeasureUnits
                                    disabled={disabled}
                                    value={hardware.unitNumber || ""}
                                    handleChange={event => handleChange('unitNumber', event.target.value)}
                                />
                            </Grid>


                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <NotesAndImages
                        disabled={disabled}
                        category={"hardware"}
                        notes={hardware.notes}
                        images={ hardware.images }
                        handleChange={handleChange}
                        handleDeleteImage={handleDeleteImage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default NewHardware
