import React from "react";
import UserFormContext from './UserFormContext'
import { gql } from '@apollo/client';
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router'
import {User_Fragment} from './common/fragments.graphql'

class UserFormProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formChanged: false,
            userSaved: false,
            userid:  '',
            userEditId: '',
            formSubmitted: false,
            name: '',
            role: 'foreman',
            email: '',
            phone: '',
            warehouse: '',
            confirmedAt: '',
            managers: [],
            team: [],
            username: '',
            password: '',
            showPassword: false,
            password2: '',
            showPassword2: false,
            enabled: true,
            passwordMatch: true,
            validation: {
                email: {},
                username: {},
                password: {}
            },
            confirmDialogOpen: false,
            redirect: '',
            isProfile: props.isProfile ? props.isProfile : false,
            resendWelcome: false
        };
        this.apolloClient = this.props.apolloClient
    }

    componentDidMount () {
        const { userid, isProfile } = this.props
        if (typeof userid !== 'undefined' || isProfile) {
            if (isProfile) {
                const userPromise = this.getUser('', true)
                userPromise.then( (user) => {
                    if (user) {
                        this.setState({userEditId: user.id, isProfile: true})
                        this.populateUser(user)
                    }
                })
            }
            else {
                this.setState({userEditId: this.props.userid})
                const userPromise = this.getUser(userid)
                userPromise.then( (user) => {
                    this.populateUser(user)
                })
            }
        }
    }

    humanize = (str) => {
        if (str === null || str === 'null' || str === undefined) return '';
        var frags = str.split('_');
        for (let i=0; i<frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    };

    populateUser = (user) => {
        // console.log(user)
        this.setState({
            name: user.name,
            email: user.email,
            warehouse: user.warehouse,
            phone: user.phone,
            role: user.role,
            username: user.username,
            enabled: user.enabled,
            confirmedAt: user.confirmedAt,
            ...(user.role === 'foreman' && {
                managers: user.managedBy.map(manager => manager.id),
            }),
            ...(user.role === 'staff' && user.team && {
                team: user.team.map(foreman => foreman.id),
            }),
        })
    }

    getUser = async ( userId, me = false) => {
        if (!me && userId === '') return ;

        if (me) {
            var GET_Me = gql`
                {
                    getMe{
                        ...UserParts
                    }
                }
                ${User_Fragment}
            `;
            const {data} = await this.apolloClient.query({
                query: GET_Me
            });
            if (data.getMe !== null) {
                return data.getMe
            }
        }
        else {
            var GET_USER = gql`
                {
                    User(id:"${userId}") {
                        ...UserParts
                    }
                }
                ${User_Fragment}
            `;
            const {data} = await this.apolloClient.query({
                query: GET_USER,
                fetchPolicy: 'no-cache'
            });
            // console.log('data', data)
            if (data.User !== null) {
                return data.User[0]
            }
        }
        return false
    }

    resendWelcome = async (userId) => {
        const RESEND = gql`
            mutation resendWelcome($id: ID!){
                resendWelcome(id: $id)
            }
        `;
        const {data} = await this.apolloClient.mutate({
            mutation: RESEND,
            variables: {id: userId}
        });
        if (data.resendWelcome !== null ) {
            //
            return data.resendWelcome
        }
        return false
    }

    saveUser = async () => {
        // console.log(this.apolloClient)
        // validate password match
        if (!this.state.passwordMatch && this.state.password) {
            return false
        }
        const ADD_USER = gql`
            mutation newUser($input: NewUser!) {
                newUser(input: $input) {
                    ...UserParts
                }
            }
            ${User_Fragment}
        `;

        const EDIT_USER = gql`
            mutation updateUser($id: String!, $input: NewUser!) {
                updateUser(id: $id, input: $input) {
                    ...UserParts
                }
            }
            ${User_Fragment}
        `;

        const USERS_QUERY = gql`
            query AllUsers{
                allUsers {
                    ...UserParts
                }
            }
            ${User_Fragment}
        `;

        const INACTIVE_USERS_QUERY = gql`
            query InAllUsers{
                allUsers (enabled: false) {
                    ...UserParts
                }
            }
            ${User_Fragment}
        `;

        var GET_USER = gql`
            query User{
                User(id:"${this.state.userEditId}") {
                    ...UserParts
                }
            }
            ${User_Fragment}
        `;
        let variables = {
            id: this.props.userid || this.state.userEditId,
            input: {
                username: this.state.username,
                email: this.state.email,
                name: this.state.name,
                warehouse: this.state.warehouse,
                phone: this.state.phone,
                role: this.state.role,
                enabled: this.state.enabled,
                ...(this.state.role === 'foreman' && {
                    managedBy: this.state.managers,
                }),
                ...(this.state.role === 'staff' && {
                    team: this.state.team,
                }),
            },
        }

        variables['input']['passwordHash'] =  this.state.password ? this.state.password : ''

        const {data} = await this.apolloClient.mutate({
            mutation: typeof this.props.userid !== 'undefined' || this.state.userEditId ? EDIT_USER : ADD_USER,
            refetchQueries: [{ query: USERS_QUERY},{ query: INACTIVE_USERS_QUERY},{ query: GET_USER}],
            variables: variables
        });
        if (data.updateUser !== null || data.newUser !== null) {
            let savedUser = typeof this.props.userid !== 'undefined' || this.state.userEditId ? data.updateUser : data.newUser
            return savedUser
        }
        return false
    }

    saveForm = () => {
        var savePromise = this.saveUser();
        savePromise.then( (user) => {
            if (user) {
                if (window.location.href.indexOf('newUser') > 0) {
                    window.history.pushState("", "", `/updateUser/${user.id}`);
                }
                // console.log(this.state.isProfile ? <Redirect to='/' /> : <Redirect to='/users' />)
                this.setState({
                    username: user.username ,
                    userEditId: user.id,
                    formChanged: false,
                    password: '',
                    showPassword: false,
                    password2: '',
                    showPassword2: false,
                    redirect: this.state.isProfile ? <Redirect to='/' /> : <Redirect to='/users' />
                })
            }
        });
    }
    validateUnique = async (field, value) => {
        const VALIDATE = gql`
            query validateUnique{
                validateUnique(field:"${field}" , value:"${value}")
            }
        `;
        const {data} = await this.apolloClient.query({
            query: VALIDATE,
        });
        if (data.validateUnique !== null) {
            return data.validateUnique
        }
        return false
    }

    validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    deleteUser = async (userId) => {
        const DELETE_USER = gql`
            mutation deleteUser{
                deleteUser(id: "${userId}")
            }
        `;

        const GET_USERS = gql`
            query AllUsers{
                allUsers {
                    ...UserParts
                }
            }
            ${User_Fragment}
        `;

        const {data} = await this.apolloClient.mutate({
            mutation: DELETE_USER,
            refetchQueries: [{ query: GET_USERS}]
        });
        if (data.deleteUser !== null) {
            return data.deleteUser
        }
        return false
    }

    render () {
        return (
            <UserFormContext.Provider value={{
                state: this.state,
                setState: (obj) => {
                    this.setState(obj, () => {
                        if (
                            (this.props.userid || this.state.userEditId ) &&
                            (this.state.password ||
                            this.state.password2 ) &&
                            this.state.password2 !== this.state.password
                        ) {
                            this.setState({passwordMatch: false})
                        }
                        else {
                            this.setState({passwordMatch: true})
                        }
                    })
                    //console.log(obj)
                },
                validateUnique: (field, value) => {
                    // console.log(field, value)
                    if (
                        ( field !== 'email' && this.state[field].length < 5 ) ||
                        ( field === 'email' && this.state[field].length < 5 && !this.validateEmail(this.state[field]))
                    ) {
                        this.setState({
                            validation: {
                                ...this.state.validation,
                                [field]: {
                                    valid: false,
                                    message: 'Invalid '+ field
                                }
                            }
                        })
                    }
                    else {
                        this.validateUnique(field, value).then((response) => {
                            // console.log(response)
                            this.setState({
                                validation: {
                                    ...this.state.validation,
                                    [field]: {
                                        valid: !response,
                                        message: !response ? '' : field+' already exists!'
                                    }
                                }
                            })
                        })
                    }
                },
                saveUser: () => {
                    return this.saveForm()
                },
                humanize: (str) => {
                    return this.humanize(str)
                },
                removeUser: (userId) => {
                    const deletePromise = this.deleteUser(userId);
                    deletePromise.then( (user) => {
                        if (user) {
                            // console.log(`user deleted is ${userId}`)
                        }
                    });
                    this.setState({ confirmDialogOpen: false, redirect: <Redirect to="/users" />})
                },
                resendWelcome: () => {
                    this.setState({resendWelcome: true})
                    if (this.state.userEditId)
                        return this.resendWelcome(this.state.userEditId);
                    else return false;
                }
            }}>
                {this.props.children}
            </UserFormContext.Provider>
        )
    }
}

export default withRouter(UserFormProvider)