import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import NotesAndImages from './NotesAndImages'
import SpecsType from '../Specs/SpecsType';
import * as Lib from '../lib/lib';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {trimAttributes} from '../../common/constants'
import CustomToggleGroup from '../../common/CustomToggleGroup'
import AdditionalItem from './AdditionalItem';
import MeasureUnits from './MeasureUnits'
import InlineEdit from '../../common/InlineEdit'

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper:{
        padding:'16px',
    },
    group:{
        flexDirection: 'row',
    },
    chip: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    box: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
    }
}));

const NewTrim2 = (props) => {
    const {context} = props;
    const inputRef = useRef(null);
    const classes = useStyles();

    const types = ['dap', 'baseboard', 'casing', 'crown_moulding', 'shoe_moulding', 'doorstop', 'jamb', 'chair_rail', 'architrave', 'backband', 'burlap', 'flat_stock', 'cove', 'quarter_round', 'handrail', 'attic_lid', 'attic_hatch', 'attic_foam_tape', 'shim', 'sheet', 'sill', 'panel_mould', 'shiplap'];
    const disabled = context.isDoneTakeoff();
    const trim = context.state.measures.custom.trim;
    // console.log({trim})
    const additionalItem = trim.additionalItem;
    const additionalItemObj = Object.keys(additionalItem).length === 0 && additionalItem.constructor === Object ? 
                                context.state.additionalOrder.additionalItem : additionalItem
    const [state, setState] = useState({
        type: '',
        labelWidth: 0,
    })
    useEffect(() => {
        if (context.state.editTrimIndex === -1 ) {
            setState({
                ...state, 
                labelWidth: inputRef.current.offsetWidth,
            });
        }
    }, []);

    useEffect(() => {
        if (context.state.editTrimIndex === -1 ) {
            context.copySpecs('specs', 'specialSpecs', 'trim');
            context.copySpecs('specs', 'specialSpecs', 'products');
        } else {
            setState({
                ...state, 
                type: trim.type
            })
        }
    }, []);

    const handleChange = (field, value) => {
        let maxIndex = 0;
        if (field === 'type') {
            maxIndex = Math.max(
                ...context.state.trim
                .filter(item => item && item.type === value)
                .map(o => 'itemIndex' in o ? o.itemIndex : 0)
                , 0);
        }
        context.setState({
            measures: {
                ...context.state.measures,
                custom: {
                    ...context.state.measures.custom,
                    trim: {
                        ...trim,
                        [field]: value,
                        ...(field === 'type' ? {itemIndex: maxIndex+1} : {})
                    }
                }
            }
        }, () => {
            if (field === 'type' && value ) {
                context.copySpecs('specs', 'specialSpecs', 'trim', value);
                if (Lib.deepAttributeExists(['products', value, 'code'], context.state.specialSpecs)) {
                    context.copySpecs('specs', 'specialSpecs', 'products', value);
                }
            }
        })
    };

    const handleDeleteImage = (index) => {
        context.deleteTypeImage(index, 'trim')
    };

    const handleSpecChange = type => async ({specs, propagate}) => {
        if (!Lib.isEmptyOrNullObject(specs)) {
            const typeAttributes = {
                category: "Trim",
                type: type === 'sill' ? 'Window Stool' : type,
                ...specs,
            };
            const typePromise = context.getAnyProduct(typeAttributes);
            typePromise.then(product => {
                if (product) {
                    const typeProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        attributes:     typeAttributes
                    };
                    // console.log(typeProduct);
                    context.saveSpecs({
                        scope: 'specialSpecs',
                        product: typeProduct,
                        category: 'trim',
                        type,
                        specs,
                        propagate
                    });
                }
            })
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>
                        {
                            trim.type &&
                            `${Lib.humanize(trim.type)} `
                        }
                        <InlineEdit
                            text={
                                trim.title || 
                                trim.itemIndex || 
                                ""
                            }
                            onSetText={(value) => handleChange('title', value)}
                        />
                    </Typography>
                </Grid>
                {
                    context.state.isAdditionalOrder &&
                    <AdditionalItem 
                        additionalItem={additionalItemObj}
                        disabled={disabled}
                        onItemChange={(value)=> {
                            handleChange('additionalItem', value);
                        }}
                    />
                }
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            
                            {
                                context.state.editTrimIndex === -1 &&
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel
                                            htmlFor="trim-type"
                                            required
                                            ref={inputRef}
                                        >
                                            Type
                                        </InputLabel>
                                        <Select
                                            disabled={disabled}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={state.labelWidth}
                                                    name="type"
                                                    id="trim-type"
                                                />
                                            }
                                            value={trim.type}
                                            onChange={ event => {
                                                setState({...state, type: event.target.value});
                                                handleChange('type', event.target.value);
                                            }}
                                        >
                                            <MenuItem value={''}>{""}</MenuItem>
                                            {
                                                types.sort().map((type, i) => (
                                                    <MenuItem key={i} value={type}>{Lib.humanize(type)}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            {
                                state.type &&
                                <Grid item xs={12}>
                                    <SpecsType
                                        hideCopy
                                        disabled={disabled}
                                        category={'trim'}
                                        type={state.type === 'sill' ? "window_stool" : state.type}
                                        attributes={trimAttributes(state.type)}
                                        handleChange={handleSpecChange(state.type)}
                                        specs={context.state.specialSpecs.trim[state.type]}
                                        globalSpecs={context.state.specs.trim[state.type]}
                                    />
                                </Grid>
                            }
                            {
                                state.type &&
                                Lib.deepAttributeExists(['products', state.type, 'code'], context.state.specialSpecs) !== null &&
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled={disabled}
                                        id="new-trim-length"
                                        label={`Quantity (${
                                            state.type && Lib.deepAttributeExists([state.type, 'uom'], context.state.specialSpecs.products) || ``
                                        })`}
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                        // inputRef={qtyRef}
                                        type={"number"}
                                        value={trim.count || ""}
                                        onChange={ (event) => {
                                            context.setState({
                                                measures: {
                                                    ...context.state.measures,
                                                    custom: {
                                                        ...context.state.measures.custom,
                                                        trim:{
                                                            ...trim,
                                                            count: event.target.value
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                            {
                                // Add sqft textfield if union install is selected
                                context.state.installPricingMode === 'union' &&
                                state.type === 'baseboard' &&
                                Lib.deepAttributeExists(['products', state.type, 'code'], context.state.specialSpecs) !== null &&
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled={disabled}
                                        label={`SQFTG`}
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                        type={"number"}
                                        value={trim.sqft || trim.serviceCount}
                                        onChange={ (event) => {
                                            context.setState({
                                                measures: {
                                                    ...context.state.measures,
                                                    custom: {
                                                        ...context.state.measures.custom,
                                                        trim:{
                                                            ...trim,
                                                            sqft: event.target.value || trim.serviceCount
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                            {
                                ['crown_moulding', 'shoe_moulding', 'quarter_round'].includes(state.type) &&
                                Lib.deepAttributeExists(['products', state.type, 'code'], context.state.specialSpecs) !== null &&
                                <>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    disabled={disabled}
                                                    checked={Lib.deepAttributeExists(['attributes', state.type === 'crown_moulding' ? 'highCeiling' : 'install'], trim) || false}
                                                    onChange={(event) => {
                                                        context.setState({
                                                            measures: {
                                                                ...context.state.measures,
                                                                custom: {
                                                                    ...context.state.measures.custom,
                                                                    trim:{
                                                                        ...trim,
                                                                        attributes: {
                                                                            ...trim.attributes,
                                                                            [state.type === 'crown_moulding' ? 'highCeiling' : 'install']: event.target.checked
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }}
                                                />
                                            }
                                            label={state.type === 'crown_moulding' ? 'High Ceiling' : 'Install'}
                                        />
                                    </Grid>
                                </>
                            }
                            {
                                context.state.installPricingMode === 'union' &&
                                ['shoe_moulding', 'quarter_round'].includes(state.type) &&
                                trim.attributes?.install &&
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled={disabled}
                                        id="install-trim"
                                        label={`Install Quantity`}
                                        className={classes.textField}
                                        variant="outlined"
                                        fullWidth
                                        type={"number"}
                                        value={trim.serviceCount || ""}
                                        onChange={ (event) => {
                                            context.setState({
                                                measures: {
                                                    ...context.state.measures,
                                                    custom: {
                                                        ...context.state.measures.custom,
                                                        trim:{
                                                            ...trim,
                                                            serviceCount: event.target.value
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                            {
                                state.type === 'dap' &&
                                context.state.installPricingMode === 'contract_pricing' &&
                                <Grid item xs={12}>
                                    <CustomToggleGroup
                                        items={{
                                            'Dap Wood to Wood': 'Wood to Wood',
                                            'Dap Wood to Drywall': 'Wood to Drywall',
                                        }}
                                        value={trim.extra_service || ""}
                                        handleChange={(event, value) => context.setState({
                                            measures: {
                                                ...context.state.measures,
                                                custom: {
                                                    ...context.state.measures.custom,
                                                    trim:{
                                                        ...trim,
                                                        extra_service: value
                                                    }
                                                }
                                            }
                                        })}
                                    />
                                </Grid>
                            }
                            {
                                state.type === 'dap' &&
                                context.state.installPricingMode === 'contract_pricing' &&
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        disabled={disabled}
                                        id="new-trim-length"
                                        label={`No Of Service`}
                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        // inputRef={qtyRef}
                                        type={"number"}
                                        value={trim.serviceCount || 0}
                                        onChange={ (event) => {
                                            context.setState({
                                                measures: {
                                                    ...context.state.measures,
                                                    custom: {
                                                        ...context.state.measures.custom,
                                                        trim:{
                                                            ...trim,
                                                            serviceCount: event.target.value
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <MeasureUnits
                                    disabled={disabled}
                                    value={trim.unitNumber || ""}
                                    handleChange={event => handleChange('unitNumber', event.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <NotesAndImages
                        disabled={disabled}
                        category={"trim"}
                        notes={trim.notes}
                        images={ trim.images }
                        handleChange={handleChange}
                        handleDeleteImage={handleDeleteImage}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default NewTrim2
