import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SummaryList from './Summary/SummaryList'
import DoorPrep from './Summary/DoorPrep'
import ShareTakeoff from './Summary/ShareTakeoff'
import Activity from './Summary/Activity'
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth : '100%'
    },
    tabsIndicator: {
        backgroundColor: theme.palette.grey[50],
    },
    hideOnPrint:{
        '@media print' : {
            display: 'none'
    }}
}));

const Measure = (props) => {
    const { context } = props;
    const classes = useStyles();
    const [tab, setTab] = useState('order');
    // const takeoffId = context.state.takeoffEditId;
    // const prep = Lib.deepAttributeExists(['door_treatment', 'prep'], context.state.specs) || '';
    return (
        <div className={classes.root}>
            <AppBar color="secondary" position="static" elevation={0} className={classes.hideOnPrint}>
                <Tabs
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                    centered
                    variant="fullWidth"
                    classes={{indicator: classes.tabsIndicator }}
                >
                    <Tab value="order" label="Order" />
                    <Tab value="prep" label="Prep" />
                    <Tab value="activity" label="Activity" />
                    <Tab value="process" label="Process" />
                </Tabs>
            </AppBar>
            {tab === 'order'    && <SummaryList context={context} />}
            {tab === 'prep'     && <DoorPrep context={context} />}
            {tab === 'activity' && <Activity id={context.state.takeoffEditId || null} />}
            {tab === 'process'  && <ShareTakeoff context={context} />}
        </div>
    )
}

export default Measure