export const defaultPricingTier = "gb1";

export const AdditionalOrderConstants = {
    // siteChange: {
    //     customer:       "Customer",
    //     trimmer:        "Trimmer",
    //     descrepancy:    "Descrepancy"
    // },
    // material: {
    //     damage: "Damage",
    //     theft:  "Theft"
    // },
    riversideError: {
        // app:        "App",
        // damaged:    "Damaged",
        // data_entry: "Data Entry",
        // defective:  "Defective",
        // measure:    "Measure",
        // misc:       "Misc",
        // purchasing: "Purchasing",
        // site_change: "Site Change / Extra",
        // theft:      "Theft",
        // trimmer:    "Trimmer",
        // warehouse:  "Warehouse",

        // New codes
        london_mill:    "London Mill",
        site_damaged:   "Site Damaged",
        data_entry:     "Data Entry",
        defective:      "Defective",
        measure:        "Measure",
        delivery:       "Delivery",
        waterloo_mill:  "Waterloo Mill",
        site_change:    "Site Change / Extra",
        theft:          "Theft",
        trimmer:        "Trimmer",
        warehouse:      "Warehouse",
    }
}

export const installItems = {
  "attic"                 : "SV-ATTIC",
  "column"                : "SV-COLINT",
  "attic_access"          : "SV-ATTIC",
  "archway"               : "SV-ARCH",
  "buildout"              : "SV-BO",
  "interior_column"       : "SV-COLINT",
  "continuous_rail"       : "SV-CONT. RAIL",
  "cold_room_door"        : "SV-CRD",
  "double_bifold_door"    : "SV-DB",
  "double_door"           : "SV-DD",
  "exterior_door"         : "SV-DEXT",
  "pocket_door"           : "SV-PD",
  "barn_door"             : "SV-BARN",
  "single_bifold_door"    : "SV-SB",
  "single_door"           : "SV-SD",
  "slider"                : "SV-SLIDER",
  "shower_stall"          : "SV-SS",
  "stairs"                : "SV-STAIR",
  "wall_capping"          : "SV-WC",
  "window"                : "SV-WINDW",
  "high_window"           : "SV-WINH",
  "arch_window"           : "SV-WINR",
  "continuous_handrail"   : "SV-CONT. RAIL",
  "double_door_475_jamb_no_drill" : "SV-DD475",
  "extra_opening_install" : "SV-EXTRA OPENINGS",
};

export const trimAttributes = (type) => {
    switch (type) {
        case "casing":
        case "architrave":
        case "chair_rail":
        case "handrail":
        case "crown_moulding":
        case "doorstop":
        case "burlap":
        case "backband":
        case "shoe_moulding":
            return ['supplier', 'style', 'species', 'width', 'thickness', 'length'];
        case "sill":
            return ['supplier', 'style', 'species', 'width', 'thickness'];
        case "shiplap":
        case "panel_mould":
        case "baseboard":
            return ['supplier', 'style', 'species', 'width', 'thickness', 'finish', 'length'];
        case "flat_stock":
            return ['supplier', 'style', 'finish', 'species', 'width', 'thickness', 'length'];
        case "jamb":
        case "jamb_84":
        case "jamb_96":
            return ['supplier', 'finish', 'species', 'width', 'thickness', 'height', 'style'];
        case "cove":
            return ['supplier', 'finish', 'species', 'width', 'thickness', 'length'];
        case "quarter_round":
            return ['supplier', 'style', 'species', 'width', 'length'];
        case "sheet":
            return ['species', 'style', 'thickness', 'grade', 'length'];
        case 'attic_lid':
        case 'attic_hatch':
        case 'attic_foam_tape':
        case 'dap':
        case 'shim':
            return ['style'];
        case 'curve':
            return ['style', 'species', 'finish'];
        case 'column':
            return ['supplier', 'style', 'species', 'radius', 'width', 'length'];
        default:
            return [];
    }
};

export const shelvingAttributes = (type, validation = false) => {
    switch (type) {
        case 'wire':
            return ['mode'];
        case 'cleat':
            return validation ? 
                        ['supplier' ,'species', 'width', 'thickness'] : 
                        ['supplier', 'style' ,'finish', 'species', 'width', 'thickness', 'length'];
        case 'organizer':
            return ['color', 'hardware'];
        default:
            return [];
    }
};