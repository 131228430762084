import { gql } from '@apollo/client';
import axios from 'axios';
import { NODE_ENV, REACT_APP_API_HOST } from '../envs';

export const humanize = (str) => {
    if (str === null || str === 'null' || str === undefined) return '';
    var frags = str.split('_');
    for (let i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
};

const dataImport_Fragment = gql`
    fragment DataImportParts on DataImport {
        id,
        filename,
        type,
        total,
        noOfLines
        status,
        notes,
        changed{door, trim, hardware, shelving, customer, sheet, labour uncategorized}
        added{door, trim, hardware, shelving, customer, sheet, labour uncategorized}
        removed{door, trim, hardware, shelving, customer, sheet, labour uncategorized}
        createdBy{username}
        createdAt
    }
`

const dataImportGQL = (id) => {
    if (id === '' || id === null || id === undefined) return ''
    return gql`
        {
            DataImport(id:"${id}") {
                ...DataImportParts
            }
        }
        ${dataImport_Fragment}
    `;
}

const dataImportsGQL = (type='product') => {
    return gql`
        {
            allDataImports(type: "${type}") {
                ...DataImportParts
            }
        }
        ${dataImport_Fragment}
    `;
}

export const getDataImportA = async ( id) => {
    const apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
    let config = {
        headers: {'Authorization': "bearer " + localStorage.getItem('token')},
        timeout: 4000
    };
    let data = {
        id: id
    };
    return axios.post(apiBaseUrl+'check-importer', data, config)
    .then(function (res) {
        if (res) {
            return res.data
        }
    })
    .catch(function (err) {
        if (err.code !== 'ECONNABORTED') {
            // console.log('Error',err.code)
        }
        return false
    });
}
export const getDataImport = async ( id, apolloClient, force = false) => {
    if (!id) return ''
    let GET_DATA_IMPORTS = dataImportGQL(id)
    const {data} = await apolloClient.query({
        query: GET_DATA_IMPORTS,
        fetchPolicy: force ? 'network-only' : 'cache-first'
    });
    if (data.allDataImports !== null) {
        return data.DataImport
    }
    return false
}

export const getDataImports = async ( apolloClient, type, force = false) => {
    let GET_DATA_IMPORTS = dataImportsGQL(type)
    const {data} = await apolloClient.query({
        query: GET_DATA_IMPORTS,
        fetchPolicy: force ? 'no-cache' : 'cache-first'
    });
    if (data.allDataImports !== null) {
        return data.allDataImports
    }
    return false
}