import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import MeasureListItemActions from "./MeasureListItemActions";
import * as Lib from "../lib/lib";
import ProductState from './ProductState';

const useStyles = makeStyles(() => ({
    avoidBreak: {
        '@media print': {
            display: 'block',
            breakInside: 'avoid',
        }
    },
    measureDoorListItem: {
        justifyContent: 'space-between',
        '@media print': {
            breakInside: 'avoid',
        }
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const MeasureDoorListItem = ({ door, dIndex, context, disabled, cpu }) => {
    const classes = useStyles();
    return (
        <div>
            {
                door &&
                <ListItem
                    button
                    className={classes.measureDoorListItem}
                    onClick={() => {
                        context.editMeasureDoor(dIndex)
                    }}
                >
                    <Grid alignItems='center' container className={classes.avoidBreak} spacing={2}>
                        <Grid item xs={10}>
                            <Typography variant="body2" gutterBottom>
                                <ProductState cpu={cpu} code={door.code} />
                                {door.isSpecial && "**"} Door {door.title || door.itemIndex || ""} : {door.code} {door.isDouble ? "(Double)" : ""}
                                {door.handleDirection === "left" ? `(LS)` : door.handleDirection === "right" ? "(RS)" : ""}
                                {door.hasCutdown ? ` (${door.cutdownHeight}" Cutdown)` : ''}
                                {
                                    context.state.isAdditionalOrder && 
                                    ` (${
                                        [
                                            door.additionalItem.labour,
                                            door.additionalItem.bill,
                                            door.additionalItem.po_required
                                        ]
                                        .filter (x => x)
                                        .map(x => x && Lib.humanize(x)).join(', ')
                                    })`
                                }
                                {door.unitNumber ? ` (Unit ${door.unitNumber})` : ""}
                            </Typography>
                            <Typography variant="caption" gutterBottom>{door.description}</Typography>
                            {
                                Object.keys(door.hardware)
                                .filter( type => type !== 'double' && door.hardware[type])
                                .map( key => (
                                    key &&
                                    'code' in door.hardware[key] &&
                                    door.hardware[key].code &&
                                    <Typography key={'hardware-measure-list'+key} variant="body2" gutterBottom>
                                        <ProductState cpu={cpu} code={door.hardware[key].code} />
                                        {door.hardware[key].isSpecial ? '**' : ''}{`${context.humanize(key)} ${ door.hardware[key].count} EA x ${ door.hardware[key].code}`}
                                    </Typography>
                                ))
                            }
                            {
                                'double' in door.hardware &&
                                Object.keys(door.hardware.double)
                                .filter(side => door.hardware.double[side])
                                .map(side => (
                                    Object.keys(door.hardware.double[side])
                                    .map( (type, i) => (
                                        (
                                            door.hardware.double[side][type] &&
                                            'code' in door.hardware.double[side][type] &&
                                            door.hardware.double[side][type].code) ?
                                            <Typography key={'hardware-measure-list'+i} variant="body2" gutterBottom>
                                                <ProductState cpu={cpu} code={door.hardware.double[side][type].code} />
                                                {door.hardware.double[side][type].isSpecial ? '**' : ''}{`${context.humanize(type)} ${door.hardware.double[side][type].count} EA x ${door.hardware.double[side][type].code}`}
                                            </Typography> :
                                            Array.isArray(door.hardware.double[side][type]) &&
                                            door.hardware.double[side][type].map( (item, i) => (
                                                <Typography key={'hardware-measure-list'+i} variant="body2" gutterBottom>
                                                    <ProductState cpu={cpu} code={item.code} />
                                                    {item.isSpecial ? '**' : ''}{`${context.humanize(type)} ${item.count} EA x ${ item.code }`}
                                                </Typography>
                                            ))
                                    ))
                                ))
                            }
                            {
                                door.materialType === 'sheet' &&
                                door.sheet?.code &&
                                <Typography variant="body2" gutterBottom>
                                    Sheet { door.sheet.code }
                                </Typography>
                            }
                            {
                                Object.keys(door.trim)
                                .filter( type => door.trim[type] && 'code' in door.trim[type] && door.trim[type].code)
                                .map( key => (
                                    key &&
                                    'code' in door.trim[key] &&
                                    door.trim[key].code &&
                                    <Typography key={'hardware-trim-list'+key} variant="body2" gutterBottom>
                                        <ProductState cpu={cpu} code={door.trim[key].code} />
                                        {door.trim[key].isSpecial ? '**' : ''}{`${context.humanize(key)} ${Lib.ceilToN(door.trim[key].count, 0.5)} ${Lib.uoms(door.trim[key].uom)}  x ${ door.trim[key].code}`}
                                    </Typography>
                                ))
                            }
                            {
                                ['u-channel', 'machined'].includes(context.state.specs.door_treatment.prep) &&
                                door.prehang_service?.length > 0 &&
                                //Lib.deepAttributeExists(['prehang_service', 'code'], door) &&
                                <Typography variant="body2" gutterBottom>
                                    Prehang {door.prehang_service.map(s => `${s.isSpecial ? '**' : ''}${s.code}`).join(', ')}
                                </Typography>
                            }
                            {
                                context.state.installPricingMode !== 'none' &&
                                'install_service' in door &&
                                door.install_service?.length > 0 &&
                                <Typography variant="body2" gutterBottom>
                                    Install {door.install_service.filter(s => s && 'code' in s && s.code).map(s => `${s.isSpecial ? '**' : ''}${s.code}`).join(', ')}
                                </Typography>
                            }
                            {
                                context.state.installPricingMode !== 'none' &&
                                door.hasExtraService &&
                                <Typography variant="body2" gutterBottom>
                                    {door.extraService || 0} Extra install service(s)
                                </Typography>
                            }
                            {
                                door.images?.length > 0 &&
                                <Typography variant={'caption'}>{door.images.length} IMAGE(S) ATTACHED</Typography>
                            }
                            {
                                door.notes &&
                                <Typography className={classes.ellipsis} variant="caption" gutterBottom>
                                    NOTES | {door.notes}
                                </Typography>
                            }
                        </Grid>
                        {
                            !disabled &&
                            <Grid item xs={2}>
                                <MeasureListItemActions context={context} category={'doors'} index={dIndex} />
                            </Grid>
                        }
                    </Grid>
                </ListItem>
            }
            <Divider />
        </div>
    )
}

export default MeasureDoorListItem