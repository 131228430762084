import {useContext} from 'react';
import * as Lib from '../lib/lib';
import useTakeoffParser from '../lib/useTakeoffParser';
import {TakeoffFormContext} from '../../TakeoffFormContext';

const useDoorPrep = () => {
    const context = useContext(TakeoffFormContext);
    let hingeDesc       = {}, door_prep = {}, shelves = {}, organizers = [], jamb_prep = {}, loose = {}, ndLoose = {},uoms = {}, doorNotes = {};
    const curves        = {}, buildouts = {}, sills = {Commodity: {}, Mill: {}}, caps = {}, archways = {}, architrave = {}, jambTypes = {};
    const {doors, shelving, windows}         = context.state;
    const [state]       = useTakeoffParser();
    const all_jambs     = Lib.deepAttributeExists(['trim', 'jamb'], state);
    const descriptions  = {};
    const {
        has =           {},
        opening_count = 0,
        wire_install =  {},
        extra =         {},
        opening =       {},
        notes =         [],
    } = state;
    const backset       = Lib.deepAttributeExists(['door_treatment', 'backset'], context.state.specs) || '';

    // Opening
    windows
    .filter( opening => ['arch_window', 'window', 'archway', 'caps'].includes(opening.type))
    .forEach(item => {
        if (Lib.deepAttributeExists(['trim', 'curve', 'code'], item)) {
            const code                              = item.trim.curve.code;
            descriptions[code]                      = item.trim.curve.description;
            curves[code]                            = curves[code] || {};
            curves[code][item.width]                = curves[code][item.width] || {};
            curves[code][item.width][item.height]   = (curves[code][item.width][item.height] || 0) + 1;
        }
        if (Lib.deepAttributeExists(['trim', 'sill', 'code'], item)) {
            const code                                   = item.trim.sill.code;
            descriptions[code]                           = item.trim.sill.description;
            const supplier                               = 'Commodity';//Lib.deepAttributeExists(['attributes', 'supplier'], item.trim.sill) || 'Commodity';
            // console.log(supplier);
            sills[supplier][code]                                  =  sills[supplier][code] || {};
            sills[supplier][code][item.sillDepth]                  =  sills[supplier][code][item.sillDepth] || {};
            sills[supplier][code][item.sillDepth][item.sillLength] = (sills[supplier][code][item.sillDepth][item.sillLength] || 0) + 1;
        }
        if (item.type === 'caps' && item.materialType && Lib.deepAttributeExists(['trim', item.materialType, 'code'], item)) {
            const code                          = item.trim[item.materialType].code;
            descriptions[code]                  = item.trim[item.materialType].description;
            caps[code]                          = caps[code] || {};
            caps[code][item.width]              = caps[code][item.width] || {};
            caps[code][item.width][item.height] = (caps[code][item.width][item.height] || 0) + 1;
        }
        if (item.type === 'archway' && Lib.deepAttributeExists(['trim', item.materialType, 'code'], item)) {
            const code                                                  = item.trim[item.materialType].code;
            descriptions[code]                                          = item.trim[item.materialType].description;
            const depth                                                 = /jamb/.test(item.type) ? Lib.deepAttributeExists([item.materialType, 'attributes', 'width'], item.trim) : item.openingDepth
            archways[code]                                              = archways[code] || {};
            archways[code]["-"]                                  = archways[code]["-"] || {};
            archways[code]["-"][item.height]                     = archways[code]["-"][item.height] || {};
            archways[code]["-"][item.height][depth]              = (archways[code]["-"][item.height][depth] || 0) + 2;
        }
        if (item.type === 'archway' && ["flat_stock", "sheet"].includes(item.headerMaterial) && Lib.deepAttributeExists(['trim', 'archway_header', 'code'], item)) {
            const code                                                  = item.trim.archway_header.code;
            descriptions[code]                                          = item.trim.archway_header.description;
            const depth                                                 = /jamb/.test(item.type) ? Lib.deepAttributeExists([item.materialType, 'attributes', 'width'], item.trim) : item.openingDepth
            archways[code]                                              = archways[code] || {};
            archways[code][item.width]                                  = archways[code][item.width] || {};
            archways[code][item.width]["-"]                             = archways[code][item.width]["-"] || {};
            archways[code][item.width]["-"][depth]                      = (archways[code][item.width]["-"][depth] || 0) + 1;
        }
        if (item.type === 'window' && Lib.deepAttributeExists(['trim', item.materialType, 'code'], item)) {
            const code                                      = item.trim[item.materialType].code;
            descriptions[code]                              = item.trim[item.materialType].description;
            const mnLength                                  = Lib.deepAttributeExists(['attributes', 'length'], item.trim[item.materialType]);
            const mLength                                   = mnLength && mnLength !== 'n/a' ? mnLength : 8;
            const lengthInFT                                = item.materialType === 'sheet' ? Math.floor(mLength / 12) : mLength;
            const cLength                                   = item.trim.casing.count;
            const quantity                                  = Math.ceil((cLength / lengthInFT));
            buildouts[code]                                 =  buildouts[code] || {};
            buildouts[code][item.openingDepth]              =  buildouts[code][item.openingDepth] || {};
            buildouts[code][item.openingDepth][lengthInFT]  = (buildouts[code][item.openingDepth][lengthInFT] || 0) + quantity;
        }
        if (item.type === 'window' && Lib.deepAttributeExists(['trim', 'architrave', 'code'], item)) {
            const code                                 = item.trim.architrave.code;
            descriptions[code]                         = item.trim.architrave.description;
            architrave[code]                           = architrave[code] || {};
            architrave[code][item.architraveWidth]     = architrave[code][item.architraveWidth] || {};
            architrave[code][item.architraveWidth][0]  = (architrave[code][item.architraveWidth][0] || 0) + 1;
        }
    });
    const onsite    = {doors: {}, buildouts, sills: sills['Commodity'], archways, caps, architrave};
    const mill_prep = {curves,  sills: sills['Mill']};
    // console.log(onsite, mill_prep)
    // Shelving
    shelving
    .filter( shelf => ['wire', 'organizer'].includes(shelf.type))
    .forEach( shelf => {
        if (shelf.type === 'wire') {
            descriptions[shelf.product.code]                    = shelf.product.description;
            shelves[shelf.product.code]                         = shelves[shelf.product.code] || {};
            shelves[shelf.product.code][shelf.width]            = shelves[shelf.product.code][shelf.width] || {};
            shelves[shelf.product.code][shelf.width]['count']   = Number(shelves[shelf.product.code][shelf.width]['count']|| 0)  + Number(shelf.quantity) ;
            if (shelf.notes) {
                shelves[shelf.product.code][shelf.width]['notes'] = shelves[shelf.product.code][shelf.width]['notes'] ? 
                    {...shelves[shelf.product.code][shelf.width]['notes'], [shelf.itemIndex]: shelf.notes} : {[shelf.itemIndex]: shelf.notes}
            }
        } else {
            organizers.push({
                layout: shelf.layout,
                dimensions: shelf.dimensions,
                wallDesigns: shelf.wallDesigns,
                notes: shelf.notes,
            });
        }
    });
    // Doors
    if (['u-channel', 'machined'].includes(Lib.deepAttributeExists(['door_treatment', 'prep'], context.state.specs))) {
        doors
        .filter ( door => door && Lib.deepAttributeExists(['attributes', 'type'], door) !== 'Utility' )
        .forEach( (door, v) => {
            // Door prep
            const {itemIndex, title, code, handleType, doorFunction, isDouble, handleMode, latching, operating, hasCutdown, hasCustomHinge, hasCustomLockset, customLockset, customHinges, handleDirection, hardware, trim, cutdownHeight, width, description} = door;
            const prehangIndex      = door.prehang_service && door.prehang_service.findIndex(x => Lib.deepAttributeExists(['attributes', 'type'], x) === 'Pre-Hang');
            const prehang           = (![-1, null, undefined].includes(prehangIndex) && door.prehang_service[prehangIndex] && door.prehang_service[prehangIndex]['code']) || 'No Pre-hang';
            const hingesPerDoors    = Lib.deepAttributeExists(['hinge', 'count'], hardware) || 0;
            const hingeRadius       = (Lib.deepAttributeExists(['hinge', 'code'], hardware) || 'No Hinge');
            hingeDesc[hingeRadius]  = Lib.deepAttributeExists(['hinge', 'description'], hardware) || '';
            const doorsPerItem      = door.isDouble ? 2 : 1;
            const hingesPerDoor     = hingesPerDoors / doorsPerItem;
            const codeArr           = code.split(' ');
            const style             = codeArr[0];
            const material          = Lib.isNum(door.materialType) || /jamb/.test(door.materialType) ? 'jamb' : door.materialType;
            const jamb_width        = Lib.deepAttributeExists([material, 'attributes', 'width'], trim);
            const jamb_depth        = door.openingDepth;
            const jamb_code         = Lib.deepAttributeExists([material, 'code'], trim) || 'No Jamb';
            const jamb_desc         = Lib.deepAttributeExists([material, 'description'], trim) || 'No Jamb';
            const door_height       = (Lib.deepAttributeExists(['attributes', 'height'], door) || "");
            // const direction         = (doorFunction === 'swing' ? isDouble ? 'DD' : handleDirection === 'left' ? 'LH' : 'RH' : "N/A") ; // newer

            // const direction         = (doorFunction === 'swing' && handleMode !== 'Dummy' ? isDouble ? 'DD' : handleDirection === 'left' ? 'LH' : 'RH' : "N/A");
            descriptions[`${style} ${width}X${door_height}`]      = description;
            if (door.notes) {
                doorNotes['dn_'+v] = door.notes;
            }

            if (isDouble) {
                ['LH', 'RH'].forEach( dir => {
                    const direction = (doorFunction === 'swing' ? dir : 'N/A') + (door.notes ? `__${v}` : "");
                    // const direction   = (doorFunction === 'swing' ? handleDirection === 'left' ? 'LH' : 'RH' : "N/A") + (door.notes ? `__${v}` : "");
                    // console.log(door[direction === 'LH' ? 'leftHandleMode' : 'rightHandleMode'])
                    let drill     = doorFunction === 'swing' ? handleType && !['Dummy', '1', '2'].includes(door[direction === 'LH' ? 'leftHandleMode' : 'rightHandleMode']) ? 'DRILL' : 'NO DRILL' : 'SLAB';
                    // pocket
                    if (
                        doorFunction === 'pocket' &&
                        Lib.deepAttributeExists(['pocket', 'attributes', 'style'], door.hardware) === 'Round'
                    ) {
                        drill = 'DRILL'
                    }
                    door_prep[hingeRadius]                                                                          = door_prep[hingeRadius] || {};
                    door_prep[hingeRadius][prehang]                                                                 = door_prep[hingeRadius][prehang] || {};
                    door_prep[hingeRadius][prehang][door_height]                                                    = door_prep[hingeRadius][prehang][door_height] || {};
                    door_prep[hingeRadius][prehang][door_height][width]                                             = door_prep[hingeRadius][prehang][door_height][width] || {};
                    door_prep[hingeRadius][prehang][door_height][width][jamb_code]                                  = door_prep[hingeRadius][prehang][door_height][width][jamb_code] || {};
                    door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth]                      = door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth] || {};
                    door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style]               = door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style] || {};
                    door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]    = door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction] || {};
                    // const oldCount = door_prep[style][height_width][direction][drill] ? door_prep[style][height_width][direction][drill]['count'] :  0;
                    const oldCount = Lib.deepAttributeExists([drill, hingesPerDoor, 'count'], door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]) || 0 ;
                    // console.log(`${hingeRadius}__${prehang}__${door_height}__${width}__${jamb_code}__${jamb_depth}__${style}__${direction}__${drill}__${hingesPerDoor}`);
                    // console.log(door_height, operating, direction)
                    door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction][drill] = {
                        ...door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction][drill],
                        [hingesPerDoor]: {
                            ...(Lib.deepAttributeExists([drill, hingesPerDoor], door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]) || {}),
                            count: oldCount + 1,
                            ...(
                                (
                                    (
                                        latching === 'flushbolt' &&
                                        (
                                            (operating === 'right' && direction.includes('LH')) ||
                                            (operating === 'left' && direction.includes('RH'))
                                        )
                                    ) ||
                                    (
                                        latching === 'catches' &&
                                        Lib.deepAttributeExists(['catches', 'attributes', 'style'], hardware) === 'Ball'
                                    ) ||
                                    hasCutdown ||
                                    hasCustomHinge ||
                                    hasCustomLockset
                                ) &&
                                {
                                    custom: [
                                        ...(Lib.deepAttributeExists([drill, hingesPerDoor, 'custom'], door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]) || []),
                                        {
                                            ref: title || itemIndex,
                                            ...(hasCutdown && {"Cut Height": `${cutdownHeight}"`}),
                                            ...(hasCustomLockset && {Lock: `${customLockset}"`}),
                                            ...( hasCustomHinge && {
                                                ...(
                                                    customHinges &&
                                                    customHinges.length > 0 &&
                                                    customHinges.reduce(function(map, val, i) {
                                                        map[`Hng${i+1}`] = `${val}"`;
                                                        return map;
                                                    }, {})
                                                )
                                            }),
                                            ...(
                                                (
                                                    (
                                                        latching === 'flushbolt' &&
                                                        (
                                                            (operating === 'right' && direction.includes('LH')) ||
                                                            (operating === 'left' && direction.includes('RH'))
                                                        )
                                                    ) ||
                                                    (
                                                        latching === 'catches' &&
                                                        Lib.deepAttributeExists(['catches', 'attributes', 'style'], hardware) === 'Ball'
                                                    )
                                                ) &&
                                                {Prep: latching === 'catches' ? "Ball Catch" : "Flushbolt"}
                                            ),
                                        }
                                    ]
                                }
                            )
                        }
                    }
                })
            } else { // Single door
                const direction   = (doorFunction === 'swing' ? handleDirection === 'left' ? 'LH' : 'RH' : "N/A") + (door.notes ? `__${v}` : "");
                let drill         = doorFunction === 'swing' ? handleType && !['Dummy'].includes(handleMode) ? 'DRILL' : 'NO DRILL' : 'SLAB';
                if (
                    doorFunction === 'pocket' &&
                    Lib.deepAttributeExists(['pocket', 'attributes', 'style'], door.hardware) === 'Round'
                ) {
                    drill = 'DRILL'
                }
                door_prep[hingeRadius]                                                                        = door_prep[hingeRadius] || {};
                door_prep[hingeRadius][prehang]                                                               = door_prep[hingeRadius][prehang] || {};
                door_prep[hingeRadius][prehang][door_height]                                                  = door_prep[hingeRadius][prehang][door_height] || {};
                door_prep[hingeRadius][prehang][door_height][width]                                           = door_prep[hingeRadius][prehang][door_height][width] || {};
                door_prep[hingeRadius][prehang][door_height][width][jamb_code]                                = door_prep[hingeRadius][prehang][door_height][width][jamb_code] || {};
                door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth]                    = door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth] || {};
                door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style]             = door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style] || {};
                door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]  = door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction] || {};
                // const oldCount = door_prep[style][height_width][direction][drill] ? door_prep[style][height_width][direction][drill]['count'] :  0;
                const oldCount = Lib.deepAttributeExists([drill, hingesPerDoor, 'count'], door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]) || 0 ;
                // console.log(`${hingeRadius}__${prehang}__${door_height}__${width}__${jamb_code}__${jamb_depth}__${style}__${direction}__${drill}__${hingesPerDoor}`);
                door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction][drill]     = {
                    ...door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction][drill],
                    [hingesPerDoor]: {
                        ...(Lib.deepAttributeExists([drill, hingesPerDoor], door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]) || {}),
                        count: oldCount + 1,
                        ...(
                            (
                                hasCutdown  ||
                                hasCustomHinge ||
                                hasCustomLockset
                            ) &&
                            {
                                custom: [
                                    ...(Lib.deepAttributeExists([drill, hingesPerDoor, 'custom'], door_prep[hingeRadius][prehang][door_height][width][jamb_code][jamb_depth][style][direction]) || []),
                                    {
                                        ref: title || itemIndex,
                                        ...(hasCutdown && {"Cut Height": `${cutdownHeight}"`}),
                                        ...(hasCustomLockset && {Lock: `${customLockset}"`}),
                                        ...( hasCustomHinge && {
                                            ...(
                                                customHinges &&
                                                customHinges.length > 0 &&
                                                customHinges.reduce(function(map, val, i) {
                                                    map[`Hng${i+1}`] = `${val}"`;
                                                    return map;
                                                }, {})
                                            )
                                        }),

                                    }
                                ]
                            }
                        )
                    }
                }
            }
            // Jamb prep
            if (material) {
                const direction         = (doorFunction === 'swing' ? isDouble ? 'DD' : handleDirection === 'left' ? 'LH' : 'RH' : "N/A");
                descriptions[jamb_code] = jamb_desc;
                const jamb_type = material === 'jamb' ? 'jamb' : 'custom';
                jambTypes[jamb_type] = true;
                uoms[jamb_code] = Lib.deepAttributeExists([material, 'uom'], trim) || 'PC';
                const hinge_direction  = direction !== 'N/A' && hingeRadius !== "No Hinge" ? direction+'-H' : 'N/A';
                const strike_direction = direction !== 'N/A' && !["Dummy", ""].includes(handleMode) ? direction+'-S' : 'N/A';
                
                const widthOrDepth   = material === 'jamb' ? jamb_width : door.openingDepth;
                const heightOrLength = material === 'jamb' ? door_height : door_height;
                const dim = material === 'jamb' ? `${heightOrLength}_${widthOrDepth}` : `${door.isDouble ? door.width * 2 : door.width}_${door.openingDepth}`;

                if ( isDouble && hinge_direction !== 'N/A') {
                    jamb_prep[jamb_type]                                = jamb_prep[jamb_type] || {};
                    jamb_prep[jamb_type][heightOrLength]                = jamb_prep[jamb_type][heightOrLength] || {};
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]  = jamb_prep[jamb_type][heightOrLength][widthOrDepth] || {};
                    const jambCount        = door.isHalfTrim ? 0.5 : 1;
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]['LH-H']                           = jamb_prep[jamb_type][heightOrLength][widthOrDepth]['LH-H'] || {};
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]['LH-H'][hingesPerDoor]            = jamb_prep[jamb_type][heightOrLength][widthOrDepth]['LH-H'][hingesPerDoor] || {};
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]['LH-H'][hingesPerDoor][jamb_code] = (Lib.deepAttributeExists([jamb_type, heightOrLength, widthOrDepth, 'LH-H', hingesPerDoor, jamb_code], jamb_prep) + jambCount) || jambCount;
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]['RH-H']                           = jamb_prep[jamb_type][heightOrLength][widthOrDepth]['RH-H'] || {};
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]['RH-H'][hingesPerDoor]            = jamb_prep[jamb_type][heightOrLength][widthOrDepth]['RH-H'][hingesPerDoor] || {};
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]['RH-H'][hingesPerDoor][jamb_code] = (Lib.deepAttributeExists([jamb_type, heightOrLength, widthOrDepth, 'RH-H', hingesPerDoor, jamb_code], jamb_prep) + jambCount) || jambCount;

                    loose[jamb_type]                              = loose[jamb_type] || {};
                    loose[jamb_type][jamb_code]                   = loose[jamb_type][jamb_code] || {};
                    loose[jamb_type][jamb_code][dim]              = loose[jamb_type][jamb_code][dim] || {}
                    loose[jamb_type][jamb_code][dim]['quantity']  = (loose[jamb_type][jamb_code][dim]['quantity'] + jambCount) || jambCount
                    loose[jamb_type][jamb_code][dim]['count']     = (loose[jamb_type][jamb_code][dim]['count'] || 0) + 1
                } else if (hinge_direction !== 'N/A' || strike_direction !== 'N/A'){
                    const jambCount        = door.isHalfTrim ? 0.5 : 1;
                    let looseCount = 0.5

                    jamb_prep[jamb_type]                                = jamb_prep[jamb_type] || {};
                    jamb_prep[jamb_type][heightOrLength]                = jamb_prep[jamb_type][heightOrLength] || {};
                    jamb_prep[jamb_type][heightOrLength][widthOrDepth]  = jamb_prep[jamb_type][heightOrLength][widthOrDepth] || {};
                    if (hinge_direction !== 'N/A') {
                        jamb_prep[jamb_type][heightOrLength][widthOrDepth][hinge_direction]                  = jamb_prep[jamb_type][heightOrLength][widthOrDepth][hinge_direction] || {};
                        jamb_prep[jamb_type][heightOrLength][widthOrDepth][hinge_direction][hingesPerDoor]   = {
                            ...jamb_prep[jamb_type][heightOrLength][widthOrDepth][hinge_direction][hingesPerDoor],
                            [jamb_code]: (Lib.deepAttributeExists([jamb_type, heightOrLength, widthOrDepth, hinge_direction, hingesPerDoor, jamb_code], jamb_prep) + jambCount) || jambCount
                        };
                    } else looseCount += 1;
                    if (strike_direction !== 'N/A') {
                        jamb_prep[jamb_type][heightOrLength][widthOrDepth][strike_direction] = {
                            ...jamb_prep[jamb_type][heightOrLength][widthOrDepth][strike_direction],
                            [jamb_code]: (Lib.deepAttributeExists([jamb_type, heightOrLength, widthOrDepth, strike_direction, jamb_code], jamb_prep) + jambCount) || jambCount
                        };
                    } else looseCount += 1;
                    
                    loose[jamb_type]                       = loose[jamb_type] || {};
                    loose[jamb_type][jamb_code]            = loose[jamb_type][jamb_code] || {};
                    loose[jamb_type][jamb_code][dim]       = loose[jamb_type][jamb_code][dim] || {}
                    loose[jamb_type][jamb_code][dim]['quantity']  = (loose[jamb_type][jamb_code][dim]['quantity'] + looseCount) || looseCount
                    loose[jamb_type][jamb_code][dim]['count']  = (loose[jamb_type][jamb_code][dim]['count'] || 0) +1
                } else {
                    const count = isDouble && !door.isHalfTrim ?
                                    3 : isDouble && door.isHalfTrim ?
                                        1.5 : !isDouble && !door.isHalfTrim ?
                                            2.5 : 1.5
                    loose[jamb_type]                       = loose[jamb_type] || {};
                    loose[jamb_type][jamb_code]            = loose[jamb_type][jamb_code] || {};
                    loose[jamb_type][jamb_code][dim]       = loose[jamb_type][jamb_code][dim] || {}
                    loose[jamb_type][jamb_code][dim]['quantity']  = (loose[jamb_type][jamb_code][dim]['quantity'] + count) || count
                    loose[jamb_type][jamb_code][dim]['count']  = (loose[jamb_type][jamb_code][dim]['count'] || 0) +1
                }
            }
        })
    }
    if (all_jambs) {
        Object.keys(all_jambs).forEach(code => {
            uoms[code]  = all_jambs[code]['uom'];
            const count = all_jambs[code]['uom'] === 'LF' ? Math.ceil(all_jambs[code]['count']) : all_jambs[code]['count'];
            ndLoose[code]  = (ndLoose[code] + count) || count;
        })
    }

    return {
        doorNotes,
        hingeDesc,
        uoms,
        ndLoose,
        loose,
        descriptions,
        jambTypes, 
        jamb_prep,
        backset,
        door_prep,
        shelves,
        organizers,
        mill_prep,
        opening_count,
        has,
        extra,
        opening,
        wire_install,
        onsite,
        notes
    };
}

export default useDoorPrep;