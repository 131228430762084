import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ProductImages from './ProductImages'

const useStyles = makeStyles(() => ({
    paper:{
        padding:'16px',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
}));

const NotesAndImages = (props) => {
    const classes = useStyles();
    const {images, notes, category, handleDeleteImage, disabled} = props
    const handleChange = (field) => value => {
        props.handleChange(field, Array.isArray(value) ?  value : value.target.value)
    };

    return (
        <Paper className={classes.paper} elevation={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>Notes & Images</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        id="new-door-notes"
                        label="Notes"
                        multiline
                        rows="4"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={ notes }
                        onChange={handleChange('notes')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProductImages
                        disabled={disabled}
                        category={category}
                        images={images}
                        handleChange={handleChange('images')}
                        handleDeleteImage={handleDeleteImage}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default NotesAndImages
