import React, {useState, useEffect, useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/RemoveCircleOutline';
import * as Lib from "./lib/lib";
import * as AttributeLib from "./lib/attributes.lib";
import { useQuery } from "@apollo/client";
import CustomToggleGroup from '../common/CustomToggleGroup';
import {usePrevious} from '../common/hooks';
import isEqual from 'lodash.isequal';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    field: {
        flex: 1,
        margin: '16px 0px 0px 0px'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        marginBottom: 0
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    actionButton: {
        minWidth: '64px'
    }
}));

const AttributeSelect = (props) => {
    const classes = useStyles();
    const randomNum = Math.floor(Math.random() * 100) + 1;
    const { title, field, refSpec, handleChange, value, hideDelete = false, selectionControl = "select",  disabled, allowedValues= [], extraFields = []} = props;
    
    const label = field === 'supplier' ? 'Source' : field === 'species' ? 'Material' : title || field;
    const { data, loading } = useQuery(AttributeLib.attributeValuesQuery(extraFields), {
        variables: {
            field,
            condition: AttributeLib.attributeValuesQueryOptions(props),
            allowedValues,
            extraFields
        }
    });
    
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    const [options, setOptions] = useState([]);
    const prevValuesRef = usePrevious(options);

    useEffect(() => {
        if(selectionControl === "select"){
            setLabelWidth(inputLabel.current.offsetWidth || 130);
        }
    }, [selectionControl]);

    useEffect(() => {
        // console.log(field, data?.productGroups)
        setOptions(data?.productGroups || []) // setOptions(data.productGroups && data.productGroups.concat({_id: 'n/a'} || []))
    }, [data?.productGroups])
    
    useEffect(() => {
        if (
            options &&
            options.length === 1 &&
            !isEqual(options, prevValuesRef) &&
            (
                !(field in refSpec) ||
                !refSpec[field] ||
                refSpec[field] === ''
            ) &&
            !value
        ) {
            handleChange(null, options[0]._id)
        }
    }, [prevValuesRef, options, value, field, refSpec, handleChange]);

    const selectedValue = value ?
                            value : field in refSpec && refSpec[field] ?
                                refSpec[field] : '';
    const items = {}
    if (options && selectionControl === "radio") {
        options.forEach( option => {
            if (option._id && option._id.match(/^ *$/) === null) {
                items[option._id] = (option._id !== null) ? option._id.charAt(0).toUpperCase() + option._id.slice(1) : ''
            }
        })
    }
    // console.log(field, selectedValue, options, data)
    // console.log(field, disabled, !(field in refSpec), !refSpec[field], refSpec[field] === '')
    return (
        <div className={classes.root}>
            <div className={selectionControl !== "radio" ? classes.field : ''}>
                {
                    selectionControl === "select" &&
                    <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <InputLabel
                            htmlFor={`outlined-${field}-${randomNum}`}
                            ref={inputLabel}
                        >
                            {Lib.humanize( label )}
                        </InputLabel>
                        <Select
                            disabled={disabled || !options || !data || !( 'productGroups' in data) || options.length < 1}
                            value={options.length > 0 ? selectedValue : ''}
                            onChange={handleChange}
                            displayEmpty
                            native={false}
                            inputProps={{
                                margin: 'dense'
                            }}
                            input={
                                <OutlinedInput
                                    labelWidth={labelWidth}
                                    name={field}
                                    id={`outlined-${field}-${randomNum}`}
                                />
                            }
                        >
                            <MenuItem value="">&nbsp;</MenuItem>
                            {
                                options &&
                                options
                                    .filter(option => option._id)
                                    .map( (option, i) => {
                                        if (option._id && option._id.match(/^ *$/) === null)
                                            return (
                                            <MenuItem value={option._id} key={i}>{
                                                (option._id !== null) ? Lib.humanize(option._id) : ''}
                                                {option[extraFields[0]] ? ` - ${extraFields[0]}: "${option[extraFields[0]]}"` : ''}
                                            </MenuItem>);
                                        return ''
                                    })
                            }
                        </Select>
                    </FormControl>
                }
                {
                    selectionControl === "radio" &&
                    options &&
                    options.length > 0 &&
                    <div>
                        <Typography variant="subtitle1" gutterBottom={false}>
                            {Lib.humanize( title || field )}
                        </Typography>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={items}
                            value={selectedValue}
                            handleChange={ handleChange }
                        />
                    </div>
                }
                {
                    selectionControl === "radio1" &&
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">{Lib.humanize( title || field )}</FormLabel>
                        <RadioGroup
                            aria-label={Lib.humanize( title || field )}
                            name={title || field }
                            style={{flexDirection: 'row'}}
                            value={selectedValue}
                            onChange={handleChange}
                            disabled={disabled || !options || !( 'productGroups' in data) || options.length < 1}
                        >
                            {
                                options && options.map( (option, i) => {
                                    if (option._id && option._id.match(/^ *$/) === null)
                                        return (<FormControlLabel key={i} value={option._id} control={<Radio />} label={(option._id !== null) ? option._id.charAt(0).toUpperCase() + option._id.slice(1) : ''} />);
                                    return ''
                                })
                            }
                            {
                                options && options.length === 0 &&
                                <FormControlLabel control={<Radio />} label={'No result'} />
                            }
                        </RadioGroup>
                    </FormControl>
                }
            </div>
            {
                !hideDelete &&
                !loading &&
                <div className={classes.actionButton}>
                    <Button
                        disabled={disabled || !(field in refSpec) || !refSpec[field] || refSpec[field] === ''}
                        onClick={() => handleChange(null, '')}>
                        <ClearIcon style={{ fontSize: 20 }} />
                    </Button>
                </div>
            }
            {
                !hideDelete &&
                loading &&
                <div className={classes.actionButton}>
                    <CircularProgress size={20} />
                </div>
            }
            {
                !loading &&
                selectionControl === "radio" &&
                options &&
                options.length === 0 &&
                <div>
                    <Typography variant={'subtitle1'} gutterBottom>{Lib.humanize( title || field )}.</Typography>
                    <Typography>No data available.</Typography>
                </div>
            }
        </div>
    )
}

export default AttributeSelect