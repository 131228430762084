import React from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import UserFormContext from '../UserFormContext'

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    
      },
      flex: {
        flex: 1,
      },
      menuButton: {
        marginLeft: -12,
        marginRight: 12,
      },
}));

const TopBarUserBack = () => {
    let history = useHistory();
    const classes = useStyles();
    return (
        <UserFormContext.Consumer>
            {
                (context) => {
                    return (
                        <div className={classes.root}>
                            <AppBar position="fixed" color="primary" elevation={0}>
                                <Toolbar>
                                    <IconButton
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="Back"
                                        onClick={() => history.push('/users')}
                                    >
                                        <Icon>arrow_back</Icon>
                                    </IconButton>
                                    <Typography variant="h6" color="inherit" className={classes.flex}>
                                        { context.state.userEditId === '' ? 'New User': context.state.username }
                                    </Typography>
                                    <Button variant="contained" color="secondary" onClick={
                                        () => {
                                            context.saveUser(true)
                                        }
                                    }
                                    >
                                        {context.state.userEditId === '' ? 'SAVE': 'UPDATE'}
                                    </Button>
                                </Toolbar>
                            </AppBar>
                        </div>
                    )
                }
            }
        </UserFormContext.Consumer>
    )
}

export default TopBarUserBack