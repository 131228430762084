import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ImportContext from '../ImportContext'
import ImportList from './ImportList'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
    root:{
        width: "100%",
        flexGrow: 1,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    tabsIndicator: {
        backgroundColor: theme.palette.grey[50],
    },
}));

const ImportTabs = () => {
    const classes = useStyles();
    return (
        <ImportContext.Consumer>
            {
                context => (
                <div className={classes.root}>
                    <AppBar color="secondary" position="static" elevation={0}>
                        <Tabs value={context.state.tab}
                              onChange={ (evt, value) => {
                                  context.changeTab(value)
                              }}
                              centered
                              variant="fullWidth"
                              classes={{indicator: classes.tabsIndicator }}
                        >
                            <Tab value={'product'} label="Products" />
                            <Tab value={'customer'} label="Customers" />
                            <Tab value={'labour'} label="Labour" />
                        </Tabs>
                    </AppBar>
                    {context.state.tab === 'product'  && <ImportList category={'product'} context={context} />}
                    {context.state.tab === 'customer' && <ImportList category={'customer'} context={context} />}
                    {context.state.tab === 'labour' && <ImportList category={'labour'} context={context} />}
                </div>
            )}
        </ImportContext.Consumer>
    );
}

export default ImportTabs