import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CustomToggleGroup from '../../common/CustomToggleGroup';
import * as Lib from '../lib/lib';
import SpecsType from '../Specs/SpecsType';
import TextField from '@material-ui/core/TextField';
import { Divider } from '@material-ui/core';


const JambMaterial = (props) => {
    const {
        type,
        value,
        items,
        quantity,
        specs,
        customType,
        customQuantity,
        customSpecType,
        specialSpecs,
        handleChange,
        handleSpecChange,
        openingDepth,
        showDepth,
        disabled
    } = props;
    const fractions = [
        {'0.25': '1/4'},
        {'0.5': '1/2'},
        {'0.75': '3/4'},
        {'1': 'Full'},
    ];
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom={false}>
                        {Lib.humanize(type)} Material
                    </Typography>
                    <CustomToggleGroup
                        disabled={disabled}
                        items={items}
                        value={value}
                        handleChange={(event, value) => handleChange(customType || 'materialType', value)}
                    />
                </Grid>
                {
                    showDepth &&
                    ['sheet', 'flat_stock'].includes(value) &&
                    !Lib.isEmptyOrNullObject(customSpecType ? specialSpecs.trim[customSpecType] : specialSpecs.trim[value]) &&
                    <Grid item xs={12}>
                        <TextField
                            required
                            disabled={disabled}
                            id="door-custom-width"
                            label="Depth (IN)"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            type={"number"}
                            value={openingDepth || ''}
                            onChange={ (e) => handleChange('openingDepth', e.target.value)}
                        />
                    </Grid>
                }
                {
                    ['sheet', 'flat_stock'].includes(value) &&
                    !Lib.isEmptyOrNullObject(customSpecType ? specialSpecs.trim[customSpecType] : specialSpecs.trim[value]) &&
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom={false}>
                            Quantity
                        </Typography>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={fractions}
                            value={quantity}
                            handleChange={(event, value) => handleChange(customQuantity || 'quantity', value)}
                        />
                    </Grid>
                }
                {
                    value === 'sheet' &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            hideCopy={customSpecType || false}
                            label={'Sheet'}
                            category={'trim'}
                            type={'sheet'}
                            attributes={['species', 'style', 'thickness', 'grade', 'length']}
                            handleChange={handleSpecChange('trim' , customSpecType || 'sheet', 'sheet')}
                            specs={customSpecType ? specialSpecs.trim[customSpecType] : specialSpecs.trim.sheet}
                            globalSpecs={specs.trim.sheet}
                        />
                    </Grid>
                }
                {
                    value === 'flat_stock' &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            category={'trim'}
                            type={'flat_stock'}
                            attributes={['supplier' , 'style', 'finish', 'species', 'width', 'thickness', 'length']}
                            handleChange={handleSpecChange('trim' , customSpecType || 'flat_stock', 'flat_stock')}
                            specs={customSpecType ? specialSpecs.trim[customSpecType] : specialSpecs.trim.flat_stock}
                            globalSpecs={specs.trim.flat_stock}
                        />
                    </Grid>
                }
                {
                    /jamb/.test(value) &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            hideCopy={customSpecType || false}
                            label={items[value]}
                            category={'trim'}
                            type={'jamb'}
                            attributes={['supplier', 'finish', 'species', 'width', 'thickness', 'height', 'style']}
                            handleChange={handleSpecChange('trim' , customSpecType || value, 'jamb')}
                            specs={customSpecType ? specialSpecs.trim[customSpecType] : specialSpecs.trim[value]}
                            globalSpecs={specs.trim[value]}
                        />
                    </Grid>
                }
                <Grid item xs={12}><Divider /></Grid>
            </Grid>
        </div>
    )
}

export default JambMaterial