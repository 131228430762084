// React component for the custom item form
import React from "react";
import { Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, TextField, OutlinedInput } from "@material-ui/core";
//material UI   
import { makeStyles } from "@material-ui/core/styles";
import NotesAndImages from './NotesAndImages';
import SearchProduct from "./SearchProduct";
import AdditionalItem from "./AdditionalItem";


const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paperA:{
        padding:'16px',
    },
    container: {
        position: 'relative',
      },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
}));

const CustomProduct = ({ context }) => {
    const classes = useStyles();
    const {disabled} = context.state;
    const custom_install_service = context.state.measures.custom.custom_install_service;
    const additionalItem = custom_install_service.additionalItem || {};
    const additionalItemObj = Object.keys(additionalItem).length === 0 && additionalItem.constructor === Object ? 
                                context.state.additionalOrder.additionalItem : additionalItem;

    const handleSelect = (item) => {
        handleContextChange("code", item.code);
    }

    const handleContextChange = (field, value) => {
        context.setState({
            measures: {
                ...context.state.measures,
                custom: {
                    ...context.state.measures.custom,
                    custom_install_service: {
                        ...context.state.measures.custom.custom_install_service,
                        [field]: value
                    }
                }
            }
        });
    };
    
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {
                        context.state.isAdditionalOrder &&
                        <AdditionalItem
                            additionalItem={additionalItemObj}
                            disabled={disabled}
                            onItemChange={(value)=> {
                                handleContextChange('additionalItem', value);
                            }}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paperA} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom={false}>Search for Product</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="product-type" required>Type</InputLabel>
                                    <Select
                                        inputProps={{
                                            margin: 'dense'
                                        }}
                                        input={
                                            <OutlinedInput
                                                labelWidth={50}
                                                name={'product-type'}
                                                id={`outlined-type`}
                                            />
                                        }
                                        value={custom_install_service.type}
                                        disabled={disabled || context.state.editTrimIndex !== -1}
                                        onChange={ event => {
                                            // if (event.target.value === 'Union Install') {
                                            //     const audio = new Audio(SVU);
                                            //     audio.play();
                                            // }
                                            handleContextChange('type', event.target.value)}
                                        }
                                    >
                                        <MenuItem value={''}></MenuItem>
                                        <MenuItem value={'Install'}>Install</MenuItem>
                                        <MenuItem value={'Machine'}>Machine</MenuItem>
                                        <MenuItem value={'Union Install'}>Union</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                custom_install_service.type && (
                                    <SearchProduct
                                        category={"labour"}
                                        handleSelect={handleSelect}
                                        disabled={disabled}
                                        type={custom_install_service.type}
                                    />
                                )
                            }
                            {
                                custom_install_service.code && (
                                    <>
                                        <Typography variant="h6" gutterBottom={false}>
                                                [{custom_install_service.code}] {custom_install_service.description}
                                            </Typography>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                type="number"
                                                label="Quantity"
                                                value={custom_install_service.count}
                                                onChange={ event => {
                                                    handleContextChange('count', Math.abs(event.target.value))
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NotesAndImages
                                                disabled={disabled}
                                                category={"custom_install_service"}
                                                notes={custom_install_service.notes}
                                                images={custom_install_service.images}
                                                handleChange={(field, value) => handleContextChange(field, value)}
                                                handleDeleteImage={(index) => context.deleteTypeImage(index, 'custom_install_service')}
                                            />
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item xs={12}></Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>    
    );
};

export default CustomProduct;