import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomerDetails from '../CustomerDetails';
import * as Lib from '../lib/lib';
import logo from '../../../images/logo-black.png';
import moment from 'moment'
import { REACT_APP_S3_LINK } from '../../envs';
import {TakeoffFormContext} from '../../TakeoffFormContext';

const useStyles = makeStyles(() => ({
    root:{
        marginTop: '16px',
    },
    image: {
        cursor: 'pointer',
        marginRight: '16px',
        height: '100px',
        objectFit: 'cover'
    },
    paper: {
        padding: '16px',
        width: "100%"
    },
    logo:{
        width: "70%",
    },
    noPrint: {
        '@media print': {
            display: 'none',
        }
    },
    print: {
        display: 'none',
        '@media print': {
            display: 'block',
        }
    }
}));

const getS3ImagePath = (image) => {
    // let bucket = window.location.hostname === 'localhost' ? 'riverside-local' : window.location.hostname.includes("dev") ? 'riverside-dev' : window.location.hostname.includes("staging") ? 'riverside-staging' : 'riverside-prod'
    let bucket =  window.location.hostname === 'localhost' || window.location.hostname.includes("dev") ? 'rmg-dev-uploads' : window.location.hostname.includes("staging") ? 'rmg-test-uploads' : 'rmg-prod-uploads';
    return `${REACT_APP_S3_LINK}${bucket}/${image}`
}

const TakeoffInfoSummary = (props) => {
    const context = useContext(TakeoffFormContext);
    const { creator, code, customerType, site, installAt, installPricingMode, specs, createdAt} = context.state;
    const { label = '', isPrint = false,  showImages, notes, price, showPrices = true} = props;
    const classes = useStyles();
    const prep = (specs && specs.door_treatment && specs.door_treatment.prep) || "";
    const backset = (specs && specs.door_treatment && specs.door_treatment.backset) || "";
    const head = (specs && specs.door_treatment && specs.door_treatment.head) || "";

    return (
        <Grid
            container
            spacing={2}
            className={classNames(classes.root, isPrint ? classes.print : classes.noPrint)}
        >
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={isPrint ? 4 : 12} md={4}>
                            <img alt='logo' src={logo} className={classes.logo}/>
                        </Grid>
                        <Grid item xs={isPrint ? 4 : 12} md={4}>
                            <Typography variant="h6">{label}</Typography>
                        </Grid>
                        <Grid item xs={isPrint ? 4 : 12} md={4}>
                            <Typography variant="h6">{code}</Typography>
                            <Typography variant="body2">By {creator}</Typography>
                            {
                                createdAt &&
                                <Typography variant="body2">Created {moment( createdAt).format('MMM Do YYYY')}</Typography>
                            }
                            {
                                showPrices && (price || price === 0) &&
                                <Typography variant="body1">${Math.ceil(price)} + Tax</Typography>
                            }
                        </Grid>
                        <Grid item xs={isPrint ? 4 : 12} md={4}>
                            <Typography variant="h6">{Lib.humanize(customerType) || `Customer`}</Typography>
                            <CustomerDetails
                                customer={context.state[customerType]}
                                customerType={customerType}
                            />
                        </Grid>
                        {
                            !Lib.isEmptyOrNullObject(site) &&
                            <Grid item xs={isPrint ? 4 : 12} md={4}>
                                <Typography variant="h6">Site</Typography>
                                {
                                    Object.keys(site)
                                    .filter(field => site[field])
                                    .map((field, i) => (
                                        <Typography key={i} variant="body2">
                                            {Lib.humanize(field.replace('model', ''))}: {site[field]}
                                        </Typography>
                                    ))
                                }
                            </Grid>
                        }
                        <Grid item xs={isPrint ? 4 : 12} md={4}>
                            <Typography variant="h6">Install/Machine</Typography>
                                <Typography variant="body2">
                                    {Lib.humanize(installPricingMode === 'contract_pricing' ? 'per_opening' : Lib.humanize(installPricingMode)) || 'No installation'}
                                </Typography>
                                {
                                    installAt &&
                                    <Typography variant="body2">
                                        Install date: {installAt instanceof Date ? installAt.toISOString().slice(0,10) : installAt.slice(0,10) }
                                    </Typography>
                                }
                                {
                                    prep &&
                                    <Typography variant="body2">
                                        Door Prep: {prep}
                                    </Typography>
                                }
                                {
                                    backset &&
                                    <Typography variant="body2">
                                        Backset: {backset}
                                    </Typography>
                                }
                                {
                                    head &&
                                    <Typography variant="body2">
                                        Head: {head}
                                    </Typography>
                                }

                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {
                notes &&
                notes.length > 0 &&
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Notes</Typography>
                                {
                                    notes.map((note, i) => (
                                        <React.Fragment key={i}>
                                            <Typography  variant="body2">
                                                {note.index}: {note.notes}
                                            </Typography>
                                            {
                                                showImages &&
                                                note.images &&
                                                note.images.map((image, j) => (
                                                    <a key={j} href={getS3ImagePath(image)} target="_blank" rel="noopener noreferrer">
                                                        <img key={j} className={classes.image} src={getS3ImagePath(image)} alt={'gallery'} />
                                                    </a>
                                                ))
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            }
        </Grid>
    )
}

export default TakeoffInfoSummary