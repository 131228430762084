import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root:{
        width: "100%",
        flexGrow: 1,
    },
    dialog: {},
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    tabsIndicator: {
        backgroundColor: theme.palette.grey[50],
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ImportFileModal = ({context}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={context.state.dialog}
            TransitionComponent={Transition}
            keepMounted
            className={classes.dialog}
            onClose={() => {
                document.getElementById("csv-upload-form").reset();
                context.clearImporter()
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title1">
                {
                    context.state.progress === 100 || Object.keys(context.state.importObj).length > 0 ?
                        context.humanize(context.state.importObj.status) : 'Uploading data file'
                }
            </DialogTitle>
            <DialogContent style={{minWidth: '320px', width: '40vw'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {
                            context.state.progress !== 100 ?
                                <LinearProgress color={'secondary'} variant="determinate" value={context.state.progress} /> :
                                context.state.importObj && context.state.importObj.status === 'error' ?
                                    <LinearProgress color={'secondary'} variant="determinate" value={100} /> :
                                    <LinearProgress color={'secondary'} />
                        }
                    </Grid>
                    {
                        context.state.importObj &&
                        Object.keys(context.state.importObj).length > 0 &&
                        context.state.importObj.notes &&
                        Object.keys(context.state.importObj.notes).length > 0 &&
                        <Grid item xs={12}>
                            {
                                (
                                    !context.state.importObj.notes.header ||
                                    context.state.importObj.notes.codes.length > 0 ||
                                    context.state.importObj.notes.attributes.length > 0
                                ) &&
                                <>
                                    <Typography variant={'subtitle1'}>
                                        Error with data, review below:
                                    </Typography>
                                    {
                                        !context.state.importObj.notes.header &&
                                        <Typography variant={"body1"}>
                                            Uploaded file doesn't have the required header columns, please add it and re-upload!
                                        </Typography>
                                    }
                                    {
                                        context.state.importObj.notes.codes.length > 0 &&
                                            <>
                                                <Typography variant={"body1"}>
                                                    Duplicate {context.state.tab} codes
                                                </Typography>
                                                {context.state.importObj.notes.codes.map( (code, i) => (
                                                    <Typography key={i} variant={"body2"}>
                                                        {code}
                                                    </Typography>
                                                ))}
                                            </>
                                    }
                                    {
                                        context.state.importObj.notes.attributes.length > 0 &&
                                        <>
                                            <Typography variant={"body1"}>
                                                Duplicate product attributes
                                            </Typography>
                                            {context.state.importObj.notes.attributes.map( (code, i) => (
                                                <Typography key={i} variant={"body2"}>
                                                    {code}
                                                </Typography>
                                            ))}
                                        </>
                                    }
                                </>
                            }
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions style={{padding: '16px'}}>
                <Button
                    color="default"
                    variant={'contained'}
                    onClick={() => {
                        document.getElementById("csv-upload-form").reset();
                        context.clearImporter()
                    }}
                >
                    Cancel
                </Button>
                {
                    context.state.progress === 100 &&
                    context.state.importObj &&
                    Object.keys(context.state.importObj).length > 0 &&
                    context.state.importObj.notes &&
                    Object.keys(context.state.importObj.notes).length > 0 &&
                    (
                        context.state.importObj.notes.header &&
                        context.state.importObj.notes.codes.length === 0 &&
                        context.state.importObj.notes.attributes.length === 0
                    )
                    &&
                    <Button
                        color="secondary"
                        variant={'contained'}
                        onClick={() => {
                            context.proceed()
                        }}
                    >
                        Continue
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ImportFileModal