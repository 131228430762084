import { gql } from '@apollo/client';
import { Takeoff_Fragment, Mini_Takeoff_Fragment, PricingTiers_Fragment, Shelving_Fragment} from '../../common/fragments.graphql';
import isEqual from 'lodash.isequal';

export const deepAttributeExists = (path, obj) => path.reduce((a, x) => (a && a[x]) ? a[x] : null, obj);
export const objectHasAttributes = (stack, obj) => obj && Object.keys(obj).length > 0 && stack.filter(attr => attr in obj && obj[attr]).length > 0

export const humanizePricingTier = (tier) => {
    const tiers = {
        lp00: 'LP',
        lp05: '-5%',
        lp10: '-10%',
        lp15: '-15%',
    };
    return tiers[tier] || humanize(tier);
}
export const uoms = (unit, fallback = null) => {
  const units = {
      "Lineal Feet"   : "LF",
      "Pieces"        : "PC",
      "Each"          : "EA",
      "Board Feet"    : "BF",
      "Pair"          : "PR",
      "Set"           : "ST",
  };
  return units[unit] || fallback || humanize(unit);
};

const sanitizeObject = (obj) => {
    const result = {};
    Object.keys(obj)
        .filter(k => obj[k] && obj[k] !== 'n/a')
        .forEach(k => {
            result[k] = obj[k];
        })
    return result;
}
export const isSpecialSpecs = (localSpec = {}, globalSpec = {}, isDoor = false) => {
    const localS = JSON.parse(JSON.stringify(localSpec));
    const globalS = JSON.parse(JSON.stringify(globalSpec));
    delete localS.category;
    if (!isDoor) delete localS.type;
    delete localS.extra_specs;
    delete globalS.extra_specs;
    if (
        'mode' in localS &&
        ['Interior', 'Entry', 'Passage'].includes(localS.mode)
    ) {
        delete localS.mode;
        delete globalS.mode;
        delete localS.direction;
        delete globalS.direction;
    }
    return !isEqual(sanitizeObject(localS), sanitizeObject(globalS));
}

export const isNum = (val) => /^\d+$/.test(val);

export const humanize = (str, allCaps = false) => {
    if (str === 'n/a') return 'N/A';
    if (str === null || str === 'null' || str === undefined) return '';
    let frags = str.split('_');
    for (let i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return allCaps ? frags.join(' ').toUpperCase() : frags.join(' ');
};

export const isSpecSet = (field, category, type='') => {
    return type ?
        category &&
        field in category[type] &&
        hasValue(category[type][field])
        :
        category &&
        field in category &&
        hasValue(category[field])
};

export const hasValue = (field) => {
    return  field !== 'undefined' && field && field !== '';
};

export const checkSpecs = (obj, attributes) => {
    let result = true;
    attributes.forEach( attribute => {
        if (!isSpecSet(attribute, obj))
            result = false
    });
    // console.log(obj, result)
    return result;
};

export const getDimensions = async (conditions,  apolloClient) => {
    let variables = {};
    // Object.keys(conditions).forEach((key) => (conditions[key] === 'n/a') && (conditions[key] = null));
    variables['condition'] = conditions;
    let GET_DIMENSIONS = gql`
        query availableDimensions($condition: Condition) {
            availableDimensions (
                condition: $condition
            ) {
                core mode species machined thickness direction finish radius length width height header brand supplier depth rosette firerated tracklength sticking panelProfile material size design
            }
        }
    `;
    const {data} = await apolloClient.query({
        query: GET_DIMENSIONS,
        variables: variables
    });
    if (data.availableDimensions !== null) {
        return data.availableDimensions;
    }
};

export const getHandleProduct = async (customer, door, specs, apolloClient) => {
    let GET_PRODUCT = '';
    let type = door.handleType;
    if (
        type &&
        Object.keys(door).length > 0 &&
        specs.brand &&
        specs.style &&
        specs.finish &&
        door.handleMode
    ) {
        GET_PRODUCT = gql`
            query fetchProduct ($condition: Condition!){
                fetchProduct(condition: $condition) {
                    code description uom pricingTiers {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh}
                }
            }
        `;
        const {data} = await apolloClient.query({
            query: GET_PRODUCT,
            variables: {
                condition: {
                    category:    "Hardware",
                    type:       humanize(type),
                    brand:      specs.brand,
                    style:      specs.style,
                    finish:     specs.finish ,
                    direction:  type === 'knob' ? '' : ( (door.handleDirection === 'right' && !door.isReverse) || (door.handleDirection === 'left' && door.isReverse) ) ? 'RH' : 'LH' ,
                    mode:       door.handleMode,
                    rosette:    specs.rosette || '',
                    firerated:  specs.firerated || ''
                }
            }
        });
        if (data.fetchProduct !== null) {
            return data.fetchProduct
        }
    }
    return false
};

export const validateTakeoff = async (id, apolloClient) => {
    const VALIDATE_TAKEOFF = gql`
        mutation validateTakeoff{
            validateTakeoff(id: "${id}") {
                ...TakeoffParts
            }
        }
        ${Takeoff_Fragment}
    `;
    const {data} = await apolloClient.mutate({
        mutation: VALIDATE_TAKEOFF,
    });
    if (data.validateTakeoff !== null ) {
        return data.validateTakeoff;
    }
    return false;
};

export const getProduct = async (code, tier, apolloClient) => {
    let GET_PRODUCT = gql`
        query fetchProduct ($condition: Condition!){
                fetchProduct(condition: $condition) {
                uom pricingTiers {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh}
            }
        }
    `;
    const {data} = await apolloClient.query({
        query: GET_PRODUCT,
        variables: {
            condition: {
                code
            }
        }
    });
    if (data.fetchProduct !== null) {
        return data.fetchProduct;
    }
    return false;
};

export const deleteDoorImageInit = async (imagePath, apolloClient) => {
    let savePromise = deleteDoorImageRequest(imagePath, apolloClient);
    return savePromise.then( (result) => {
        return result;
    })
};

const deleteDoorImageRequest = async (imagePath, apolloClient) => {
    const DELETE_IMAGE = gql`
        mutation deleteDoorImage{
            deleteDoorImage(image: "${imagePath}")
        }
    `;
    const {data} = await apolloClient.mutate({
        mutation: DELETE_IMAGE,
    });
    if (data.deleteDoorImage !== null || data.deleteDoorImage !== null) {
        return data.deleteDoorImage;
    }
    return false;
};

export const getUpdatedDoor = async (customer, door, specs, apolloClient) => {
    const GET_PRODUCT = gql`
        query fetchProduct ($condition: Condition!){
                fetchProduct(condition: $condition) {
                id description code uom width height type style core thickness frame material finish sticking panelProfile bifold pricingTiers {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh}
            }
        }
    `;
    const {data} = await apolloClient.query({
        query: GET_PRODUCT,
        variables: {
            condition: {
                category:    "Door",
                type:       specs.type,
                style:      specs.style,
                core:       specs.core,
                thickness:  specs.thickness,
                height:     specs.height,
                width:      door.width,
                frame:      door.frame,
                finish:     door.finish,
                material:   door.material,
                sticking:   door.sticking,
                panelProfile: door.panelProfile,
                bifold:     door.isBifold
            }
        }
    });
    if (data.fetchProduct !== null) {
        return data.fetchProduct
    }
    return false
};

export const getLabourItem = async (attributes, apolloClient, withAttributes = false, extraAttributes = {}) => {
    if ( !attributes.category ) return false;
    if (
        Object.keys(attributes).length > 0
    ) {
        let GET_LABOUR = gql`
            query fetchLabour($category: String!, $code: String, $type: String, $attributes:  JSON) {
                fetchLabour(
                    category:    $category
                    code:        $code
                    type:        $type
                    attributes:  $attributes
                ) {
                    code description uom pricingTiers {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh}
                }
            }
        `;
        const {data} = await apolloClient.query({
            query: GET_LABOUR,
            variables: attributes
        });
        if (data.fetchLabour !== null) {
            return {
                ...data.fetchLabour,
                ...(withAttributes && {attributes}),
                ...extraAttributes
            };
        }
    }
    return false;
};

export const getAnyProduct = async (attributes = {}, apolloClient, moreAtrributes = [], addedAttributes = {}) => {
    // Object.keys(attributes).forEach((key) => (attributes[key] === 'n/a') && (attributes[key] = null));
    if ( !attributes.category ) return false;
    const extraAttributes = moreAtrributes.length > 0 ? moreAtrributes : Object.keys(attributes);
    if (
        Object.keys(attributes).length > 0
    ) {
        let GET_PRODUCT = gql`
            query fetchProduct ($condition: Condition!){
                fetchProduct(condition: $condition) {
                    code description uom pricingTiers {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh} ${ extraAttributes.map( attribute => `${attribute} ,`).join('') }
                }
            }
        `;
        const {data} = await apolloClient.query({
            query: GET_PRODUCT,
            variables: {
                condition: {
                    category:   humanize(attributes.category),
                    type:       humanize(!['shoe_moulding', 'Shoe Moulding'].includes(attributes.type) ? attributes.type : 'Doorstop'),
                    code:       'code' in attributes && attributes.code  ? attributes.code : '',
                    brand:      attributes.brand || '',
                    height:     attributes.height|| '',
                    length:     attributes.length || '',
                    width:      attributes.width  || '',
                    radius:     attributes.radius || '',
                    core:       attributes.core || '',
                    hook:       attributes.hook || '',
                    bifold:     attributes.bifold || false,
                    backband:   attributes.backband || false,
                    style:      attributes.style || '',
                    species:    attributes.species || '',
                    finish:     attributes.finish || '',
                    thickness:  attributes.thickness || '',
                    supplier:   attributes.supplier || '',
                    material:   attributes.material || '',
                    shelflength: attributes.shelflength || 0,
                    tracklength: attributes.tracklength || '',
                    depth:       attributes.depth || '',
                    size:        attributes.size || '',
                    mode:        attributes.mode || '',
                    direction:   attributes.direction || '',
                    frame:       attributes.frame || '',
                    rosette:     attributes.rosette || '',
                    firerated:   attributes.firerated || '',
                    grade:       attributes.grade     || '',
                    color:       attributes.color     || '',
                    design:      attributes.design     || '',
                    accessory:   attributes.accessory     || '',
                    drawer:      attributes.drawer     || '',
                    hardware:    attributes.hardware     || '',
                    sticking:    attributes.sticking     || '',
                    panelProfile:    attributes.panelProfile     || '',
                }
            }
        });
        if (data.fetchProduct !== null) {
            // console.log(111,data)
            return {...data.fetchProduct, ...addedAttributes};
        }
    }
    return false;
};

export const calculateArchwayTrim = (item) => {
    let total = {totalCasing: 0, totalJamb: 0};
    let lookup = [28, 56];
    if (parseStringNumber(item.height) < 84) {
        total.totalCasing = lookup[0] + ceilToN(item.width / 12, 7);
        total.totalJamb = Math.ceil(total.totalCasing / 2);
    } else {
        total.totalCasing = lookup[1] + ceilToN(item.width / 12, 7);
        total.totalJamb = Math.ceil(total.totalCasing / 2);
    }
    return total;
};

const ceilToArrayValue = (array, value) => {
  return array.reduce(function(prev, curr) {
    return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
  });
}

const fetchTrimCount = (type, height, length = '', isDouble, halfTrimFactor ) => {
    // console.log(type, height, length, isDouble, halfTrimFactor);
    const lookup  = {
        casing: {
            '8': {
                '80': [40,48], '84': [40,48], '90': [40,48], '96': [],
            },
            '10': {
                '80': [50,60], '84': [50,60], '90': [50,60], '96': [50,60],
            },
            '12': {
                '80': [48,60], '84': [48,60], '90': [48,60], '96': [48,60],
            },
            '14': {
                '80': [35,42], '84': [56,56], '90': [56,56], '96': [56,70],
            },
            '16': {
                '80': [40,48], '84': [40,48], '90': [40,48], '96': [64,64],
            },
            'none': {
                '80': [35,42], '84': [40,48], '90': [40,48], '96': [42,50],
            },
        },
        doorstop:   { '96': [28, 35], '84': [20, 24], '80': [17, 21], },
        jamb:       { '96': [28, 35], '84': [20, 24], '80': [17, 21], },
    };
    const nHeight = type === 'casing' ?
                        ceilToArrayValue(Object.keys(lookup[type][length]), height) :
                        ceilToArrayValue(Object.keys(lookup[type]), height);
    return (type === 'casing' ? (lookup[type][length][nHeight][isDouble ? 1 : 0]) : (lookup[type][nHeight][isDouble ? 1 : 0]) * halfTrimFactor)
};

export const calculateTrims = (type, door, casingLength = 14) => {
    const length = isNum(casingLength) ? String(2 * Math.floor(casingLength / 2)) : casingLength === 'n/a' ? '14' : casingLength || '14';
    const height = door.attributes.height;
    const halfTrimFactor = door.isHalfTrim ? 0.5 : 1;

    return fetchTrimCount(type, height, length, door.isDouble, halfTrimFactor);
};

export const notifyInsideOffice = async (recepients, id, apolloClient) => {
    const SEND_READY = gql`
        mutation notifyInsideOffice($id:ID! ,$recepients: [String]!, $sender: String!) {
            notifyInsideOffice(id: $id, recepients: $recepients, sender: $sender)
        }
    `;

    const {data} = await apolloClient.mutate({
        mutation: SEND_READY,
        variables: { id, recepients, sender: localStorage.getItem('name') }
    });
    if (data.notifyInsideOffice !== null ) {
        return data.notifyInsideOffice
    }
}

export const notifyOffice = async (warehouse, reviewType, id, apolloClient) => {
    const SEND_READY = gql`
        mutation notifyWarehouseOffice($id:ID! ,$warehouse: String!, $reviewType: String) {
            notifyWarehouseOffice(id: $id, warehouse: $warehouse, reviewType: $reviewType)
        }
    `;

    const {data} = await apolloClient.mutate({
        mutation: SEND_READY,
        variables: { id, warehouse, reviewType }
    });
    if (data.notifyWarehouseOffice !== null ) {
        return data.notifyWarehouseOffice
    }
}

const takeoffGQL = (id) => {
    if (id === '' || id === null || id === undefined) return '';
    return gql`
        query Takeoff($id: ID!){
            Takeoff(id: $id) {
                ...TakeoffParts
            }
        }
        ${Takeoff_Fragment}
    `;
};

const takeoffWithUpdateGQL = (id) => {
    if (id === '' || id === null || id === undefined) return '';
    return gql`
        query getTakeoffAndUpdate($id: ID!){
            getTakeoffAndUpdate(id: $id) {
                ...TakeoffParts
            }
        }
        ${Takeoff_Fragment}
    `;
};

export const getTakeoff = async (id, apolloClient, forceUpdate = false) => {
    if (id === '') return ;
    const GET_TAKEOFF = takeoffGQL(id);
    const GETU_TAKEOFF = takeoffWithUpdateGQL(id);
    return apolloClient.query({
        query: forceUpdate ? GETU_TAKEOFF : GET_TAKEOFF,
        variables: {id},
        fetchPolicy: 'no-cache'
    })
    .then(result => {
        if (result && result.data ) {
            return forceUpdate ?  result.data.getTakeoffAndUpdate : result.data.Takeoff || false
        }
    })
    .catch(() => {
        // console.log(err);
        return false
    });
};

export const saveSpecsType = async (variables, apolloClient) => {
    let SAVE_SPECS;
    if (variables.propagate) {
        SAVE_SPECS = gql`
            mutation saveSpecsType(
            $id: ID, $category: String!, $type: String, $specs: NewGenericSpecs, $product: NewProductType, $propagate: Boolean, $notes: JSON
            ) {
                saveSpecsType(id: $id, category: $category, type: $type, specs: $specs, product: $product, propagate: $propagate, notes: $notes) {
                    ...TakeoffParts
                }
            }
            ${Takeoff_Fragment}
        `;
    } else {
        SAVE_SPECS = gql`
            mutation saveSpecsType(
            $id: ID, $category: String!, $type: String, $specs: NewGenericSpecs, $product: NewProductType, $propagate: Boolean, $notes: JSON
            ) {
                saveSpecsType(id: $id, category: $category, type: $type, specs: $specs, product: $product, propagate: $propagate, notes: $notes) {
                    ...MiniTakeoffParts
                }
            }
            ${Mini_Takeoff_Fragment}
        `;
    }
    let GET_TAKEOFF = takeoffGQL(variables.id);

    const {data} = await apolloClient.mutate({
        mutation: SAVE_SPECS,
        ...('id' in variables && variables.id ? {refetchQueries: [{ query: GET_TAKEOFF, variables: {id: variables.id}}]} : {} ),
        variables: variables
    });
    if (data.saveSpecsType !== null ) {
        return data.saveSpecsType
    }
};

export const duplicateMeasureItem = async (variables, apolloClient) => {
    const DUPLICATE_ITEM = gql`
        mutation duplicateMeasureItem(
        $id: ID!, $category: String!, $item_id: ID!, $index: Int!, $count: Int!
        ) {
            duplicateMeasureItem(id: $id, category: $category, item_id: $item_id, index: $index, count: $count) {
                id code createdBy{username name} items{id createdAt updatedAt}
            }
        }
    `;
    let GET_TAKEOFF = takeoffGQL(variables.id);

    const {data} = await apolloClient.mutate({
        mutation: DUPLICATE_ITEM,
        ...('id' in variables && variables.id ? {refetchQueries: [{ query: GET_TAKEOFF, variables: {id: variables.id}}]} : {} ),
        variables: variables
    });
    if (data.duplicateMeasureItem !== null ) {
        return data.duplicateMeasureItem
    }
};

export const saveMeasureShelf = async (variables, apolloClient) => {
    const SAVE_ITEM = gql`
        mutation saveItem(
            $id: ID, $category: String!, $index: ID, $item: NewGenericMeasureItem
        ) {
            saveItem(id: $id, category: $category, index: $index, item: $item) {
                id code createdBy{ username name } 
                item { ...ShelvingParts }
            }
        }
    ${Shelving_Fragment}`;
    let GET_TAKEOFF = takeoffGQL(variables.id);

    const {data} = await apolloClient.mutate({
        mutation: SAVE_ITEM,
        ...('id' in variables && variables.id ? {refetchQueries: [{ query: GET_TAKEOFF, variables: {id: variables.id}}]} : {} ),
        variables: {category: "shelving", ...variables}
    });
    if (data.saveItem !== null ) {
        return data.saveItem
    }
};

export const saveMeasureItem = async (variables, apolloClient) => {
    const SAVE_ITEM = gql`
        mutation saveItem(
            $id: ID, $category: String!, $index: ID, $item: NewGenericMeasureItem
        ) {
            saveItem(id: $id, category: $category, index: $index, item: $item) {
                id code createdBy{username name} item {id createdAt updatedAt}
            }
        }
    `;
    let GET_TAKEOFF = takeoffGQL(variables.id);

    const {data} = await apolloClient.mutate({
        mutation: SAVE_ITEM,
        ...('id' in variables && variables.id ? {refetchQueries: [{ query: GET_TAKEOFF, variables: {id: variables.id}}]} : {} ),
        variables: variables
    });
    if (data.saveItem !== null ) {
        return data.saveItem
    }
};

export const saveAttribute = async (variables, apolloClient) => {
    const SAVE_ITEM = gql`
        mutation saveAttribute(
            $id: ID, $attribute: String!, $site: NewSite, $value: String
        ) {
            saveAttribute(id: $id, attribute: $attribute, site: $site, value: $value) {
                ...MiniTakeoffParts
            }
        }
        ${Mini_Takeoff_Fragment}
    `;
    let GET_TAKEOFF = takeoffGQL(variables.id);

    const {data} = await apolloClient.mutate({
        mutation: SAVE_ITEM,
        ...('id' in variables && variables.id ? {refetchQueries: [{ query: GET_TAKEOFF, variables: {id: variables.id}}]} : {} ),
        variables: variables
    });
    if (data.saveAttribute !== null ) {
        return data.saveAttribute
    }
};

export const saveAdditionalOrderInfo = async (variables, apolloClient) => {
    const SAVE_ADDITIONAL_ORDER = gql`
        mutation saveAdditionalOrderInfo(
            $id: ID, $additionalOrder:  NewAdditionalOrder!
        ) {
            saveAdditionalOrderInfo(id: $id, additionalOrder: $additionalOrder) {
                ...MiniTakeoffParts
            }
        }
        ${Mini_Takeoff_Fragment}
    `;
    let GET_TAKEOFF = takeoffGQL(variables.id);

    const {data} = await apolloClient.mutate({
        mutation: SAVE_ADDITIONAL_ORDER,
        ...('id' in variables && variables.id ? {refetchQueries: [{ query: GET_TAKEOFF, variables: {id: variables.id}}]} : {} ),
        variables: variables
    });
    if (data.saveAdditionalOrderInfo !== null ) {
        return data.saveAdditionalOrderInfo
    }
};

export const changeCustomer = async (variables, apolloClient) => {
    const CHANGE_CUSTOMER = gql`
        mutation changeCustomer(
        $id: ID, $customer: NewCustomer!, $customerType: String, $updatePricing: Boolean
        ) {
            changeCustomer(id: $id, customer: $customer, customerType: $customerType, updatePricing: $updatePricing) {
                ...TakeoffParts
            }
        }
        ${Takeoff_Fragment}
    `;

    const {data} = await apolloClient.mutate({
        mutation: CHANGE_CUSTOMER,
        ...('id' in variables && variables.id ? {refetchQueries: [{ query: takeoffGQL(variables.id), variables: {id: variables.id}}]} : {} ),
        variables: variables
    });
    if (data.changeCustomer !== null ) {
        return data.changeCustomer
    }
};

export const removeMeasureItem = async (variables, apolloClient) => {
    const REMOVE_ITEM = gql`
        mutation removeMeasureItem(
            $id: ID!, $category: String!, $index: ID!, $itemIndex: String
        ) {
            removeMeasureItem(id: $id, category: $category, index: $index, itemIndex: $itemIndex)
        }
    `;
    // let GET_TAKEOFF = takeoffGQL(variables.id);

    const {data} = await apolloClient.mutate({
        mutation: REMOVE_ITEM,
        // refetchQueries: [{ query: GET_TAKEOFF}],
        variables: variables
    });
    if (data.removeMeasureItem !== null ) {
        return data.removeMeasureItem
    }
}

export const isEmptyOrNullObject = (obj, exceptions = []) => {
    if (obj && exceptions.length > 0) {
        exceptions.forEach(e => delete obj[e])
    }
    return !obj || Object.keys(obj).length < 1 || Object.keys(obj).every(attr => !obj[attr] || obj[attr] === '')
};

export const renderDimensions = (dim) => {
    let pattern = /(\d+)\s*[x|X]\s*(\d+)/;
    return dim.match(pattern)
};

export const calculateWindowDimensions = (width, height, inInches = false) => {
    let perimeter =  (Number(width)+Number(height)) / (inInches ? 12 : 1);
    return ceilToN(perimeter, 7)
};

export const ceilToN = (number, factor = 7) => {
    return Math.ceil(number / factor) * factor;
};

export const roundToN = (number, factor = 7) => {
    return Math.round(number / factor) * factor;
};

export const parseStringNumber = (str = "") => {
    if (!str || ['n/a', 'N/A'].includes(str)) return 0;
    const parts = str.replace("-", " ").split(/ |\//);
    switch (parts.length) {
        case 1:
            return Number(parts[0]);
        case 2:
            return Number(parts[0]) / Number(parts[1]);
        case 3:
            return Number(parts[0]) + (Number(parts[1]) / Number(parts[2]));
        default:
            return 0
    }
};

// eslint-disable-next-line
export const evil = (fn, {w = 0, l = 0}) => {
    return new Function('return ' + fn)();
};

export const getExtDoorCasingBurlap = (size, type) => {
    let size_casing = {
        single: 18,
        single_large: 24,
        double: 21,
        '1_side': 21,
        '2_side': 24,
    };
    let size_burlap = {
        '1_side': 8,
        '2_side': 16,
    };
    if (!Object.keys(size_casing).includes(size)) return 0;
    return type === 'casing'? size_casing[size] : size_burlap[size];
};

export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const getLatestCPU = (id, apolloClient) => {
    if (id === '') return ;
    
    const CPU_REPORT = gql`
        query CPUReport($id: ID!) {
            LastCPUReport (id: $id) {
            id description needsAction isResolved createdAt 
            report {
            prices_change {id code category notes}
            discontinued {id code category notes resolvedBy {username} }
            }
        }
        }
    `;
    return apolloClient.query({
        query: CPU_REPORT,
        variables: {id},
        fetchPolicy: 'network-only'
    })
    .then(result => {
        return result?.data?.LastCPUReport || false
    })
    .catch(() => {
        return false
    });
}

export const saveCustomProduct = async (variables, apolloClient) => {
    const CREATE_PRODUCT = gql`
        mutation saveCustomProduct($id: ID!, $index: String, $product: CustomItem, $category: String) {
            saveCustomProduct(id: $id, index: $index, product: $product, category: $category) {
                itemIndex title unitNumber attributes count type notes images additionalItem {bill labour po_required}
                product { attributes description code price { ...PricingTiersParts } }
            }
        }
        ${PricingTiers_Fragment}
    `;
    const {data} = await apolloClient.mutate({
        mutation: CREATE_PRODUCT,
        variables: variables
    });
    if (data.saveCustomProduct !== null ) {
        return data.saveCustomProduct
    }
}

export const getUnionServices = async (id, variables, apolloClient) => {
    const GET_SERVICES = gql`
        query fetchUnionServices($id: ID!, $item: UnionQuery) {
            fetchUnionServices(id: $id, item: $item) {
                attributes description code 
                price {lp00 lp02 lp04 lp05 lp06 lp08 lp10 lp15 gb1 gb2 wn3 wn4 la3 la4 rmg1 rmg2 rmg3 tw dt hh}
            }
        }
    `;
    return apolloClient.query({
        query: GET_SERVICES,
        variables: {id, item: variables},
        fetchPolicy: 'network-only'
    })
    .then(result => {
        return result?.data?.fetchUnionServices || false
    })
    .catch(() => {
        return false
    });
}
