import React from "react";
//material UI
import { makeStyles } from "@material-ui/core/styles";
//components
import MeasureDoors from "./NewDoors";
import MeasureWindows from "./NewWindow";
import MeasureTrim from "./NewTrim2";
import MeasureShelving from "./NewShelving";
import MeasureHardware from "./NewHardware";
import CustomProduct from "./CustomProduct";
import CustomInstall from "./CustomInstall";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}));

const SpecsTabs = ({context}) => {
    const classes = useStyles();
    const value  = context.state.measureTab;
        return (
            <div className={classes.root}>
                {value === 'door'   && <MeasureDoors context={context} />}
                {value === 'trim'   && <MeasureTrim context={context} />}
                {value === 'window' && <MeasureWindows context={context} />}
                {value === 'shelving' && <MeasureShelving context={context} />}
                {value === 'hardware' && <MeasureHardware context={context} />}
                {value === 'custom_product' && <CustomProduct context={context} />}
                {value === 'custom_install_service' && <CustomInstall context={context} />}
            </div>
        );
}

export default SpecsTabs
