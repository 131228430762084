import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Redirect } from 'react-router'
import { Helmet } from 'react-helmet-async'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TopBar from './common/TopBar';
import { NODE_ENV, REACT_APP_API_HOST } from './envs';

const useStyles = makeStyles(() => ({
    root:{
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },

    paper:{
        marginTop:'24px',
        paddingTop:'24px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '24px',
        maxWidth: '400px',
    },
}));

const Login = (props) => {
    const {forgetPassword} = props
    const apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
    const [redirectTo, setRedirectTo] = useState("");
    const [state, setState] = useState({
        username:'',
        password:'',
        msg: '',
        username_error: '',
        password_error: '',
        snack: false,
        redirect: false,
        forgotPassword: false,
        forgotEmail: ''
    })
    const handleSnackClose = () =>    {
        setState({ 
            ...state,
            snack: false 
        });
    };

    const handleForgot = () => {
        if (!state.forgotEmail) {
            return false;
        }
        const payload= {
            email: state.forgotEmail,
        }

        const options = {
            method: 'post',
            url: apiBaseUrl + 'reset',
            data: { payload },
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(options)
        .then(function (response) {
            if (response.status === 200) {
                setState({
                    ...state,
                    msg: "Check your email to reset your password",
                    snack: true,
                    forgotPassword: false,
                    forgotEmail: ''
                })
            } else {
                setState({
                    ...state,
                    msg: "A problem happened!",
                    snack: true,
                })
            }
        })
    }

    const handleClick = () => {
        const username = state.username;
        setState({
            ...state,
            username_error: state.username === '' ? "Username is required!" : '',
            password_error: state.password === '' ? "Password is required!" : ''
        })
        if (state.username === '' || state.password === '') {
            return;
        }

        const payload= {
            username: state.username,
            password: state.password
        }

        const options = {
            method: 'post',
            url: apiBaseUrl + 'signin',
            data: { payload },
            headers: {
                'Content-Type': 'application/json'
            }
        }

        axios(options)
            .then(function (response) {
                if (response.status === 200) {
                    // TODO: Maybe save them in a global context state object
                    let token = response.data.token
                    let data = parseJwt(token)
                    localStorage.setItem('username', username)
                    localStorage.setItem('token', token)
                    localStorage.setItem('role', data.role)
                    localStorage.setItem('name', data.name)
                    localStorage.setItem('tokenedAt', new Date())
                    localStorage.setItem('expiresAt', data.exp)
                    setState({
                        ...state,
                        msg: "Login successful.",
                        snack: true,
                        redirect: true
                    })
                }
                else {
                    setState({
                        ...state,
                        msg: "Username and Password don't match.",
                        snack: true
                    })
                }
            })
            .catch(function (error) {
                setState({
                    ...state,
                    msg: "A problem occurred! "+ error,
                    snack: true
                })
            });
    }

    const { redirect } = state;
    const classes = useStyles();

    useEffect(() => {
        if (forgetPassword)
            setState({...state, forgotPassword: true})
    }, [state, forgetPassword]);

    useEffect(() => {
        if (redirect && localStorage.getItem("lastHref") !== null) {
            const lastHref = localStorage.getItem("lastHref");
            localStorage.removeItem("lastHref")
            setRedirectTo( <Redirect to={lastHref} />);
        } else if (redirect) {
            setRedirectTo( <Redirect to={'/'} />);
        }
    }, [redirect]);

    
    return (
        <div className={classes.root}>
            <Helmet>
                <title>Riverside Takeoffs :: Login Page</title>
            </Helmet>
            {redirectTo}
            <TopBar isLoggedIn={false} title="Login to Riverside"/>
            {
                state.forgotPassword &&
                <Paper className={classes.paper} elevation={24}>
                    <FormControl fullWidth className={classes.margin}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Forgot Password
                            </Typography>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="email"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange = {(event) => setState({...state, forgotEmail:event.target.value})}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    id="login-btn"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleForgot}
                                    fullWidth>
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    className={classes.button}
                                    fullWidth
                                    onClick={() => setState({...state, forgotPassword: false})}
                                >
                                    Cancel
                                </Button>
                            </Grid>

                        </Grid>
                    </FormControl>
                </Paper>
            }
            {
                !state.forgotPassword &&
                <Paper className={classes.paper} elevation={24}>
                    <FormControl fullWidth className={classes.margin}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Login
                            </Typography>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="name"
                                    label="Username"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange = {(event) => setState({...state, username:event.target.value})}
                                />
                                <TextField
                                    required
                                    id="password"
                                    label="Password"
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    type={"password"}
                                    onChange = {(event) => setState({...state, password:event.target.value})}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    id="login-btn"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleClick}
                                    fullWidth>
                                    Login
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    className={classes.button}
                                    fullWidth
                                    onClick={() => setState({...state, forgotPassword: true})}
                                >
                                    Forgot password
                                </Button>
                            </Grid>

                        </Grid>
                    </FormControl>
                </Paper>
            }
            <Snackbar
                open={state.snack}
                message={state.msg}
                autoHideDuration={4000}
                onClose={handleSnackClose}
            />
        </div>
    );
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export default Login