import React from "react";
import CustomToggleGroup from '../../common/CustomToggleGroup'
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    flex:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    flexLabel: {
        marginRight: '24px',
    },
    flexItem: {

    },
}));

const items = [
    {
        name: 'door_80',
        label: "80 Door",
        default: "2",
        values: {
            '2': 'TWO',
            '3': 'THREE'
        }
    },
    {
        name: 'door_84',
        label: "84 Door",
        default: "3",
        values: {
            '3': 'THREE',
            '4': 'Four'
        }
    },
    {
        name: 'door_96',
        label: "96 Door",
        default: "4",
        values: {
            '4': 'Four'
        }
    }
];

const HingeSpecs = (props) => {
    const {disabled, values = {}, handleFieldChange} = props;
    const classes = useStyles();
    // console.log(values)
    return (
        <React.Fragment>
            {
                items.map((item, i) => (
                    <div key={i} className={classes.flex}>
                        <div className={classes.flexLabel}>
                            <Typography variant="body1">{item.label}</Typography>
                        </div>
                        <div className={classes.flexItem}>
                            <CustomToggleGroup
                                disabled={disabled}
                                items={item.values}
                                value={values[item.name] || item.default}
                                handleChange={ (event, value) => handleFieldChange('extra_specs', item.name)(null, value || '') }
                            />
                        </div>
                    </div>
                ))
            }
        </React.Fragment>
    )
}

export default HingeSpecs