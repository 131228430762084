import React from "react";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomHinge = ({disabled, hasCustomLockset, hasCustomHinge, customHinges, customLockset, setMeasureDoorAttr, hingeCount}) => {
    const hingeLocation  = (i) => {
        return (customHinges && customHinges[i]) || "";
    }
    const handleHingeLocation = (i, e) => {
        let value = customHinges || Array(Number(hingeCount)).fill();
        value = [
            ...value.slice(0,i),
            e.target.value,
            ...value.slice(i+1)
        ]
        setMeasureDoorAttr('customHinges', value)
    }
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            checked={hasCustomLockset}
                            onChange={(event) => setMeasureDoorAttr('hasCustomLockset', event.target.checked || false)}
                            value={true}
                        />
                    }
                    label="Custom Lockset Locations"
                />
            </Grid>
            {
                hasCustomLockset &&
                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        label={`Lockset location`}
                        margin="dense"
                        variant="outlined"
                        type={"number"}
                        fullWidth
                        value={customLockset || ''}
                        onChange={(e) => setMeasureDoorAttr('customLockset', e.target.value)}
                    />
                </Grid>

            }
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            checked={hasCustomHinge}
                            onChange={(event) => setMeasureDoorAttr('hasCustomHinge', event.target.checked || false)}
                            value={true}
                        />
                    }
                    label="Custom Hinge Locations"
                />
            </Grid>
            {
                hasCustomHinge &&
                Array(Number(hingeCount) || 0).fill().map( (num, i) => (
                    <Grid key={i} item xs={12}>
                        <TextField
                            disabled={disabled}
                            label={`Hinge ${i+1}`}
                            margin="dense"
                            variant="outlined"
                            type={"number"}
                            fullWidth
                            value={hingeLocation(i)}
                            onChange={(e) => handleHingeLocation(i, e)}
                        />
                    </Grid>
                ))
            }
        </React.Fragment>
    )
}

export default CustomHinge