import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';
import {Grid, Box, Dialog, DialogTitle, DialogContent, IconButton, Typography, CircularProgress, Slide, Chip} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
// import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// import moment from 'moment'

const CPU_REPORT = gql`
  query CPUReport($id: ID!) {
    CPUReport (id: $id) {
      id description needsAction isResolved createdAt 
      report {
        prices_change {id code category notes}
        discontinued {id code category notes resolvedBy {username} }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CpuReport = ({report, handleClose}) => {
  const classes = useStyles();
  const { loading, data } = useQuery(CPU_REPORT, {
    variables: {id: report}
  });
  if (!report) return "";

  if (loading) return <CircularProgress />
  const {CPUReport} = data || {};
  return (
    <Dialog
        fullScreen={true}
        open={report && true}
        onClose={handleClose}
        TransitionComponent={Transition}
    >
      <DialogTitle variant={'h1'} className={classes.title} id="responsive-dialog-title">
          <Typography variant="h4">
            CPU Report
            {
              CPUReport?.needsAction && 
              <Chip
                label="Needs Action"
                color="secondary"
              />
            }
            {
              CPUReport?.isResolved && 
              <Chip
                label="Resolved"
                color="green"
              />
            }
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {
              CPUReport?.description && 
              <Box mb={3}>
                <Typography variant={"h6"}>{CPUReport?.description}</Typography>
              </Box>
            }
            {
              CPUReport?.report?.prices_change?.length > 0 && 
              <>
                <Box mb={3}>
                  <Typography variant={"h6"}>Price Changes</Typography>
                </Box>
                {
                  CPUReport?.report?.prices_change
                  .filter((elem, index, self) => self.findIndex((t) => {return (t.code === elem.code)}) === index)
                  .map((item, i) => (
                    <Box mb={3} key={i}>
                      <Typography variant={"body1"}>{item.code}</Typography>
                      <Typography variant={"body2"}>{item.notes}</Typography>
                    </Box>
                  ))
                }
              </>
            }
            {
              CPUReport?.report?.prices_change?.length === 0 && 
              <Typography variant={"body1"}>CPU didn't detect any price change on this takeoff.</Typography>
            }
            {
              CPUReport?.report?.discontinued && 
              <>
                <Box mb={3}>
                  <Typography variant={"h6"}>Discontinued Products</Typography>
                </Box>
                {
                  CPUReport?.report?.discontinued
                  .filter((elem, index, self) => self.findIndex((t) => {return (t.code === elem.code)}) === index)
                  .map((item, i) => (
                    <Box mb={3} key={i}>
                      
                      {/* <Typography variant={"body1"}>
                        {
                          item.resolvedBy?.username ?
                          <AssignmentTurnedInIcon /> : <AssignmentLateIcon />
                        }
                        {item.code}
                      </Typography> */}
                      <Typography variant={"body1"}>{item.code}</Typography>
                      <Typography variant={"body2"}>{item.notes}</Typography>
                    </Box>
                  ))
                }
              </>
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default CpuReport;