import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import SpecsType from '../Specs/SpecsType';
import Grid from '@material-ui/core/Grid';
import NewExteriorDoorHardware from './NewExteriorDoorHardware';
import * as Lib from '../lib/lib';
import CustomToggleGroup from '../../common/CustomToggleGroup';

const NewExteriorDoor = ({context, handleSpecChange, disabled}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom={false}>Hardware</Typography>
            </Grid>
            {
                context.state.measures.custom.window.size &&
                <Grid item xs={12}>
                    <NewExteriorDoorHardware
                        context={context}
                        disabled={disabled}
                        door={context.state.measures.custom.window}
                        hardwareOption={context.state.measures.custom.window.hardwareOptions}
                        handleSpecChange={handleSpecChange}
                        setMeasureOpenAttr={context.setMeasureOpenAttr}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={disabled}
                            checked={context.state.measures.custom.window.hasCloser}
                            onChange={(event) =>context.setState({
                                measures :{
                                    ...context.state.measures,
                                    custom: {
                                        ...context.state.measures.custom,
                                        window: {
                                            ...context.state.measures.custom.window,
                                            hasCloser: event.target.checked
                                        }
                                    }
                                }
                            })}
                        />
                    }
                    label="Add Closer"
                />
            </Grid>
            {
                context.state.measures.custom.window.hasCloser &&
                Lib.deepAttributeExists(['closer', 'code'], context.state.specialSpecs.products) &&
                <Grid item xs={12}>
                    <CustomToggleGroup
                        disabled={context.isDoneTakeoff()}
                        items={{
                            '1': 'One',
                            '2': 'Two',
                        }}
                        value={`${context.state.measures.custom.window.closerCount}`}
                        handleChange={(event, value) => context.setMeasureOpenAttr('closerCount', value || 1)}
                    />
                </Grid>
            }
            {
                context.state.measures.custom.window.hasCloser &&
                <Grid item xs={12}>
                    <SpecsType
                        disabled={context.isDoneTakeoff()}
                        label={'Closer'}
                        category={'hardware'}
                        type={'closer'}
                        attributes={['brand', 'style', 'finish']}
                        handleChange={handleSpecChange('hardware','closer')}
                        specs={context.state.specialSpecs.hardware.closer}
                        globalSpecs={context.state.specs.hardware.closer}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default NewExteriorDoor