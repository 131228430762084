import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import MeasureListItemActions from "./MeasureListItemActions";
import ProductState from './ProductState';

import * as Lib from "../lib/lib";

const useStyles = makeStyles((theme) => ({
    avoidBreak: {
        '@media print': {
            display: 'block',
            breakInside: 'avoid',
        }
    },
    measureTrimListItem: {
        justifyContent: 'space-between',
        '@media print': {
            breakInside: 'avoid',
        }
    },
    negativeItem: {
        backgroundColor: theme.palette.secondary[100]
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const uoms = {
    "Lineal Feet"   : "LF",
    "Pieces"        : "PC",
    "Each"          : "EA",
    "Board Feet"    : "BF",
    "Pair"          : "PR",
    "Set"           : "ST",
};

const MeasureCustomItem = ({ type, product, dIndex, context, disabled, cpu }) => {
    const classes = useStyles();
    return (
        <div className={product.count < 0 ? classes.negativeItem : ''}>
            <ListItem
                button
                className={classes.measureTrimListItem}
                onClick={() => context.editMeasureCustomProduct(dIndex, type === 'custom_product' ? 'product' : 'install_service')}
            >
                <Grid alignItems='center' container className={classes.avoidBreak} spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant={ "body2" } gutterBottom>
                            <ProductState cpu={cpu} code={product.product.code} />
                            {product.product.isSpecial && "**"}{context.humanize(product.type)} {product.title} : {product.count} {uoms[product.product.uom] || 'LF'}: {product.product.code}
                            {
                                context.state.isAdditionalOrder && 
                                ` (${
                                    [
                                        product.additionalItem.labour,
                                        product.additionalItem.bill,
                                        product.additionalItem.po_required
                                    ]
                                    .filter (x => x)
                                    .map(x => x && Lib.humanize(x)).join(', ')
                                })`
                            }
                            {product.unitNumber ? ` (Unit ${product.unitNumber})` : ""}
                        </Typography>
                        <Typography variant="caption" gutterBottom>{product.product.description}</Typography>
                        {
                            product.images.length > 0 &&
                            <Typography variant={'caption'}>{product.images.length} IMAGE(S) ATTACHED</Typography>
                        }
                        {
                            product.notes &&
                            <Typography className={classes.ellipsis} variant="caption" gutterBottom>
                                NOTES | {product.notes}
                            </Typography>
                        }
                    </Grid>
                    {
                        !disabled &&
                        <Grid item xs={2}>
                            <MeasureListItemActions 
                                hideCopy 
                                context={context} 
                                category={type === 'custom_product' ? 'custom_products' : 'custom_install_services'} 
                                index={dIndex} 
                            />
                        </Grid>
                    }
                </Grid>
            </ListItem>
            <Divider />
        </div>
    );
}

export default MeasureCustomItem