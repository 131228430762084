import React from "react";
import ImportContext from './ImportContext'
import axios from 'axios';
import * as Lib from './Import/import.lib'
import { NODE_ENV, REACT_APP_API_HOST } from './envs';

export default class ImportProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: [],
            file: {},
            filename: '',
            tab: 'product',
            dialog: false,
            progress: 0,
            importObj: {},
            timer: '',
        }
        this.apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
    }

    componentDidMount() {
        this.fetchDataImports(true)

    }
    componentWillUnmount() {
        this.stopPolling()
    }

    startPolling () {
        this.interval = setInterval(() => {
            if (!this.state.dialog) {
                this.fetchDataImports(true)
            }
        }, 10000);
    }
    stopPolling () {
        clearInterval(this.interval);
        this.interval = null;
    }

    fetchDataImports = (force = false) => {
        const { apolloClient } = this.props
        const dataImportsPromise = Lib.getDataImports(apolloClient, this.state.tab, force)
        dataImportsPromise.then( dataImports => {
            if (dataImports) {
                const inprogressImports = dataImports.filter(file => ['validating', 'importing', 'pending'].includes(file.status))

                if (inprogressImports.length === 0) {
                    this.stopPolling ()
                }
                else if (!this.interval) {
                    this.startPolling()
                }
                this.setState({
                    loading: false,
                    dialog: false,
                    list: dataImports
                })
            }
        })
    }

    doImport = () => {
        let that = this
        if ('name' in this.state.file && this.state.filename) {

            let config = {
                headers: {'Authorization': "bearer " + localStorage.getItem('token')},
            };
            let data = {
                filename: this.state.file.name,
                type: this.state.tab
            }
            axios.post(this.apiBaseUrl+'do-import', data, config)
            .then(function () {
                that.fetchDataImports(true)
            })
            .catch(function (err) {
                console.log(err.message);
            });
        }
    }

    clearImporter = () => {
        clearInterval(this.state.timer)
        this.setState({
            file: {},
            filename: '',
            dialog: false,
            progress: 0,
            importObj: {},
            timer: '',
        })
    }

    render () {
        return (
            <ImportContext.Provider value={{
                state: this.state,
                setState: (obj) => {
                    this.setState(obj)
                },
                addUploadedFile: (file) => {
                    this.setState({
                        files: [...this.state.files, file]
                    })
                },
                humanize: (str) => {
                    return Lib.humanize(str)
                },
                proceed: () => {
                    this.doImport()
                },
                changeTab: (value) => {
                    this.setState({tab: value}, () => {
                        this.fetchDataImports(true)
                    })
                },
                fetchDataImports: (force) => {
                    this.fetchDataImports(force)
                },
                getFilename: (name) => {
                    return name ? name.split('_').pop().slice(0,-4) : 'Unknown'
                },
                clearImporter: () => {
                    this.clearImporter()
                },
                pollServer: () => {
                    if (!this.interval) {
                        this.startPolling()
                    }
                }
            }}>
                {this.props.children}
            </ImportContext.Provider>
        )
    }
}