import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {makeStyles} from "@material-ui/core/styles/index";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(() => ({
    moreIcon: {
        textAlign: 'right',
        '@media print': {
            display: 'none',
        }
    }
}));

const MeasureListItemActions = ({ context, category, index, hideCopy }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleDuplicate = event => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null)
        context.duplicateMeasureItemDialog(category, index);
    };

    const handleDelete = event => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null)
        context.deleteMeasureItemDialog(category, index);
    };

    const handleClose = event => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null)
    };

    return (
        <div className={classes.moreIcon}>
            <IconButton
                aria-label="More"
                aria-owns={open ? 'long-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                    },
                }}
            >
                <MenuItem onClick={handleDelete}>
                    Delete
                </MenuItem>
                {
                    !hideCopy &&
                    <MenuItem onClick={handleDuplicate}>
                        Add Copies
                    </MenuItem>
                }
            </Menu>
        </div>
    );
}

export default MeasureListItemActions