import React, {useState, useContext} from 'react';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TakeoffListContext from "../TakeoffListContext";

const TakeoffListItemActions = React.memo(({takeoffId, status = 'New', isOwner, isNotAdditional}) => {
    const context = useContext(TakeoffListContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <ListItemSecondaryAction>
            <IconButton
                id={'menuIcon-'+takeoffId}
                className={'menuIcon-'+takeoffId}
                aria-owns={open ? 'long-menu' : null}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={'menu-'+takeoffId}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: 200,
                    },
                }}
            >
                <MenuItem key={'clone-'+takeoffId} onClick={
                    (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        context.setState({
                            confirmDialogOpen: true,
                            takeoffAction:{
                                action: "clone",
                                id: takeoffId
                            },
                            anchorEls: {
                                ...context.state.anchorEls,
                                [takeoffId]: null
                            }
                        })
                    }}>
                    Duplicate Takeoff
                </MenuItem>
                <MenuItem key={'clone-specs-'+takeoffId} onClick={
                    (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        context.setState({
                            confirmDialogOpen: true,
                            takeoffAction:{
                                action: "clone-specs",
                                id: takeoffId
                            },
                            anchorEls: {
                                ...context.state.anchorEls,
                                [takeoffId]: null
                            }
                        })
                    }}>
                    Duplicate Specs
                </MenuItem>
                {
                    isNotAdditional &&
                    <MenuItem key={'add-additional-'+takeoffId} onClick={
                        (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            context.setState({
                                confirmDialogOpen: true,
                                takeoffAction:{
                                    action: "add-additional",
                                    id: takeoffId
                                },
                                anchorEls: {
                                    ...context.state.anchorEls,
                                    [takeoffId]: null
                                }
                            })
                        }}>
                        Additional Order
                    </MenuItem>
                }
                {
                    isOwner &&
                    <MenuItem key={'archive-'+takeoffId} onClick={
                        (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            context.setState({
                                confirmDialogOpen: true,
                                takeoffAction:{
                                    action: status === 'archived' ? 'restore' : 'archive',
                                    id: takeoffId
                                },
                                anchorEls: {
                                    ...context.state.anchorEls,
                                    [takeoffId]: null
                                }
                            })
                        }
                    }>
                        {status === 'archived' ? 'Restore' : 'Archive'}
                    </MenuItem>
                }
                {
                    isOwner &&
                    <MenuItem key={'cpu-'+takeoffId} onClick={
                        (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            context.setState({
                                confirmDialogOpen: true,
                                takeoffAction:{
                                    action: "cpu",
                                    id: takeoffId
                                },
                                anchorEls: {
                                    ...context.state.anchorEls,
                                    [takeoffId]: null
                                }
                            })
                        }
                    }>
                        Request CPU
                    </MenuItem>
                }
            </Menu>
        </ListItemSecondaryAction>
    )
})

export default TakeoffListItemActions;