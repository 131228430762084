import React from 'react';
import {Typography, Dialog, Grid, Slide, Link, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import * as Lib from './lib/lib'
import CustomToggleGroup from '../common/CustomToggleGroup';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerDetails = ({ updateCustomer, disabled, customer = {}, customerType, showPricing = false}) => {
    const [dialog, setDialog] = React.useState(false);
    const [pricingTier, setPricingTier] = React.useState((customer?.pricingTier && customer?.pricingTier.toLowerCase()) || '');
    
    const handlePricingTier = (_e, value) => {
        setPricingTier(value);
    }
    const handleSubmit = () => {
        const defaultPricingTier = !customer.defaultPricingTier && customer?.pricingTier;
        const newCustomer = {
            ...customer,
            ...(defaultPricingTier && {defaultPricingTier}),
            pricingTier
        }
        setDialog(false);
        updateCustomer(newCustomer);
    }
    return (
        <React.Fragment>
            <Dialog
                open={dialog}
                onClose={() => setDialog(false)}
                TransitionComponent={Transition}
                aria-labelledby="responsive-dialog-title"
                maxWidth={'md'}
            >
                <DialogTitle id="responsive-dialog-title">
                    Edit Price List {customer.defaultPricingTier && `(Default: ${customer.defaultPricingTier.toUpperCase()})`}
                </DialogTitle>
                <DialogContent>
                    <CustomToggleGroup
                        disabled={disabled}
                        items={{
                            lp00: 'LP',
                            lp05: '-5%',
                            lp10: '-10%',
                            lp15: '-15%',
                        }}
                        value={pricingTier}
                        handleChange={handlePricingTier}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialog(false)}>Cancel</Button>
                    <Button color={'secondary'} href="#" onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        {
            customerType === 'customer' &&
            customer.name &&
            <Grid item xs={12}>
                {
                    showPricing &&
                    <Typography variant="body2">
                        {customer.pricingTier ? `Sales Price: ${Lib.humanizePricingTier(customer.pricingTier || '', true)}` : ''}
                        {"  "}<Link href="#" onClick={() => setDialog(true)}>Edit</Link>
                    </Typography>
                }
                <Typography variant="body2">
                    { customer.name}
                </Typography>

                <Typography variant="body2">
                    {customer.phone ? `Tel: ${customer.phone || ''}` : ''}
                </Typography>
            </Grid>
        }
        {
            customerType === 'customer' &&
            Lib.objectHasAttributes(['address', 'address2', 'city', 'province', 'postalCode', 'country'], customer) &&
            <Grid item xs={12}>
                <Typography variant="body2">
                    {customer.address || ''}
                </Typography>
                <Typography variant="body2">
                    {customer.address2 || ''}
                </Typography>
                <Typography variant="body2">
                    {customer.city ? `${customer.city},` : ''} {/*customer.province ? `${customer.province}, ` : ''*/} {customer.postalCode || ''}
                </Typography>
            </Grid>
        }
        {
            customerType === 'prospect' &&
            Lib.objectHasAttributes(['pricingTier', 'firstname', 'lastname', 'phone'], customer) &&
            <Grid item xs={12}>
                <Typography variant="body2">
                    {customer.pricingTier ? `Sales Price: ${Lib.humanizePricingTier(customer.pricingTier || '', true)}` : ''}
                </Typography>
                <Typography variant="body2">
                    { `${customer.firstname || ''} ${customer.lastname || ''}`}
                </Typography>
                {customer.company && <Typography variant="body2">{customer.company}</Typography>}
                <Typography variant="body2">
                    {customer.phone ? `Tel: ${customer.phone || ''}` : ''}
                </Typography>
            </Grid>
        }
        {
            customerType === 'prospect' &&
            Lib.objectHasAttributes(['address', 'address2', 'city', 'province', 'postalCode', 'country'], customer) &&
            <Grid item xs={12}>
                <Typography variant="body2">
                    {customer.address || ''}
                </Typography>
                <Typography variant="body2">
                    {customer.address2 || ''}
                </Typography>
                <Typography variant="body2">
                    {customer.city ? `${customer.city},` : ''} {/*customer.province ? `${customer.province}, ` : ''*/} {customer.postalCode || ''}
                </Typography>
            </Grid>
        }
        </React.Fragment>
    )
}

export default CustomerDetails;