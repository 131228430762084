import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: theme.spacing(2),
    },
    root: {},
    paper: {},
    hiddenRoot: {},
    hiddenPaper: {
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden"
    },
}));

const LoadingModal = (props) => {
    const {open, error, handleClose} = props;
    const classes = useStyles();
    const [expire, setExpire] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setExpire(open ? true : false);
        }, 30000);
        return () => clearTimeout(timer);
    }, [open]);

    function handleOk() {
        handleClose();
    }

    const showError = expire || error;

    return (
        <Dialog
            open={open}
            disableBackdropClick={false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            BackdropProps={{
                classes: {
                    root: showError ? classes.root : classes.hiddenRoot
                }
            }
            }
            PaperProps ={{
                classes: {
                    root: showError ? classes.paper : classes.hiddenPaper
                }
            }}
        >
            {
                showError ?
                <>
                    <DialogTitle id="confirmation-dialog-title2">Error</DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1">
                            A problem happened, please refresh the app and check if any missing products.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOk} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </> :
                <CircularProgress size={50} color="secondary" className={classes.progress} />
            }
        </Dialog>
    )
};

export default LoadingModal
