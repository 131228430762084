import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import MeasureListItemActions from "./MeasureListItemActions";
import ProductState from './ProductState';
import * as Lib from "../lib/lib";

const useStyles = makeStyles(() => ({
    avoidBreak: {
        '@media print': {
            display: 'block',
            breakInside: 'avoid',
        }
    },
    measureTrimListItem: {
        justifyContent: 'space-between',
        '@media print': {
            breakInside: 'avoid',
        }
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const MeasureListItem = ({ category, object, dIndex, context, cpu }) => {
    const classes = useStyles();
    return (
        <div>
            <ListItem
                button
                className={classes.measureTrimListItem}
                onClick={() => context.editMeasureWindow(dIndex)}
            >
                <Grid alignItems='center' container className={classes.avoidBreak} spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant={ "body2" } gutterBottom>
                            {context.humanize(category)} {object.title || object.itemIndex}
                            {
                                ['window', 'exterior_door'].includes(category) &&
                                `: ${context.humanize(object.size)} ${object.isHigh ? `High ${object.highOpeningLocation ? `(${object.highOpeningLocation})` : ''}` : ''}` 
                            }
                            {
                                context.state.isAdditionalOrder && 
                                ` (${
                                    [
                                        object.additionalItem.labour,
                                        object.additionalItem.bill,
                                        object.additionalItem.po_required
                                    ]
                                    .filter (x => x)
                                    .map(x => x && Lib.humanize(x)).join(', ')
                                })`
                            }
                            {object.unitNumber ? ` (Unit ${object.unitNumber})` : ""}
                        </Typography>
                        
                        <Typography variant="caption" gutterBottom>
                            {
                                ['window', 'archway', 'caps', 'arch_window'].includes(object) &&
                                `${object.width} ${category === 'window' && object.size !== 'custom' ? 'FT' : 'IN'} x ${object.height} ${category === 'window' && object.size !== 'custom' ? 'FT' : 'IN'} ${category === 'arch_window' ? ` - ${Lib.deepAttributeExists(['curve', 'attributes', 'style'], object.trim) || ''}` : ''}`
                            }
                        </Typography>
                        {
                            ['trim', 'hardware']
                            .filter(cat => cat in object && object[cat])
                            .map(cat => {
                                return Object.keys(object[cat])
                                .filter(type =>
                                    object[cat][type] && (
                                        (object[cat][type].code) ||
                                        type === 'double'
                                )) //
                                .map( (type, i) => {
                                    return (
                                        type === 'double' ?
                                        Object.keys(object[cat].double)
                                        .filter(side => object[cat].double[side])
                                        .map(side => (
                                            Object.keys(object[cat].double[side])
                                            .map( (type, i) => (
                                                (
                                                    object[cat].double[side][type] &&
                                                    'code' in object[cat].double[side][type] &&
                                                    object[cat].double[side][type].code) ?
                                                        <Typography key={'hardware-measure-list'+i} variant="body2" gutterBottom>
                                                            <ProductState cpu={cpu} code={object[cat].double[side][type].code} />
                                                            {object[cat].double[side][type].isSpecial ? '**' : ''}{`${ context.humanize(type) } ${object[cat].double[side][type].count } EA x ${ object[cat].double[side][type].code}` }
                                                        </Typography> :
                                                        Array.isArray(object[cat].double[side][type]) &&
                                                        object[cat].double[side][type].map( (item, i) => (
                                                            <Typography key={'hardware-measure-list'+i} variant="body2" gutterBottom>
                                                                <ProductState cpu={cpu} code={item.code} />
                                                                {item.isSpecial ? '**' : ''}{`${context.humanize(type)} ${item.count} EA x ${item.code}`}
                                                            </Typography>
                                                    ))
                                            ))
                                        ))
                                        :
                                        <Typography key={i} variant="body2" gutterBottom>
                                            <ProductState cpu={cpu} code={object[cat][type].code} />
                                            {object[cat][type].isSpecial ? '**' : ''}{`${context.humanize(type)} ${['EA', 'Each'].includes(object[cat][type].uom) ? object[cat][type].count : Math.ceil(object[cat][type].count)} ${category === 'hardware' ? 'EA' : Lib.uoms(object[cat][type].uom)} x ${object[cat][type].code}`}
                                        </Typography>
                                    )
                                })
                            })
                        }
                        {
                            'sheet' in object &&
                            object.sheet &&
                            object.sheet.code &&
                            <Typography variant="body2" gutterBottom>
                                <ProductState cpu={cpu} code={object.sheet.code} />
                                {`Sheet ${object.sheet.code}`}
                            </Typography>
                        }
                        {
                            context.state.installPricingMode !== 'none' &&
                            object.install_service?.map((service, i) => (
                                <Typography key={i} variant="body2" gutterBottom>
                                    {context.humanize(service.code)} {service.description}
                                </Typography>
                            ))
                        }
                        {
                            'hasExtraService' in object &&
                            object.hasExtraService &&
                            <Typography variant="body2" gutterBottom>
                                {object.extraService || 0} Extra install service(s)
                            </Typography>
                        }
                        {
                            object.images &&
                            object.images.length > 0 &&
                            <Typography variant={'caption'}>{object.images.length} IMAGE(S) ATTACHED</Typography>
                        }
                        {
                            object.notes &&
                            <Typography className={classes.ellipsis} variant="caption" gutterBottom>
                                NOTES | {object.notes}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <MeasureListItemActions context={context} category={'windows'} index={dIndex} />
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
        </div>
    )
}

export default MeasureListItem