import {useState, useEffect, useReducer, useContext} from 'react';
import {uoms, humanize, deepAttributeExists, ceilToN} from './lib';
import {TakeoffFormContext} from '../../TakeoffFormContext';

const waste = 1.05;

const initialState = {
    has: {
        doors:      false,
        trim:       false,
        shelving:   false,
    },
    doors:                  {
        doors:      {},
        machine:    {},
        jamb:       {},
        hinge:      {},
        casing:     {},
        doorstop:   {},
        doorstopper:    {},
        lever:          {},
        knob:           {},
    },
    hardware:           {
        'hinge':            {},
    },
    trim:               {
        'baseboard':        {},
        'casing':           {},
        'burlap':           {},
        'cove':             {},
        'handrail':         {},
        'handrail_bracket': {},
        'shim':             {},
        'attic_lid':        {},
        'attic_hatch':      {},
        'attic_foam_tape':  {},
        'doorstop':         {},
        'shoe_moulding':    {},
        'sheet':            {},
        'flat_stock':       {},
        'quarter_round':    {},
        'cleat':            {},
        'shelving':         {},
        'shiplap':          {},
    },
    door_jamb:          {},
    wire_shelving: {},
    shelving:           {
        melamine: {}
    },
    accessories:        {},
    sheet:              {},
    machine:            {},
    extra:              {},
    opening:            {},
    wire_install:       {},
    notes:              [],
    prices:             {
        doors:          0,
        hardware:       0,
        trim:           0,
        door_jamb:      0,
        wire_shelving:  0,
        wire_install:   0,
        shelving:       0,
        accessories:    0,
        sheet:          0,
        extra:          0,
        machine:        0,
        opening:        0,
    },
    opening_count:      0,
    done:            false
};

const shouldRoundedUp = (product) => product && deepAttributeExists(['attributes', 'supplier'], product) === 'Commodity' && ['Lineal Feet', 'Feet'].includes(product.uom)
const priceOf = (obj, tier) => obj && tier in obj ? obj[tier] : 0;

function reducer (state, action) {
    const {type, code, category, subcategory, object, count, value } = action;
    if (!['reset', 'add_notes'].includes(type) && !code ) {
        // console.log(action, 'code is required')
        return state;
    }
    switch (type) {
        case 'edit_object': {
          // if (deepAttributeExists([category], state)){
            const oldCount = state[category][subcategory][code]['count']
            const price = state[category][subcategory][code]['price'];
            const diffPrice = (count - oldCount ) * price;
            // console.log(count, oldCount, diffPrice)
            return {
              ...state,
              [category]: {
                ...state[category],
                [subcategory]: {
                  ...state[category][subcategory],
                  [code]: {
                    ...state[category][subcategory][code],
                    count
                  }
                }
              },
              prices: {
                ...state.prices,
                [category]: state.prices[category] + diffPrice
              }
            };
          // }
        }
        case 'add_object': {
            let new_state = state;
            const pCategory         = subcategory ? state[category][subcategory] || {} : state[category] || {};
            const old_total_count   = pCategory && code in pCategory ? (pCategory[code].count || 0) : 0;
            const total_count       = pCategory && code in pCategory ? (pCategory[code].count || 0) + Number(count) : Number(count);
            new_state = {
                ...new_state,
                has:    {
                    ...new_state.has,
                    [category]: true
                },
                [category]: {
                    ...new_state[category],
                    ...(
                        subcategory ? {
                            [subcategory] : {
                                ...new_state[category][subcategory],
                                [code]: {
                                    ...object,
                                    count: total_count
                                }
                            }
                        } : {
                            [code]: {
                                ...object,
                                count: total_count
                            }
                        }
                    )
                },
                ...(
                    category === 'opening' &&
                    {opening_count: state.opening_count + count}
                )
            };
            const newPrice     = ['Pair', 'PR'].includes(object.uom) ? (Number(object.price) * Math.ceil((total_count - old_total_count) / 2)) : Number(count * object.price)
            // if (subcategory === 'hinge')
            //     console.log(category, subcategory, old_total_count, newPrice)
            let category_price = new_state.prices[category] + newPrice;
            if (['wire_install', 'wire_shelving'].includes(category)) {
                category_price = Object.keys(new_state[category]).reduce((acc, cur) => {
                    return acc + (Math.ceil(new_state[category][cur].count || 0 ) * new_state[category][cur].price);
                }, 0);
            }
            new_state = {
                ...new_state,
                prices: {
                    ...state.prices,
                    [category]: category_price
                }
            }
            return new_state;
        }
        case 'add_notes':
            return {
                ...state,
                notes: [...state.notes, value]
            }
        case 'done':
          return {
            ...state,
            done: true
          }
        case 'reset':
            // console.log('reset')
            return {
                ...initialState
            }
        default:
            throw new Error();
    }
}

const useTakeoffParser = () => {
    const context = useContext(TakeoffFormContext);
    const tier = context.getPricingTier();
    const productUpdated = context.state.productUpdated;

    const { doors, windows: openings, shelving, trim, hardware, custom_products = [], custom_install_services = [], installPricingMode, notes} = context.state;
    const specsNotes = context.state.specs.notes;
    const door_prep = context.state.specs.door_treatment.prep || '';
    const opening_service = context.state.specs.products.opening_service || {};
    const [state, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(true);
    // const [counter, setCounter] = useState(0);
    // const [step, setStep] = useState('');

    useEffect(() => {
      dispatch({type: 'reset'});
        if (notes) {
          dispatch({
              type: 'add_notes',
              value: {
                  index: 'General',
                  notes
              }
          });
      }
      if (specsNotes) {
          Object.keys(specsNotes).forEach(type => dispatch({
              type: 'add_notes',
              value: {
                  index: `${humanize(type)} Specs`,
                  notes: specsNotes[type]
              }
          }))
      }
      // Shelving
      shelving.forEach( shelf => {
          if (shelf.notes || (shelf.images && shelf.images.length > 0)){
              dispatch({
                  type: 'add_notes',
                  value: {
                      index: `Shelf ${shelf.title || shelf.itemIndex}`,
                      notes: shelf.notes || '',
                      images: shelf.images || []
                  }
              });
          }
          if (['wire', 'organizer', 'accessories'].includes(shelf.type) ) {
              shelf.install_service?.filter(service => service && (
                ['organizer', 'accessories'].includes(shelf.type) || 
                installPricingMode !== 'none' || 
                shelf.addedInstall
              ))
              .forEach(service => {
                  dispatch({
                      type: 'add_object',
                      category: shelf.type === 'wire' ? 'wire_install' : 'extra',
                      code: service.code,
                      count: (['Organizer Accessories', 'Organizer'].includes(service.attributes.type) ? Number(service.count) : Number(shelf.product.count)) || 1,
                      object: {
                          description: service.description,
                          uom: uoms(service.uom),
                          price: priceOf(service.price, tier),
                      }
                  });
              })
          } 
          if (['wood', 'wire', 'organizer'].includes(shelf.type)) {
              dispatch({
                  ceil: shelf.type === 'wire',
                  type: 'add_object',
                  category: shelf.type === 'wire' ? 'wire_shelving' : 'shelving',
                  subcategory: shelf.type === 'wire' ? null : shelf.type === 'organizer' ? 'organizer' : 'melamine',
                  code: shelf.product.code,
                  count: Number(shelf.product.count),
                  object: {
                      description: shelf.product.description,
                      uom: uoms(shelf.product.uom),
                      price: priceOf(shelf.product.price, tier),
                  }
              })
          }
          if ('trim' in shelf && Object.keys(shelf.trim).length > 0) {
              Object.keys(shelf.trim)
              .filter( type => shelf.trim[type] && shelf.trim[type].code )
              .forEach((type) => {
                  const trimLength = shouldRoundedUp(shelf.trim[type]) ? Number(deepAttributeExists(['attributes', 'length'], shelf.trim[type])) || 0 : 0
                //   console.log(trimLength)
                  const count = shelf.trim[type].uom &&
                              shelf.trim[type].uom === 'Lineal Feet' &&
                              deepAttributeExists(['attributes', 'supplier'], shelf.trim[type]) === 'Mill'
                                  ? Math.ceil(Number(shelf.trim[type].count) * waste) : Number(shelf.trim[type].count);
                  dispatch({
                      type: 'add_object',
                      category: 'shelving',
                      subcategory: type === 'd4s' ? 'cleat' : type,
                      code: shelf.trim[type].code,
                      count,
                      object: {
                          description: shelf.trim[type].description,
                          uom:         uoms(shelf.trim[type].uom),
                          price:       priceOf(shelf.trim[type].price, tier),
                          ...(trimLength && trimLength !== 'n/a' && {trimLength})
                      }
                  })
              })
          }
          if ('hardware' in shelf && Object.keys(shelf.hardware).length > 0) {
              Object.keys(shelf.hardware)
              .forEach((type) => {
                  if( Array.isArray(shelf.hardware[type]) ) {
                        shelf.hardware[type]
                        .filter( item => item && item.code)
                        .forEach( item => {
                            const count = Number(item.count);
                            dispatch({
                                type: 'add_object',
                                category: 'shelving',
                                subcategory: type,
                                code: item.code,
                                count,
                                object: {
                                    description: item.description,
                                    uom:         uoms(item.uom, 'EA'),
                                    price:       priceOf(item.price, tier),
                                }
                            })
                        })
                  } else if (shelf.hardware[type]?.code){
                      const count = Number(shelf.hardware[type].count);
                      dispatch({
                          type: 'add_object',
                          category: 'shelving',
                          subcategory: type,
                          code: shelf.hardware[type].code,
                          count,
                          object: {
                              description: shelf.hardware[type].description,
                              uom:         uoms(shelf.hardware[type].uom),
                              price:       priceOf(shelf.hardware[type].price, tier),
                          }
                      })
                  }
              })
          }
            if ('wallDesigns' in shelf && Object.keys(shelf.wallDesigns).length > 0) {
                for (let wall in shelf.wallDesigns) {
                    if (shelf.wallDesigns[wall]?.designs?.length > 0) {
                        for (let design of shelf.wallDesigns[wall]?.designs) {
                            if (design?.product?.code) {
                                const count = Number(design.product.count);
                                dispatch({
                                    type: 'add_object',
                                    category: 'shelving',
                                    subcategory: 'organizer',
                                    code: design.product.code,
                                    count,
                                    object: {
                                        description: design.product.description,
                                        uom:         uoms(design.product.uom, 'EA'),
                                        price:       priceOf(design.product.price, tier),
                                    }
                                })
                            }
                        }
                    }
                }
            }
            
          if ('accessories' in shelf && shelf.accessories.length > 0) {
            shelf.accessories
            .filter( i => i && i.code)
            .forEach((accessory) => {
                const count = Number(accessory.count);
                dispatch({
                    type: 'add_object',
                    category: 'shelving',
                    subcategory: 'accessory',
                    code: accessory.code,
                    count,
                    object: {
                        description: accessory.description,
                        uom:         uoms(accessory.uom),
                        price:       priceOf(accessory.price, tier),
                    }
                })
            })
        }
      });
     
      // Add Shims
      if (
          deepAttributeExists(['shim', 'code'], context.state.specs.products) &&
          doors.length > 0
      ) {
          const shim = context.state.specs.products.shim;
          dispatch({
              type: 'add_object',
              category: 'trim',
              subcategory: 'shim',
              code: shim.code,
              count: Math.ceil(doors.length / 12),
              object: {
                  description: shim.description,
                  uom: uoms(shim.uom),
                  price: priceOf(shim.price, tier),
              }
          });
      }
      // Doors
      doors.filter(door => door).reduce((rv, x) => {
          if (x.notes || (x.images && x.images.length > 0)){
              dispatch({
                  type: 'add_notes',
                  value: {
                      index: `Door ${x.title || x.itemIndex}`,
                      notes: x.notes || '',
                      images: x.images || []
                  }
              });
          }
          if (
              installPricingMode !== 'none' &&
              x.install_service?.length > 0
          ) {
              x.install_service
              .filter(service => service)
              .forEach(service => {
                  dispatch({
                      type: 'add_object',
                      category: 'extra',
                      code: service.code,
                      count: Number(service.count),
                      object: {
                          description: service.description,
                          uom: uoms(service.uom),
                          price: priceOf(service.price, tier),
                      }
                  });
              })
          }
          if (
              ['u-channel', 'machined'].includes(door_prep) &&
              'prehang_service' in x &&
              x.prehang_service
              // deepAttributeExists(['code'], x.prehang_service)
          ) {
              x.prehang_service.forEach(service => {
                  dispatch({
                      type: 'add_object',
                      category: 'doors',
                      subcategory: 'machine',
                      code: service.code,
                      count: service.count,
                      object: {
                          description: service.description,
                          uom: uoms(service.uom),
                          price: priceOf(service.price, tier),
                      }
                  });
              });
          }
          if (
              installPricingMode === 'contract_pricing' &&
              'price' in opening_service &&
              'opening_service' in x &&
              (
                  (x.opening_service && x.opening_service.length > 0) ||
                  x.hasExtraService
              )
          ) {
              if (x.hasExtraService) {
                  dispatch({
                      type: 'add_object',
                      category: 'opening',
                      code: 'extra_opening_install',
                      count: Number(x.extraService || 0),
                      object: {
                          price:       priceOf(opening_service.price, tier),
                      }
                  })
              }
              x.opening_service.forEach(service => {
                    const count = (x.isDouble ? 2 : 1) * (['pocket_door', 'barn_door'].includes(service) ? 2 : 1);
                    dispatch({
                            type: 'add_object',
                            category: 'opening',
                            code: service,
                            count,
                            object: {
                                price:       priceOf(opening_service.price, tier),
                            }
                    })
              })
          }
          dispatch({
              type: 'add_object',
              category: 'doors',
              subcategory: 'doors',
              code: x['code'],
              count: x.isDouble ? 2 : 1,
              object: {
                    description: x.description,
                    uom:         "EA",
                    price:       priceOf(x.price, tier),
                    width:      x.width,
                    height:      deepAttributeExists(['attributes', 'height'], x)
              }
          })
          if ('sheet' in x && x.sheet && Object.keys(x.sheet).length > 0) {
              if (Object.keys(x.sheet).length > 0 && 'code' in x.sheet && x.sheet.code) {
                  dispatch({
                      type: 'add_object',
                      category: 'doors',
                      subcategory: 'sheet',
                      code: x.sheet.code,
                      count: Number(x.sheet.count),
                      object: {
                          description: x.sheet.description,
                          uom:         "PCS",
                          price:       priceOf(x.sheet.price, tier),
                      }
                  })
              }
          }
          if (Object.keys(x.hardware).length > 0 ) {
              Object.keys(x.hardware)
              .filter( hardware => hardware === 'double' || deepAttributeExists(['code'], x.hardware[hardware]))
              .forEach( hardware => {
                  if (x.hardware[hardware].code) {
                      const count = Number(x.hardware[hardware].count);
                      dispatch({
                          type: 'add_object',
                          category: 'doors',
                          subcategory: hardware,
                          code: x.hardware[hardware].code,
                          count,
                          object: {
                              description: x.hardware[hardware].description,
                              uom:         uoms(x.hardware[hardware].uom, 'EA'),
                              price:       priceOf(x.hardware[hardware].price, tier),
                          }
                      })
                  }
                  else if (hardware === 'double') {
                      Object.keys(x.hardware[hardware]).forEach(side => {
                          Object.keys(x.hardware[hardware][side])
                          // .filter(type => x.hardware[hardware][side][type])
                          .forEach(type => {
                              // console.log(side, type, x.hardware[hardware][side][type]);
                              if (Array.isArray(x.hardware[hardware][side][type])) {
                                  x.hardware[hardware][side][type]
                                  .filter(item => item && 'code' in item && item.code)
                                  .forEach( item => {
                                      const count = Number(item.count);
                                      dispatch({
                                          type: 'add_object',
                                          category: 'doors',
                                          subcategory: type,
                                          code: item.code,
                                          count,
                                          object: {
                                              description: item.description,
                                              uom:         uoms(item.uom, 'EA'),
                                              price:       priceOf(item.price, tier)
                                          }
                                      })
                                  })
                              } else {
                                  const item = x.hardware[hardware][side][type];
                                  if (item && 'code' in item && item.code) {
                                      const count = Number(item.count);
                                      dispatch({
                                          type: 'add_object',
                                          category: 'doors',
                                          subcategory: type,
                                          code: item.code,
                                          count,
                                          object: {
                                              description: item.description,
                                              uom:         uoms(item.uom, 'EA'),
                                              price:       priceOf(item.price, tier)
                                          }
                                      })
                                  }
                              }
                          })
                      })
                  }
              })
          }
          if (Object.keys(x.trim).length > 0 ) {
              Object.keys(x.trim)
              .filter( trim => deepAttributeExists(['code'], x.trim[trim]))
              .forEach( trim => {
                  if (x.trim[trim].code) {
                        const trimLength = shouldRoundedUp(x.trim[trim]) ? Number(deepAttributeExists(['attributes', 'length'], x.trim[trim])) || 0 : 0
                        const count =
                            x.trim[trim].uom &&
                            x.trim[trim].uom  === 'Lineal Feet' &&
                            deepAttributeExists(['attributes', 'supplier'], x.trim[trim]) === 'Mill'
                                ? Math.ceil(Number(x.trim[trim].count) * waste) : Number(x.trim[trim].count);
                        dispatch({
                            type: 'add_object',
                            category: (door_prep === 'u-channel' && trim === 'casing') || trim === 'jamb' ? 'doors' : 'trim',
                            subcategory:  trim,
                            code: x.trim[trim].code,
                            count,
                            object: {
                                description: x.trim[trim].description,
                                uom:         uoms(x.trim[trim].uom, 'LF'),
                                price:       priceOf(x.trim[trim].price, tier),
                                ...(trimLength && trimLength !== 'n/a' && {trimLength})
                            }
                        })
                  }
              })
          }
          return rv;
      }, {});

      // Windows trims
      openings.forEach( (opening) => {
            if (opening.notes || (opening.images && opening.images.length > 0)){
                    dispatch({
                            type: 'add_notes',
                            value: {
                                index: `${humanize(opening.type)} ${opening.title || opening.itemIndex}`,
                                notes: opening.notes || '',
                                images: opening.images || []
                            }
                    });
            }
          if (
              installPricingMode !== 'none' &&
              opening.install_service?.length > 0
          ) {
              opening.install_service
              .filter(service => service)
              .forEach(service => {
                  dispatch({
                      type: 'add_object',
                      category: 'extra',
                      code: service.code,
                      count: service.count,
                      object: {
                          description: service.description,
                          uom: uoms(service.uom),
                          price: priceOf(service.price, tier),
                      }
                  });
              })
          }
          if (
              installPricingMode === 'contract_pricing' &&
              'price' in opening_service &&
              'opening_service' in opening &&
              (
                  opening.opening_service?.length > 0 ||
                  opening.hasExtraService
              )
          ) {
              if (opening.hasExtraService) {
                  dispatch({
                      type: 'add_object',
                      category: 'opening',
                      code: 'extra_opening_install',
                      count: Number(opening.extraService || 0),
                      object: {
                          price:       priceOf(opening_service.price, tier),
                      }
                  })
              }
              opening.opening_service.forEach(service => {
                  dispatch({
                      type: 'add_object',
                      category: 'opening',
                      code: service,
                      count: opening.type === 'archway' && opening.width > 192 ? 2 : 1,
                      object: {
                          price:       priceOf(opening_service.price, tier),
                      }
                  })
              })
          }

          if ('trim' in opening && Object.keys(opening.trim).length > 0) {
              Object.keys(opening.trim)
              .filter(type => opening.trim[type])
              .forEach( (type) => {
                  if (Object.keys(opening.trim[type]).length > 0 && 'code' in opening.trim[type] && opening.trim[type].code) {
                      const trimLength = shouldRoundedUp(opening.trim[type]) ? Number(deepAttributeExists(['attributes', 'length'], opening.trim[type])) || 0 : 0
                      const count =
                          opening.trim[type].uom &&
                          opening.trim[type].uom === 'Lineal Feet' &&
                          deepAttributeExists(['attributes', 'supplier'], opening.trim[type]) &&
                          opening.trim[type].attributes.supplier === 'Mill'
                              ? Math.ceil(Number(opening.trim[type].count) * waste) :
                              Number(opening.trim[type].count);
                      dispatch({
                          type: 'add_object',
                          category: 'trim',
                          subcategory: type === "archway_header" ? /jamb/.test(opening.headerMaterial) ? 'jamb' : opening.headerMaterial : type,
                          code: opening.trim[type].code,
                          count,
                          object: {
                              description: opening.trim[type].description,
                              uom:         uoms(opening.trim[type].uom, 'LF'),
                              price:       priceOf(opening.trim[type].price, tier),
                              ...(trimLength && trimLength !== 'n/a' && {trimLength})
                          }
                      })
                  }
              })
          }
          if ('hardware' in opening && Object.keys(opening.hardware).length > 0) {
              Object
              .keys(opening.hardware)
              .filter( type => type === 'double' || deepAttributeExists(['code'], opening.hardware[type]))
              .forEach( type => {
                  // console.log(type)
                  if (Object.keys(opening.hardware[type]).length > 0 && 'code' in opening.hardware[type] && opening.hardware[type].code) {
                      const count = Number(opening.hardware[type].count);
                      dispatch({
                          type: 'add_object',
                          category: type === 'handrail_bracket' ? 'trim' : 'doors',
                          subcategory: type,
                          code: opening.hardware[type].code,
                          count,
                          object: {
                              description: opening.hardware[type].description,
                              uom:         uoms(opening.hardware[type].uom, 'EA'),
                              price:       priceOf(opening.hardware[type].price, tier),
                          }
                      })
                  } else if (type === 'double') {
                      Object
                      .keys(opening.hardware[type])
                      .forEach(side => {
                          // console.log(opening.hardware[type][side])
                          Object.keys(opening.hardware[type][side])
                          .filter(hardware => opening.hardware[type][side][hardware])
                          .forEach(hardware => {
                              // console.log(side, type, x.hardware[type][side][hardware]);
                              if (Array.isArray(opening.hardware[type][side][hardware])) {
                                  opening.hardware[type][side][hardware]
                                  .filter(item => item && 'code' in item && item.code)
                                  .forEach( item => {
                                      const count = Number(item.count);
                                      dispatch({
                                          type: 'add_object',
                                          category: 'doors',
                                          subcategory: hardware,
                                          code: item.code,
                                          count,
                                          object: {
                                              description: item.description,
                                              uom:         uoms(item.uom, 'EA'),
                                              price:       priceOf(item.price, tier)
                                          }
                                      })
                                  })
                              } else {
                                  const item = opening.hardware[type][side][hardware];
                                  if (item && 'code' in item && item.code) {
                                      const count = Number(item.count);
                                      dispatch({
                                          type: 'add_object',
                                          category: 'doors',
                                          subcategory: hardware,
                                          code: item.code,
                                          count,
                                          object: {
                                              description: item.description,
                                              uom:         uoms(item.uom, 'EA'),
                                              price:       priceOf(item.price, tier)
                                          }
                                      })
                                  }
                              }
                          })
                      })
                  }
              })
          }
          
          if ('sheet' in opening && opening.sheet && Object.keys(opening.sheet).length > 0) {
              if (Object.keys(opening.sheet).length > 0 && 'code' in opening.sheet && opening.sheet.code) {
                  dispatch({
                      type: 'add_object',
                      category: 'trim',
                      subcategory: 'sheet',
                      code: opening.sheet.code,
                      count: Number(opening.sheet.count),
                      object: {
                          description: opening.sheet.description,
                          uom:         "PCS",
                          price:       priceOf(opening.sheet.price, tier),
                      }
                  })
              }
          }
      });
      // Trim
      trim
      .filter(item => deepAttributeExists(['product', 'code'], item))
      .forEach( item => {
          if (item.notes || (item.images && item.images.length > 0)){
              dispatch({
                  type: 'add_notes',
                  value: {
                      index: `${humanize(item.type)} ${item.title || item.itemIndex}`,
                      notes: item.notes || '',
                      images: item.images || []
                  }
              });
          }
          if (
              installPricingMode !== 'none' &&
              item.install_service?.length > 0
          ) {
              item.install_service
              .filter(service => service)
              .forEach(service => {
                  dispatch({
                      type: 'add_object',
                      category: 'extra',
                      code: service.code,
                      count: service.count,
                      object: {
                          description: service.description,
                          uom: uoms(service.uom),
                          price: priceOf(service.price, tier),
                      }
                  });

              })
          }
          const trimLength = shouldRoundedUp(item.product) ? Number(deepAttributeExists(['attributes', 'length'], item.product)) || 0 : 0
          dispatch({
              type: 'add_object',
              category: 'trim',
              subcategory: item.type,
              code: item.product.code,
              count: Number(item.count),
              object: {
                  description: item.product.description,
                  uom:         uoms(item.product.uom, 'LF'),
                  price:       priceOf(item.product.price, tier),
                  ...(trimLength && trimLength !== 'n/a' && {trimLength})
              }
          })
      });

      // Add measure hardware
      hardware
      .filter(item => deepAttributeExists(['product', 'code'], item))
      .forEach( item => {
          if (item.notes || (item.images && item.images.length > 0)){
              dispatch({
                  type: 'add_notes',
                  value: {
                      index: `${humanize(item.type)} ${item.title || item.itemIndex}`,
                      notes: item.notes || '',
                      images: item.images || []
                  }
              });
          }
          const count = Number(item.count);
          dispatch({
              type: 'add_object',
              category: item.type === 'handrail_bracket' ? 'trim' : 'doors',
              subcategory: item.type,
              code: item.product.code,
              count,
              object: {
                  description: item.product.description,
                  uom:         uoms(item.product.uom, 'EA'),
                  price:       priceOf(item.product.price, tier),
              }
          })
      });

    // Add custom products
    custom_products
        .filter(item => deepAttributeExists(['product', 'code'], item))
        .forEach( item => {
            if (item.notes || (item.images && item.images.length > 0)){
                dispatch({
                    type: 'add_notes',
                    value: {
                        index: `${humanize(item.type)} ${item.title || item.itemIndex}`,
                        notes: item.notes || '',
                        images: item.images || []
                    }
                });
            }
            const count = Number(item.count);
            const {category, type} = item.product.attributes;
            if (category) {
                dispatch({
                    type: 'add_object',
                    category: category?.toLowerCase() === 'door' ||  category?.toLowerCase() === 'hardware' ? 'doors' : category?.toLowerCase(),
                    subcategory: type?.toLowerCase() || 'custom',
                    code: item.product.code,
                    count,
                    object: {
                        description: item.product.description,
                        uom:         uoms(item.product.uom, 'EA'),
                        price:       priceOf(item.product.price, tier),
                    }
                })
            }
        });
        custom_install_services
            .filter(service => service)
            .forEach(service => {
                dispatch({
                    type: 'add_object',
                    category: 'extra',
                    code: service.product.code,
                    count: service.count,
                    object: {
                        description: service.product.description,
                        uom: uoms(service.product.uom, 'EA'),
                        price: priceOf(service.product.price, tier),
                    }
                });
            })


      setLoading(false);
      return () => dispatch({type: 'reset'});
    }, [
        productUpdated,
        installPricingMode,
        door_prep,
        tier
    ]);

    useEffect(()=>{
      const total = !loading && Object.keys(state.prices).reduce((sum,key) => sum + parseFloat(state.prices[key]||0),0);
      if (total > 0) {
        ['doors', 'trim', 'shelving']
        .filter(category => state && category in state)
        .forEach(category => {
          Object.keys(state[category]).forEach(type => {
            Object.keys(state[category][type])
            .filter(code => type && 'trimLength' in state[category][type][code])
            .forEach(code => {
              if (state[category][type][code]['count'] % state[category][type][code]['trimLength']) {
                dispatch({
                  type: 'edit_object',
                  category,
                  subcategory: type,
                  code,
                  count: ceilToN(state[category][type][code]['count'], state[category][type][code]['trimLength']),
                })
                // console.log(code, state[category][type][code]['count'], state[category][type][code]['trimLength'])
              }
            })
          })
        });
      }
      // console.log(loading, state)
    }, [state, loading])

    return [state, loading];
}

export default useTakeoffParser;