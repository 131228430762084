import React, {useEffect} from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from "@material-ui/core";

import CustomToggleGroup from '../../common/CustomToggleGroup'

const useStyles = makeStyles(() => ({
    paper:{
        padding:'16px',
    },
}));

const AdditionalItem = ({title, additionalItem, disabled, onItemChange}) => {
    const classes = useStyles();
    const handleChange = (field, value) => {
        onItemChange({
            ...additionalItem,
            [field]: value
        })
    }
    const body = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">{title || "Additional Item"}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Labour By:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                foreman:    'Foreman',
                                technician: 'Technician',
                                trimmer:    'Trimmer',
                            }}
                            value={additionalItem.labour || ''}
                            handleChange={ (event, value) => handleChange('labour', value || '')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Bill to:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                customer:   'Cust',
                                trimmer:    'Trim',
                                no_charge:  'No Charge',
                                contract:   'Contract',
                            }}
                            value={additionalItem.bill || ''}
                            handleChange={ (event, value) => handleChange('bill', value || '')}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">PO Required:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                customer:   'Customer',
                                site:       'Site',
                                none:       'None',
                            }}
                            value={additionalItem.po_required || ''}
                            handleChange={ (event, value) => handleChange('po_required', value || '')}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    useEffect(() => {
        if (!(Object.keys(additionalItem).length === 0 && additionalItem.constructor === Object)) {
            onItemChange(additionalItem)
        }
    }, [])
    
    return (
        title ? 
        body :
        <Grid item xs={12}>
            <Paper className={classes.paper} elevation={4}>
                {body}
            </Paper>
        </Grid>
    )
}

export default AdditionalItem