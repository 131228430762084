import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {usePrevious} from '../../common/hooks'

const useStyles = makeStyles((theme) => ({
    icon:{
        marginRight: '8px',
        color: theme.palette.secondary[400]
    },
    list: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
        margin: '8px'
    }
}));

const AvailableDimensions = (props) => {
    const { context, category, type, specs = null } = props
    const prevSpecs = usePrevious(specs);
    const [state, setState] = useState({
        open: false,
        dimensions: []
    })

    const classes = useStyles();

    useEffect(() => {
        getDimensions();
    }, []);

    useEffect(() => {
        if (specs !== prevSpecs) {
            getDimensions();
        }
    }, []);
    
    const getDimensions = () => {
        let dimensionsPromise = context.getAvailableDimensions(category, type, specs);
        dimensionsPromise.then(dimensions => {
            if (dimensions) {
                let result = dimensions.map( line => {
                    return Object.keys(line)
                    .filter(attribute => line[attribute])
                    .map(attribute => line[attribute])
                })
                setState({...state, dimensions: result})
            }
        })
    };

    const handleClick = () => {
        setState(state => ({ 
            ...state, 
            open: !state.open 
        }));
    };

    const interpretDimensions = line => Object.keys(line).filter(attr => line[attr] !== 'n/a').map(attribute => `${line[attribute]}, `).join(' ').slice(0,-2);
    
    if (state.dimensions.length === 0) return '';
    return (
        <Grid item xs={12} className={classes.list}>
            <ListItem button onClick={handleClick}>
                <ListItemText primary="Dimensions/Properties" />
                {state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={state.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        state.dimensions.map ((dimension, i) => {
                            return dimension && (
                                <ListItem key={i}>
                                    <ListItemText primary={interpretDimensions(dimension)} />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Collapse>
        </Grid>
    )
}


export default AvailableDimensions