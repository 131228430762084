import React, { useRef, useEffect, useContext } from "react";
import {makeStyles} from "@material-ui/core/styles/index";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { FormControl, FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import UserFormContext from '../UserFormContext'
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { gql, useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "16px",
        paddingLeft: "16px",
        paddingRight: "16px",
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    appBar: {
        position: "relative"
    },
    flex: {
        flex: 1
    },
    paper: {
        padding: "16px"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
    input: {
        color: 'white',
        '::before':{
            backgroundColor: 'white',
            '::hover':{
                backgroundColor:'white',
            },
        },

    },

    'input::before':{
        backgroundColor: 'white',
    },

    closeButton: {
        marginRight: -12
    },
    deleteGrid: {
        textAlign: 'right',
        margin: '16px'
    }
}));

const WAIT_INTERVAL = 1000;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const MANAGERS_QUERY = gql`
    query AllManagers{
        allManagers {
            id username name role
        }
    }
`;
const FOREMEN_QUERY = gql`
    query AllForemen{
        allForemen {
            id username name role
        }
    }
`;

const UserForm = (props) => {
    const context = useContext(UserFormContext);
    const allManagers = useQuery(MANAGERS_QUERY);
    const allForemen = useQuery(FOREMEN_QUERY);
    const classes = useStyles();
    let timer = null;

    const nameInput = useRef(null)
    useEffect(() => {
        nameInput.current.focus();
    }, [])

    let managers = [];
    let foremen = [];
    if (allManagers && 'allManagers' in allManagers && allManagers.allManagers) {
        managers = allManagers.allManagers
    }
    if (allForemen && 'allForemen' in allForemen && allForemen.allForemen) {
        foremen = allForemen.allForemen
    }
    useEffect(() => {
        // setLabelWidth(90);
    }, []);

    const handleChange = (field, value, context) => {
        context.setState({
            [field]: value
        })
        clearTimeout(timer);

        timer = setTimeout(() => {
            context.validateUnique(field, value)
        }, WAIT_INTERVAL);
    }

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    return (
        <Grid container spacing={0} className={classes.root}>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom={false}>
                                User Information
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.margin}>
                                <TextField
                                    // id="name"
                                    label="Name"
                                    placeholder="Enter the name"
                                    margin="dense"
                                    variant="outlined"
                                    inputRef={nameInput}
                                    value={context.state.name || ''}
                                    onChange={(event) => context.setState({name: event.target.value})}
                                    autoComplete={"off"}
                                />
                            </FormControl>
                        </Grid>
                        {
                            (!context.state.isProfile ) &&
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    className={classes.margin}
                                    error={
                                        Object.keys(context.state.validation.email).length > 0 &&
                                        !context.state.validation.email.valid
                                    }
                                >
                                    <TextField
                                        // id="email"
                                        label="Email"
                                        placeholder="Enter the email"
                                        margin="dense"
                                        variant="outlined"
                                        value={context.state.email || ''}
                                        onChange={(event) => handleChange('email', event.target.value, context)}
                                        autoComplete={"off"}
                                    />
                                    {
                                        Object.keys(context.state.validation.email).length > 0 &&
                                        !context.state.validation.email.valid &&
                                        <FormHelperText id="name-error-text">{context.state.validation.email.message}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                        }
                        {
                            (!context.state.isProfile ) &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel
                                        htmlFor="warhouse-location"
                                    >
                                        Warehouse Location
                                    </InputLabel>
                                    <Select
                                        value={context.state.warehouse || ''}
                                        onClick={(event) => context.setState({warehouse: event.target.value })}
                                        input={
                                            <OutlinedInput
                                                labelWidth={150}
                                                name="type"
                                                id="warhouse-location"
                                            />
                                        }
                                    >
                                        <MenuItem value=''>{""}</MenuItem>
                                        <MenuItem value='TOWaterloo'>Waterloo</MenuItem>
                                        <MenuItem value='TOLondon'>London</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.margin}>
                                <TextField
                                    // id="phone"
                                    label="Phone"
                                    placeholder="Enter the phone"
                                    margin="dense"
                                    variant="outlined"
                                    value={context.state.phone || ''}
                                    onChange={(event) => context.setState({phone: event.target.value})}
                                    autoComplete={"off"}
                                />
                            </FormControl>
                        </Grid>
                        {
                            (!context.state.isProfile || context.state.role === 'admin') &&
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    className={classes.margin}
                                    error={
                                        Object.keys(context.state.validation.username).length > 0 &&
                                        !context.state.validation.username.valid
                                    }
                                >
                                    <TextField
                                        // id="username"
                                        label="Username"
                                        placeholder="Enter the username"
                                        margin="dense"
                                        variant="outlined"
                                        value={context.state.username || ''}
                                        onChange={(event) => handleChange('username', event.target.value, context)}
                                        autoComplete={"off"}
                                    />
                                    {
                                        Object.keys(context.state.validation.username).length > 0 &&
                                        !context.state.validation.username.valid &&
                                        <FormHelperText id="name-error-text">{context.state.validation.username.message}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                        }
                        {
                            (!context.state.isProfile ) &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel
                                        htmlFor="name-native-error"
                                    >
                                        User role
                                    </InputLabel>
                                    <Select
                                        value={context.state.role ? context.state.role : 'foreman'}
                                        onClick={(event) => context.setState({role: event.target.value })}
                                        input={
                                            <OutlinedInput
                                                labelWidth={90}
                                                name="type"
                                                id="user-role"
                                            />
                                        }
                                    >
                                        <MenuItem value='foreman'>Outside Sales</MenuItem>
                                        <MenuItem value='staff'>Inside Sales</MenuItem>
                                        <MenuItem value='admin'>Admin</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {
                            context.state.role === 'foreman' &&
                            managers.length > 0 &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="select-multiple-checkbox">Managed By</InputLabel>
                                    <Select
                                        multiple
                                        value={context.state.managers}
                                        onChange={(event) => context.setState({managers: event.target.value })}
                                        input={
                                            <OutlinedInput
                                                labelWidth={90}
                                                name="type"
                                                id="user-managers"
                                            />
                                        }
                                        renderValue={selected => managers
                                                                .filter(manager => selected.includes(manager.id))
                                                                .map(manager => manager.name)
                                                                .join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                    {managers.map((manager, i) => (
                                        <MenuItem key={i} value={manager.id}>
                                            <Checkbox checked={context.state.managers.indexOf(manager.id) > -1} />
                                            <ListItemText primary={manager.name} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {
                            context.state.role === 'staff' &&
                            foremen.length > 0 &&
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="select-multiple-checkbox">Team</InputLabel>
                                    <Select
                                        multiple
                                        value={context.state.team}
                                        onChange={(event) => context.setState({team: event.target.value })}
                                        input={
                                            <OutlinedInput
                                                labelWidth={90}
                                                name="type"
                                                id="user-managers"
                                            />
                                        }
                                        renderValue={selected => foremen
                                                                .filter(foreman => selected.includes(foreman.id))
                                                                .map(foreman => foreman.name)
                                                                .join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                    {foremen.map((foreman, i) => (
                                        <MenuItem key={i} value={foreman.id}>
                                            <Checkbox checked={context.state.team.indexOf(foreman.id) > -1} />
                                            <ListItemText primary={foreman.name} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.margin}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={context.state.enabled || false}
                                            onChange={(event, checked) => context.setState({ enabled: checked })}
                                            value="true"
                                            color="primary"
                                        />
                                    }
                                    label="Enabled"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {
                ( props.userid || context.state.userEditId) &&
                localStorage.getItem('role') === 'admin' &&
                <Grid item xs={12} style={{margin: '16px 0px'}}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom={false}>
                                    Passwords
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-adornment-password"
                                    variant="outlined"
                                    error={!context.state.passwordMatch}
                                    type={context.state.showPassword ? 'text' : 'password'}
                                    label="Password"
                                    value={context.state.password}
                                    onChange={(event) => context.setState({password: event.target.value})}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={() => context.setState(state => ({ showPassword: !state.showPassword }))}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {context.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-adornment-password2"
                                    variant="outlined"
                                    error={!context.state.passwordMatch}
                                    type={context.state.showPassword ? 'text' : 'password'}
                                    label="Password"
                                    value={context.state.password2}
                                    onChange={(event) => context.setState({password2: event.target.value})}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            }
            {
                !context.state.confirmedAt &&
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className={classes.deleteGrid}>
                        <Button
                            variant="contained"
                            color="default"
                            disabled={context.state.resendWelcome}
                            className={classes.button}
                            onClick={() => context.resendWelcome()}
                        >
                            Resend Welcome Email
                        </Button>
                    </Grid>
                </Grid>
            }
            {
                (
                    context.state.isProfile ||
                    context.state.userEditId === '' ||
                    context.state.role === '' ||
                    context.state.role === 'admin'
                ) ?
                    '' :
                    <Grid item md={6} xs={12} className={classes.deleteGrid}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={() => context.setState({confirmDialogOpen: true})}
                        >
                            Delete User
                        </Button>
                    </Grid>
            }
            {
                context.state.userEditId &&
                context.state.role &&
                context.state.role !== 'admin' &&
                <Dialog
                    open={context.state.confirmDialogOpen}
                    onClose={() => context.setState({confirmDialogOpen: false})}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => context.setState({confirmDialogOpen: false})} color="default">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                context.removeUser(context.state.userEditId)
                            }}
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {context.state.redirect}
        </Grid>
    )
}


export default UserForm
