import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
//components
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
    list: {
        maxHeight: 'calc(100vh - 56px)',
        position: 'relative',
        overflow: 'auto',
    },

    listSection: {
        backgroundColor: 'inherit',
        marginTop: '64px'
    },
    listSubheader:{
        backgroundColor: theme.palette.grey[50],
        color: 'black',
        paddingTop:'8px',
        paddingBottom:'8px',
        borderBottom: '1px solid ' + theme.palette.grey[200],
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    progress: {
        margin: theme.spacing(2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerDialog = (props) => {
    const { context, open, closeDialog, handleChange, rootClasses, updateCustomer} = props;
    const classes = useStyles();
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={closeDialog}
            TransitionComponent={Transition}
        >
            <div className={rootClasses.appBar}>
                <AppBar position="fixed" elevation={0}>
                    <Toolbar>
                        <FormControl className={rootClasses.flex}>
                            <Input
                                className={rootClasses.input}
                                id="input-with-icon-adornment"
                                placeholder="Start typing to search"
                                autoFocus={true}
                                onChange={(event) => handleChange(event.target.value, context)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Icon>search</Icon>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <IconButton
                            className={rootClasses.closeButton}
                            color="inherit"
                            onClick={closeDialog}
                            aria-label="Close"
                        >
                            <Icon>close</Icon>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {
                    context.state.customerKeyword.length > 2 &&
                    <CustomerList updateCustomer={updateCustomer} classes={classes} context={context} keyword={context.state.customerKeyword} closeDialog={closeDialog} />
                }
            </div>
        </Dialog>
    )
}

const CUSTOMERS_QUERY = gql`
    query allCustomers($keyword: String) {
        allCustomers(keyword: $keyword) {
            id name accountNo pricingTier name accountNo phone email address address2 city 
            province country postalCode accountNo territory salesRep taxGroup currency createdAt
        }
    }
`;
const CustomerList = ({ classes, context, closeDialog, keyword, updateCustomer }) => {
    const { loading, error, data } = useQuery(CUSTOMERS_QUERY, {
        variables: {keyword}
    });
    if (loading) return <CircularProgress className={classes.progress} />;
    if (error) return `Error! ${error.message}`;
    return (
        <List component="nav" className={classes.list} subheader={<li />}>
            <li key={`section-1`} className={classes.listSection}>
                <ul className={classes.ul}>
                    {
                        data.allCustomers && data.allCustomers.map( (customer, i) => (
                            <div key={i}>
                                <ListItem
                                    button
                                    onClick={ () => {
                                        updateCustomer(customer)
                                        // context.updateCustomer(customer);
                                        context.setState({customerKeyword : ''})
                                        closeDialog();
                                    }}
                                >
                                    <Typography variant="body2">
                                        {customer.accountNo + ' - ' +customer.name}
                                    </Typography>
                                </ListItem>
                                <Divider />
                            </div>
                        ))
                    }
                </ul>
            </li>
        </List>
    );
};

export default CustomerDialog