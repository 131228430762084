import React from "react";
import { gql, useQuery} from '@apollo/client';
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import UserListContext from "../UserListContext";
import UserListItem from "./UserListItem";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        overflow: "auto",
        width: '100%',
    },
    list: {
        position: "relative",
        overflow: "auto",
        width: '100%',
    },
    listSection: {
        backgroundColor: theme.palette.background.paper
    },
    listSubheader: {
        backgroundColor: theme.palette.grey[100],
        color: "black",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderBottom: "1px solid " + theme.palette.grey[200]
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0
    },
    "@media (min-width: 1080px)":{
        list:{
            boxShadow: theme.shadows[5],
        },
    }
}));

const USERS_QUERY = gql`
    query AllUsers($enabled: Boolean) {
        allUsers(enabled: $enabled) {
            id username email phone warehouse name role createdBy{ username name } 
            managedBy { id name } team { id name } createdAt enabled confirmedAt
        }
    }
`;

const INACTIVE_USERS_QUERY = gql`
    query inAllUsers($enabled: Boolean) {
        allUsers (enabled: $enabled) {
            id username email phone warehouse name role createdBy { username name } 
            managedBy { id name } team { id name } createdAt enabled confirmedAt
        }
    }
`;

const UserList = () => {
    const classes = useStyles();

    const {data, loading, error} = useQuery(USERS_QUERY, { variables: {enabled: true}});
    const {data: inData, loading: inLoading, error: inError} = useQuery(INACTIVE_USERS_QUERY, { variables: {enabled: false}});

    if (loading || inLoading) {
        return <CircularProgress />
    }

    if (error || inError) {
      return <div>Error</div>
    }

    const allUsersArr = data.allUsers
    const inAllUsersArr = inData.allUsers

    return (
        <UserListContext.Consumer>
            {
                (context) => {
                    return (
                        <div className={classes.root}>
                            <List className={classes.list}>
                                {
                                    allUsersArr.length > 0 &&
                                    <li key={`section-1`} className={classes.listSection}>
                                        <ul className={classes.ul}>
                                            <ListSubheader className={classes.listSubheader}>
                                                <Typography variant="subtitle1">Active Users</Typography>
                                            </ListSubheader>
                                            {
                                                allUsersArr ? allUsersArr.map((user, i) => {
                                                    return <UserListItem context={context} user={user} key={i} data-id={user.id} count={i + 1}/>
                                                }) : ''
                                            }
                                        </ul>
                                    </li>
                                }
                                {
                                    inAllUsersArr.length > 0 &&
                                    <li key={`section-2`} className={classes.listSection}>
                                        <ul className={classes.ul}>
                                            <ListSubheader className={classes.listSubheader}>
                                                <Typography variant="subtitle1">Inactive Users</Typography>
                                            </ListSubheader>
                                            {
                                                inAllUsersArr ? inAllUsersArr.map((user, i) => {
                                                    return <UserListItem context={context} user={user} key={i} data-id={user.id} count={i + 1}/>
                                                }) : ''
                                            }
                                        </ul>
                                    </li>
                                }
                            </List>
                        </div>
                    )
                }
            }
        </UserListContext.Consumer>
    );
}

export default UserList
