import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
//material UI
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import DoorIcon from '../../images/icon-door.png';
import TrimIcon from '../../images/icon-trim.png';
import ShelvingIcon from '../../images/icon-shelf.png';
import WindowIcon from '../../images/icon-window.png';
import HardwareIcon from '../../images/icon-hardware.png';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from "@material-ui/core/IconButton";
//components
import MeasureList from './Measure/MeasureList';
import AddProductDialog from './Measure/AddProductDialog';
// Speed Dial
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ConstructionIcon from '@material-ui/icons/Build';
import ProductIcon from '@material-ui/icons/Category';
import EditIcon from '@material-ui/icons/Whatshot';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '100px'
    },
    gridListContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.main,
    },
    missingSpecs: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        backgroundColor: theme.palette.secondary[300],
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
        '@media print': {
            display: 'none',
        }
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 100,
    },
    fabMoveUp: {
        transform: 'translate3d(0, -46px, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    fabMoveDown: {
        transform: 'translate3d(0, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    buttonBase: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    drawerIcon: {
        width: '40px'
    },
    link: {
        color: 'black'
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(12),
        right: theme.spacing(5),
        zIndex: 100,
    },
}));

const actions = [
    { icon: <ConstructionIcon />, name: 'Install', action: 'custom_install_service' },
    { icon: <ProductIcon />, name: 'Product', action: 'custom_product' },
  ];

const Measure = ({ context }) => {
    const classes = useStyles();
    const [speedDial, setSpeedDial] = useState(false)
    const door_treatment = context.state.specs.door_treatment;
    const installPricingMode = context.state.installPricingMode;
    const isDoorTreatmentSet = true;
    // const isDoorTreatmentSet = door_treatment && (
    //     door_treatment.prep === 'loose' ||
    //     ( door_treatment.backset && door_treatment.head )
    // );
    const disabled = !(
        installPricingMode &&
        isDoorTreatmentSet
    );
    const done = context.isDoneTakeoff();
    return (
        <div className={classes.root}>
            <AddProductDialog context={context}/>
            <MeasureList context={context} />
            {
                !disabled &&
                <SpeedDial
                    ariaLabel="SpeedDial openIcon example"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                    onClick={() => setSpeedDial(!speedDial)}
                    // onClose={() => setSpeedDial(false)}
                    open={speedDial}
                    >
                    {actions.map(action => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => context.openMeasure(action.action)}
                        />
                    ))}
                </SpeedDial>
            }
            {
                !done &&
                <Drawer
                    anchor="bottom"
                    open={true}
                    variant="permanent"
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onKeyDown={() => context.setState({measureDrawer: false})}
                    >
                        <div className={classes.gridListContainer}>
                            {
                                disabled ?
                                <GridList className={classes.gridList} cellHeight={84} cols={5}>
                                    <GridListTile cols={5}>
                                        <div className={classes.missingSpecs}>
                                            <ButtonBase
                                                className={classes.buttonBase}
                                                focusRipple
                                                focusVisibleClassName={classes.focusVisible}
                                                onClick={() => context.setPrimaryTab(!installPricingMode ? 'customer' : 'specs')}
                                            >
                                                <Typography variant="subtitle1">
                                                    {installPricingMode ? '' : 'Installation type'}
                                                    {!installPricingMode && !isDoorTreatmentSet ? ' & ' : ''}
                                                    {isDoorTreatmentSet ? '' : 'Door Treatments'} are Incomplete</Typography>
                                                <Typography style={{textDecoration: 'underline'}} variant="overline">Add Door Prep, Backset, and Head options.</Typography>
                                            </ButtonBase>
                                        </div>
                                    </GridListTile>
                                </GridList>
                                :
                                <GridList className={classes.gridList} cellHeight={84} cols={5}>
                                    <GridListTile key={1} cols={1}>
                                        <ButtonBase
                                            disabled={disabled}
                                            className={classes.buttonBase}
                                            focusRipple
                                            focusVisibleClassName={classes.focusVisible}
                                            onClick={() => context.openMeasure('door')}
                                        >
                                            <img className={classes.drawerIcon} alt='DoorIcon' src={DoorIcon}/>
                                            <Typography variant="overline">Doors</Typography>
                                        </ButtonBase>
                                    </GridListTile>
                                    <GridListTile key={2} cols={1}>
                                        <ButtonBase
                                            disabled={disabled}
                                            className={classes.buttonBase}
                                            focusRipple
                                            focusVisibleClassName={classes.focusVisible}
                                            onClick={() => context.openMeasure('window')}
                                        >
                                            <img className={classes.drawerIcon} alt='WindowIcon' src={WindowIcon}/>
                                            <Typography variant="overline">Opens</Typography>
                                        </ButtonBase>
                                    </GridListTile>
                                    <GridListTile key={3} cols={1}>
                                        <ButtonBase
                                            disabled={disabled}
                                            className={classes.buttonBase}
                                            focusRipple
                                            focusVisibleClassName={classes.focusVisible}
                                            onClick={() => context.openMeasure('hardware')}
                                        >
                                            <img className={classes.drawerIcon} alt='ShelvingIcon' src={HardwareIcon}/>
                                            <Typography variant="overline">Hardware</Typography>
                                        </ButtonBase>
                                    </GridListTile>
                                    <GridListTile key={4} cols={1}>
                                        <ButtonBase
                                            disabled={disabled}
                                            className={classes.buttonBase}
                                            focusRipple
                                            focusVisibleClassName={classes.focusVisible}
                                            onClick={() => context.openMeasure('shelving')}
                                        >
                                            <img className={classes.drawerIcon} alt='ShelvingIcon' src={ShelvingIcon}/>
                                            <Typography variant="overline">Shelving</Typography>
                                        </ButtonBase>
                                    </GridListTile>
                                    <GridListTile key={5} cols={1}>
                                        <ButtonBase
                                            disabled={disabled}
                                            className={classes.buttonBase}
                                            focusRipple
                                            focusVisibleClassName={classes.focusVisible}
                                            onClick={() => context.openMeasure('trim')}
                                        >
                                            <img className={classes.drawerIcon} alt='TrimIcon' src={TrimIcon}/>
                                            <Typography variant="overline">Trim</Typography>
                                        </ButtonBase>
                                    </GridListTile>
                                </GridList>
                            }
                        </div>
                    </div>
                </Drawer>
            }

            {
                context.state.addMeasureItemSnack && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={context.state.addDoorDialog}
                        autoHideDuration={4000}
                        onClose={() => context.setState({
                            addMeasureItemSnack: false,
                            measureItemMessage: ''
                        })}
                        message={
                            <span id="message-id">
                                {context.state.measureItemMessage}
                            </span>
                        }
                        action={[
                            <Button
                                key="undo"
                                color="secondary"
                                size="small"
                                onClick={() => { context.addMeasureItem(); }}
                            >
                                RETRY
                            </Button>,
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close} onClick={() => context.setState({
                                    addMeasureItemSnack: false,
                                    measureItemMessage: ''
                                })}
                            >
                                <CloseIcon/>
                            </IconButton>,
                        ]}
                    />
                )
            }
        </div>
    );
}

export default Measure
