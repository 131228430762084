import React, { useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import CustomToggleGroup from '../../common/CustomToggleGroup'
import SpecsType from '../Specs/SpecsType';
import JambMaterial from './JambMaterial';
import * as Lib from '../lib/lib';
import {usePrevious} from '../../common/hooks'

const useStyles = makeStyles((theme) => ({
    paper:{
        padding:"16px",
    },
    group:{
        flexDirection: "row",
    },
    chip: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    }
}));

const NewDoorTrim = ({ context }) => {
    const prevContext   = usePrevious(context)
    const classes       = useStyles();
    const disabled      = context.isDoneTakeoff();

    // const doorHeightChanges = (oldSpecs, specs) => {
    //     const height    = Lib.deepAttributeExists(['door', 'height'], specs)
    //     const oldHeight = Lib.deepAttributeExists(['door', 'height'], oldSpecs)
    //     // console.log(height, oldHeight)
    //     return (
    //         !oldSpecs ||
    //         height !== oldHeight
    //     )
    // }

    useEffect(() => {
        if (context.state.editDoorIndex !== -1) {
            const door = context.state.doors[context.state.editDoorIndex];
            if (!Lib.isEmptyOrNullObject(door.sheet)) {
                context.copySpecs('specs', 'specialSpecs', 'sheet', "", door.sheet.attributes || {});
                context.copySpecs('specs', 'specialSpecs', 'products', 'sheet', door.sheet);
            }
            Object.keys(door.trim)
            .filter(type => {
                return  door.trim[type] &&
                    'code' in door.trim[type] &&
                    door.trim[type].code
            })
            .forEach( type => {
                context.copySpecs('specs', 'specialSpecs', 'trim', (type === 'jamb' ? door.materialType : type), door.trim[type].attributes || {});
                context.copySpecs('specs', 'specialSpecs', 'products', (type === 'jamb' ? door.materialType : type), door.trim[type]);
            })
        } else {
            context.copySpecs('specs', 'specialSpecs', 'trim', 'jamb');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'jamb_84');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'jamb_96');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'sheet');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'casing');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'doorstop');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'burlap');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'flat_stock');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'architrave');
            context.copySpecs('specs', 'specialSpecs', 'trim', 'backband');
            context.copySpecs('specs', 'specialSpecs', 'products', 'jamb');
            context.copySpecs('specs', 'specialSpecs', 'products', 'jamb_84');
            context.copySpecs('specs', 'specialSpecs', 'products', 'jamb_96');
            context.copySpecs('specs', 'specialSpecs', 'products', 'casing');
            context.copySpecs('specs', 'specialSpecs', 'products', 'doorstop');
            context.copySpecs('specs', 'specialSpecs', 'products', 'burlap');
            context.copySpecs('specs', 'specialSpecs', 'products', 'flat_stock');
            context.copySpecs('specs', 'specialSpecs', 'products', 'architrave');
            context.copySpecs('specs', 'specialSpecs', 'products', 'backband');
        }
    }, []);

    useEffect(() => {
        const materialType = context.state.measures.door.materialType;
        const height    = Lib.deepAttributeExists(['height'], context.state.specialSpecs.door)
        const oldHeight = Lib.deepAttributeExists(['height'], prevContext?.state.specialSpecs.door) || height;
        // console.log(materialType , height , oldHeight, oldHeight !== height)
        if ((!materialType && height) || oldHeight !== height) {
            const jambStandard = !height ? null : Number(height) < 84 ? '' : Number(height) <  96 ? '84': '96';
            handleChange('materialType', height ? `jamb${ jambStandard ? `_${jambStandard}` : '' }`: '' )
        }
    }, [
        context.state.specialSpecs.door,
        prevContext?.state.specialSpecs.door
    ]);

    const handleSpecChange = (category, type = null) =>  ({specs, copy, propagate}) => {
        // console.log(category, type, specs)
        if (
            (
                ['casing', 'doorstop', 'burlap', 'flat_stock', 'jamb', 'architrave', 'backband', 'sheet'].includes(type) ||
                /jamb/.test(type) ||
                category === 'sheet'
            ) &&
            !Lib.isEmptyOrNullObject(specs)
        ) {
            const productAttribute = {
                category,
                ...(type ? {type: /jamb/.test(type) ? 'jamb' : type} : {}),
                ...specs,
            };
            const productPromise = context.getAnyProduct(productAttribute);
            productPromise.then(product => {
                if (product && !Lib.isEmptyOrNullObject(specs)) {
                    const newProduct = {
                        code:           product.code,
                        description:    product.description,
                        price:          product.pricingTiers,
                        uom:            product.uom,
                        // count:          specs.window.quantity,
                        attributes:     productAttribute
                    };
                    context.saveSpecs({
                        scope: 'specialSpecs',
                        product: newProduct,
                        category,
                        type,
                        specs,
                        copy,
                        propagate
                    });
                } else {
                    context.saveSpecs({
                        scope: 'specialSpecs',
                        category,
                        type,
                        specs,
                        copy,
                        propagate
                    });
                }
            })
        } else {
            context.saveSpecs({
                scope: 'specialSpecs',
                category,
                type,
                specs,
                copy
            });
        }
    };

    const handleChange = (type, value) => {
        context.setMeasureDoorAttr(type, value || '');
    }

    return (
        <Paper className={classes.paper} elevation={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>Trim</Typography>
                </Grid>
                {
                    Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs) !== 'Utility' &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <JambMaterial
                                disabled={disabled}
                                type={'Jamb'}
                                value={context.state.measures.door.materialType}
                                quantity={context.state.measures.door.quantity}
                                items={{
                                    jamb: '80',
                                    jamb_84: '84',
                                    jamb_96: '96',
                                    sheet: 'sheet',
                                    flat_stock: 'Flat',
                                }}
                                handleSpecChange={handleSpecChange}
                                handleChange={handleChange}
                                specs={context.state.specs}
                                specialSpecs={context.state.specialSpecs}
                                showDepth={true}
                                openingDepth={context.state.measures.door.openingDepth}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom={false}>Casing</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SpecsType
                                disabled={disabled}
                                category={'trim'}
                                type={'casing'}
                                attributes={['supplier', 'style', 'species', 'width', 'thickness', 'length']}
                                handleChange={handleSpecChange('trim', 'casing')}
                                specs={context.state.specialSpecs.trim.casing}
                                globalSpecs={context.state.specs.trim.casing}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </React.Fragment>
                }
                {
                    context.state.measures.door.doorFunction !== 'bypass' &&
                    !context.state.measures.door.isBifold &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom={false}>Doorstop</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SpecsType
                                disabled={disabled}
                                category={'trim'}
                                type={'doorstop'}
                                attributes={['supplier', 'style', 'species', 'width', 'thickness', 'length']}
                                handleChange={handleSpecChange('trim', 'doorstop')}
                                specs={context.state.specialSpecs.trim.doorstop}
                                globalSpecs={context.state.specs.trim.doorstop}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                }
                {
                    context.state.measures.door.latching === 'flushbolt' &&
                    !context.state.measures.door.isBifold &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom={false}>Burlap</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SpecsType
                                disabled={disabled}
                                category={'trim'}
                                type={'burlap'}
                                attributes={['supplier', 'style', 'species', 'width', 'thickness']}
                                handleChange={handleSpecChange('trim', 'burlap')}
                                specs={context.state.specialSpecs.trim.burlap}
                                globalSpecs={context.state.specs.trim.burlap}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={disabled}
                                checked={context.state.measures.door.addBackband}
                                onChange={(event) => handleChange('addBackband', event.target.checked)}
                            />
                        }
                        label="Add Backband"
                    />
                </Grid>
                {
                    context.state.measures.door.addBackband &&
                    <>
                        <Grid item xs={12}>
                            <CustomToggleGroup
                                disabled={context.isDoneTakeoff()}
                                items={{
                                    one_side: 'One Side',
                                    two_sides: 'Two Sides',
                                }}
                                value={context.state.measures.door.backbandSides}
                                handleChange={ (event, value) => handleChange('backbandSides', value)}
                            />
                        </Grid>
                        {
                            context.state.measures.door.backbandSides &&
                            <Grid item xs={12}>
                                <SpecsType
                                    disabled={disabled}
                                    category={'trim'}
                                    type={'backband'}
                                    attributes={['supplier', 'style', 'species', 'width', 'thickness']}
                                    handleChange={handleSpecChange('trim', 'backband')}
                                    specs={context.state.specialSpecs.trim.backband}
                                    globalSpecs={context.state.specs.trim.backband}
                                />
                            </Grid>
                        }
                    </>
                }
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={disabled}
                                checked={context.state.measures.door.addArchitrave}
                                onChange={(event) => handleChange('addArchitrave', event.target.checked)}
                            />
                        }
                        label="Add Architrave"
                    />
                </Grid>
                {
                    context.state.measures.door.addArchitrave &&
                    <Grid item xs={12}>
                        <CustomToggleGroup
                            disabled={context.isDoneTakeoff()}
                            items={{
                                one_side: 'One Side',
                                two_sides: 'Two Sides',
                            }}
                            value={context.state.measures.door.architraveSides}
                            handleChange={ (event, value) => handleChange('architraveSides', value)}
                        />
                    </Grid>
                }
                {
                    context.state.measures.door.addArchitrave &&
                    context.state.measures.door.architraveSides &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            category={'trim'}
                            type={'architrave'}
                            attributes={['supplier', 'style', 'species', 'width', 'thickness']}
                            handleChange={handleSpecChange('trim', 'architrave')}
                            specs={context.state.specialSpecs.trim.architrave}
                            globalSpecs={context.state.specs.trim.architrave}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={disabled}
                                checked={context.state.measures.door.isHalfTrim}
                                onChange={(event) => handleChange('isHalfTrim', event.target.checked)}
                            />
                        }
                        label="Halve Casing/Jamb ?"
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}


export default NewDoorTrim