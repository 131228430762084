import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SpecsType from '../Specs/SpecsType';
import AttributeSelect from '../AttributeSelect';
import CustomToggleGroup from '../../common/CustomToggleGroup'
import * as Lib from '../lib/lib';

const NewExteriorDoorHardware = ({ context, door, hardwareOption, handleSpecChange, setMeasureOpenAttr}) => {
    const { size, operating, hardwareOptions, handleType, extHardwareMode, extHardwareMode2, extHandleMode, extHandleMode2, doorstopper_mode_left, doorstopper_mode, doorstopper_mode_right } = door;
    const typeA = hardwareOption.indexOf('_') > -1 ? hardwareOption.substr(0, hardwareOption.indexOf('_')) : hardwareOption;
    const typeB = hardwareOption.indexOf('_') > -1 ? hardwareOption.substr((hardwareOption.indexOf('_') + 1)) : null;
    const disabled = context.isDoneTakeoff();

    useEffect(() => {
        const knobSpecsSet =  !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.knob);
        const leverSpecsSet = !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.lever);
        if (!handleType && knobSpecsSet ^ leverSpecsSet) {
            setMeasureOpenAttr('handleType', knobSpecsSet ? 'knob' : 'lever')
        }
    },[])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom={false}>
                    {size === 'double' ? 'Operating Door' : 'Swing Direction'}
                </Typography>
                <CustomToggleGroup
                    disabled={disabled}
                    items={{
                        left: 'Left',
                        right: 'Right',
                    }}
                    value={operating}
                    handleChange={(event, value) => setMeasureOpenAttr('operating', value || '')}
                />
            </Grid>
        {
            operating &&
            <React.Fragment>
            {
                size &&
                <Grid item xs={12}>
                    <CustomToggleGroup
                        disabled={disabled}
                        items={{
                            gripset_interior: 'Gripset',
                            deadbolt_passage: 'Deadbolt',
                            entry: 'Entry',
                        }}
                        value={hardwareOptions}
                        handleChange={ (event, value) => setMeasureOpenAttr('hardwareOptions', value || '', {'extHardwareMode': '', 'extHardwareMode2': '', 'extHandleMode': '', 'extHandleMode2': ''})}
                    />
                </Grid>
            }
            {
                typeA && typeB &&
                <React.Fragment>
                    {
                        typeA === 'gripset' &&
                        !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware[typeA]) &&
                        <Grid item sm={size === 'double' ? 6 : 12} xs={12}>
                            <AttributeSelect
                                hideDelete
                                disabled={disabled}
                                field={"mode"}
                                category={"hardware"}
                                type={typeA}
                                title={"Operating Gripset"}
                                value={extHardwareMode}
                                selectionControl={"radio"}
                                handleChange={(event, value) => setMeasureOpenAttr('extHardwareMode',  value || '')}
                                refSpec={{
                                    ...context.state.specialSpecs.hardware[typeA],
                                    mode: extHardwareMode
                                }}
                                allowedValues={['Entry']}
                            />
                        </Grid>
                    }
                    {
                        size === 'double' &&
                        typeA === 'gripset' &&
                        !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware[typeA]) &&
                        <Grid item sm={6} xs={12}>
                            <AttributeSelect
                                hideDelete
                                disabled={disabled}
                                field={"mode"}
                                category={"hardware"}
                                type={typeA}
                                title={"Fixed Gripset"}
                                value={extHardwareMode2}
                                selectionControl={"radio"}
                                handleChange={(event, value) => setMeasureOpenAttr('extHardwareMode2',  value || '')}
                                refSpec={{
                                    ...context.state.specialSpecs.hardware[typeA],
                                    mode: extHardwareMode2
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            category={'hardware'}
                            type={typeA}
                            attributes={typeA === 'deadbolt' ? ['brand', 'mode', 'finish', 'style', 'rosette', 'firerated'] : ['brand', 'style', 'finish']}
                            handleChange={handleSpecChange('hardware', typeA)}
                            specs={context.state.specialSpecs.hardware[typeA]}
                            globalSpecs={context.state.specs.hardware[typeA]}
                        />
                    </Grid>
                </React.Fragment>
            }
            {
                (typeA === 'entry' || typeB) &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom={false}>
                            Handle Type
                        </Typography>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                knob: 'Knob',
                                lever: 'Lever',
                            }}
                            value={handleType}
                            handleChange={(event, value) => setMeasureOpenAttr('handleType', value || '')}
                        />
                    </Grid>
                    {
                        !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware[handleType]) &&
                        <Grid item md={size === 'double' ? 6 : 12} xs={12}>
                            <AttributeSelect
                                hideDelete
                                disabled={disabled}
                                field={"mode"}
                                category={"hardware"}
                                type={handleType}
                                title={"Operating Handle"}
                                value={extHandleMode}
                                selectionControl={"radio"}
                                handleChange={(event, value) => setMeasureOpenAttr('extHandleMode',  value || '')}
                                refSpec={{
                                    ...context.state.specialSpecs.hardware[handleType],

                                }}
                                allowedValues={
                                    typeB === 'interior' ? ['Interior', 'Interior (SCE)'] :
                                    typeB === 'passage' ? ['Passage'] : ['Entry', 'Electronic', 'Touchscreen']
                                }
                            />
                        </Grid>
                    }
                    {
                        size === 'double' &&
                        !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware[handleType]) &&
                        <Grid item md={6} xs={12}>
                            <AttributeSelect
                                hideDelete
                                disabled={disabled}
                                field={"mode"}
                                category={"hardware"}
                                type={handleType}
                                title={"Fixed Handle"}
                                value={extHandleMode2}
                                selectionControl={"radio"}
                                handleChange={(event, value) => setMeasureOpenAttr('extHandleMode2',  value || '')}
                                refSpec={{
                                    ...context.state.specialSpecs.hardware[handleType],
                                    mode: typeB
                                }}
                                allowedValues={
                                    typeB === 'interior' ? ['Interior', 'Dummy', 'Interior Dummy', 'Interior (SCE)'] : ['Passage', 'Dummy']
                                }
                            />
                        </Grid>
                    }
                    {
                        handleType &&
                        <Grid item xs={12}>
                            <SpecsType
                                disabled={disabled}
                                category={'hardware'}
                                type={handleType}
                                label={Lib.humanize(handleType)}
                                attributes={['brand', 'style', 'finish', 'rosette']}
                                handleChange={handleSpecChange('hardware', handleType)}
                                specs={context.state.specialSpecs.hardware[handleType]}
                                globalSpecs={context.state.specs.hardware[handleType]}
                            />
                        </Grid>
                    }
                </React.Fragment>
            }
            </React.Fragment>
        }
        {
            size === "double" &&
            Lib.deepAttributeExists(['doorstopper', 'finish'], context.state.specialSpecs.hardware) &&
            <React.Fragment>
                <Grid item md={6} xs={12}>
                    <AttributeSelect
                        hideDelete
                        disabled={disabled}
                        field={"mode"}
                        category={"hardware"}
                        type={"doorstopper"}
                        title={"Left Doorstopper"}
                        value={doorstopper_mode_left}
                        handleChange={(event, value) => setMeasureOpenAttr('doorstopper_mode_left', event ? event.target.value : value ? value : '')}
                        refSpec={context.state.specialSpecs.hardware.doorstopper}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AttributeSelect
                        hideDelete
                        disabled={disabled}
                        field={"mode"}
                        category={"hardware"}
                        type={"doorstopper"}
                        title={"Right Doorstopper"}
                        value={doorstopper_mode_right}
                        handleChange={(event, value) => setMeasureOpenAttr('doorstopper_mode_right', event ? event.target.value : value ? value : '')}
                        refSpec={context.state.specialSpecs.hardware.doorstopper}
                    />
                </Grid>
            </React.Fragment>
        }
        {
            !(["", "double"].includes(size)) &&
            Lib.deepAttributeExists(['doorstopper', 'finish'], context.state.specialSpecs.hardware) &&
            <Grid item xs={12}>
                <AttributeSelect
                    hideDelete
                    disabled={disabled}
                    field={"mode"}
                    category={"hardware"}
                    type={"doorstopper"}
                    title={"Doorstopper Mode"}
                    value={doorstopper_mode}
                    handleChange={(event, value) => setMeasureOpenAttr('doorstopper_mode', event ? event.target.value : value ? value : '')}
                    refSpec={context.state.specialSpecs.hardware.doorstopper}
                />
            </Grid>
        }
        {
            size &&
            <Grid item xs={12}>
                <SpecsType
                    disabled={disabled}
                    category={'hardware'}
                    type={'doorstopper'}
                    attributes={['finish']}
                    handleChange={handleSpecChange('hardware', `doorstopper`)}
                    specs={context.state.specialSpecs.hardware.doorstopper}
                    globalSpecs={context.state.specs.hardware.doorstopper}
                />
            </Grid>
        }
        </Grid>
    )
}

export default NewExteriorDoorHardware