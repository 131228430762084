import React from 'react';
import SpecsType from '../Specs/SpecsType';
import { IconButton, Button, Grid, Switch, FormControlLabel, Paper, Typography, Box, FormControl, TextField, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomToggleGroup from '../../common/CustomToggleGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirmation } from "../../../components/ConfirmationService";
import AttributeSelect from '../AttributeSelect';
import ReachInHelper from '../../../images/reach_in-dims.png';

const useStyles = makeStyles((theme) => ({
  root: {
      marginLeft: theme.spacing(1),
  },
  box: {
    padding: theme.spacing(1),
    // border: '1px solid #999',
    borderRadius: '4px',
    boxShadow: 'none',
    overflow: 'auto',
    marginBottom: theme.spacing(2)
  },
  actions: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  walkin_lh_SVG: {
    strokeDasharray: '20, 40, 40, 40'
  },
  walkin_rh_SVG: {
    strokeDasharray: '40, 40, 40, 20'
    
  },
  u_shape_SVG: {
    strokeDasharray: '40, 20'
  },
}));

const organizerLayouts = {
  reach_in: {
    label: 'Reach-in',
    dimensions: ['width_1', 'width_2', 'width_3', 'width_4', 'width_5', 'width_6', 'room_height'],
    noOfWalls: 1,
  },
  walkin_lh: {
    label: 'Walk-in LH',
    dimensions: ['height', 'width_1', 'width_2'],
    noOfWalls: 2,
  },
  walkin_rh: {
    label: 'Walk-in RH',
    dimensions: ['height', 'width_1', 'width_2'],
    noOfWalls: 2,
  },
  u_shape: {
    label: 'U Shape',
    dimensions: ['height', 'width_1', 'width_2', 'width_3'],
    noOfWalls: 3,
  }
}

const miscellaneous = {
  baseboardIinstalled: {
    label: 'Baseboard installed?',
    type: 'switch',
    capture: ['baseboard_height', 'baseboard_thickness']
  },
  shoeMouldIinstalled: {
    label: 'Shoe mould installed?',
    type: 'switch',
    capture: ['shoe_mould_height', 'shoe_mould_thickness']
  },
  casing: {
    label: 'Casing',
    type: 'text',
    capture: ['casing_height', 'casing_thickness']
  },
  windows: {
    label: 'Windows?',
    type: 'switch',
  },
  lightSwitch: {
    label: 'Light Switche?',
    type: 'switch',
  },
  outlets: {
    label: 'Outlets?',
    type: 'switch',
  },
  floorRegister: {
    label: 'Floor Register?',
    type: 'switch',
  },
  lights: {
    label: 'Lights?',
    type: 'switch',
  },
}

const ClosetSide = ({type, side}) => {
  const classes = useStyles();
  return (
    <svg height="30">
      <rect x="10" y="10" width="20" height="20" className={classes[type]} style={{ fill: 'none', stroke: 'black' }}/>
      <line 
        x1={
          ( (type === 'walkin_rh_SVG' && side === 2) || (type === 'u_shape_SVG' && side === 3) ) ? 30 : 10
        }
        y1="10" 
        x2={['u_shape_SVG', 'walkin_lh_SVG'].includes(type) && side === 1 ? 10 : 30}
        y2={
          (
            ( ['u_shape_SVG', 'walkin_lh_SVG'].includes(type) && side === 2) ||
            ( type === 'walkin_rh_SVG' && side === 1)
          ) ? 10 : 30
        }
        style={{ stroke:'red', strokeWidth:'3px' }} 
      />
  </svg>
  );
}

const Accessory = ({index, accessory, classes, removeAccessory, disabled, handleChange, handleSpecChange}) => (
  <Paper elevation={1}>
    <Grid container spacing={2} className={classes.box}>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'subtitle1'}>Accessory {index}</Typography>
          <IconButton
            aria-label="delete"
            onClick={() => removeAccessory()}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SpecsType
          hideNotes
          disabled={disabled}
          label={'Accessory'}
          category={'shelving'}
          type={'Organizer Accessories'}
          attributes={['color', 'size', 'accessory']}
          handleChange={handleSpecChange('shelving', 'accessory')}
          specs={accessory.specs}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom={false}>
          Quantity
        </Typography>
        <CustomToggleGroup
          disabled={disabled}
          items={{
            '1': 'One',
            '2': 'Two',
            '3': 'Three',
            '4': 'Four',
          }}
          value={accessory.quantity || '1'}
          handleChange={(event, value) => handleChange('quantity', value || '') }
        />
      </Grid>
      
    </Grid>
  </Paper>
)

const ShelfOrganizer = (props) => {
  const {shelving, handleChange, disabled, showOrganizer, organizerSpecs, handleSpecChange} = props;
  // console.log({shelving});
  const classes = useStyles();
  const confirm = useConfirmation();
  
  const addAccessory = () => {
    handleChange('accessories', [
      ...shelving.accessories,
      {
        quantity: '1',
        specs: {}
      }
    ])
  }

  const handleAccessoryChange = (index) => (attr, value) => {
    const newAccessories = shelving.accessories.map((item, i) => (index === i ? {...item, [attr]: value} : item))
    handleChange('accessories', newAccessories)
  }
  
  const handleAccessorySpecChange = (index) => (category, type) => ({specs}) =>{
    const newAccessories = shelving.accessories.map((item, i) => (index === i ? {...item, specs: {category, type, ...specs}} : item))
    handleChange('accessories', newAccessories)
  }

  const handleOrganizerWallSpecChange = (wall) => (category, type) => ({specs, copy, propagate}) => {
    if (wall) {
      handleChange('organizerSpecs', {
        ...shelving.organizerSpecs,
        [wall]: specs,
      });
    }
    handleSpecChange(category, type)({specs, copy, propagate});
  }

  const handleGroupChange = (group, attribute, value) => {
    const newGroup = {
      ...shelving[group] || {},
      [attribute]: value
    }
    handleChange(group, newGroup)
  }
  
  const removeAccessory = (i) => {
    confirm({
      variant: "danger",
      catchOnCancel: true,
      title: `Remove Accessory ${i+1}`,
      description: `Are you sure you want to remove accessory ${i+1}?`
    })
    .then(() => {
      const newAccessories = shelving.accessories.filter((item, index) => index !== i)
      handleChange('accessories', newAccessories)
    })
    .catch(() => {});
  }

  const addOrganizerDesign = (wall) => {
    const newWallDesigns = {
      ...(shelving.wallDesigns || {}),
      [`wall${wall}`]: {
        ...(shelving.wallDesigns?.[`wall${wall}`] || {}),
        designs: [
          ...(shelving.wallDesigns?.[`wall${wall}`]?.designs || []),
          {
            design: '',
            size: '',
            width: '',
          }
        ]
      }
    };
    handleChange('wallDesigns', newWallDesigns)
  }

  const removeOrganizerDesign = (wall, index) => {
    confirm({
      variant: "danger",
      catchOnCancel: true,
      title: `Remove Design ${index+1}`,
      description: `Are you sure you want to remove design ${index+1}?`
    })
    .then(() => {
      const newWallDesigns = {
        ...(shelving.wallDesigns || {}),
        [wall]: {
          ...shelving.wallDesigns[wall],
          designs: shelving.wallDesigns[wall]?.designs.filter((item, i) => i !== index)
        }
      };
      handleChange('wallDesigns', newWallDesigns)
    })
    .catch(() => {});
  }

  const handleWallHasJoiner = (wall) => (event) => {
    const newWallDesigns = {
      ...(shelving.wallDesigns || {}),
      [wall]: {
        ...shelving.wallDesigns?.[wall],
        hasJoiner: event.target.checked
      }
    };
    handleChange('wallDesigns', newWallDesigns)
  }

  const handleOrganizerWallDesign = (wall, j, field) => (event, value) => {
    const newWallDesigns = {
      ...(shelving.wallDesigns || {}),
      [wall]: {
        ...shelving.wallDesigns[wall],
        designs: shelving.wallDesigns?.[wall]?.designs.map((item, i) => (i === j ? {
          ...item, 
          [field]: event?.target?.value || ''
        } : item))
      }
    };
    handleChange('wallDesigns', newWallDesigns)
  }

  const organizerWallDesigns = () => {
    const noOfWalls = organizerLayouts[shelving.layout].noOfWalls;
    const walls = Array(noOfWalls).keys() || [];
    return [...walls].map((i) => (
      <Grid item xs={12} md={noOfWalls === 1 ? 12 : noOfWalls == 2 ? 6 : 4} key={i}>
        <Paper elevation={1}>
          <Grid container spacing={2} className={classes.box}>
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant={'h6'}>Wall {noOfWalls > 1 && (i+1)}</Typography>
                {
                  shelving.layout !== 'reach_in' &&
                  <>
                    <ClosetSide type={`${shelving.layout}_SVG`} side={i+1} />
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={disabled}
                          checked={shelving.wallDesigns?.[`wall${i+1}`]?.hasJoiner || false}
                          onChange={handleWallHasJoiner(`wall${i+1}`)}
                        />
                      }
                      label={'Add Joiner'}
                    />
                  </>
                }
              </Box>
            </Grid>
            {
              shelving.wallDesigns?.[`wall${i+1}`]?.designs?.map((design, j) => (
                <Grid item xs={12} key={j}>
                  <Grid container spacing={2} className={classes.box}>
                    <Grid item xs={12}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography variant={'subtitle1'}>Design {j+1}</Typography>
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeOrganizerDesign(`wall${i+1}`, j)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <AttributeSelect 
                        disabled={disabled}
                        field={'design'}
                        category={'shelving'}
                        type={'organizer'}
                        title={'Design'}
                        handleChange={handleOrganizerWallDesign(`wall${i+1}`, j, 'design')}
                        refSpec={{
                          ...organizerSpecs,
                          size: design.size,
                          design: design.design
                        }}
                        value={design.design}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AttributeSelect 
                        disabled={disabled}
                        field={'size'}
                        category={'shelving'}
                        type={'organizer'}
                        title={'Size'}
                        handleChange={handleOrganizerWallDesign(`wall${i+1}`, j, 'size')}
                        refSpec={{
                          ...organizerSpecs,
                          design: design.design,
                          size: design.size,
                        }}
                        value={design.size}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        type={"number"}
                        // margin="dense"
                        variant="outlined"
                        disabled={disabled}
                        label={'Design Width'}
                        placeholder={`Enter the design width in inches`}
                        value={design.width || ""}
                        onChange={handleOrganizerWallDesign(`wall${i+1}`, j, 'width')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))
            }
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => addOrganizerDesign(i+1)}
                fullWidth
              >
                Add Organizer Design
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )) 
  }

  return (
    <>
      {
        showOrganizer &&
        <Grid item xs={12}>
          <SpecsType
            hideNotes
            disabled={disabled}
            label={'Organizer'}
            category={'shelving'}
            type={'organizer'}
            attributes={['color', 'hardware']}
            handleChange={handleSpecChange('shelving', 'organizer')}
            specs={organizerSpecs}
          />
        </Grid>
      }
      {
        showOrganizer &&
        organizerSpecs.color &&
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom={false}>
            Layout
          </Typography>
          <CustomToggleGroup
            disabled={disabled}
            items={Object.keys(organizerLayouts).reduce((a, i) => ({
              ...a,
              [i]: organizerLayouts[i].label
            }), {})}
            value={shelving.layout}
            handleChange={(event, value) => handleChange('layout', value || '', { dimensions: {} })}
          />
        </Grid>
      }
      {
        shelving.layout && 
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.box}>
              {
                shelving.layout === 'reach_in' && 
                <Grid item xs={12}>
                  <img src={ReachInHelper} width={600} />
                </Grid>
              }
              {
                organizerLayouts[shelving.layout].dimensions.map((dimension, i) => (
                  <Grid key={i} item md={6} xs={12}>  
                    <FormControl fullWidth margin={'dense'}>
                      <TextField
                        required
                        type={"number"}
                        margin="dense"
                        variant="outlined"
                        disabled={disabled}
                        label={`${dimension.includes('width') ? 'Wall' : ''} ${dimension.replace(/_/g, ' ')}`}
                        placeholder={`Enter the organizer ${dimension.replace(/_/g, ' ')} in inches`}
                        value={shelving.dimensions?.[dimension] || ''}
                        onChange={(event) => handleGroupChange('dimensions', dimension, event.target.value)}
                      />
                    </FormControl>
                  </Grid>  
                ))
              }
          </Grid>
        </Grid>
      }
      {
        showOrganizer &&
        shelving.layout &&
        organizerWallDesigns()
      }
      {
        organizerSpecs.color && 
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                disabled={disabled}
                checked={shelving.hasInstall || false}
                onChange={(event) => handleChange('hasInstall', event.target.checked)}
              />
            }
            label={'Install Organizer'}
          />
        </Grid>
      }
      {
        organizerSpecs.color && 
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                disabled={disabled}
                checked={shelving.hasCallout || false}
                onChange={(event) => handleChange('hasCallout', event.target.checked)}
              />
            }
            label={'Call out charge'}
          />
        </Grid>
      }
      {
        shelving.accessories.length > 0 &&
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                disabled={disabled}
                checked={shelving.hasAccessoryInstall || false}
                onChange={(event) => handleChange('hasAccessoryInstall', event.target.checked)}
              />
            }
            label={'Install Accessories'}
          />
        </Grid>
      }
      <Grid item xs={12}>
        {
          shelving.accessories.map((accessory, i) => (
            <Accessory 
              classes={classes}
              handleSpecChange={handleAccessorySpecChange(i)} 
              handleChange={handleAccessoryChange(i)} 
              removeAccessory={()=>removeAccessory(i)} 
              accessory={accessory} 
              key={i} 
              index={i+1} 
            />
          ))
        }
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => addAccessory()}
          fullWidth
        >
          Add Accessory
        </Button>
      </Grid>
      {
        shelving.layout &&
        <Grid item xs={12}>
        <Paper elevation={1}>
          <Grid container spacing={2} className={classes.box}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom={false}>
                Measurements
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {
                Object.keys(miscellaneous).map((key, i) => (
                  <Grid key={key} container spacing={1} className={classes.box}>
                      {
                        miscellaneous[key].type === 'switch' ?
                        <Grid item md={4} xs={12} key={i} style={{alignContent: 'center'}}>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={disabled}
                                checked={shelving['miscellaneous']?.[key] || false}
                                onChange={(event) => handleGroupChange('miscellaneous', key, event.target.checked)}
                              />
                            }
                            label={miscellaneous[key].label}
                          />
                        </Grid>
                        :
                        <Grid item md={miscellaneous[key].capture? 4 : 12} xs={12} key={i}  style={{alignContent: 'center'}}>
                          <TextField
                            fullWidth
                            required
                            margin="dense"
                            variant="outlined"
                            disabled={disabled}
                            label={miscellaneous[key].label}
                            placeholder={`Enter the ${miscellaneous[key].label}`}
                            value={shelving['miscellaneous']?.[key] || ''}
                            onChange={(event) => handleGroupChange('miscellaneous', key, event.target.value)}
                          />
                        </Grid>
                      }
                      {
                        miscellaneous[key].capture && shelving['miscellaneous']?.[key] &&
                        miscellaneous[key].capture.map((dimension, i) => (
                          <Grid item  md={miscellaneous[key].capture? 4 : 12} xs={12} key={i}>
                            <FormControl fullWidth margin={'dense'} key={i}>
                              <TextField
                                required
                                type={"number"}
                                margin="dense"
                                variant="outlined"
                                disabled={disabled}
                                label={dimension.replace(/_/g, ' ')}
                                placeholder={`Enter the ${dimension.replace(/_/g, ' ')} in inches`}
                                value={shelving.dimensions?.[dimension] || ''}
                                onChange={(event) => handleGroupChange('dimensions', dimension, event.target.value)}
                              />
                            </FormControl>
                          </Grid>
                        ))
                      }
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Paper>
        </Grid>
      }
    </>
  )
}

export default ShelfOrganizer;