import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Avatar, Grid, Divider, ListItem, Typography} from '@material-ui/core';
import TakeoffListItemActions from './TakeoffListItemActions'
import moment from 'moment'
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { gql, useQuery } from '@apollo/client';

const CHECK_LOCKED = gql`
  query checkLocked ($id: ID!) {
    checkLocked (id: $id) {
        isLocked checkedAt lockedAt
    }
  }
`;

const useStyles = makeStyles((theme) => ({
    listItemTakeoffs: {
        justifyContent: 'space-between',
    },
    purpleAvatar: {
        color: '#fff',
        backgroundColor: theme.palette.secondary[700],
    },
    verticalAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

moment.updateLocale('en', {
    relativeTime : {
        future: "in %s",
        past:   "%s ago",
        s  : 'a sec',
        ss : '%ds',
        m:  "a min",
        mm: "%dm",
        h:  "an hr",
        hh: "%dh",
        d:  "a day",
        dd: "%dd",
        M:  "a month",
        MM: "%dmo",
        y:  "a year",
        yy: "%dy"
    }
});

const TakeoffListItem = React.memo(({ takeoff, lastItem }) => {
    const classes = useStyles();
    const totalItems = takeoff.doorsNo + takeoff.trimNo + takeoff.hardwareNo + takeoff.openingNo + takeoff.shelvingNo + takeoff.indvItemNo + takeoff.indvInstallNo;

    const [ping, setPing] = useState(false)
    const [locked, setLocked] = useState(false)
    const {  data, startPolling, stopPolling } = useQuery(CHECK_LOCKED, {
        variables: {id: takeoff.id},
        skip: !locked
    })
    // console.log(data)

    const initals = (name = '') => {
        let initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }
    const to = {
        pathname:  `/updateTakeoff/${ takeoff.id}`, takeoffid: `${ takeoff.id}`
    }
    const CustomLink = React.useMemo(
        () =>
          React.forwardRef((linkProps, ref) => (
            <Link 
                ref={ref} 
                to={to} 
                {...linkProps} />
            )),
        [to],
    );

    useEffect(() => {
        if (takeoff.isLocked) {
          // console.log(`TO is locked at opening`)
          setLocked(true)
        }
    }, []);

    useEffect(() => {
        if (locked && !ping) {
          // console.log(`TO is locked at opening`)
          setPing(true)
        }
    }, [ping, locked]);

    useEffect(() => {
        if (ping) {
            startPolling(10000)
        } else {
            stopPolling()
        }
    }, [ping, startPolling, stopPolling]);

    useEffect(() => {
        if (data?.checkLocked?.isLocked === false) {
            setPing(false);
            setLocked(false)
        }
      }, [data, setLocked, setPing]);

    return (
        <>
            <ListItem
                button
                className={classes.listItemTakeoffs}
                component={CustomLink}
            >
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={2} className={classes.verticalAlign}>
                        <Avatar className={classes.purpleAvatar}>
                            { initals(takeoff.createdBy?.name) }
                        </Avatar>
                        <Typography variant="caption">{takeoff.createdBy?.username}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                {
                                    locked && 
                                    <LockRoundedIcon 
                                        fontSize="small"
                                    />
                                }
                                {
                                    takeoff.updatedBy &&
                                    moment( takeoff.createdAt, 'YYYY-MM-DD HH:mm').toString() !== moment( takeoff.updatedAt, 'YYYY-MM-DD HH:mm').toString() &&
                                    <Typography variant="caption" gutterBottom>
                                        {
                                            `Updated ${moment( takeoff.updatedAt).fromNow()} by ${ takeoff.updatedBy.username}`
                                        }
                                    </Typography>
                                }
                                {
                                    takeoff.createdBy &&
                                    <Typography variant="caption" gutterBottom>
                                        {
                                            `${moment(takeoff.createdAt).format('MMM DD \'YY')}`
                                        }
                                        {
                                            takeoff.copiedFromId &&
                                            takeoff.copiedFromCode &&
                                            <span>
                                                {takeoff.copyType === 'additional' ? ' added to' : takeoff.copyType === 'specs' ? ` specs from ` : ` copied from `}#{takeoff.copiedFromCode.replace('TO-00', '')}
                                            </span>
                                        }
                                    </Typography>
                                }

                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {totalItems > 0 ? `${ takeoff.code} (${totalItems} items)` : `${ takeoff.code}`}
                                </Typography>
                            </Grid>
                            {
                                takeoff.site &&
                                Object.keys( takeoff.site).length > 0 &&
                                (
                                    ( 'civic' in  takeoff.site &&  takeoff.site.civic) ||
                                    ( 'lot' in  takeoff.site &&  takeoff.site.lot)
                                ) &&
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom>{`${ takeoff.site.civic || ''} ${ takeoff.site.lot ? `Lot ${ takeoff.site.lot}` : ''}`}</Typography>
                                </Grid>
                            }
                            {
                                 takeoff.customerType &&
                                 takeoff[takeoff.customerType] &&
                                Object.keys( takeoff[ takeoff.customerType]).length > 0 &&
                                <Grid item xs={12}>
                                    <Typography variant="body2" gutterBottom>
                                        { takeoff.customerType === 'customer' ?  takeoff.customer.name || '' : `${ takeoff.prospect.firstname || ''} ${ takeoff.prospect.lastname || ''}`}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <TakeoffListItemActions 
                            takeoffId={ takeoff.id} 
                            status={takeoff.status} 
                            isOwner={ takeoff.mine} 
                            isNotAdditional={takeoff.copyType !== 'additional'}
                        />
                    </Grid>
                </Grid>
            </ListItem>
            {
                !lastItem && <Divider />
            }
        </>
    )
}, (prevProps, nextProps) => prevProps.id === nextProps.id)

export default TakeoffListItem