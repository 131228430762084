import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import TakeoffInfoSummary from './TakeoffInfoSummary';
import * as Lib from '../lib/lib';

const DoorPrepDoors = (props) => {
  const { uoms, classes, jambTypes, jamb_prep, notes, door_prep, backset, loose, descriptions, ndLoose, doorNotes, hingeDesc } = props;
  return (
    <Grid container spacing={2} className="break-print">
        <Grid item xs={12}>
            <TakeoffInfoSummary
                showImages
                label="Door Prep"
                notes={notes}
            />
            <TakeoffInfoSummary
                isPrint
                showImages
                label="Door Prep"
                notes={notes}
            />
        </Grid>
        <Grid item xs={12}>
            <List className={classes.list+ " groupesdList"} subheader={<li />}>
                {
                    Object.keys(door_prep).sort().map((radius, d) => (
                        Object.keys(door_prep[radius]).sort().map((prehang, e) => {
                            return (
                                <React.Fragment key={d+e}>
                                    <ListSubheader className={classes.listSubheader}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}><Typography variant="body1">Doors</Typography></Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="body1">{radius} | {prehang}</Typography>
                                                {
                                                    radius &&
                                                    <Typography variant="caption">{hingeDesc[radius]}</Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={2}><Typography variant="body1">Dir</Typography></Grid>
                                            <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                        </Grid>
                                    </ListSubheader>
                                    {
                                        Object.keys(door_prep[radius][prehang]).sort().map((height, f) => (
                                            Object.keys(door_prep[radius][prehang][height]).sort().map((width, i) => (
                                                Object.keys(door_prep[radius][prehang][height][width]).sort().map((jamb_code, j) => (
                                                    Object.keys(door_prep[radius][prehang][height][width][jamb_code]).sort().map((jamb_depth, k) => (
                                                        Object.keys(door_prep[radius][prehang][height][width][jamb_code][jamb_depth]).sort().map((style, c) => (
                                                            Object.keys(door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style]).sort().map((direction, l) => (
                                                                Object.keys(door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style][direction]).sort().map((drill, m) => (
                                                                    Object.keys(door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style][direction][drill]).sort().map((hinges, n) => (
                                                                        <React.Fragment key={e+i+c+f+j+k+l+m+n}>
                                                                            <ListItem className={classes.summaryDoorListItem}>
                                                                                <Grid  container spacing={2}>
                                                                                    <Grid item xs={3}><Typography>{style} {width}X{height}</Typography></Grid>
                                                                                    <Grid item xs={5}>
                                                                                        <Typography>
                                                                                            {drill} {drill === 'DRILL' && backset ? `, ${backset}" BACKSET` : ''}
                                                                                            {hinges !== 0 && hinges !== '0' && `, ${hinges} HINGES`}
                                                                                            {'prep' in door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style][direction][drill][hinges] ? `, ${door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style][direction][drill][hinges]['prep']}` : ''}
                                                                                        </Typography>
                                                                                        <Typography>{jamb_code || ""} {jamb_depth > 0 ? `(${jamb_depth}")` : ""}</Typography>
                                                                                        {
                                                                                            Lib.deepAttributeExists([drill, hinges, 'custom'], door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style][direction]) &&
                                                                                            // 'cut' in door_prep[style][height][direction][drill][hinges] &&
                                                                                            // 'cut' in door_prep[style][height][direction][drill][hinges]['cut'].length > 0 &&
                                                                                            door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style][direction][drill][hinges]['custom'].map( (custom, i) => (
                                                                                                <Typography key={i} variant="body2">
                                                                                                    Door {custom.ref || ""}: {Object.keys(custom).filter(i => i !== 'ref').map(o => `${o}: ${custom[o]}`).join(', ')}
                                                                                                </Typography>
                                                                                            ))
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid item xs={2}><Typography>{direction.replace(/__\d*/g, '')}</Typography></Grid>
                                                                                    <Grid item xs={2}><Typography>{door_prep[radius][prehang][height][width][jamb_code][jamb_depth][style][direction][drill][hinges]['count']}</Typography></Grid>
                                                                                    <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
                                                                                        <Typography variant="caption">{descriptions[`${style} ${width}X${height}`] || ''}</Typography>
                                                                                    </Grid>
                                                                                    {
                                                                                        direction.indexOf("__") > -1 &&
                                                                                        <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
                                                                                            <Typography variant="caption">
                                                                                                NOTE: {doorNotes[`dn_${direction.substring(direction.indexOf("__")+2)}`] || ``}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </ListItem>
                                                                        </React.Fragment>
                                                                    ))
                                                                ))
                                                            ))
                                                        ))
                                                    ))
                                                ))
                                            ))
                                        ))
                                    }
                                </React.Fragment>
                            )
                        })
                    ))
                }
                {
                    (
                        Object.keys(jamb_prep).length > 0 ||
                        Object.keys(loose).length > 0
                    ) &&
                    Object.keys(jambTypes).reverse().map((type, f) => (
                        <React.Fragment key={f}>
                            {
                                type === 'jamb' ?
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}><Typography variant="body1">Jambs</Typography></Grid>
                                        <Grid item xs={5}><Typography variant="body1">Prep</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Dir</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                    </Grid>
                                </ListSubheader> :
                                <ListSubheader className={classes.listSubheader}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}><Typography variant="body1">Custom Jambs</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Prep</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Lengths</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Depth</Typography></Grid>
                                        <Grid item xs={1}><Typography variant="body1">Dir</Typography></Grid>
                                        <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                    </Grid>
                                </ListSubheader>
                            }
                            {
                                type in jamb_prep &&
                                Object.keys(jamb_prep[type]).sort().map((height, i) => (
                                    Object.keys(jamb_prep[type][height]).sort().map((width, j) => (
                                        Object.keys(jamb_prep[type][height][width]).sort().map((direction, k) => (
                                            direction.indexOf('-H') > 0 ?
                                            Object.keys(jamb_prep[type][height][width][direction]).sort().map((hinges, l) => (
                                                Object.keys(jamb_prep[type][height][width][direction][hinges]).sort().map((code, m) => (
                                                    <React.Fragment key={f+i+j+k+l+m}>
                                                        <ListItem className={classes.summaryDoorListItem}>
                                                            {
                                                                type === 'jamb' ?
                                                                <Grid  container spacing={1}>
                                                                    <Grid item xs={3}><Typography>{code}</Typography></Grid>
                                                                    <Grid item xs={5}><Typography>{hinges !== 0 && hinges !== '0' && `${hinges} HINGES`}</Typography></Grid>
                                                                    <Grid item xs={2}><Typography>{direction}</Typography></Grid>
                                                                    <Grid item xs={2}><Typography>{jamb_prep[type][height][width][direction][hinges][code]} {Lib.uoms('PC')}</Typography></Grid>
                                                                    <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                                </Grid> :
                                                                <Grid  container spacing={1}>
                                                                    <Grid item xs={3}><Typography>{code}</Typography></Grid>
                                                                    <Grid item xs={2}><Typography>{hinges !== 0 && hinges !== '0' && `${hinges} HINGES`}</Typography></Grid>
                                                                    <Grid item xs={2}><Typography>{height}</Typography></Grid>
                                                                    <Grid item xs={2}><Typography>{width}</Typography></Grid>
                                                                    <Grid item xs={1}><Typography>{direction}</Typography></Grid>
                                                                    <Grid item xs={2}><Typography>{jamb_prep[type][height][width][direction][hinges][code]} {Lib.uoms('PC')}</Typography></Grid>
                                                                    <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                                </Grid>
                                                            }
                                                        </ListItem>
                                                    </React.Fragment>
                                                ))
                                            )) :
                                            Object.keys(jamb_prep[type][height][width][direction]).sort().map((code, m) => (
                                                <React.Fragment key={f+i+j+k+m}>
                                                    <ListItem className={classes.summaryDoorListItem}>
                                                        {
                                                            type === 'jamb' ?
                                                            <Grid  container spacing={1}>
                                                                <Grid item xs={3}><Typography>{code}</Typography></Grid>
                                                                <Grid item xs={5}><Typography>{direction.indexOf('-S') > 0 && 'STRIKE'}</Typography></Grid>
                                                                <Grid item xs={2}><Typography>{direction}</Typography></Grid>
                                                                <Grid item xs={2}><Typography>{jamb_prep[type][height][width][direction][code]} {Lib.uoms('PC')}</Typography></Grid>
                                                                <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                            </Grid> :
                                                            <Grid  container spacing={1}>
                                                                <Grid item xs={3}><Typography>{code}</Typography></Grid>
                                                                <Grid item xs={2}><Typography>{direction.indexOf('-S') > 0 && 'STRIKE'}</Typography></Grid>
                                                                <Grid item xs={2}><Typography>{height}</Typography></Grid>
                                                                <Grid item xs={2}><Typography>{width}</Typography></Grid>
                                                                <Grid item xs={1}><Typography>{direction}</Typography></Grid>
                                                                <Grid item xs={2}><Typography>{jamb_prep[type][height][width][direction][code]} {Lib.uoms('PC')}</Typography></Grid>
                                                                <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                            </Grid>
                                                        }
                                                    </ListItem>
                                                </React.Fragment>
                                            ))
                                        ))
                                    ))
                                ))
                            }
                            {
                                type in loose &&
                                Object.keys(loose[type]).map((code, i) => (
                                    Object.keys(loose[type][code]).map((dim, j) => (
                                        <React.Fragment key={i+j}>
                                            <ListItem className={classes.summaryDoorListItem}>
                                                {
                                                    type === 'jamb' ?
                                                    <Grid  container spacing={1}>
                                                        <Grid item xs={3}><Typography>{code}</Typography></Grid>
                                                        <Grid item xs={7}><Typography>LOOSE</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{loose[type][code][dim]['quantity']} PC</Typography></Grid>
                                                        <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                    </Grid> :
                                                    <Grid  container spacing={1}>
                                                        <Grid item xs={3}><Typography>{code}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>LOOSE</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{loose[type][code][dim]['count']}x({dim.substring(0, dim.indexOf("_"))}")</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{dim.substring(dim.indexOf("_")+1)}</Typography></Grid>
                                                        <Grid item xs={1}><Typography>{""}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{loose[type][code][dim]['quantity']} PC</Typography></Grid>
                                                        <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                    </Grid>
                                                }
                                            </ListItem>
                                        </React.Fragment>
                                    ))
                                ))
                            }
                        </React.Fragment>
                    ))
                }
                {
                    Object.keys(ndLoose).length > 0 &&
                    <>
                        <List className={classes.list+ " groupesdList"} subheader={<li />}>
                            <ListSubheader className={classes.listSubheader}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}><Typography variant="body1">Loose Jambs</Typography></Grid>
                                    <Grid item xs={7}><Typography variant="body1">Prep</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                </Grid>
                            </ListSubheader>
                            {
                                Object.keys(ndLoose).map((code, i) => (
                                    <React.Fragment key={i}>
                                        <ListItem className={classes.summaryDoorListItem}>
                                            <Grid  container spacing={1}>
                                                <Grid item xs={3}><Typography>{code}</Typography></Grid>
                                                <Grid item xs={7}><Typography>LOOSE</Typography></Grid>
                                                <Grid item xs={2}><Typography>{ndLoose[code]} { Lib.uoms(uoms[code])}</Typography></Grid>
                                                <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                            </Grid>
                                        </ListItem>
                                    </React.Fragment>
                                ))
                            }
                        </List>
                    </>
                }
            </List>
        </Grid>
    </Grid>
  );
};

export default DoorPrepDoors;