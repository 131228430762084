import React, {useEffect, useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
//components
import TakeoffListContext from "../TakeoffListContext";
import TakeoffListItem from "./TakeoffListItem";
import FilterTakeoffs from "./FilterTakeoffs";
import TopBar from '../common/TopBar';
import {humanize} from '../common/lib'
import { ConfirmModal } from "../common/hooks";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // textAlign: 'center'
    },
    button:{
        textAlign: 'center',
        margin: theme.spacing(1),
    },
    subHeaderCount: {
        textAlign: 'right'
    },
    listSubheader: {
        textAlign: 'left',
        backgroundColor: theme.palette.grey[100],
        paddingTop: '8px',
        paddingBottom: '8px'
    },
    list: {
        position: "relative",
        overflow: "auto",
        width: '100%',
        padding: 0
    },
    chip:{
        margin: '0 0 0 8px',
    },
    searchField: {
        padding: '0px !important'
    },
    listContainer: {
        marginTop: '16px'
    },
    progress: {
        margin: theme.spacing(2),
    },
    listSection: {
        backgroundColor: theme.palette.background.paper
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0
    },
    "@media (min-width: 1080px)":{
        list:{
            boxShadow: theme.shadows[5],
        },
    },
    filterPaper:{
        margin: "16px 0px 24px 0px"
    },
    bar: {
        padding: '16px'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FilterBar({authors, team, getAuthorsNames, classes}) {
    const filters = JSON.parse(localStorage.getItem('filter') || '{}');
    let date = ""
    if (
        ('from' in filters && filters.from) ||
        ('to' in filters && filters.to)
    ) {
        // console.log(filters.from)
        date += `${('from' in filters && filters.from) ? moment(filters.from).format("MMM DD").toString() : 'Up '} `
        date += `to ${('to' in filters && filters.to) ? moment(filters.to).format('MMM DD').toString() : 'Today'} `

    }
    return (
        <div className={classes.bar}>
            {
                filters &&
                'code' in filters &&
                filters.code &&
                <div>
                    <Typography variant="body2" color="inherit">
                        TO-00{filters.code}
                    </Typography>
                </div>
            }
            {
                filters &&
                'status' in filters &&
                filters.status &&
                <div>
                    <Typography variant="body2" color="inherit">
                        Status {filters.status.map(status => humanize(status)).join(', ')}
                    </Typography>
                </div>
            }
            {
                'customer' in filters && filters.customer &&
                <div>
                    <Typography variant="body2" color="inherit">
                        Customer: {filters.customer}
                    </Typography>
                </div>
            }
            {
                date &&
                <div>
                    <Typography variant="body2" color="inherit">
                        {date}
                    </Typography>
                </div>
            }
            {
                (!('authors' in filters) || filters.authors.length < 1) &&
                <div>
                    <Typography variant="body2" color="inherit">
                        By {
                            (
                                localStorage.getItem('role') === 'staff' &&
                                authors.filter(user => team.includes(user.id)).map(user => user.username).length > 0 &&
                                authors
                                    .filter(user => team.includes(user.id))
                                    .map(user => user.username)
                                    .join(', ')
                            ) ||
                            (
                                localStorage.getItem('role') === 'staff' &&
                                authors.filter(user => team.includes(user.id)).map(user => user.username).length < 1 &&
                                'Team'
                            ) ||
                            (
                                localStorage.getItem('role') === 'admin' && 'Everyone'
                            ) ||
                            (
                                localStorage.getItem('role') === 'foreman' && localStorage.getItem('username')
                            )
                        }
                    </Typography>
                </div>
            }
            {
                'authors' in filters && filters.authors.length > 0 &&
                <div>
                    <Typography variant="body2" color="inherit">
                        By {getAuthorsNames(filters.authors)}
                    </Typography>
                </div>
            }
        </div>
    )
}

const dialogMessages = {
    cpu: 'Are you sure you want to CPU on this takeoff?',
    restore: 'Are you sure you want to restore this takeoff?',
    archive: 'Are you sure you want to archive this takeoff?',
    delete: 'Are you sure you want to delete this takeoff?',
    clone: 'Are you sure you want to duplicate this takeoff?',
    "clone-specs": 'Are you sure you want to duplicate the specs of this takeoff?',
    "add-additional": 'Are you sure you want to add additional order to this takeoff?',
}

const TakeoffList = () => {
    const classes = useStyles();
    const context = useContext(TakeoffListContext);
    const {
        takeoffAction = {},
        confirmDialogOpen,
        showFilters,
        takeoffs,
        loadMoreLabel,
        canPaginate,
        loading,
        team,
        authors
    } = context.state;

    const {
        // id: actionId, 
        action
    } = takeoffAction;

    useEffect(() => {
        const filter = JSON.parse(localStorage.getItem('filter') || '{}');
        context.setState({
            filter,
        })
    }, [])
    return (
        <div className={classes.root}>
            <ConfirmModal
                open={confirmDialogOpen}
                message={action && dialogMessages[action]}
                textOk={'Confirm'}
                textCancel={'Cancel'}
                handleOk={() => context.doAction()}
                handleCancel={() => context.setState({takeoffAction: {}, confirmDialogOpen: false})}
            />
            <Dialog
                fullScreen
                TransitionComponent={Transition}
                open={showFilters}
                onClose={() => context.closeFilters()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <FilterTakeoffs context={context} />
                </DialogContent>
            </Dialog>
            <TopBar showFilter={context.showFilter} isLoggedIn={true} title="Takeoffs" />
            <FilterBar 
                team={team}
                authors={authors}
                getAuthorsNames={context.getAuthorsNames}
                classes={classes} 
            />
            {
                loading ?
                    <CircularProgress size={50} className={classes.progress} /> :
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <List className={classes.list}>
                                {
                                    takeoffs.length > 0 ?
                                        takeoffs.map((takeoff, i) => {
                                            return <TakeoffListItem
                                                context={context}
                                                id={takeoff.id}
                                                takeoff={takeoff}
                                                key={i}
                                                data-id={takeoff.id}
                                                count={i + 1}
                                                lastItem={i===takeoffs.length-1}
                                            />
                                        })
                                        :
                                        <ListItem>
                                            <Typography variant={'subtitle1'}>
                                                No Takeoffs found!
                                            </Typography>
                                        </ListItem>
                                }
                            </List>
                        </Grid>
                        {
                            takeoffs.length > 0 &&
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Button
                                    variant="contained"
                                    disabled={!canPaginate}
                                    color="secondary"
                                    className={classes.button}
                                    onClick={ () => context.paginate()}
                                >
                                    {loadMoreLabel}
                                </Button>
                            </Grid>
                        }
                    </Grid>
            }
        </div>
    )
};

export default TakeoffList
