import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import MeasureListItemActions from "./MeasureListItemActions";
import ProductState from './ProductState';
import * as Lib from "../lib/lib";

const useStyles = makeStyles((theme) => ({
    avoidBreak: {
        '@media print': {
            display: 'block',
            breakInside: 'avoid',
        }
    },
    measureHardwareListItem: {
        justifyContent: 'space-between',
        '@media print': {
            breakInside: 'avoid',
        }
    },
    negativeItem: {
        backgroundColor: theme.palette.secondary[100]
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const MeasureHardwareListItem = ({ hardware, dIndex, context, disabled, cpu }) => {
    const classes = useStyles();
    return (
        <div className={hardware.count < 0 ? classes.negativeItem : ''}>
            <ListItem
                button
                className={classes.measureHardwareListItem}
                onClick={() => {
                    context.editMeasureHardware(dIndex)
                }}
            >
                <Grid alignItems='center' container className={classes.avoidBreak} spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant={ "body2" } gutterBottom>
                            <ProductState cpu={cpu} code={hardware.product.code} />
                            {hardware.product.isSpecial && "**"}
                            {context.humanize(hardware.type)} 
                            {hardware.title || hardware.itemIndex} : Qty {hardware.count} : {hardware.product.code}
                            {
                                context.state.isAdditionalOrder && 
                                ` (${
                                    [
                                        hardware.additionalItem.labour,
                                        hardware.additionalItem.bill,
                                        hardware.additionalItem.po_required
                                    ]
                                    .filter (x => x)
                                    .map(x => x && Lib.humanize(x)).join(', ')
                                })`
                            }
                            {hardware.unitNumber ? ` (Unit ${hardware.unitNumber})` : ""}
                        </Typography>
                        <Typography variant="caption" gutterBottom>{hardware.product.description}</Typography>
                        {
                            hardware.images.length > 0 &&
                            <Typography variant={'caption'}>{hardware.images.length} IMAGE(S) ATTACHED</Typography>
                        }
                        {
                            hardware.notes &&
                            <Typography className={classes.ellipsis} variant="caption" gutterBottom>
                                NOTES | {hardware.notes}
                            </Typography>
                        }
                    </Grid>
                    {
                        !disabled &&
                        <Grid item xs={2}>
                            <MeasureListItemActions context={context} category={'hardware'} index={dIndex} />
                        </Grid>
                    }
                </Grid>
            </ListItem>
            <Divider />
        </div>
    )
}

export default MeasureHardwareListItem