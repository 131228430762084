import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import TakeoffInfoSummary from './TakeoffInfoSummary';
import * as Lib from '../lib/lib';

const DoorPrepOnsite = ({ classes, onsite, notes, descriptions}) => {

  return (
    (
        Object.keys(onsite.doors).length > 0 ||
        Object.keys(onsite.buildouts).length > 0 ||
        Object.keys(onsite.caps).length > 0 ||
        Object.keys(onsite.archways).length > 0 ||
        Object.keys(onsite.sills).length > 0
    ) ?
    <Grid spacing={2} container className="break-print">
        <Grid item xs={12}>
            <TakeoffInfoSummary
                isPrint
                label="Onsite Prep"
                notes={notes}
            />
        </Grid>
        <Grid item xs={12} style={{
            '@media print' : {
                clear: 'both',
                breakBefore: 'all !important',
            }
        }}>
            <List className={classes.list+ " groupesdList"} subheader={<li />}>
                {
                    Object.keys(onsite)
                    .filter(type => Object.keys(onsite[type]).length > 0)
                    .map( (type, i) => (
                        <React.Fragment key={i}>
                            <ListSubheader className={classes.listSubheader}>
                                <Grid container spacing={2}>
                                    <Grid item xs={type === 'archways' ? 4 : 6}><Typography variant="body1">{Lib.humanize(type)}</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">{type === 'doors' ? 'Prep' : ['sills', 'buildouts'].includes(type) ? 'Depth' : 'Width' }</Typography></Grid>
                                    <Grid item xs={2}><Typography variant="body1">{type === 'doors' ? 'Dir' : ['sills', 'buildouts'].includes(type) ? 'Length' : !['architrave'].includes(type) && 'Height'}</Typography></Grid>
                                    {
                                        type === 'archways' &&
                                        <Grid item xs={2}><Typography variant="body1">Depth</Typography></Grid>
                                    }
                                    <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                                </Grid>
                            </ListSubheader>
                            {
                                Object.keys(onsite[type]).map((code, m) => (
                                    Object.keys(onsite[type][code]).map((width, n) => (
                                        Object.keys(onsite[type][code][width]).map((height, l) => (
                                            type !== 'archways' ?
                                            <ListItem key={m+n+l} className={classes.summaryDoorListItem}>
                                                <Grid  container spacing={1}>
                                                    <Grid item xs={6}><Typography>{code}</Typography></Grid>
                                                    <Grid item xs={2}><Typography>{type === 'doors' && 'Door '}{width}{type !== 'doors' ? ' IN' : '"'}</Typography></Grid>
                                                    <Grid item xs={2}><Typography>{type === 'doors' ? height : !['architrave'].includes(type) && `${height} ${type === 'buildouts' ? 'LF' : 'IN'}`}</Typography></Grid>
                                                    <Grid item xs={2}><Typography>{onsite[type][code][width][height]}</Typography></Grid>
                                                    <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                </Grid>
                                            </ListItem>
                                            :
                                            Object.keys(onsite[type][code][width][height]).map((depth, o) => (
                                                <ListItem key={m+n+l+o} className={classes.summaryDoorListItem}>
                                                    <Grid  container spacing={1}>
                                                        <Grid item xs={4}><Typography>{code}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{type === 'doors' && 'Door '}{width}{width !== '-' ? type !== 'doors' ? ' IN' : '"' : ''}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{type === 'doors' ? height : !['architrave'].includes(type) && `${height} ${height !== '-' ? type === 'buildouts' ? 'LF' : 'IN' : ''}`}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{depth ? `${depth} IN` :  ''}</Typography></Grid>
                                                        <Grid item xs={2}><Typography>{onsite[type][code][width][height][depth]}</Typography></Grid>
                                                        <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                                    </Grid>
                                                </ListItem>
                                            ))

                                        ))
                                    ))
                                ))
                            }
                        </React.Fragment>
                    ))
                }
            </List>
        </Grid>
    </Grid> : ""
  );
};

export default DoorPrepOnsite;