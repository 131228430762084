import React, {useState} from 'react';
//material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
//components
import SpecStatusIcon from './SpecStatusIcon';
import AvailableDimensions from './AvailableDimensions';
import * as Lib from "../lib/lib";
import SpecsType from "./SpecsType";
import CustomToggleGroup from '../../common/CustomToggleGroup'
import {ConfirmModal} from '../../common/hooks'

const useStyles = makeStyles(() => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper:{
        padding:'16px',
    },
    group:{
        flexDirection: 'row',
    },
    flex: {
        display: 'flex'
    }
}));

const Doors = ({ context }) => {
    const classes = useStyles();
    const handleSpecChange = ({specs, propagate}) => {
        context.saveSpecs({
            category: 'door',
            specs,
            propagate
        })
    };
    const [cutDown, setCutDown] = useState('');
    const handleSelectCutdown = (value) => {
        if (value) {
            setCutDown(value);
        } else
            context.saveDoorTreatment('cutdown',  '');
    }
    const handleOk = () => {
        context.saveDoorTreatment('cutdown', cutDown || '', true);
        setCutDown('');
    }
    const handleCancel = () => {
        context.saveDoorTreatment('cutdown', cutDown || '');
        setCutDown('');
    }

    const specs = context.state.specs;
    const disabled = context.isDoneTakeoff();
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.flex}>
                            <SpecStatusIcon
                                specs={context.state.specs.door}
                                attributes={['supplier', 'type', 'height', 'style', 'thickness', 'core', 'finish', 'material', 'panelProfile', 'sticking']}
                            />
                            <Typography variant="h6" gutterBottom={false}>Door Properties</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                !Lib.isEmptyOrNullObject(context.state.specs.door) &&
                                <AvailableDimensions context={context} category={'door'} specs={context.state.specs.door} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <SpecsType
                                hideCopy
                                disabled={context.isDoneTakeoff()}
                                category={'door'}
                                attributes={['supplier', 'type', 'height', 'style', 'thickness', 'core', 'finish', 'material', 'panelProfile', 'sticking' ]}
                                handleChange={handleSpecChange}
                                specs={context.state.specs.door}
                            />
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} elevation={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom={false}>Door Treatments</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle1'}>Door Prep</Typography>
                            <CustomToggleGroup
                                disabled={disabled}
                                items={{
                                    loose: 'Loose',
                                    machined: 'Machined',
                                    'u-channel': 'U-Channel',
                                }}
                                value={specs.door_treatment.prep}
                                handleChange={ (event, value) => {
                                    context.saveDoorTreatment('prep', value || '');
                                }}
                            />
                        </Grid>
                        {
                            ['u-channel', 'machined'].includes(specs.door_treatment.prep) &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant={'subtitle1'}>Backset</Typography>
                                    <CustomToggleGroup
                                        disabled={disabled}
                                        items={{
                                            '2 3/8': '2 3/8"',
                                            '2 3/4': '2 3/4"',
                                        }}
                                        value={specs.door_treatment.backset}
                                        handleChange={ (event, value) => context.saveDoorTreatment('backset', value || '') }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={'subtitle1'}>Head</Typography>
                                    <CustomToggleGroup
                                        disabled={disabled}
                                        items={{
                                            loose: 'Loose',
                                            dado: 'Dado',
                                            ontop: 'On Top',
                                            between: 'Between',
                                        }}
                                        value={specs.door_treatment.head}
                                        handleChange={ (event, value) => context.saveDoorTreatment('head', value || '') }
                                    />
                                </Grid>
                            </>
                        }
                        {
                            (
                                context.state.specs.door_treatment.prep === 'machined' ||
                                context.state.installPricingMode === 'contract_pricing'
                            ) &&
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom={false}>
                                    Cutdown Prep
                                </Typography>
                                <ConfirmModal
                                    open={cutDown !== ''}
                                    title={'Cutdown Height Change'}
                                    message={'Do you want to apply this selection to existing products?'}
                                    textOk={'Yes'}
                                    textCancel={'No'}
                                    handleOk={handleOk}
                                    handleCancel={handleCancel}
                                />
                                <CustomToggleGroup
                                    disabled={disabled}
                                    disableAutoSelect
                                    items={{
                                        ...(context.state.installPricingMode === 'contract_pricing'  && {'onsite': 'On Site'}),
                                        ...(specs.door_treatment.prep === 'machined' && {'inshop': 'In Shop'})
                                    }}
                                    value={specs.door_treatment.cutdown}
                                    handleChange={ (event, value) => {
                                        handleSelectCutdown(value)
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Doors