import React, {useState, useEffect, useContext} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SpecsChips from '../Specs/SpecsChips'
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {TakeoffFormContext} from '../../TakeoffFormContext';
import {usePrevious} from '../../common/hooks'
import * as Lib from "../lib/lib";
import SpecialSpecs from "./SpecialSpecs";
import TakeoffNotes from "../TakeoffNotes";
import SearchProduct from "../Measure/SearchProduct";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root:{

    },
    chip: {
        margin: theme.spacing(1),
    },
    box: {
        border: '1px solid '+theme.palette.grey[300],
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[100],
    },
    button: {
        margin: theme.spacing(1),
        float: 'right',
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    actions: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end'
    },
    dialog: {
        minWidth: '320px'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SpecsType = (props) => {
    const context = useContext(TakeoffFormContext);
    const { hideNotes=false, type, refSpecs, specs = {}, globalSpecs = null, disabled, fullScreen, category, attributes,  handleChange, hideCopy = false, extraAttributes = {}, label } = props;
    const prevType = usePrevious(type) || "";
    const prevSpecs = usePrevious(specs) || {};
    // console.log(prevSpecs, specs)
    const [localSpecs, setLocalSpecs] = useState({})
    const [state, setState] = useState({
        modal: false,
        copy: false,
        propagate: false,
        doorHeightChanged: false
    })
    useEffect(() => {
        if (Object.keys(specs).length > 0)
            setLocalSpecs(specs);
    }, []);

    useEffect(() => {
        if (
            prevType !== type ||
            (
                Object.keys(specs).length > 0 &&
                Object.keys(prevSpecs).length === 0
            )
        ) {
            // console.log(specs)
            setLocalSpecs(specs);
        }
    }, [prevType, Object.keys(specs).length, Object.keys(prevSpecs).length]);
    
    const classes = useStyles();
    // if (props.refSpecs && props.refSpecs.length > 0) console.log(specs)
    const emptyAttributes = attributes
                            .filter(attribute => (!localSpecs || !localSpecs[attribute] || localSpecs[attributes] === ''));
    const emptySpecs = Object.keys(localSpecs).filter(spec => localSpecs[spec]).length < 1;

    // if (type === 'bipass_door_track')
    //     console.log(category, type, localSpecs, specs, attributes, emptyAttributes, emptySpecs);
    // console.log(emptyAttributes , emptyAttributes.length > 0 , emptyAttributes.length , Object.keys(attributes).length);
    // console.log(localSpecs, emptyAttributes);
    // console.log(
    //     type, 
    //     !emptySpecs && emptyAttributes.length === 0 ? "default" : "secondary",
    //     emptySpecs,
    //     emptyAttributes,
    // )
    const doorHeightChanged = category === 'door' &&
                                (
                                    (Lib.deepAttributeExists(['height'], specs) && Lib.deepAttributeExists(['height'], specs) !== Lib.deepAttributeExists(['height'], localSpecs)) ||
                                    (
                                        Lib.deepAttributeExists(['type'], specs) &&
                                        Lib.deepAttributeExists(['type'], specs) !== Lib.deepAttributeExists(['type'], localSpecs) &&
                                        (
                                            Lib.deepAttributeExists(['type'], specs) === 'Utility' ||
                                            Lib.deepAttributeExists(['type'], localSpecs) === 'Utility'
                                        )
                                    )
                                );
    const  handleConfirm = () => {
        setState({
            ...state,
            modal: false,
        })
        // side effect, should move to useEffect
        let specs = localSpecs;
        delete specs.category;
        if (
            category &&
            !['door'].includes(category.toLowerCase()) &&
            !['Interior', 'Passage', 'Entry'].includes(label)
        ) {
            delete specs.type
        }
        // console.log({
        //     specs, 
        //     copy: state.copy, 
        //     emptySpecs, 
        //     propagate: (emptySpecs || !doorHeightChanged) && state.propagate
        // })
        handleChange({
            specs, 
            copy: state.copy, 
            emptySpecs, 
            propagate: (emptySpecs || !doorHeightChanged) && state.propagate
        })
    };

    const handleClickOpen = () => {
        setState({ ...state, modal: true });
    };

    const handleClose = () => {
        setState({ ...state, modal: false, specs: {} });
    };

    const handleAttributeChange = (attribute, value) => {
        setLocalSpecs({
            ...localSpecs,
            [attribute]: value
        });
    };

    const handleSearch = (item) => {
        setState({
            ...state,
            modal: false,
        })
        const {code, description, id, ...specs} = item;
        handleChange({
            specs, 
            copy: state.copy, 
            emptySpecs, 
            propagate: (emptySpecs || !doorHeightChanged) && state.propagate
        });
    };

    const clearOrCopySpecs = () => {
        // console.log(globalSpecs, refSpecs, localSpecs )
        if (!Lib.isEmptyOrNullObject(globalSpecs) && Lib.isEmptyOrNullObject(localSpecs)) {
            setLocalSpecs(globalSpecs);
        } else {
            let specs = {};
            Object.keys(localSpecs)
                .forEach( spec => {
                    specs[spec] = refSpecs?.includes(spec) ? localSpecs[spec] : null;
                });
            setLocalSpecs(specs);
        }
    };

    return (
        <>
            <div className={classes.box}>
                <Grid container >
                    <Grid item xs={12} sm={8}>
                        <SpecsChips
                            disabled={disabled}
                            label={label}
                            context={context}
                            allowedAttributes={attributes}
                            category={category}
                            type={!['Interior', 'Passage', 'Entry'].includes(label) ? type : label.toLowerCase()}
                            refSpecs={specs}
                            specLabel={context.state.specialSpecsExpanded ? 'specialSpecs' : 'specs'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            disabled={disabled}
                            size="small"
                            variant="contained"
                            color={!emptySpecs && emptyAttributes.length === 0 ? "default" : "secondary"}
                            className={classes.button}
                            onClick={handleClickOpen}
                        >
                            {!emptySpecs && emptyAttributes.length === 0 ? "Edit" : "Add"}
                        </Button>
                    </Grid>
                </Grid>
                <Dialog
                    open={state.modal}
                    TransitionComponent={Transition}
                    fullScreen={fullScreen}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={'md'}
                >
                    <DialogTitle id="responsive-dialog-title">
                        {`${label ? label : Lib.humanize(category)} ${!label && type ? Lib.humanize(type) : ''} Specs`}
                        {
                                !hideCopy &&
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={disabled}
                                                checked={state.copy}
                                                onChange={(event) => setState({
                                                    ...state,
                                                    copy: event.target.checked,
                                                    ...(!event.target.checked && {propagate: false})
                                                })}
                                            />
                                        }
                                        label="Apply to Global Specs"
                                    />
                                </Grid>
                            }
                            {
                                (
                                    hideCopy &&
                                    !globalSpecs
                                ) &&
                                <Grid item xs={12}>
                                    <FormControl error={(emptySpecs || doorHeightChanged) ? true: false} component="fieldset">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={state.propagate}
                                                    disabled={disabled || doorHeightChanged}
                                                    onChange={(event) => setState({
                                                        ...state,
                                                        propagate: event.target.checked
                                                    })}
                                                />
                                            }
                                            label="Apply to Existing Products"
                                        />
                                        {
                                            doorHeightChanged &&
                                            <FormHelperText>
                                                Replacing doors with those of a different height is not supported.
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                            }
                    </DialogTitle>
                    <DialogContent className={classes.dialog}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="caption">Lookup by product</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SearchProduct
                                    category={"product"}
                                    handleSelect={handleSearch}
                                    disabled={disabled}
                                    type={Lib.humanize(category)}
                                    subType={Lib.humanize(type)}
                                    attributes={attributes}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginBlock: 20}}>
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Or add attributes individually</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SpecialSpecs
                                    disabled={disabled}
                                    category={category}
                                    type={type}
                                    attributes={attributes}
                                    specs={localSpecs}
                                    handleChange={handleAttributeChange}
                                    extraAttributes={extraAttributes}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{padding: '16px', display: 'flex'}}>
                        <Button
                            size="small"
                            variant={'contained'}
                            color="default"
                            onClick={() => clearOrCopySpecs()}
                            disabled={
                                Lib.isEmptyOrNullObject(localSpecs) &&
                                Lib.isEmptyOrNullObject(globalSpecs)
                            }
                        >
                            {
                                !Lib.isEmptyOrNullObject(globalSpecs) &&
                                Lib.isEmptyOrNullObject(localSpecs)
                                    ? 'Copy' : 'Clear'
                            }
                        </Button>
                        <div className={classes.actions}>
                            <Button
                                size="small"
                                onClick={handleClose} variant={'contained'} color="default" className={classes.marginRight}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                disabled={
                                    emptyAttributes && emptyAttributes.length > 0 && !emptySpecs
                                }
                                onClick={handleConfirm}
                                variant={'contained'}
                                color="secondary"
                            >
                                Apply
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
            {
                !globalSpecs &&
                !hideNotes &&
                <TakeoffNotes
                    showNotes
                    disabled={context.isDoneTakeoff()}
                    value={(context.state.specs.notes && context.state.specs.notes[(type || category).toLowerCase()]) || ""}
                    onChange={(value)=> context.saveSpecsNotes(type || category, value || '')}
                />
            }
        </>
    )
}

export default SpecsType