import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import MeasureListItemActions from "./MeasureListItemActions";
import ProductState from './ProductState';

import * as Lib from "../lib/lib";

const useStyles = makeStyles((theme) => ({
    avoidBreak: {
        '@media print': {
            display: 'block',
            breakInside: 'avoid',
        }
    },
    measureTrimListItem: {
        justifyContent: 'space-between',
        '@media print': {
            breakInside: 'avoid',
        }
    },
    negativeItem: {
        backgroundColor: theme.palette.secondary[100]
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const uoms = {
    "Lineal Feet"   : "LF",
    "Pieces"        : "PC",
    "Each"          : "EA",
    "Board Feet"    : "BF",
    "Pair"          : "PR",
    "Set"           : "ST",
};

const MeasureTrimListItem = ({ trim, dIndex, context, disabled, cpu }) => {
    const classes = useStyles();
    return (
        <div className={trim.count < 0 ? classes.negativeItem : ''}>
            <ListItem
                button
                className={classes.measureTrimListItem}
                onClick={() => {
                    context.editMeasureTrim(dIndex,trim.type)
                }}
            >
                <Grid alignItems='center' container className={classes.avoidBreak} spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant={ "body2" } gutterBottom>
                            <ProductState cpu={cpu} code={trim.product.code} />
                            {trim.product.isSpecial && "**"}{context.humanize(trim.type)} {trim.title || trim.itemIndex} : {trim.count} {uoms[trim.product.uom] || 'LF'}: {trim.product.code}
                            {
                                context.state.isAdditionalOrder && 
                                ` (${
                                    [
                                        trim.additionalItem.labour,
                                        trim.additionalItem.bill,
                                        trim.additionalItem.po_required
                                    ]
                                    .filter (x => x)
                                    .map(x => x && Lib.humanize(x)).join(', ')
                                })`
                            }
                            {trim.unitNumber ? ` (Unit ${trim.unitNumber})` : ""}
                        </Typography>
                        <Typography variant="caption" gutterBottom>{trim.product.description}</Typography>
                        {
                            context.state.installPricingMode !== 'none' &&
                            trim.install_service?.filter(service => service && 'code' in service && service.code)
                            .map((service, i) => (
                                <Typography key={i} variant="body2" gutterBottom>
                                    {context.humanize(service.code)}: {service.description}
                                </Typography>
                            ))
                        }
                        {
                            trim.images.length > 0 &&
                            <Typography variant={'caption'}>{trim.images.length} IMAGE(S) ATTACHED</Typography>
                        }
                        {
                            trim.notes &&
                            <Typography className={classes.ellipsis} variant="caption" gutterBottom>
                                NOTES | {trim.notes}
                            </Typography>
                        }
                    </Grid>
                    {
                        !disabled &&
                        <Grid item xs={2}>
                            <MeasureListItemActions context={context} category={'trim'} index={dIndex} />
                        </Grid>
                    }
                </Grid>
            </ListItem>
            <Divider />
        </div>
    );
}

export default MeasureTrimListItem