import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import TakeoffInfoSummary from './TakeoffInfoSummary';

const DoorPrepShelves = ({ shelves, notes, descriptions, classes }) => {
  return (
    Object.keys(shelves).length > 0 ?
        <Grid spacing={2} container className="break-print">
            <Grid item xs={12}>
                <TakeoffInfoSummary
                    isPrint
                    label="Shelf Prep"
                    notes={notes}
                />
            </Grid>
            <Grid item xs={12} style={{
                '@media print' : {
                    clear: 'both',
                    breakBefore: 'all !important',
                }
            }}>
                <List className={classes.list+ " groupesdList"} subheader={<li />}>
                    <ListSubheader className={classes.listSubheader}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}><Typography variant="body1">Shelf</Typography></Grid>
                            <Grid item xs={2}><Typography variant="body1">Width</Typography></Grid>
                            <Grid item xs={2}><Typography variant="body1">Qty</Typography></Grid>
                        </Grid>
                    </ListSubheader>
                {
                    Object.keys(shelves).map((code, m) => (
                        Object.keys(shelves[code]).map((width, n) => (
                            <ListItem key={m+n} className={classes.summaryDoorListItem}>
                                <Grid  container spacing={1}>
                                    <Grid item xs={8}><Typography>{code}</Typography></Grid>
                                    <Grid item xs={2}><Typography>{width} IN</Typography></Grid>
                                    <Grid item xs={2}><Typography>{Number(shelves[code][width]['count'])} PC</Typography></Grid>
                                    <Grid item xs={12}><Typography variant="caption">{descriptions[code] || ''}</Typography></Grid>
                                    {
                                        shelves[code][width]['notes'] &&
                                        Object.keys(shelves[code][width]['notes']).map((index) => (
                                            <Grid key={index} item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
                                                <Typography variant="caption">
                                                    NOTE {index}: {shelves[code][width]['notes'][index]}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </ListItem>
                        ))
                    ))
                }
                </List>
            </Grid>
        </Grid> : ""
  );
};

export default DoorPrepShelves;