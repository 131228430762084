import React, {useState, useEffect} from "react";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import DoorHandleRadio from './DoorHandleRadio';
import * as Lib from '../lib/lib';
import SpecsType from '../Specs/SpecsType';
import AttributeSelect from '../AttributeSelect';
import Divider from "@material-ui/core/Divider";
import CustomToggleGroup from '../../common/CustomToggleGroup'
import CustomHinge from "./CustomHinge";
import {usePrevious} from '../../common/hooks'

const useStyles = makeStyles((theme) => ({
    root:{
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop:'16px',
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper:{
        padding:'16px',
    },
    group:{
        flexDirection: 'row',
    },
    button: {
        margin: theme.spacing(1),
    },
    link: {
        textDecoration: 'underline',
        color: 'blue',
        cursor: 'pointer'
    }
}));

const getHingeCount = (doorSpecs, hingeSpecs) => {
    if (doorSpecs?.height) {
        if (doorSpecs.height >= 96) return Lib.deepAttributeExists(['extra_specs', 'door_96'], hingeSpecs) || '4';
        if (doorSpecs.height >= 84) return Lib.deepAttributeExists(['extra_specs', 'door_84'], hingeSpecs) || '3';
        if (doorSpecs.height < 84) return Lib.deepAttributeExists(['extra_specs', 'door_80'], hingeSpecs)  || '2';
        else return '2';
    }
}

const attributes = (type) => {
    let attributes = {
        doorstopper:        ['finish'],
        flushbolt:          ['length', 'finish', 'style', 'width'],
        catches:            ['style', 'finish'],
        lever:              ['brand', 'style', 'finish', 'rosette', 'firerated'],
        knob:               ['brand', 'style', 'finish', 'rosette', 'firerated'],
        bifold_knob:        ['brand', 'finish'],
        hinge:              ['width', 'height', 'radius', 'finish', 'style', 'brand'],
        pocket:             ['rosette', 'finish', 'brand' ],
        barn_door_track:    ['brand', 'style', 'finish', 'tracklength'],
        bipass_door_track:  ['brand', 'style'],
        finger_pull:        ['finish', 'size']
    };
    return type in attributes ? attributes[type] : [];
};

function OperatingDoor(props) {
    const {context} = props;
    const door = context.state.measures.door;
    return (
        (
            (
                door.latching     === 'flushbolt' &&
                door.doorFunction === 'swing' &&
                door.operating
            ) ||
            door.doorFunction === 'bifold'
        ) &&
        door.handleType &&
        Lib.hasValue(door.handleType) ?
        <DoorHandleRadio
            context={context}
            target={[props.direction + 'HandleMode']}
            direction={props.direction === 'left' ? 'LH' : 'RH'}
            type={door.handleType}
            field={'mode'}
            rel={'specialSpecs'}
            category={'Hardware'}
            group={'hardware'}
            title={`${context.humanize(props.direction)} Door Hardware`}
            allowedValues={
                door.doorFunction === 'swing' ? ['Passage', 'Privacy', 'Entry'] : ['Dummy']
            }
        /> : <Typography variant={"body1"}>{""}</Typography>

    )
}

function NonOperatingDoor(props) {
    const {context} = props;
    const door = context.state.measures.door;
    return (
        (
            (
                door.latching     === 'flushbolt' &&
                door.doorFunction === 'swing' &&
                door.operating
            ) ||
            door.doorFunction === 'bifold' ||
            door.latching === 'catches'
        ) &&
        door.handleType &&
        Lib.hasValue(door.handleType) ?
        <DoorHandleRadio
            context={context}
            target={[props.direction + 'HandleMode']}
            direction={props.direction === 'left' ? 'LH' : 'RH'}
            type={door.handleType}
            field={'mode'}
            rel={'specialSpecs'}
            category={'Hardware'}
            group={'hardware'}
            title={`${context.humanize(props.direction)} Door Hardware`}
            allowedValues={
                door.doorFunction === 'swing' ?
                    [
                        ...(door.latching === 'flushbolt' ? [{'Passage': 'Passage'}] : []),
                        {1: '1 Dummy'},
                        {2: '2 Dummy'},
                    ]
                    : [{1: 'Dummy'}]
            }
            special={'Dummy'}
        /> : <Typography variant={"body1"}>{""}</Typography>
    )
}

const NewDoorHardware = (props) => {
    const {context} = props;
    const door = context.state.measures.door;
    const prevContext = usePrevious(context)
    const [hardware_checked, setHardwareChecked] = useState(false)

    useEffect(() => {
        if (context.state.editDoorIndex !== -1) {
            if (context.state.measures.door.doorFunction) setHardwareChecked(true);
            const door = context.state.doors[context.state.editDoorIndex];
            // hinge count
            Object.keys(door.hardware)
            .filter(type => {
                return  (door.hardware[type] &&
                            'code' in door.hardware[type] &&
                            door.hardware[type].code
                        ) || type === 'double'
            })
            .forEach( type => {
                if ( type !== 'double' ) {
                    context.copySpecs('specs', 'specialSpecs', 'products', type, door.hardware[type]);
                }
                if (type === 'double' && door.hardware.double) {
                    Object.keys(door.hardware.double).forEach(side => {
                        Object.keys(door.hardware.double[side])
                        .filter(type => door.hardware.double[side][type])
                        .forEach(type => {
                            const item = door.hardware.double[side][type];
                            const attributes = Array.isArray(item) && item.length > 0 ? item[0].attributes : typeof item === 'object' ? item.attributes : null;
                            // console.log(type, item, attributes)
                            if (attributes ) {
                                context.copySpecs('specs', 'specialSpecs', 'hardware', type, attributes);
                            }
                        });
                    });
                } else {
                    context.copySpecs('specs', 'specialSpecs', 'hardware', type, door.hardware[type].attributes || {});
                }
            })
            if ('double' in door.hardware &&
                door.hardware.double
            ) {
                // TODO: handle products
                // Object.keys(door.hardware)
            }
        } else {
            context.copySpecs('specs', 'specialSpecs', 'hardware');
            context.copySpecs('specs', 'specialSpecs', 'products');
        }
    }, []);

    useEffect(() => {
        if (
            !hardware_checked &&
            ['swing', 'bifold'].includes(door.doorFunction) &&
            (
                door.handleType === "" ||
                (
                    door.isDouble &&
                    door.latching === ""
                )
            )
        ) {
            const knobSpecsSet =         !Lib.isEmptyOrNullObject(context.state.specs.hardware.knob);
            const leverSpecsSet =        !Lib.isEmptyOrNullObject(context.state.specs.hardware.lever);
            const flushboltSpecsSet =    !Lib.isEmptyOrNullObject(context.state.specs.hardware.flushbolt);
            const catchesSpecsSet =      !Lib.isEmptyOrNullObject(context.state.specs.hardware.catches);
            const selectHandle   =       door.handleType === "" && knobSpecsSet ^ leverSpecsSet;
            const selectLatching =       door.latching === ""   && flushboltSpecsSet ^ catchesSpecsSet;

            if (selectHandle) context.setMeasureDoorAttr('handleType', selectHandle ? knobSpecsSet ? 'knob' : 'lever' : null);
            if (door.isDouble && selectLatching) context.setMeasureDoorAttr('latching', selectLatching ? catchesSpecsSet ? 'catches' : 'flushbolt' : null);

            setHardwareChecked(true)
        }
        if (door.doorFunction) {
            const height    = Lib.deepAttributeExists(['height'], context.state.specialSpecs.door)
            const oldHeight = Lib.deepAttributeExists(['height'], prevContext?.state.specialSpecs.door) || height;
    
            // console.log(height, oldHeight, door.hingesPerDoor, context.state.specialSpecs.hardware.hinge)
            if (oldHeight !== height || !door.hingesPerDoor) {
                context.setMeasureDoorAttr('hingesPerDoor', getHingeCount(context.state.specialSpecs.door, context.state.specialSpecs.hardware.hinge));
            } 
        }
    }, [context, prevContext]);

    const handleSpecChange = (type) => ({specs, copy, propagate}) => {
        if (['hinge', 'bifold_knob', 'flushbolt', 'catches', 'barn_door_track', 'finger_pull'].includes(type)) {
            if (Lib.isEmptyOrNullObject(specs)) {
                context.setState({
                    specialSpecs: {
                        ...context.state.specialSpecs,
                        products: {
                            ...context.state.specialSpecs.products,
                            [type] : {}
                        },
                        hardware: {
                            ...context.state.specialSpecs.hardware,
                            [type]: {}
                        }
                    },
                })
            } else {
                const {extra_specs, ...productSpecs} = specs;
                const typeAttributes = {
                    category: 'Hardware',
                    type: type,
                    ...productSpecs,
                };
                const typePromise = context.getAnyProduct(typeAttributes);
                typePromise.then(product => {
                    if (product) {
                        const typeProduct = {
                            code:           product.code,
                            description:    product.description,
                            price:          product.pricingTiers,
                            uom:            product.uom,
                            // count:          specs.window.quantity,
                            attributes:     {...typeAttributes, extra_specs}
                        };
                        //console.log(typeProduct);
                        context.saveSpecs({
                            scope: 'specialSpecs',
                            category: 'hardware',
                            product: typeProduct,
                            type,
                            specs,
                            copy,
                            propagate
                        });
                    }
                })
            }
        } else {
            context.saveSpecs({
                scope: 'specialSpecs',
                category: 'hardware',
                type,
                specs,
                copy
            });
        }
    };

    const classes = useStyles();
    const disabled = context.isDoneTakeoff();
    const hingeCountObj = Lib.deepAttributeExists(['door', 'height'], context.state.specialSpecs) == 96 ? {'4': 'Four',} : {
        '2': 'Two',
        '3': 'Three',
        '4': 'Four',
    };
    
    return (
        <Paper className={classes.paper} elevation={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>Hardware</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom={false}>
                        Door Function
                    </Typography>
                    <CustomToggleGroup
                        disabled={disabled || !Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs)}
                        items={{
                            ...(!door.isBifold ? {swing: 'Swing'} : {}),
                            ...(
                                !door.isBifold && Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs) !== 'Utility'
                                    ? {pocket: 'Pocket'} : {}
                            ),
                            ...(
                                !door.isBifold && Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs) !== 'Utility'
                                ? {barn: 'Barn'} : {}
                            ),
                            ...(door.isDouble && !door.isBifold ? {bypass: 'Bipass'} : {}),
                            ...(
                                door.isBifold && Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs) !== 'Utility'
                                ? {bifold: 'Bifold'} : {}
                            ),
                        }}
                        value={door.doorFunction}
                        handleChange={(event, value) => {
                            context.setMeasureDoorAttr('doorFunction', value || '')
                            if (value === 'swing' && !door.hingesPerDoor) context.setMeasureDoorAttr('hingesPerDoor', getHingeCount(context.state.specialSpecs.door, context.state.specialSpecs.hardware.hinge));
                        }}
                    />
                </Grid>
                {
                    door.doorFunction === 'swing' &&
                    door.isDouble &&
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom={false}>
                            Latching
                        </Typography>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                flushbolt: 'Flushbolt',
                                catches: 'Catch',
                            }}
                            value={door.latching}
                            handleChange={ (event, value) => context.setMeasureDoorAttr('latching', value || '')}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    door.latching &&
                    door.isDouble &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            category={'hardware'}
                            type={door.latching}
                            attributes={attributes(door.latching)}
                            handleChange={handleSpecChange(door.latching)}
                            specs={context.state.specialSpecs.hardware[door.latching]}
                            globalSpecs={context.state.specs.hardware[door.latching]}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    door.latching &&
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                }
                {
                    ['swing', 'bifold'].includes(door.doorFunction) &&
                    !door.isDouble &&
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom={false}>
                            Swing Direction
                        </Typography>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                left: 'Left',
                                right: 'Right',
                            }}
                            value={door.handleDirection}
                            handleChange={(event, value) => context.setMeasureDoorAttr('handleDirection', value || '')}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    door.isDouble &&
                    door.latching &&
                    door.latching !== 'catches' &&
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom={false}>
                            Operating Door
                        </Typography>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                left: 'Left',
                                right: 'Right',
                            }}
                            value={door.operating}
                            handleChange={(event, value) => context.setMeasureDoorAttr('operating', value || '')}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    (
                        (
                            !door.isDouble &&
                            door.handleDirection
                        ) ||
                        (
                            door.isDouble &&
                            door.operating
                        )
                    ) &&
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={door.isReverse}
                                    onChange={(event) => context.setMeasureDoorAttr('isReverse', event.target.checked)}
                                />
                            }
                            label="Reverse Hardware"
                        />

                    </Grid>
                }
                {
                    ['swing', 'bifold'].includes(door.doorFunction) &&
                    (
                        (
                            !door.isDouble &&
                            'handleDirection' in door &&
                            door.handleDirection
                        ) ||
                        (
                            door.doorFunction === 'bifold' &&
                            door.isDouble
                        ) ||
                        (
                            door.isDouble &&
                            'latching' in door &&
                            door.latching &&
                            (
                                door.latching === 'catches' ||
                                (
                                    door.latching === 'flushbolt' &&
                                    'operating' in door &&
                                    door.operating
                                )
                            )
                        )
                    ) &&
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom={false}>
                            Handle Type
                        </Typography>
                        <CustomToggleGroup
                            disabled={disabled}
                            items={{
                                ...(door.isBifold && {bifold_knob: 'Bifold Knob'}),
                                knob: 'Knob',
                                lever: 'Lever'
                            }}
                            value={door.handleType}
                            handleChange={(event, value) => context.setMeasureDoorAttr('handleType', value || '')}
                        />
                    </Grid>
                }
                {
                    !door.isDouble &&
                    ['swing', 'bifold'].includes(door.doorFunction) &&
                    (
                        (
                            door.doorFunction === 'bifold' &&
                            'handleDirection' in door &&
                            door.handleDirection
                        )
                        ||
                        (
                            !door.isDouble &&
                            'handleDirection' in door &&
                            door.handleDirection
                        )
                    ) &&
                    door.handleType &&
                    Lib.hasValue(door.handleType) &&
                    door.handleType !== 'bifold_knob' &&
                    !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware[door.handleType]) &&
                    <Grid item xs={12}>
                        <DoorHandleRadio
                            context={context}
                            target={'handleMode'}
                            type={door.handleType}
                            direction={( (door.handleDirection === 'right' && !door.isReverse) || (door.handleDirection === 'left' && door.isReverse) ) ? 'RH' : 'LH' }
                            field={'mode'}
                            rel={'specialSpecs'}
                            special={door.doorFunction === 'bifold' ? 'Dummy' : false}
                            category={'Hardware'}
                            group={'hardware'}
                            title={'Hardware Function'}
                            allowedValues={
                                door.doorFunction === 'swing' ? ['Dummy', 'Passage', 'Privacy', 'Entry', 'Electronic', 'Touchscreen'] : [{1: 'Dummy'}]
                            }
                        />
                    </Grid>
                }
                {
                    door.isDouble &&
                    ((
                            door.doorFunction === 'swing' &&
                            'latching' in door &&
                            door.latching &&
                            'operating' in door &&
                            (
                                (
                                    door.operating &&
                                    door.latching !== 'catches'
                                ) ||
                                door.latching === 'catches'
                            )
                        ) ||
                        door.doorFunction === 'bifold'
                    )
                    &&
                    'handleType' in door &&
                    door.handleType &&
                    door.handleType !== 'bifold_knob' &&
                    !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware[door.handleType]) &&
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                {
                                    door.latching === 'catches' ||
                                    door.isBifold ||
                                    door.operating === 'right' ?
                                        <NonOperatingDoor context={context} handleSpecChange={handleSpecChange} classes={classes} direction={'left'} /> :
                                        door.operating === 'left' &&
                                        <OperatingDoor context={context} handleSpecChange={handleSpecChange} classes={classes} direction={'left'} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {
                                    door.latching === 'catches' ||
                                    door.isBifold ||
                                    door.operating === 'left'  ?
                                        <NonOperatingDoor context={context} handleSpecChange={handleSpecChange} classes={classes} direction={'right'} /> :
                                        door.operating === 'right' &&
                                        <OperatingDoor context={context} handleSpecChange={handleSpecChange} classes={classes} direction={'right'} />

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    ['swing', 'bifold'].includes(door.doorFunction) &&
                    door.handleType &&
                    (
                        (
                            !door.isDouble &&
                            'handleDirection' in door &&
                            door.handleDirection &&
                            door.handleType
                        ) ||
                        (
                            door.doorFunction === 'bifold' &&
                            door.isDouble &&
                            door.handleType
                        ) ||
                        (
                            door.isDouble &&
                            'latching' in door &&
                            door.latching &&
                            'operating' in door &&
                            (
                                (
                                    door.operating &&
                                    door.latching !== 'catches'
                                ) ||
                                door.latching === 'catches'
                            ) &&
                            door.handleType
                        )
                    ) &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            category={'hardware'}
                            type={door.handleType}
                            attributes={attributes(door.handleType)}
                            handleChange={handleSpecChange(door.handleType)}
                            specs={context.state.specialSpecs.hardware[door.handleType]}
                            globalSpecs={context.state.specs.hardware[door.handleType]}
                        />
                    </Grid>
                }
                {
                    ['swing', 'bifold'].includes(door.doorFunction) &&
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    !door.isDouble &&
                    !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.doorstopper) &&
                    <Grid item xs={12}>
                        <AttributeSelect
                            hideDelete
                            disabled={disabled}
                            field={"mode"}
                            category={"hardware"}
                            type={"doorstopper"}
                            title={"Doorstopper Mode"}
                            value={door.doorstopper_mode}
                            handleChange={(event, value) => context.setMeasureDoorAttr('doorstopper_mode', event ? event.target.value : value ? value : '')}
                            refSpec={context.state.specialSpecs.hardware.doorstopper}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    door.isDouble &&
                    !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.doorstopper) &&
                    <Grid item sm={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Left Doorstopper
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AttributeSelect
                                    hideDelete
                                    disabled={disabled}
                                    field={"mode"}
                                    category={"hardware"}
                                    type={"doorstopper"}
                                    title={"Mode"}
                                    value={door.doorstopper_mode_left}
                                    handleChange={(event, value) => context.setMeasureDoorAttr('doorstopper_mode_left', event ? event.target.value : value ? value : '')}
                                    refSpec={context.state.specialSpecs.hardware.doorstopper}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    door.isDouble &&
                    !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.doorstopper) &&
                    <Grid item sm={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    Right Doorstopper
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AttributeSelect
                                    hideDelete
                                    disabled={disabled}
                                    field={"mode"}
                                    category={"hardware"}
                                    type={"doorstopper"}
                                    title={"Mode"}
                                    value={door.doorstopper_mode_right}
                                    handleChange={(event, value) => context.setMeasureDoorAttr('doorstopper_mode_right', event ? event.target.value : value ? value : '')}
                                    refSpec={context.state.specialSpecs.hardware.doorstopper}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            category={'hardware'}
                            type={'doorstopper'}
                            attributes={attributes('doorstopper')}
                            handleChange={handleSpecChange(`doorstopper`)}
                            specs={context.state.specialSpecs.hardware.doorstopper}
                            globalSpecs={context.state.specs.hardware.doorstopper}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'swing' &&
                    // !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.doorstopper) &&
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                }
                {
                    Lib.deepAttributeExists(['door', 'type'], context.state.specialSpecs) !== 'Utility' &&
                    door.doorFunction === 'swing' &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom={false}>Hinge</Typography>
                        </Grid>
                        {
                            !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.hinge) &&
                            <Grid item xs={12}>
                                <CustomToggleGroup
                                    disabled={disabled}
                                    items={hingeCountObj}
                                    value={door.hingesPerDoor}
                                    handleChange={(event, value) => context.setMeasureDoorAttr('hingesPerDoor', value || '')}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <SpecsType
                                disabled={disabled}
                                category={'hardware'}
                                type={'hinge'}
                                attributes={attributes('hinge')}
                                handleChange={handleSpecChange('hinge')}
                                specs={context.state.specialSpecs.hardware.hinge}
                                globalSpecs={context.state.specs.hardware.hinge}
                            />
                        </Grid>
                        <CustomHinge
                            disabled={disabled}
                            customLockset={door.customLockset}
                            customHinges={door.customHinges}
                            hasCustomHinge={door.hasCustomHinge || false}
                            hasCustomLockset={door.hasCustomLockset || false}
                            setMeasureDoorAttr={context.setMeasureDoorAttr}
                            hingeCount={door.hingesPerDoor}
                        />
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </React.Fragment>
                }
                {
                    door.doorFunction === 'barn' &&
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'subtitle1'}>Barn Door Track</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SpecsType
                                    disabled={disabled}
                                    category={'hardware'}
                                    type={'barn_door_track'}
                                    attributes={attributes('barn_door_track')}
                                    handleChange={handleSpecChange('barn_door_track')}
                                    specs={context.state.specialSpecs.hardware.barn_door_track}
                                    globalSpecs={context.state.specs.hardware.barn_door_track}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    door.doorFunction === 'barn' &&
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                }
                {
                    door.doorFunction === 'pocket' &&
                    !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.pocket) &&
                    <Grid item xs={12}>
                        <AttributeSelect
                            hideDelete
                            disabled={disabled}
                            field={"mode"}
                            category={"hardware"}
                            type={"pocket"}
                            title={"Mode"}
                            value={door.pocket_mode}
                            selectionControl={"radio"}
                            handleChange={(event, value) => {
                                context.setMeasureDoorAttr('pocket_mode',  value || '')
                            }}
                            refSpec={{
                                ...context.state.specialSpecs.hardware.pocket,
                                mode: door.pocket_mode
                            }}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'pocket' &&
                    <Grid item xs={12}>
                        <SpecsType
                            disabled={disabled}
                            category={'hardware'}
                            type={'pocket'}
                            attributes={attributes('pocket')}
                            handleChange={handleSpecChange('pocket')}
                            specs={context.state.specialSpecs.hardware.pocket}
                            globalSpecs={context.state.specs.hardware.pocket}
                        />
                    </Grid>
                }
                {
                    door.doorFunction === 'pocket' &&
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                }
                {
                    door.doorFunction === 'bypass' &&
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'subtitle1'}>Bipass Door Track</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SpecsType
                                    disabled={disabled}
                                    category={'hardware'}
                                    type={'bipass_door_track'}
                                    attributes={attributes('bipass_door_track')}
                                    handleChange={handleSpecChange('bipass_door_track')}
                                    specs={context.state.specialSpecs.hardware.bipass_door_track}
                                    globalSpecs={context.state.specs.hardware.bipass_door_track}
                                />
                            </Grid>
                            {
                                door.doorFunction === 'bypass' &&
                                !Lib.isEmptyOrNullObject(context.state.specialSpecs.hardware.bipass_door_track) &&
                                <Grid item xs={12} sm={6}>
                                    <AttributeSelect
                                        hideDelete
                                        disabled={disabled}
                                        field={"tracklength"}
                                        category={"hardware"}
                                        selectionControl={"radio"}
                                        type={"bipass_door_track"}
                                        title={"Track Length"}
                                        value={door.bipassTrackLength}
                                        handleChange={(event, value) => context.setMeasureDoorAttr('bipassTrackLength', value || '')}
                                        refSpec={context.state.specialSpecs.hardware.bipass_door_track}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                }
                
                {
                    door.doorFunction === 'bypass' &&
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={'subtitle1'}>Finger Pull</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SpecsType
                                    disabled={disabled}
                                    category={'hardware'}
                                    type={'finger_pull'}
                                    attributes={attributes('finger_pull')}
                                    handleChange={handleSpecChange('finger_pull')}
                                    specs={context.state.specialSpecs.hardware.finger_pull}
                                    globalSpecs={context.state.specs.hardware.finger_pull}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Paper>
    )
}

export default NewDoorHardware