import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import AttributeSelect from '../AttributeSelect';
import * as Lib from "../lib/lib";
import HingeSpecs from "./HingeSpecs";

const useStyles = makeStyles((theme) => ({
    root:{

    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginBottom: 0
    },
    group: {
        flexDirection: "row"
    },
    button: {
        float: 'right',
        marginTop: theme.spacing(1),
    }
}));

const SpecialSpecs = (props) => {
    const classes = useStyles();
    const { disabled, specs, category, type, attributes, handleChange, extraAttributes } = props;
    const handleFieldChange = (attribute, extra) => (event, value = '') => {
        // console.log(attribute, extra, value)
        if (extra) {
            handleChange(attribute, {
                ...specs[attribute],
                [extra]: value
            });
        } else {
            handleChange(attribute, event ? event.target.value : value);
        }
    };

    const title = (attribute) => {
        if (category === 'door') {
            // if (attribute === 'core') return "Core/Panel Type"
            if (attribute === 'frame') return "Frame/Panel Profile"
        } else {
            return Lib.humanize(attribute)
        }
    }

    return (
        <div className={classes.root}>
            {
                attributes
                .map((attribute, i) => (
                    <AttributeSelect
                        key={i}
                        disabled={disabled}
                        field={attribute}
                        category={category}
                        type={type}
                        title={title(attribute)}
                        handleChange={handleFieldChange(attribute)}
                        refSpec={specs}
                        value={specs[attribute]}
                        extraAttributes={extraAttributes}
                    />
                ))
            }
            {
                ['Hinge', 'hinge'].includes(type) &&
                <HingeSpecs
                    disabled={disabled}
                    handleFieldChange={handleFieldChange}
                    values={specs.extra_specs || {}}
                />
            }
        </div>
    )
}

export default SpecialSpecs