import React, {useEffect} from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import {usePrevious} from './hooks'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 56,
        padding: `${theme.spacing(1)}px 0px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    toggleButtonGroup: {
        border: '1px solid #999',
        borderRadius: '4px',
        boxShadow: 'none',
        overflow: 'auto',
        whiteSpace: 'nowrap'
    }
}));

const CustomToggleGroup = (props) => {
    const {items, value, handleChange, disableAutoSelect = false, disabled = false, disableItem = [], showTooltip} = props;
    const classes = useStyles();
    // console.log(items, value, classes, handleChange, disableAutoSelect, disabled )
    const prevValue = usePrevious(value);
    const hasOneValue = (items && Array.isArray(items) && items.length === 1) || (items && Object.keys(items).length === 1);
    const emptyValue = !value;
    useEffect(() => {
        if ( !disabled && !disableAutoSelect && !prevValue && items && Array.isArray(items) && items.length === 1) {
            handleChange(null, Object.keys(items[0])[0])
        } else if ( !disableAutoSelect && !prevValue && items && Object.keys(items).length === 1) {
            handleChange(null, Object.keys(items)[0])
        }
    }, [hasOneValue, emptyValue]);
    return (
        <div className={classes.root}>
            <ToggleButtonGroup
                id="toggle-button-group-1"
                value={value}
                onChange={handleChange}
                exclusive
                className={classes.toggleButtonGroup}
            >
                {
                    Array.isArray(items) ?
                    items.map( (item, i) => (
                        <ToggleButton
                            disabled={disabled}
                            key={i}
                            className={Object.keys(item)[0] === value ? `${disabled ? 'disabled' : 'selected'}-toggle-button` : ""}
                            value={Object.keys(item)[0]}
                        >
                            <Typography variant={'body2'}>{item[Object.keys(item)[0]]}</Typography>
                        </ToggleButton>
                    ))
                    :
                    Object.keys(items).map( (key, i) => {
                        return (
                            showTooltip ?
                            <Tooltip key={i} title={items[key].props[showTooltip]} aria-label="Add">
                                <ToggleButton
                                    disabled={disabled}
                                    className={key === value ? `${disabled || disableItem?.includes(key) ? 'disabled' : 'selected'}-toggle-button` : ""}
                                    value={key}
                                >
                                    <Typography variant={'body2'}>{items[key]}</Typography>
                                </ToggleButton>
                            </Tooltip> :
                            <ToggleButton
                                disabled={disabled || disableItem?.includes(key)}
                                key={i}
                                className={key === value ? `${disabled || disableItem?.includes(key) ? 'disabled' : 'selected'}-toggle-button` : ""}
                                value={key}
                            >
                                <Typography variant={'body2'}>{items[key]}</Typography>
                            </ToggleButton>
                        )
                    })
                }
            </ToggleButtonGroup>
        </div>
    )
}

export default CustomToggleGroup