import React, {useState} from 'react';
import { Redirect } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { ApolloConsumer } from '@apollo/client';
import logo from '../../images/logo.png'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FilterIcon from '@material-ui/icons/Search';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    root: {},
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 12,
    },
    menuHeader:{
        width: 250,
        padding: 24,
        backgroundColor: theme.palette.primary.main,

    },
    typeWhite:{
        color: "#fff",
    },
    sideMenu: {
        width: 250,
    },
    list: {
        padding: 0,
    },
    icon: {
        color: 'white',
        size: 50
    },
    logo:{
        width: "100%",
    },
}));

const TopBar = React.memo((props) => {
    const { title, showFilter } = props;
    const classes = useStyles();
    const [left, setLeft] = useState(false)
    const [redirect, setRedirect] = useState('')

    const checkAndRedirect = (page) => {
        if ( page !==  window.location.pathname) setRedirect(<Redirect to={page} />)
    }
    const toggleDrawer = (side, open) => () => {
        setLeft(open)
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const sideList = (
        <ApolloConsumer>
            {
                apolloClient => {
                    return (
                        <div className={classes.sideMenu}>
                            <div className={classes.menuHeader}>
                                <Grid alignItems='center' container spacing={1}>
                                    <Grid item xs={12}>
                                        <img alt='logo' src={logo} className={classes.logo}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom={false} className={classes.typeWhite}>
                                            Signed in as {localStorage.getItem('username')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <List className={classes.list}>
                                <ListItem
                                    button
                                    className={classes.listItemTakeoffs}
                                    onClick={() => checkAndRedirect("/")}
                                >
                                    <ListItemIcon>
                                        <Icon>assignment</Icon>
                                    </ListItemIcon>
                                    <ListItemText>Takeoffs</ListItemText>
                                </ListItem>
                                <Divider/>
                                {
                                    localStorage.getItem('role') === 'admin' &&
                                    <>
                                        <ListItem button
                                            className={classes.listItemTakeoffs}
                                            onClick={() => checkAndRedirect("/import")}
                                        >
                                            <ListItemIcon>
                                                <Icon>import_export</Icon>
                                            </ListItemIcon>
                                            <ListItemText>Import Data</ListItemText>
                                            <Divider/>
                                        </ListItem>
                                        <Divider/>
                                    </>
                                }
                                {
                                    localStorage.getItem('role') === 'admin' &&
                                    <>
                                        <ListItem button
                                            className={classes.listItemTakeoffs}
                                            onClick={() => checkAndRedirect("/export")}
                                        >
                                            <ListItemIcon>
                                                <Icon>import_export</Icon>
                                            </ListItemIcon>
                                            <ListItemText>Export Data</ListItemText>
                                            <Divider/>
                                        </ListItem>
                                        <Divider/>
                                    </>
                                }
                                <ListItem button
                                          className={classes.listItemTakeoffs}
                                          onClick={() => checkAndRedirect("/profile")}
                                >
                                    <ListItemIcon>
                                        <Icon>person</Icon>
                                    </ListItemIcon>
                                    <ListItemText>Profile</ListItemText>
                                </ListItem>
                                <Divider/>
                                <ListItem button
                                          className={classes.listItemTakeoffs}
                                          onClick={() => window.open("https://riversidemillwork.zendesk.com/hc/en-us/restricted", "_blank")}
                                >
                                    <ListItemIcon>
                                        <Icon>support</Icon>
                                    </ListItemIcon>
                                    <ListItemText>Guides & Support</ListItemText>
                                </ListItem>
                                <Divider/>
                                {
                                    localStorage.getItem('role') === 'admin' &&
                                    <ListItem
                                        button
                                        className={classes.listItemTakeoffs}
                                        onClick={() => checkAndRedirect("/users")}
                                    >
                                        <ListItemIcon>
                                            <Icon>people</Icon>
                                        </ListItemIcon>
                                        <ListItemText>Users</ListItemText>
                                    </ListItem>
                                }
                                {
                                    localStorage.getItem('role') === 'admin' &&
                                    <Divider/>
                                }
                                <ListItem
                                    button
                                    className={classes.listItemTakeoffs}
                                    onClick={() => {
                                        apolloClient.resetStore()
                                        localStorage.removeItem('token');
                                        localStorage.removeItem('tokenedAt');
                                        localStorage.removeItem('username');
                                        localStorage.removeItem('name');
                                        localStorage.removeItem('role');
                                        localStorage.removeItem('expiresAt');
                                        localStorage.removeItem('filter');
                                        setRedirect(<Redirect to='/' />)
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon>person_outline</Icon>
                                    </ListItemIcon>
                                    <ListItemText>Sign out</ListItemText>
                                </ListItem>
                                <Divider/>
                                {redirect}
                            </List>
                        </div>
                    )
                }
            }
        </ApolloConsumer>
    );


    return (
        <div className={classes.root}>
            <AppBar position="fixed" color="primary" elevation={0}>
                <Toolbar>
                    { props.isLoggedIn ?
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Back"
                            onClick={toggleDrawer('left', true)}
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                        :
                        ''}

                    <Typography variant="h6" color="inherit" className={classes.flex}>
                        {title}
                    </Typography>
                    {
                        title === 'Takeoffs' &&
                        <IconButton
                            aria-label="Toggle password visibility"
                            onClick={() => showFilter()}
                            onMouseDown={handleMouseDownPassword}
                        >
                            <FilterIcon className={classes.icon} style={{fontSize: 24}} />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={left}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                <div
                    tabIndex={0}
                    role="button"
                    onClick={toggleDrawer('left', false)}
                    onKeyDown={toggleDrawer('left', false)}
                >
                    { props.isLoggedIn ? sideList : ''}
                </div>
            </SwipeableDrawer>
        </div>
    )
})

export default TopBar