import React, {useEffect, useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Paper, Button, Link, LinearProgress} from '@material-ui/core';
import { Helmet } from 'react-helmet-async'
import TopBar from './common/TopBar';
import { NODE_ENV, REACT_APP_API_HOST } from './envs';
import axios from 'axios';
import moment from 'moment'

const useStyles = makeStyles(() => ({
    root:{
        maxWidth : '100%',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '80px'
    },
    container:{
        maxWidth : 1080,
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        margin: 'auto',
        marginBottom: '80px',
        marginTop: '16px',
    },
    paper: {
        padding: "16px"
    }
}));

const apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
let config = {
    headers: {'Authorization': "bearer " + localStorage.getItem('token')},
    timeout: 4000
};

const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}

const ExportData = () => {
    const [data, setData] = React.useState([]);
    const [triggered, setTriggered] = React.useState(false);
    const classes = useStyles();

    const handleDownload = async (type) => {
        setTriggered(true);
        triggerExport(type);
    }

    const fetchFiles = async () => {
        axios.post(apiBaseUrl+'get-exported-data', null, config)
            .then((res) => {
                if (res) {
                    if ( JSON.stringify(data) !== JSON.stringify(res.data.result) ) { 
                        setData(res.data.result);
                        setTriggered(false); 
                    }
                }
            })
            .catch(function (err) {
                console.log('Error',err.code);
                return false
            });
    }
    useEffect(() => {
        fetchFiles();
    }, [])  

    useInterval(() => {
        fetchFiles();
    }, 10000);

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Riverside Takeoffs :: Export data</title>
            </Helmet>
            <TopBar isLoggedIn={true} title="Export Data"/>
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant="h6">Customers</Typography></Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    className={classes.margin}
                                    onClick={() => handleDownload('customers')}
                                >
                                    Export Customers DB
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant="h6">Installs</Typography></Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    className={classes.margin}
                                    onClick={() => handleDownload('installs')}
                                >
                                    Export Installs DB
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant="h6">Products</Typography></Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    className={classes.margin}
                                    onClick={() => handleDownload('products')}
                                >
                                    Export Products DB
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {
                    (triggered || (data && data.length > 0)) &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}><Typography variant="h5">Files</Typography></Grid>
                                {
                                    triggered &&
                                    <Grid item xs={12}>
                                        <LinearProgress /> 
                                    </Grid>
                                }
                                {
                                    data
                                    .map((item) => (
                                        <Grid item xs={12} key={item.name}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Link href={item.url} target="_blank">{item.name}</Link>
                                                </Grid>
                                                <Grid item xs={6}><Typography variant="caption">Exported {moment(item.lastModified).fromNow()}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                }
            </Grid>
        </div>
    );
}

const triggerExport = (type) => {
    const apiBaseUrl = NODE_ENV === "production" ? "/" : REACT_APP_API_HOST;
    let config = {
        headers: {'Authorization': "bearer " + localStorage.getItem('token')},
        timeout: 4000
    };
    let data = {type};
    return axios.post(apiBaseUrl+'export-type', data, config)
        .then((res) => {
            // start polling
            console.log({res})
        })
        .catch(function (err) {
            console.log('Error',err.code)
            return false
        });
}

export default ExportData;