import React from "react";
import { Grid, Typography } from "@material-ui/core";

const NotAuthorized = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    You're not authorized to open this page
                </Typography>
            </Grid>
        </Grid>
    )
}

export default NotAuthorized;