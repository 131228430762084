import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { gql, useQuery } from '@apollo/client';
import CustomToggleGroup from '../../common/CustomToggleGroup'
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    group:{
        flexDirection: 'row',
    }
}));

const GROUP_QUERY = gql`
    query productGroups($field: String!, $condition: Condition) {
        productGroups(field: $field,condition: $condition) {
            _id
        }
    }
`;

const DoorHandleRadio = (props) => {
    const { type, field, target, rel, category, group, title, context, allowedValues, special = false, direction = ''} = props
    const classes = useStyles();
    let spec = group === 'doors' ? context.state[rel][group] : context.state[rel][group][type]
    const { loading, error, data} = useQuery(GROUP_QUERY, {
        variables: {
            field,
            condition: {
                category,
                type:       context.humanize(type),
                brand:      field === 'brand' ? '' : spec?.brand || '' ,
                style:      field === 'style' ? '' : spec?.style || '' ,
                finish:     field === 'finish' ? '' : spec?.finish || '' ,
                direction:  type === 'knob' || direction === '' ? '' : direction ,
                ...(type === 'lever' && {rosette: spec?.rosette || ''}) ,
            }
        }
    })
    // console.log(target);
    if (error) return `Error! ${error.message}`;
    if (loading) return <CircularProgress />;
    let values = [];
    const items = {};
    // if ( data.productGroups ) console.log(special, data.productGroups)
    if ( data?.productGroups && !special && allowedValues && allowedValues.length > 0) {
        values = data.productGroups
    }
    else if ( data?.productGroups && special === 'Dummy' && data.productGroups.filter(e => e._id === 'Dummy').length > 0) {
        values = allowedValues
    }
    values
    .filter(item => {
        return (
            (
                allowedValues !== 'Dummy' &&
                ['swing', 'bifold'].includes(context.state.measures.door.doorFunction) &&
                allowedValues.includes(item._id)
            ) ||
            (
                special === 'Dummy' &&
                ['swing', 'bifold'].includes(context.state.measures.door.doorFunction)
            )
        )
    })
    .forEach((item) => {
        if (special === 'Dummy')
            items[Object.keys(item)[0]] = Object.values(item)[0]
        else
            items[item._id] = item._id
    })
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom={false}>
                    {title}
                </Typography>
                <div className={classes.toggleContainer}>
                    {
                        Object.keys(items).length < 1 ?
                            <Typography variant={'body1'}>No available hardware!</Typography> :
                            <CustomToggleGroup
                                disabled={context.isDoneTakeoff()}
                                items={items}
                                value={context.state.measures.door[target]}
                                handleChange={ (event, value) => context.setMeasureDoorAttr(target, value || '')}
                            />
                    }
                </div>
            </Grid>
        </Grid>
    )
}

export default DoorHandleRadio