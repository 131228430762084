import React from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

const ProductState = ({code, cpu}) => (
  code && cpu?.priceChanged?.includes(code) ?
    <ImportExportIcon fontSize="small" /> :
    code && cpu?.discontinued?.includes(code) ?
      <DeleteForeverOutlinedIcon fontSize="small" />  :
      ""
)

export default ProductState;